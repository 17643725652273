import './MobileFeatureNav.scss'

import { RouteConstants } from '../../Constants/routeConstants'
import FeaturesNav from '../FeaturesNav/FeaturesNav'

function MobileFeatureNav() {
  const isDetailPage: any = Object.values(RouteConstants).indexOf(window.location.pathname as RouteConstants)

  return (
    <div
      className={
        isDetailPage === -1 ? 'MobileFeatureNav' : 'MobileFeatureNav d-none .d-md-block .d-lg-block .d-xl-none'
      }
    >
      <FeaturesNav />
    </div>
  )
}

export default MobileFeatureNav
