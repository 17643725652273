import { createSlice } from '@reduxjs/toolkit'

import { socket } from '../../AppConfig/socket'

const initialState: any = {
  data: null,
  totalCount: 0,
  skeleton: true,
}

export const messageBoardSlice: any = createSlice({
  name: 'messageboard',
  initialState,
  reducers: {
    loadMsgBoardMessages: (state, action) => {
      state.data = action.payload.data
      state.totalCount = action.payload.data.totalCount
    },
    setSkeleton: (state, action) => {
      state.skeleton = action.payload
    },
    appendNewMessage: (state, action) => {
      if (state.data._id.toString() === action.payload.messageBoard.toString()) {
        state.data = { ...state.data, messages: [...state.data.messages, { ...action.payload }] }
        state.totalCount++
      }
      socket.emit('messageBoardRefresh', {
        messageBoard: state.data._id,
      })
    },
    appendNewReply: (state, action) => {
      if (state.data._id.toString() === action.payload.messageBoard.toString()) {
        const messages = state.data.messages
        const appendedReplyMessages = messages.map((m: any) => {
          if (m._id === action.payload.message) {
            if (Array.isArray(m.replylist)) {
              return { ...m, replylist: [...m.replylist, { ...action.payload }] }
            } else {
              return { ...m, replylist: [{ ...action.payload }] }
            }
          } else {
            return m
          }
        })
        state.data = { ...state.data, messages: appendedReplyMessages }
        socket.emit('messageBoardRefresh', {
          messageBoard: state.data._id,
        })
      }
    },
    appendNewReaction: (state, action) => {
      const messages = state.data.messages
      const appendedReaction = messages.map((m: any) => {
        if (Array.isArray(m.reactions)) {
          if (m._id === action.payload.message) {
            return { ...m, reactions: [...m.reactions, { ...action.payload }] }
          }
          return m
        }
        return undefined
      })
      state.data = { ...state.data, messages: appendedReaction }
      socket.emit('messageBoardRefresh', {
        messageBoard: state.data._id,
      })
    },
    appendNewReplyReaction: (state, action) => {
      const messages = state.data.messages
      let newMessageReply: any
      for (let i = 0; i < messages.length; i++) {
        for (let j = 0; j < messages[i].replylist.length; j++) {
          if (messages[i].replylist[j]._id === action.payload.messageReply) {
            const reply = { ...messages[i].replylist[j] }
            let replyReact: any[] = []

            if (reply.reactions?.length > 0) {
              replyReact = [...reply.reactions]
            }
            const concatObj = { ...reply, reactions: [...replyReact, { ...action.payload }] }
            const replyMerge = messages[i].replylist.map((r: any) => {
              if (r._id === concatObj._id) {
                return { ...r, reactions: concatObj.reactions }
              } else {
                return r
              }
            })
            newMessageReply = { ...messages[i], replylist: replyMerge }
          }
        }
      }

      const messagesMerge = messages.map((m: any, i: any) => {
        const nm = newMessageReply._id
        if (m._id === nm) {
          return newMessageReply
        } else {
          return m
        }
      })
      state.data = { ...state.data, messages: messagesMerge }
      socket.emit('messageBoardRefresh', {
        messageBoard: state.data._id,
      })
    },
  },
})

export const {
  loadMsgBoardMessages,
  setSkeleton,
  appendNewMessage,
  appendNewReply,
  appendNewReaction,
  appendNewReplyReaction,
} = messageBoardSlice.actions
export default messageBoardSlice.reducer
