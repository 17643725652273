import './ReactionListPopup.scss'

import cn from 'classnames'
import { Emoji } from 'emoji-picker-react'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useDispatch } from 'react-redux'

import AxiosClass from '../../../Api/Interceptor'
import PostApi from '../../../Api/PostAPI'
import { socket } from '../../../AppConfig/socket'
import { useAppSelector } from '../../../Hooks'
import { loading } from '../../../Redux/slices/userSlice'
import { deleteCommentReactionThunk, deleteCommentReplyReactionThunk } from '../../../Redux/thunks/postThunk'
import Loader from '../../Loader/Loader'

function ReactionListPopup(props: any) {
  const dispatch = useDispatch()
  const { show, handleClose, activeReaction, activePost, type } = props
  const userData = useAppSelector((state: any) => state?.user)

  const removeReaction = (reactions: any) => {
    const reactId = reactions?._id
    if (type === 'comment-reply') {
      const postId = activePost.activeIndex !== null ? activePost.medias[activePost.activeIndex]?._id : activePost?._id
      const type = activePost.activeIndex !== null ? 'media' : 'post'
      if (reactions.reply) {
        dispatch(deleteCommentReplyReactionThunk(reactId, postId, type))
      } else {
        dispatch(deleteCommentReactionThunk(reactId, postId, type))
      }
    } else if (type === 'message') {
      new PostApi(AxiosClass)
        .removeReaction(reactId)
        .then((r: any) => {
          if (r) {
            socket.emit('messageBoardRefresh', {
              messageBoard: props.messageBoard,
            })
          }
          if (r.success) {
            dispatch(loading(false))
          } else {
            throw r
          }
        })
        .catch((e: any) => {
          dispatch(loading(false))
        })
      handleClose()
    } else if (type === 'fundraiser') {
      const postId = activePost?._id
      const type = 'fundraiser'
      if (reactions.reply) {
        dispatch(deleteCommentReplyReactionThunk(reactId, postId, type))
      } else {
        dispatch(deleteCommentReactionThunk(reactId, postId, type))
      }
    }
  }
  const reactionWrrperComponent = (reactions: any) => {
    return (
      <div
        onClick={() => {
          removeReaction(reactions)
        }}
        className={cn('ReactorWrpper', {
          ownReaction: reactions?.createdBy?._id === userData?.value?.data?._id,
        })}
      >
        <div className="ReactorImg">
          <div className="ImgsHolder">
            <div className="ownerPic">
              {reactions?.createdBy?.image ? (
                <img src={reactions?.createdBy?.image} className="message-pp" alt="" />
              ) : (
                <div className="ownerAlpha">
                  <span>{reactions?.createdBy?.name?.charAt(0)}</span>
                </div>
              )}
            </div>
          </div>
          <div className="Reactions">
            <Emoji unified={reactions?.name} size={14} />
          </div>
        </div>
        <div className="ReactorName">
          {reactions?.createdBy?.name}
          {reactions?.createdBy?._id === userData?.value?.data?._id && (
            <span className="remove"> click here to remove</span>
          )}
        </div>
      </div>
    )
  }

  return (
    <Modal show={show} onHide={handleClose} centered className="ReactionPopup">
      {userData.loading && <Loader />}
      <Modal.Header closeButton>
        <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="mb-3">
          <Tab
            eventKey="all"
            title={
              <React.Fragment>
                All <span className="reaction-count">{activeReaction?.allReaction?.length}</span>
              </React.Fragment>
            }
          >
            {activeReaction?.allReaction?.map((reactions: any, key: number) => {
              return <React.Fragment key={key}>{reactionWrrperComponent(reactions)}</React.Fragment>
            })}
          </Tab>
          {Object.keys(activeReaction?.reactionGroup).map((reactionKey: any, index: any) => {
            return (
              <Tab
                eventKey={reactionKey}
                key={index}
                title={
                  <React.Fragment>
                    <Emoji unified={reactionKey} size={18} />{' '}
                    <span className="reaction-count">{activeReaction?.reactionGroup[reactionKey]?.length}</span>
                  </React.Fragment>
                }
              >
                {activeReaction?.reactionGroup[reactionKey]?.map((reactions: any, key: number) => {
                  return <React.Fragment key={key}>{reactionWrrperComponent(reactions)}</React.Fragment>
                })}
              </Tab>
            )
          })}
        </Tabs>
      </Modal.Header>
    </Modal>
  )
}

export default ReactionListPopup
