import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  notificationList: {},
  takeNotificationActionResp: {},
  getNotificationCountResp: {},
  updateStatusOfNotificationResp: {},
}

export const NotificationSlice: any = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    loadNotification: (state, action: any) => {
      state.notificationList = action.payload
    },
    takeNotificationAction: (state, action: any) => {
      state.takeNotificationActionResp = action.payload
    },
    getNotificationCountResponse: (state, action: any) => {
      state.getNotificationCountResp = action.payload
    },
    updateStatusOfNotificationResponse: (state, action: any) => {
      state.updateStatusOfNotificationResp = action.payload
    },
  },
})

export const {
  loadNotification,
  takeNotificationAction,
  getNotificationCountResponse,
  updateStatusOfNotificationResponse,
} = NotificationSlice.actions
export default NotificationSlice.reducer
