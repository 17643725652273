import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'

import left from './assets/left.png'
import Right from './assets/right.png'
import Slide from './Slide'

const Wrapper = styled.div`
  position: relative;
  max-width: 550px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  height: 320px;
`

const NavigationButtons = styled.div`
  position: absolute;
  top: 50%;
  margin-top: 0;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 660px;
  height: auto;
  display: flex;
  justify-content: space-between;
`

const QuestionCount = styled.div`
  position: absolute;
  top: 74.5%;
  margin-top: 0;
  transform: translate(-50%, -74.5%);
  left: 50%;
  width: 660px;
  height: auto;
  display: block;
  color: #ffffff;
  text-align: center;
`

const NavBtn = styled.div`
  cursor: pointer;
`

function mod(a, b) {
  return ((a % b) + b) % b
}

class VerticalCarousel extends React.Component {
  state = {
    index: 0,
    goToSlide: null,
    prevPropsGoToSlide: 0,
    newSlide: false,
  }

  componentDidMount = () => {}

  static propTypes = {
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        content: PropTypes.object,
      })
    ).isRequired,
    totalquestions: PropTypes.number,
    goToSlide: PropTypes.number,
    showNavigation: PropTypes.bool,
    offsetRadius: PropTypes.number,
    animationConfig: PropTypes.object,
    moveNextCall: PropTypes.string,
    setMoveNextCall: PropTypes.func,
  }

  static defaultProps = {
    offsetRadius: 2,
    animationConfig: { tension: 120, friction: 14 },
  }

  modBySlidesLength = index => {
    return mod(index, this.props.slides.length)
  }

  moveSlide = direction => {
    this.setState({
      index: this.modBySlidesLength(this.state.index + direction),
      goToSlide: null,
    })
  }

  clampOffsetRadius(offsetRadius) {
    const { slides } = this.props
    const upperBound = Math.floor((slides.length - 1) / 2)

    if (offsetRadius < 0) {
      return 0
    }
    if (offsetRadius > upperBound) {
      return upperBound
    }

    return offsetRadius
  }

  getPresentableSlides() {
    const { slides } = this.props
    const { index } = this.state
    let { offsetRadius } = this.props
    offsetRadius = this.clampOffsetRadius(offsetRadius)
    const presentableSlides = []

    for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
      presentableSlides.push(slides[this.modBySlidesLength(index + i)])
    }

    return presentableSlides
  }

  patternItem(x, lastNum) {
    return x % lastNum
  }

  render() {
    const { animationConfig, offsetRadius, showNavigation, moveNextCall, setMoveNextCall, totalquestions } = this.props

    if (moveNextCall === 'next') {
      this.moveSlide(1)
      setMoveNextCall('')
    }

    let navigationButtons = null
    if (showNavigation) {
      navigationButtons = (
        <NavigationButtons>
          <NavBtn
            onClick={() => {
              this.moveSlide(-1)
            }}
          >
            <img src={left}></img>
          </NavBtn>
          <NavBtn
            onClick={() => {
              this.moveSlide(1)
            }}
          >
            <img src={Right}></img>
          </NavBtn>
        </NavigationButtons>
      )
    }
    return (
      <React.Fragment>
        <Wrapper>
          {this.getPresentableSlides().map((slide, presentableIndex) => (
            <Slide
              key={slide.key}
              slideIndex={slide.key}
              content={slide.content}
              moveSlide={this.moveSlide}
              offsetRadius={this.clampOffsetRadius(offsetRadius)}
              index={presentableIndex}
              animationConfig={animationConfig}
            />
          ))}
        </Wrapper>
        {navigationButtons}
        {totalquestions === 1 ? (
          <QuestionCount>
            {totalquestions}/{totalquestions}
          </QuestionCount>
        ) : (
          <QuestionCount>
            {this.patternItem(this.state.index + 1, totalquestions)
              ? this.patternItem(this.state.index + 1, totalquestions)
              : totalquestions}
            /{totalquestions}
          </QuestionCount>
        )}
      </React.Fragment>
    )
  }
}

export default VerticalCarousel
