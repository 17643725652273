import { createSlice } from '@reduxjs/toolkit'

export enum ErrorStates {
  success = 'SUCCESS',
  error = 'ERROR',
  warning = 'WARNING',
}

export interface ErrorMeta {
  name?: string
  state?: ErrorStates.error | ErrorStates.success | ErrorStates.warning
  content?: string
  status: boolean
}
export interface AppMetaData {
  error: ErrorMeta | null
  version: 'v2' // .ENV
  apiVersion: 'v2' // .ENV,
  sidepanel: {
    panelBody: any
    status: boolean
  }
  popupType: any
  realmUser: any
}
// Define the initial state using that type
const initialState: AppMetaData = {
  error: null,
  version: 'v2',
  apiVersion: 'v2',
  sidepanel: {
    panelBody: null,
    status: false,
  },
  popupType: null,
  realmUser: null,
}

export const metaSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setAppError: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //   let newError =
      state.error = action.payload
    },
    setSidePanel: (state, action) => {
      state.sidepanel = action.payload
    },
    setPopup: (state, action) => {
      state.popupType = action.payload
    },
    setRealmUser: (state, action) => {
      state.realmUser = action.payload
    },
    destroyClient: state => {
      state.error = null
      state.version = 'v2'
      state.apiVersion = 'v2'
      state.sidepanel = {
        panelBody: null,
        status: false,
      }
      state.popupType = null
    },
  },
})

export const { setAppError, setSidePanel, setPopup, destroyClient, setRealmUser } = metaSlice.actions
export const selectMetadata = (state: any) => state.metadata
export default metaSlice.reducer
