import './MidSection.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../Hooks'
import { type pageDetailData } from '../../Redux/slices/pageDetailSlice'
import { allowPostPagination } from '../../Redux/slices/post'
import { getPosts } from '../../Redux/thunks/postThunk'

export const CenterSection = (props: any) => {
  const dispatch = useDispatch()

  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const userState = useAppSelector(state => state.user)
  const allowpostPagination = useAppSelector((state: any) => state?.post?.allowpostpagination)
  const pagePostCount = useAppSelector((state: any) => state?.post?.postList?.count)

  const [getScrollCount, setScrollCount] = useState<number>(2)

  const isBlur = () => {
    return (
      pageDetailState.privacy_status === 'PRIVATE' &&
      pageDetailState?.belongsTo?._id !== userState?.value?.data?._id &&
      pageDetailState?.subscribtion_status !== 'APPROVED'
    )
  }
  const [screenSize, getDimension] = useState({
    dynamicHeight: window.innerHeight,
    dynamicOHeight: window.outerHeight,
  })
  const setDimension = () => {
    getDimension({
      ...screenSize,
      dynamicHeight: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])
  const CenterSection = {
    maxHeight: `${screenSize.dynamicHeight - 80}px`,
  }

  const handleScroll = (e: any) => {
    if (pageDetailState?._id) {
      const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop)
      if (
        Math.abs(bottom - e?.target?.clientHeight + 5) < 10 &&
        pagePostCount &&
        pagePostCount > 0 &&
        allowpostPagination !== false
      ) {
        if (getScrollCount <= Math.ceil(pagePostCount / 15)) {
          dispatch(allowPostPagination(false))
          dispatch(getPosts(pageDetailState?._id, getScrollCount))
          setScrollCount(getScrollCount + 1)
        }
      }
    }
  }

  return (
    <>
      <div
        className={isBlur() ? 'isblur CenterSection' : 'CenterSection'}
        style={CenterSection}
        onScroll={e => {
          if (window.innerWidth > 767.98 && window.innerWidth <= 1199.98 && pageDetailState?._id) {
            handleScroll(e)
          }
        }}
      >
        {props.component?.midView?.map((component: any, index: any) => {
          return <React.Fragment key={index}>{component}</React.Fragment>
        })}
      </div>
    </>
  )
}
