import './cropper.scss'

import React, { useCallback, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Cropper from 'react-easy-crop'

import getCroppedImg from './cropImage'

const RoundedCropper = (props: any) => {
  const dogImg = props.selectedImg

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(() => {
    try {
      getCroppedImg(dogImg, croppedAreaPixels, rotation).then((data: any) => {
        props.setCroppedImage(data)
      })
    } catch (e) {}
  }, [croppedAreaPixels, rotation])

  const handleClose = useCallback(() => {
    props.setCroppedImage('cancel')
  }, [])

  return (
    <>
      <Modal className="Cropper pages" show={props.show} centered onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* --- Cropper Popup --- */}
            <div
              className="form-group col-md-12 "
              style={{
                position: 'relative',
                width: '100%',
                height: 200,
                background: '#333',
              }}
            >
              <Cropper
                image={dogImg}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                showGrid={false}
                aspect={props?.croppershap === 'rect' ? 300 / 200 : 1 / 1}
                cropShape={props?.croppershap ? props?.croppershap : 'rect'}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className="mt-3 col-md-12 text-right">
              <p className="text note">
                <strong>Tip:</strong> Center your photo around and zoom in till it&apos;s perfect.
              </p>
            </div>
            <div className="mt-3 col-md-12 text-center">
              <Button onClick={showCroppedImage} className="buttonCropper" type="button">
                Crop & Upload
              </Button>
            </div>
            {/* --- End Cropper Popup --- */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default RoundedCropper
