import cn from 'classnames'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { createdByMonthAndYear, createdByYear } from '../../../commonservice'
import { useAppSelector } from '../../../Hooks'
import {
  addPageAction,
  addPageActionData,
  type galllery,
  type pageDetailData,
  type pageGallleryData,
} from '../../../Redux/slices/pageDetailSlice'
import ReportPopup from '../../Modals/ReportPopup'

interface MediaProps {
  showThumbnail: any
  handleShow: any
  setSelectedMedia: any
  timeLineType: any
  handleDelete: any
  pageGallleryData: pageGallleryData
  showAlbumGalllery: boolean
  handleAddMedia: any
  mediaType: any
}

const Media = (props: any) => {
  const ref = useRef<HTMLInputElement>(null)
  const [reportshow, setReportshow] = useState(false)

  const [height, setHeight] = useState(0)
  const [reportData, setReportData] = useState({})
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    if (ref.current !== null) {
      setHeight(ref.current.offsetWidth)
    }
  })

  const handleReportPopupClose = () => {
    setReportshow(false)
    setReportData({})
  }

  const {
    showThumbnail,
    handleShow,
    setSelectedMedia,
    timeLineType,
    handleDelete,
    pageGallleryData,
    showAlbumGalllery,
    handleAddMedia,
    mediaType,
  }: MediaProps = props

  const [gallleryData, setGallleryData] = useState<galllery[]>()
  const PageGalleryDataArray = showAlbumGalllery ? pageGallleryData?.currentAlbum : pageGallleryData?.gallery
  const userState: any = useAppSelector(state => state.user)
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)

  useEffect(() => {
    const pageGallleryDataUpdate = PageGalleryDataArray?.map((media: galllery, index: number) => {
      return {
        ...media,
        isToggled: false,
        createdYear: `${createdByYear(media.createdAt)}`,
        createdMonthYear: `${createdByMonthAndYear(media.createdAt)}`,
      }
    })
    if (mediaType === 'all') {
      setGallleryData(pageGallleryDataUpdate)
    } else {
      const filterMedia = pageGallleryDataUpdate?.filter((media: galllery, index: number) => media.type === mediaType)
      setGallleryData(filterMedia)
    }
  }, [PageGalleryDataArray, mediaType])

  const toggleUpdate = (media: galllery) => {
    const updatepageMemberData = gallleryData?.map((mediaItem: galllery, index: number) => {
      if (mediaItem._id === media._id) {
        return {
          ...mediaItem,
          isToggled: !mediaItem.isToggled,
        }
      } else {
        return {
          ...mediaItem,
          isToggled: false,
        }
      }
    })
    setGallleryData(updatepageMemberData)
  }

  const groupBy = (key: string) => {
    return function group(array: any) {
      return array?.reduce((acc: any, obj: any) => {
        const property = obj[key]
        acc[property] = acc[property] || []
        acc[property].push(obj)
        return acc
      }, {})
    }
  }

  const groupByMonthYear = groupBy('createdMonthYear')
  const groupByYear = groupBy('createdYear')

  const timelineObject = timeLineType === 'month' ? groupByMonthYear(gallleryData) : groupByYear(gallleryData)

  const openPostPreview = (media: galllery, index: number) => {
    const activePost = {
      medias: gallleryData,
      counts: {
        comments: 0,
        likes: 0,
      },
      likes: [],
      activeIndex: index,
      openfromGallery: true,
    }
    dispatch(addPageActionData(activePost))
    dispatch(addPageAction('open-media-post-priview'))
  }

  const mediaTiles = (media: galllery, index: number) => {
    return (
      <div
        className="eachTiles "
        ref={ref}
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        {media.type === 'image' ? (
          <img
            onClick={() => {
              openPostPreview(media, index)
            }}
            src={media.url}
            alt={media._id}
          />
        ) : media.type === 'audio' ? (
          <div
            onClick={() => {
              openPostPreview(media, index)
            }}
            className="audio-media"
          >
            <i className="fa fa-butterflyheadphone"></i>
          </div>
        ) : media.type === 'video' ? (
          <div
            onClick={() => {
              openPostPreview(media, index)
            }}
            className="video-media"
            style={{
              backgroundImage: `url(${media?.thumbnail ? media?.thumbnail : ''})`,
            }}
          >
            <i className="fa fa-play"></i>
          </div>
        ) : (
          <></>
        )}

        <span
          className="metalballs"
          onClick={() => {
            toggleUpdate(media)
          }}
        >
          <i className=" fa fa-meatballs"></i>
        </span>
        {media?.isToggled && (
          <div
            className={cn('overlay', {
              isAlbumMedia: showAlbumGalllery,
            })}
          >
            <span
              className="close"
              onClick={() => {
                toggleUpdate(media)
              }}
            >
              <i className=" fa fa-close"></i>
            </span>
            {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
              <div>
                <span
                  onClick={() => {
                    setSelectedMedia(media)
                    handleDelete(`${showAlbumGalllery ? 'album-media' : 'media'}`)
                  }}
                >
                  <i className="fa fa-butterflydelete"></i>
                  Delete
                </span>
                {!showAlbumGalllery && (
                  <span
                    onClick={() => {
                      setSelectedMedia(media)
                      handleShow()
                    }}
                  >
                    <i className="fa fa-galleryicon"></i>+ Album
                  </span>
                )}
              </div>
            ) : media?.post?.createdBy?._id === userState?.value?.data?._id ? (
              <div className="report-image">
                <span
                  onClick={() => {
                    setSelectedMedia(media)
                    handleDelete('media')
                  }}
                >
                  <i className="fa fa-butterflydelete"></i>
                  Delete
                </span>
              </div>
            ) : (
              <div className="report-image">
                <span
                  onClick={() => {
                    setReportData({
                      page: media?.page,
                      post: media?.post?._id,
                      media: media?._id,
                      reporter: userState?.value?.data?._id,
                      report: '',
                      type: 'MEDIA', // MEDIA , POST
                    })
                    setReportshow(true)
                  }}
                >
                  <i className="fa fa-image-report"></i>
                  Report Image
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  const addMediaComponent = () => {
    return (
      <div className="eachTiles firstTiles">
        <button
          onClick={() => {
            handleAddMedia()
          }}
        >
          <i className="fa fa-additem"></i>
          <span>
            Add
            <br />
            Photos/Videos/Audio
          </span>
        </button>
      </div>
    )
  }

  return (
    <>
      {showThumbnail ? (
        <div className="media">
          {!showAlbumGalllery && pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
            <React.Fragment>{addMediaComponent()}</React.Fragment>
          ) : (
            <></>
          )}
          {PageGalleryDataArray?.length ? (
            <>
              {gallleryData?.map((media: galllery, index: number) => {
                return <React.Fragment key={index}>{mediaTiles(media, index)}</React.Fragment>
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          <div className="media timelineView">
            {!showAlbumGalllery && pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
              <React.Fragment>{addMediaComponent()}</React.Fragment>
            ) : (
              <></>
            )}
            {Object.keys(timelineObject).map((monthYearKey: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className="date">{monthYearKey}</div>
                  <div className="eachrow">
                    {timelineObject[monthYearKey]?.map((media: galllery, index: number) => {
                      return <React.Fragment key={index}>{mediaTiles(media, index)}</React.Fragment>
                    })}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </>
      )}
      {!showAlbumGalllery && pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
        <button
          onClick={() => {
            handleAddMedia()
          }}
          className="CreateMedia"
        >
          <i className="fa fa-plus"></i> Add Media
        </button>
      ) : (
        <></>
      )}
      {reportshow ? (
        <ReportPopup
          reportshow={reportshow}
          reportdata={reportData}
          handleclose={() => {
            handleReportPopupClose()
          }}
        />
      ) : (
        <></>
      )}{' '}
    </>
  )
}

export default Media
