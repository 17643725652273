import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import AxiosClass from '../../Api/Interceptor'
import UserApi from '../../Api/UserApi'
import { environment } from '../../AppConfig/environment'
import { loading } from '../../Redux/slices/userSlice'
import { loginThunk } from '../../Redux/thunks'

function GsignIn(props: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Glogin = useGoogleLogin({
    onSuccess: (tokenResponse: any) => {
      getProfileData(tokenResponse)
      trackEvent('Conversion Event', 'Google Sign In', 'Sign In/Sign Up')
    },
    onError: () => {
      console.log('Login Failed')
    },
  })

  const getProfileData = (tokenResponse: any) => {
    new UserApi(AxiosClass)
      .getUserInfoFromToken(tokenResponse.access_token)
      .then((data: any) => {
        dispatch(loading(false))
        const payload = {
          name: data.name,
          email: data.email,
          password: '',
          recaptcha_token: '',
          otp: '',
          access_token: tokenResponse.access_token,
          google_id: data.id,
          refresh_token: null,
          token_expiresIn: tokenResponse.expires_in,
          provider: 'google', // google or manual
        }
        dispatch(loginThunk(payload, navigate))
      })
      .catch(e => {
        dispatch(loading(false))
      })
  }

  return (
    <button
      className="googleCta"
      onClick={() => {
        Glogin()
      }}
    >
      <span>
        <img src={environment.siteUrl + 'images/ade0dc9664c1b32cbb467edccee50e61.svg'} alt="" />
      </span>
      {props.name} with Google
    </button>
  )
}

export default GsignIn
