import Modal from 'react-bootstrap/Modal'

const PostMediaView = (props: any) => {
  const { handleclose, MediaData } = props

  return (
    <Modal className="PostMediaPopup" show={MediaData.status} onHide={handleclose} centered>
      <Modal.Header closeButton>
        <div className="NotificationText">
          <div
            dangerouslySetInnerHTML={{
              __html: MediaData?.data?.notification,
            }}
          ></div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="media-section">
          <video
            src={MediaData?.subData?.url}
            height={300}
            width={300}
            controls={true}
            className="video-player"
          ></video>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={() => {}}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PostMediaView
