import styled from '@emotion/styled'
import cn from 'classnames'
import React from 'react'
import { Spring } from 'react-spring/renderprops'
import { withGesture } from 'react-with-gesture'

const SlideContainer = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
`

const SlideCard = styled.div`
  width: 550px;
  height: 315px;
  background: white;
  border-radius: 5px;
`

function Slide({
  slideIndex,
  content,
  offsetRadius,
  index,
  animationConfig,
  moveSlide,
  delta,
  down,
}: {
  slideIndex: any
  content: any
  offsetRadius: any
  index: any
  animationConfig: any
  moveSlide: any
  delta: any
  down: any
}) {
  const offsetFromMiddle = index - offsetRadius
  const totalPresentables = 2 * offsetRadius + 1

  const translateYoffset = 50 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1))
  let translateY = -50

  if (offsetRadius !== 0) {
    if (index === 0) {
      translateY = 0
    } else if (index === totalPresentables - 1) {
      translateY = -100
    }
  }

  if (offsetFromMiddle === 0 && down) {
    translateY += delta[1] / (offsetRadius + 1)
    if (translateY > -40) {
      // moveSlide(-1);
    }
    if (translateY < -100) {
      // moveSlide(1);
    }
  }
  if (offsetFromMiddle > 0) {
    translateY += translateYoffset
  } else if (offsetFromMiddle < 0) {
    translateY -= translateYoffset
  }

  return (
    <Spring to={styles[index]} config={animationConfig}>
      {style => (
        <SlideContainer
          style={{
            ...style,
            zIndex: Math.abs(Math.abs(offsetFromMiddle) - 10),
          }}
        >
          <SlideCard onClick={() => moveSlide(offsetFromMiddle)}>
            <div
              className={cn('question-segment', {
                active: slideIndex === index,
              })}
            >
              {content}
            </div>
          </SlideCard>
        </SlideContainer>
      )}
    </Spring>
  )
}

interface StyleItem {
  transform: string
  top: string
  opacity: number
}

const styles: Record<number, StyleItem> = {
  0: {
    transform: 'translateX(0%) translateY(-41.7%) scale(0.8)',
    top: '22%',
    opacity: 0,
  },
  1: {
    transform: 'translateX(0%) translateY(-80%) scale(0.85)',
    top: '42%',
    opacity: 0,
  },
  2: {
    transform: 'translateX(0%) translateY(-70%) scale(0.9)',
    top: '44%',
    opacity: 0,
  },
  3: {
    transform: 'translateX(0%) translateY(-60%) scale(0.95)',
    top: '47%',
    opacity: 0,
  },
  4: {
    transform: 'translateX(0%) translateY(-50%) scale(1)',
    top: '50%',
    opacity: 1,
  },
  5: {
    transform: 'translateX(0%) translateY(-40%) scale(0.95)',
    top: '47%',
    opacity: 0.7,
  },
  6: {
    transform: 'translateX(0%) translateY(-30%) scale(0.9)',
    top: '44%',
    opacity: 0.5,
  },
  7: {
    transform: 'translateX(0%) translateY(-20%) scale(0.85)',
    top: '41%',
    opacity: 0.3,
  },
  8: {
    transform: 'translateX(0%) translateY(-60%) scale(0.8)',
    top: '42%',
    opacity: 0,
  },
}

// @ts-expect-error arguments not matching
export default withGesture()(Slide)
