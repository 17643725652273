import { isMobile } from 'react-device-detect'

import AxiosClass from '../../Api/Interceptor'
import MessageBoardApi from '../../Api/MessageBoardAPI'
import { loadMsgBoardMessages, setSkeleton } from '../slices/messageBoardSlice'
import { loading, setAuthAlert as setAlert } from '../slices/userSlice'

export const loadMessagesThunk: any = (pageId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    if (isMobile) {
      dispatch(setSkeleton(true))
    }
    new MessageBoardApi(AxiosClass)
      .loadMessages(pageId)
      .then((r: any) => {
        if (r.success) {
          dispatch(loading(false))
          dispatch(loadMsgBoardMessages(r))
          dispatch(setSkeleton(false))
        } else {
          throw r
        }
      })
      .catch(e => {
        dispatch(loading(false))
        dispatch(
          setAlert({
            status: true,
            content: e.message,
          })
        )
      })
  }
}

export const loadMessagesSilentlyThunk: any = (pageId: number) => {
  return (dispatch: any, getState: any) => {
    new MessageBoardApi(AxiosClass)
      .loadMessages(pageId)
      .then((r: any) => {
        if (r.success) {
          dispatch(loadMsgBoardMessages(r))
        } else {
          throw r
        }
      })
      .catch(e => {
        dispatch(
          setAlert({
            status: true,
            content: e.message,
          })
        )
      })
  }
}
