import '../index.scss'

import React from 'react'

import { butterflyBlack } from '../../../commonservice'

const SiteMap = () => {
  return (
    <div className="mainWrapper legal-page">
      <div className="lhs"></div>
      <div className="rhs">
        <div className="logoWrap">
          <img className="logo" src={butterflyBlack} />
        </div>
        <div className="sitemap">
          <h1 className="title mt-0">Sitemap</h1>
          <ul id="primaryNav" className="col4">
            <li id="home">
              <a href="http://butterfly.co">Home</a>
            </li>
            <li>
              <a href="/signin">Sign In</a>
            </li>
            <li>
              <a href="/register">Register</a>
            </li>
            <li>
              <a href="/mypages">My Pages</a>
              <ul>
                <li>
                  <a href="/page/create">Create Page</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="/joinedpages">Joined Pages</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms-condition">Terms & Conditions</a>
            </li>
            <li>
              <a href="/find-pages">Pages</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default SiteMap
