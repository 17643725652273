import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom'

import { butterflyBlack, logoNoText } from '../../commonservice'
import ProgBar from './ProgressBar'

function StepTwo(props: any) {
  const navigate = useNavigate()
  const [privacy, setPrivacy] = useState<any>(null)
  const d = new Date()
  const year = d.getFullYear()
  useEffect(() => {
    setPrivacy(props.propsData[3])
  }, [props?.propsData])
  return (
    <>
      <div className="imgHolder d-none d-md-block">
        <div className="contentWrapper stepTwoBg">
          <div className="sideBar">
            <div
              className="logo"
              onClick={() => {
                navigate('/mypages')
              }}
            >
              <img src={logoNoText} alt="Butterfly Logo" />
            </div>
            {window.location.pathname !== '/create' ? (
              <ul className="Back">
                <li
                  onClick={() => {
                    window.history.back()
                  }}
                >
                  <i className="fa fa-arrow-left"></i>
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
          <div className="overlay"></div>
          <div className="title">
            Your page. <br />
            Your call.
          </div>
        </div>
      </div>
      <div className="formHolder">
        <div className="d-xs-block d-md-none m-logo">
          <img src={butterflyBlack} alt="Butterfly logo" />
          <div className="title">A Full Life Deserves a Full Tribute</div>
        </div>
        <ProgBar step={props.propsData[2]} />
        <div className="stepWrapper">
          <div className="steps stepTwo">
            <h2>We give you three privacy choices</h2>
            <p>These settings can be updated at any time</p>
            <Form>
              <div className="privacy">
                <Card>
                  <Card.Body className="d-flex p-0">
                    <div className="lhs">
                      <div className="title">
                        <i className="fa fa-butterflyeye" aria-hidden="true"></i>
                        <span>Public</span>
                      </div>
                      <p className="subTitle">Everyone can automatically view and share</p>
                    </div>
                    <div className="rhs">
                      <label className="switch switch200">
                        <input
                          checked={privacy === 'PUBLIC'}
                          type="radio"
                          id="public"
                          name="create"
                          value="PUBLIC"
                          onChange={e => {
                            setPrivacy(e.target.value)
                          }}
                        />
                        <span className="slider slider200"></span>
                      </label>
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="d-flex p-0">
                    <div className="lhs">
                      <div className="title">
                        <i className="fa fa-butterflylock2" aria-hidden="true"></i>
                        <span>Private</span>
                      </div>
                      <p className="subTitle">
                        A small portion of the page is viewable, you get to approve everyone who sees the entire page
                      </p>
                    </div>
                    <div className="rhs">
                      <label className="switch switch200">
                        <input
                          checked={privacy === 'PRIVATE'}
                          type="radio"
                          id="private"
                          name="create"
                          value="PRIVATE"
                          onChange={e => {
                            setPrivacy(e.target.value)
                          }}
                        />
                        <span className="slider slider200"></span>
                      </label>
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="d-flex p-0">
                    <div className="lhs">
                      <div className="title">
                        <i className="fa fa-butterflyeyehidden" aria-hidden="true"></i>
                        <span>Hidden</span>
                      </div>
                      <p className="subTitle">No way, no how. The entire page is available by invitation only</p>
                    </div>
                    <div className="rhs">
                      <label className="switch switch200">
                        <input
                          checked={privacy === 'HIDDEN'}
                          type="radio"
                          id="hidden"
                          name="create"
                          value={'HIDDEN'}
                          onChange={e => {
                            setPrivacy(e.target.value)
                          }}
                        />
                        <span className="slider slider200"></span>
                      </label>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="justify-content-between d-flex">
                <Button className="prev" onClick={props.propsData[1]} disabled={props.propsData[2] === 1}>
                  Previous
                </Button>
                <Button
                  onClick={() => {
                    props.propsData[0]()
                    props.handleForm({
                      privacy_status: privacy,
                    })
                  }}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </div>
        {/* <div className="text-center d-block">
					<p className="CopyRight w-100 text-center mt-5">© 2023 Butterfly.co</p>
				</div> */}
        <div className="position-absolute">
          <p className="CopyRight text-center position-fixed">
            © {year} Better Place Memories Co. All rights reserved
          </p>
        </div>
      </div>
    </>
  )
}

export default StepTwo
