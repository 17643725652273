import { LayoutWrapper } from './LayoutWrapper'

/**
 * @interface
 * Enforces the type checking on the layout sections.
 * The whole layout is divided in three sections. Left , Mid and right
 * and those sections are simply a pre-ordered array of React.Components
 */
export interface Views {
  leftView?: any
  midView?: any
  rightView?: any
  allView?: any
}

/**
 *
 * @param view Object containing the views
 * @param data Optional parameter for additional data or state that needs to be passed to the layout on a global context
 * @returns
 */
const withLayout = (view: Views, data: any | null) => {
  return (
    <>
      <LayoutWrapper view={view} />
    </>
  )
}

export default withLayout
