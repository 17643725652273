import { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import AxiosClass from '../../Api/Interceptor'
import UserApi from '../../Api/UserApi'
import { useAppDispatch, useAppSelector } from '../../Hooks'
import { ErrorStates, setAppError } from '../../Redux/slices/metaSlice'
import { loading, login, verifyEmail } from '../../Redux/slices/userSlice'

function VerifyEmail() {
  const userState = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const [otp, setOtp] = useState('')
  const [errorMessage] = useState('')
  const [error] = useState(false)
  const [countdown, setCountDown] = useState(300)
  const navigate = useNavigate()
  const handleVerifyOtp = () => {
    dispatch(loading(true))
    new UserApi(AxiosClass)
      .verifyOTP({
        email: userState.email && userState.email,
        otpCode: otp,
      })
      .then((r: any) => {
        if (r.success === true) {
          dispatch(loading(false))
          localStorage.setItem('user_id', r?.data?._id)
          dispatch(login(r))
          trackEvent('Conversion Event', 'Verify Email', `${r?.data?._id}`)
          if (localStorage.getItem('backToPublicPage') !== null) {
            const backURL: any = localStorage.getItem('backToPublicPage')
            navigate(backURL)
            setTimeout(() => {
              dispatch(verifyEmail(false))
            }, 150)
          } else if (localStorage.getItem('notificationEventIdRedirect') !== null) {
            const backURL: any = localStorage.getItem('notificationEventIdRedirect')
            navigate(backURL)
          } else if (!r?.data?.is_initiated) {
            navigate('/page/create')
          }
        } else {
          dispatch(loading(false))
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
        dispatch(
          setAppError({
            name: 'error',
            status: true,
            state: ErrorStates.warning,
            content: e.message,
          })
        )
      })
  }

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountDown(c => c - 1)
      }, 1000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [countdown])

  return (
    <div className="formHolder forgotPassword verifyMail">
      <div className="tab-content">
        <h2>A verification email has been sent</h2>
        <p>
          Check your inbox at <span>{userState?.email && userState?.email}</span> for a five digit code. Enter it below
          and you’re good to go.
        </p>
        <form
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
        >
          <div className="inputWithIcon">
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                required
                isInvalid={error}
                value={otp}
                placeholder="Enter OTP"
                disabled={userState?.loading}
                onChange={e => {
                  setOtp(e.currentTarget.value)
                }}
              />
              <i className="fa fa-shieldcheck"></i>
              {error && <Form.Control.Feedback type="valid">{errorMessage}</Form.Control.Feedback>}
            </InputGroup>
          </div>
          {userState?.loading ? (
            ''
          ) : (
            <>
              <button
                type="button"
                disabled={otp.toString().trim() === ''}
                onClick={() => {
                  handleVerifyOtp()
                }}
              >
                Verify email
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  )
}

export default VerifyEmail
