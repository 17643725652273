import AxiosClass from '../../Api/Interceptor'
import NotificationApi from '../../Api/NotificationAPI'
import {
  getNotificationCountResponse,
  loadNotification,
  takeNotificationAction,
  updateStatusOfNotificationResponse,
} from '../slices/NotificationSlice'
import { loading } from '../slices/userSlice'

export const getNotification: any = (userId: any, pageno: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new NotificationApi(AxiosClass)
      .fetchNotificationList(userId, pageno)
      .then((r: any) => {
        if (r) {
          if (pageno <= 1) {
            dispatch(loadNotification(r))
          } else {
            const { notification } = getState()
            dispatch(loadNotification({ ...r, data: [...notification?.notificationList?.data, ...r.data] }))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const takeNotificationActionThunk: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new NotificationApi(AxiosClass)
      .takeNotificationActionService(payload)
      .then((r: any) => {
        if (r) {
          dispatch(takeNotificationAction(r))
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getNotificationCount: any = (userId: any) => {
  return (dispatch: any, getState: any) => {
    new NotificationApi(AxiosClass)
      .getNotification_Count(userId)
      .then((r: any) => {
        if (r) {
          dispatch(getNotificationCountResponse(r))
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const ReadStatusUpdateNotification: any = (userId: any) => {
  return (dispatch: any, getState: any) => {
    new NotificationApi(AxiosClass)
      .updateNotificationReadStatus(userId)
      .then((r: any) => {
        if (r) {
          dispatch(updateStatusOfNotificationResponse(r))
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
