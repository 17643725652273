import axios from 'axios'

import { destroyClient } from '../../Redux/slices/metaSlice'
import { loading, logout } from '../../Redux/slices/userSlice'
import { store } from '../../Redux/store'
import aconfig from '../Config'

const instance = axios.create({
  baseURL: aconfig.BASE_URL,
  maxBodyLength: Infinity,
})

// Add a request interceptor
instance.interceptors.request.use(
  async (config: any) => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json'
    }
    const state = store.getState()
    config.headers.Authorization = `Bearer ${state.user?.value?.token}`
    const userId = localStorage.getItem('user_id')
    config.headers.user = userId
    return config
  },
  async (error: any) => {
    return await Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('INTERCEPTOR ERROR ', error)
    if (error.response.status === 403) {
      store.dispatch(loading(false))
      // store.dispatch(
      //   setAppError({
      //     name: "AuthError",
      //     status: true,
      //     state: "ERROR",
      //     content: "Your session has expired. Please login again",
      //   })
      // );
      setTimeout(() => {
        store.dispatch(logout())
        store.dispatch(destroyClient())
        // window.location.href = "/signin";
      }, 3700)
    }
    return await Promise.reject(error)
  }
)

export const get = async (url: any, options: any) => {
  return await new Promise((resolve, reject) => {
    instance
      .get(url, options)
      .then((result: { status: number; data: unknown }) => {
        if (result.status === 200) {
          resolve(result.data)
        } else if (result.status === 204) {
          resolve({ error: 204 })
        } else {
          reject(result.data)
        }
      })
      .catch((error: { response: any }) => {
        reject(error?.response)
      })
  })
}

export const post = async (url: any, data: any) => {
  return await new Promise(resolve => {
    instance
      .post(url, data)
      .then((result: { status: number; data: unknown }) => {
        result.status === 200 ? resolve(result.data) : resolve(false)
      })
      .catch((error: { response: { status: any } }) => {
        resolve({ error: error?.response?.status || 500 })
      })
  })
}
export const postWithHeader = async (url: any, data: any, header: any) => {
  return await new Promise((resolve, reject) => {
    instance
      .post(url, data, header)
      .then((result: any) => {
        if (result?.status === 200 || result?.status === 201) {
          resolve(result.data)
        } else {
          resolve(result.response)
        }
      })
      .catch((error: { response: any }) => {
        reject(error.response)
      })
  })
}
export const postWithHeaderErrorResponse = async (url: any, data: any, header: any) => {
  return await new Promise((resolve, reject) => {
    instance
      .post(url, data, header)
      .then((result: { status: number; data: unknown }) => {
        if (result.status === 200) {
          resolve(result.data)
        } else {
          resolve({ error: true })
        }
      })
      .catch((error: { response: any }) => {
        reject(error?.response)
      })
  })
}

export const put = async (url: any, data: any) => {
  return await new Promise(resolve => {
    instance
      .put(url, data)
      .then((result: { status: number; data: unknown }) => {
        result.status === 200 ? resolve(result.data) : resolve(false)
      })
      .catch((error: { response: { status: any } }) => {
        resolve({ error: error?.response?.status || 500 })
      })
  })
}

export const putWithHeader = async (url: any, data: any, header: any) => {
  return await new Promise((resolve, reject) => {
    instance
      .put(url, data, header)
      .then((result: { status: number; data: unknown }) => {
        if (result.status === 200 || result.status === 204 || result.status === 201) {
          resolve(result.data)
        } else {
          reject(result.data)
        }
      })
      .catch((error: { response: unknown }) => {
        resolve(error.response)
      })
  })
}

export const remove = async (url: any, data: any) => {
  return await new Promise((resolve, reject) => {
    instance
      .delete(url, {
        data,
      })
      .then((result: { status: number; data: unknown }) => {
        if (result.status === 200) {
          resolve(result.data)
        } else {
          reject(result.data)
        }
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

export const removeWithHeader = async (url: any, data: any, header: any) => {
  return await new Promise((resolve, reject) => {
    instance
      .delete(url, header)
      .then((result: { status: number; data: unknown }) => {
        result.status === 200 ? resolve(result.data) : reject(result.data)
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

export const patch = async (url: any, data: any) => {
  return await new Promise((resolve, reject) => {
    instance
      .patch(url, data)
      .then((result: { status: number; data: unknown }) => {
        result.status === 200 ? resolve(result.data) : reject(result.data)
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

export const consolidateError = (error: { data: any; statusText: any }) => {
  if (error) {
    return {
      error: true,
      data: error?.data || null,
      statusText: error?.statusText || 'Please try after some times',
    }
  } else {
    return {
      error: true,
      data: null,
      statusText: 'Please try after some times',
    }
  }
}

export default instance
