import React from 'react'

import { isset, timeSince } from '../../commonservice'

interface PostAudioNotificationProps {
  element: any
  key: number
  children?: React.ReactNode
  notificationType: string
  keyindex: number
}

export const PostAudioNotification = ({ element, key, notificationType, keyindex }: PostAudioNotificationProps) => {
  return (
    <>
      <div
        className="EachNotificationWrapper"
        key={'notification-item-' + key}
        id={keyindex === 0 ? 'notification-item-' + keyindex : ''}
      >
        <div className="EachNotification">
          <div className="ProfileImg">
            {isset(element?.createdBy?.image) ? (
              <img src={element?.createdBy?.image} alt="" />
            ) : (
              <span>{element?.createdBy?.name?.charAt(0)}</span>
            )}
          </div>
          <div className="NotificationText">
            <div
              dangerouslySetInnerHTML={{
                __html: element?.notification,
              }}
            ></div>
          </div>
        </div>
        <div className="row">
          <span className="personal-audio-msg">
            <i className="fa fa-butterflyheadphone"></i>
          </span>
          <div className="time">{timeSince(element.createdAt)}</div>
        </div>
      </div>
    </>
  )
}
