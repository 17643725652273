import './SidePanel.scss'

import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../Hooks'
import {
  addPageAction,
  type pageDetail,
  type pageDetailData,
  pageReturnAction,
} from '../../Redux/slices/pageDetailSlice'
import { loading, pagePublishFrom } from '../../Redux/slices/userSlice'
import { getNotification } from '../../Redux/thunks/NotificationThunk'
import { pageGetEventsThunk, pageGetGallleryThunk, pagePublishThunk } from '../../Redux/thunks/pageDetailThunk'
import EditPage from '../EditPage/EditPage'
import EventPage from '../Events/EventPage'
import Fundraiser from '../Fundraiser/Fundraiser'
import Gallery from '../Gallery/Gallery'
import Members from '../Members/Members'
import MessageBoard from '../MessageBoard/MessageBoard'
import Question from '../MobileQuestion/Question'
import AudioListMobile from '../Modals/AudioPopup/AudioListMobile'
import DeletePopup from '../Modals/DeletePopup'
import Notification from '../Notification/Notification'
import Search from '../Search/Search'
import Settings from '../Settings/Settings'

function SidePanel(props: any) {
  const { setDeleteshow, setDeleteType } = props
  const topRef = useRef<null | HTMLDivElement>(null)
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)
  const pageDetailReturnAction = pageDetailState?.returnAction
  const appMeta = useAppSelector(state => state.metadata)
  const dispatch = useDispatch()
  const pageData: pageDetailData = pageDetailState.data
  const userState = useAppSelector(state => state.user)
  const pageGallleryCount: any = useAppSelector((state: any) => state?.pagedetail?.gallleryData?.galleryCount)
  const pageEventsCount: any = useAppSelector((state: any) => state?.pagedetail?.eventCount)
  const NotificationCount: any = useAppSelector((state: any) => state?.notification?.notificationList?.totalRecords)

  const navigate = useNavigate()
  const pageAction = useAppSelector((state: any) => state?.pagedetail?.pageAction)

  const pageUnpublish = (status: boolean) => {
    dispatch(pagePublishThunk(pageData?._id, status))
    dispatch(pagePublishFrom(true))
  }

  /*  useEffect(() => {
    var href: any = window.location.pathname;
		var urlSegments: any = href.match(/([^\/]*)\/*$/);
		let urlParams: any = parseURLParams(window.location.href);
		if (urlParams?.from?.length && urlParams?.from[0]) {
			if (
				urlParams?.from[0] === "eventview" &&
				urlParams?.eventId?.length &&
				urlParams?.eventId[0] &&
         !document.getElementById('event-list-view-btn-'+urlParams?.eventId[0])
			) {
				dispatch(loading(true));
			}
		}
  }); */

  useEffect(() => {
    if (
      pageDetailState.activeSideSlug === 'event-page' &&
      eventHeader === 'Your events' &&
      localStorage.getItem('notificationEventId') !== null
    ) {
      dispatch(loading(true))
    }
  })

  useEffect(() => {
    const handleEsc = (e: any) => {
      if (e.keyCode === 27) {
        props.handleClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])
  const [showBlockedMemberStatus, setShowBlockedMemberStatus] = useState(false)
  const [showBackToGalary, setShowBackToGalary] = useState(false)

  const [eventHeader, setEventHeader] = useState('Your events')
  const [eventsScreen, setEventsScreen] = useState('list')
  const [deleteshowPopup, setDeleteshowPopup] = useState(false)
  const [deleteTypePopup, setDeleteTypePopup] = useState('')
  const [fundraisercurrent, setfundraisercurrentpage] = useState('list')
  const [getScrollCount, setScrollCount] = useState<number>(2)

  const handleDeletesPopupClose = () => {
    setDeleteshowPopup(false)
  }

  useEffect(() => {
    setEventsScreen('list')
    setfundraisercurrentpage('list')
    setEventHeader('Your events')
    if (pageDetailState.activeSideSlug === '') {
      props.handleClose()
      setScrollCount(2)
    }
    document.body.removeAttribute('class')
    if (pageDetailState.activeSideSlug !== '') {
      document.body.classList.add(`${pageDetailState.activeSideSlug}`)
    } else {
      // 👇 Remove all classes from element
      document.body.removeAttribute('class')
    }

    if (pageDetailState.activeSideSlug === 'fundraiser' && appMeta.sidepanel.panelBody === 'Edit Fundraisers') {
      setfundraisercurrentpage('edit-direct')
    }

    // document.body.classList.remove('my-class-3');
  }, [pageDetailState.activeSideSlug])

  useEffect(() => {
    if (pageAction === 'side-panel-top-scroll') {
      topRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
      dispatch(addPageAction(''))
    }
  }, [pageAction])

  useEffect(() => {
    if (pageDetailReturnAction === 'back-to-event-list') {
      dispatch(pageReturnAction(''))
      setEventsScreen('list')
    }
  }, [pageDetailReturnAction])

  const handleScroll = (e: any) => {
    if (pageDetailState.activeSideSlug === 'gallery-add-page') {
      // For gallery pagination
      const selector: any = document.getElementById('galleryScrollBtn')
      if (e.target.scrollTop > 100) {
        if (selector) {
          selector.style.display = 'inline-block'
        }
      } else {
        if (selector) {
          selector.style.display = 'none'
        }
      }

      if (pageDetailState.activeSideSlug === 'gallery-add-page') {
        const bottom = e.target.scrollHeight - parseInt(e.target.scrollTop)
        if (Math.abs(bottom - e?.target?.clientHeight) === 0 && pageGallleryCount && pageGallleryCount > 0) {
          if (getScrollCount <= Math.ceil(pageGallleryCount / 25)) {
            // 25  = limit for gallery list per page
            dispatch(pageGetGallleryThunk(pageData?._id, getScrollCount))
            setScrollCount(getScrollCount + 1)
          }
        }
      }
    } else if (pageDetailState.activeSideSlug === 'event-page' && eventHeader === 'Your events') {
      // For event pagination
      const selector: any = document.getElementById('galleryScrollBtn')
      if (e.target.scrollTop > 100) {
        if (selector) {
          selector.style.display = 'inline-block'
        }
      } else {
        if (selector) {
          selector.style.display = 'none'
        }
      }
      const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop)
      if (Math.abs(bottom - e?.target?.clientHeight) === 0 && pageEventsCount && pageEventsCount > 0) {
        if (getScrollCount <= Math.ceil(pageEventsCount / 6)) {
          // 6  = limit for event list per page
          dispatch(pageGetEventsThunk(pageData?._id, userState?.value?.data?._id, getScrollCount))
          setScrollCount(getScrollCount + 1)
        }
      }
    } else if (pageDetailState.activeSideSlug === 'notification-page') {
      const selector: any = document.getElementById('galleryScrollBtn')
      if (e.target.scrollTop > 100) {
        if (selector) {
          selector.style.display = 'inline-block'
        }
      } else {
        if (selector) {
          selector.style.display = 'none'
        }
      }
      const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop)
      if (Math.abs(bottom - e?.target?.clientHeight) === 0 && NotificationCount && NotificationCount > 0) {
        if (getScrollCount <= Math.ceil(NotificationCount / 15)) {
          // 15  = limit for event list per page
          dispatch(getNotification(userState?.value?.data?._id, getScrollCount))
          setScrollCount(getScrollCount + 1)
        }
      }
    }
  }

  const handleScrollTop = () => {
    if (pageDetailState.activeSideSlug === 'event-page' && eventHeader === 'Your events') {
      const element: any = document.getElementById('event-item0')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
    } else if (pageDetailState.activeSideSlug === 'notification-page') {
      const element: any = document.getElementById('notification-item-0')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
    } else {
      topRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }

  return (
    <>
      <div className={props.Active ? 'active panel-wrap' : 'panel-wrap'}>
        <div
          id="mainSidePanel"
          className={cn('panel', {
            Question: pageDetailState.activeSideSlug === 'question-page',
            EditPage: pageDetailState.activeSideSlug === 'edit-page',
            EventPage: pageDetailState.activeSideSlug === 'event-page',
            Gallery: pageDetailState.activeSideSlug === 'gallery-add-page',
            Members: pageDetailState.activeSideSlug === 'members-page',
            Notification: pageDetailState.activeSideSlug === 'notification-page',
            MessageBoard:
              pageDetailState.activeSideSlug === 'message-board' ||
              pageDetailState.activeSideSlug === 'personal-message',
            AudioMessage: pageDetailState.activeSideSlug === 'audio-message',
            SearchPages: pageDetailState.activeSideSlug === 'search-page',
            SettingsPages: pageDetailState.activeSideSlug === 'settings-page',
            Fundraiser: pageDetailState.activeSideSlug === 'fundraiser',
          })}
          onScroll={(e: any) => {
            handleScroll(e)
          }}
        >
          <div ref={topRef} className="header" id="sidepanelHeader">
            {showBlockedMemberStatus ? (
              <h5
                onClick={() => {
                  setShowBlockedMemberStatus(!showBlockedMemberStatus)
                }}
              >
                <i className="fa fa-arrow-left"></i>
                View active members
              </h5>
            ) : showBackToGalary ? (
              <h5
                onClick={() => {
                  setShowBackToGalary(!showBackToGalary)
                }}
              >
                <i className="fa fa-arrow-left"></i>
                <span className="darkcolor">Back to Albums</span>
              </h5>
            ) : pageDetailState.activeSideSlug === 'event-page' ? (
              <>
                {eventHeader === 'View all events' ? (
                  <h5
                    className="view-all-events"
                    onClick={() => {
                      setEventsScreen('list')
                    }}
                  >
                    <i className="fa fa-arrow-left"></i>
                    {eventHeader}
                  </h5>
                ) : (
                  ''
                )}
                {eventHeader === 'Your events' ? (
                  <h4>Events for {pageData.first_name + ' ' + pageData.last_name}</h4>
                ) : (
                  ''
                )}
                {eventHeader === 'Edit event' ? (
                  <h5
                    onClick={() => {
                      document.getElementById('edit-event-cancel')?.click()
                    }}
                  >
                    <i className="fa fa-arrow-left"></i>
                    {eventHeader}
                  </h5>
                ) : (
                  ''
                )}
                {eventHeader !== 'View all events' && eventHeader !== 'Edit event' && eventHeader !== 'Your events' ? (
                  <h4>{eventHeader}</h4>
                ) : (
                  ''
                )}
              </>
            ) : pageDetailState.activeSideSlug === 'fundraiser' ? (
              <>
                {fundraisercurrent === 'list' || fundraisercurrent === 'edit-direct' ? (
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: appMeta.sidepanel.panelBody,
                    }}
                  ></h4>
                ) : (
                  <h5
                    className="view-all-events"
                    onClick={() => {
                      setfundraisercurrentpage('list')
                    }}
                  >
                    <i className="fa fa-arrow-left"></i>
                    {'View all fundraisers'}
                  </h5>
                )}
              </>
            ) : (
              <h4
                dangerouslySetInnerHTML={{
                  __html: appMeta.sidepanel.panelBody,
                }}
              ></h4>
            )}
            {pageDetailState.activeSideSlug === 'edit-page' && (
              <div className="icons">
                {pageData.is_published ? (
                  <span
                    onClick={() => {
                      pageUnpublish(false)
                    }}
                  >
                    <i className="fa fa-unpublish"></i> Unpublish page
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      pageUnpublish(true)
                    }}
                  >
                    <i className="fa fa-butterflypublish"></i> Publish page
                  </span>
                )}
                &nbsp;&nbsp;&nbsp;
                <span
                  onClick={() => {
                    setDeleteshow(true)
                    setDeleteType('page')
                  }}
                >
                  <i className="fa fa-butterflydelete"></i> Delete page
                </span>
              </div>
            )}
            {pageDetailState.activeSideSlug === 'event-page' &&
            eventHeader === 'View all events' &&
            pageData?.belongsTo?._id === userState?.value?.data?._id &&
            eventsScreen !== 'create' ? (
              <div className="icons">
                <span
                  onClick={() => {
                    setDeleteshowPopup(true)
                    setDeleteTypePopup('event')
                  }}
                >
                  <i className="fa fa-butterflydelete"></i> Delete event
                </span>
              </div>
            ) : (
              <></>
            )}
            {eventHeader !== 'View all events' &&
            eventHeader !== 'Edit event' &&
            eventHeader !== 'Select your page members' &&
            fundraisercurrent !== 'edit' &&
            fundraisercurrent !== 'create' &&
            !showBlockedMemberStatus ? (
              <button
                className="close-btn"
                onClick={() => {
                  if (userState?.value?.data?.showStep) {
                    if (pageDetailState.activeSideSlug === 'event-page') {
                      dispatch(addPageAction('show-publish-steps'))
                    } else if (pageDetailState.activeSideSlug === 'question-page') {
                      if (
                        userState?.questionAddDeletePopupFlag === null &&
                        userState?.value?.data?.step === 4 &&
                        userState?.value?.data?.showStep === true
                      ) {
                        document.getElementById('AddDeleteQCta')?.click()
                        return
                      } else {
                        dispatch(addPageAction('show-event-steps'))
                      }
                    }
                  }
                  if (pageDetailState.activeSideSlug === 'edit-page') {
                    navigate(window.location.pathname)
                  }
                  props.handleClose()
                  setEventsScreen('list')
                  setShowBackToGalary(false)
                }}
              >
                <i className="fa fa-butterflynoapproval"></i>
              </button>
            ) : (
              ''
            )}
          </div>
          {pageDetailState.activeSideSlug === 'question-page' ? <Question /> : ''}
          {pageDetailState.activeSideSlug === 'edit-page' ? <EditPage /> : ''}
          {pageDetailState.activeSideSlug === 'fundraiser' ? (
            <Fundraiser
              setfundraisercurrentpage={setfundraisercurrentpage}
              updatefundraisercurrentpage={fundraisercurrent}
            />
          ) : (
            ''
          )}
          {pageDetailState.activeSideSlug === 'event-page' ? (
            <EventPage
              handleEventsScreen={setEventsScreen}
              topEventsScreen={eventsScreen}
              setEventHeader={setEventHeader}
            />
          ) : (
            ''
          )}
          {pageDetailState.activeSideSlug === 'gallery-add-page' ? (
            <Gallery
              showAlbumMedia={(status: boolean) => {
                setShowBackToGalary(status)
              }}
              showAlbums={showBackToGalary}
            />
          ) : (
            ''
          )}
          {pageDetailState.activeSideSlug === 'members-page' ? (
            <Members
              showBlockedMember={(status: boolean) => {
                setShowBlockedMemberStatus(status)
              }}
              showAllMembers={showBlockedMemberStatus}
            />
          ) : (
            ''
          )}
          {pageDetailState.activeSideSlug === 'notification-page' ? <Notification /> : ''}
          {pageDetailState.activeSideSlug === 'message-board' ? <MessageBoard type={'message'} /> : ''}
          {pageDetailState.activeSideSlug === 'personal-message' ? <MessageBoard type={'personal'} /> : ''}
          {pageDetailState.activeSideSlug === 'audio-message' ? <AudioListMobile /> : ''}
          {pageDetailState.activeSideSlug === 'search-page' ? <Search /> : ''}
          {pageDetailState.activeSideSlug === 'settings-page' ? <Settings /> : ''}
          <button className="MoveTop" id="galleryScrollBtn" onClick={handleScrollTop} style={{ display: 'none' }}>
            <i className="fa fa-angle-up"></i>
          </button>
        </div>
      </div>
      {deleteshowPopup ? (
        <DeletePopup
          deleteshow={deleteshowPopup}
          pageid={pageData?._id}
          handleclose={() => {
            handleDeletesPopupClose()
          }}
          deleteType={deleteTypePopup}
          mediadata={pageDetailState?.currentEventsData}
          albumdata={{}}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default SidePanel
