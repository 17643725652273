import 'react-tagsinput/react-tagsinput.css'
import './Events.scss'

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../Hooks'
import { type pageDetailData } from '../../Redux/slices/pageDetailSlice'
import { pageGetEventsThunk } from '../../Redux/thunks/pageDetailThunk'
import Loader from '../Loader/Loader'
import CreateEvents from './CreateEvents'
import EventEdit from './EventEdit'
import EventList from './EventList'

function EventPage(props: any) {
  const { handleEventsScreen, setEventHeader, topEventsScreen } = props

  const dispatch = useDispatch()
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)

  const pageEventsData = useAppSelector((state: any) => state.pagedetail.eventsData)
  const userState: any = useAppSelector(state => state.user)
  const [eventsScreen, setEventsScreen] = useState('list')
  const [selectedEvent, setSelectedEvent] = useState<any>()

  useEffect(() => {
    if (pageDetailState._id) {
      dispatch(pageGetEventsThunk(pageDetailState?._id, userState?.value?.data?._id, 1))
    }
  }, [pageDetailState])

  useEffect(() => {
    setEventsScreen(topEventsScreen)
  }, [topEventsScreen])

  useEffect(() => {
    switch (eventsScreen) {
      case 'list':
        setEventHeader('Your events')
        break
      case 'create':
        setEventHeader('View all events')
        break
      case 'edit':
        setEventHeader('Edit event')
        break
      case 'memberList':
        setEventHeader('Select your page members')
        break
      case 'detail':
        setEventHeader('View all events')
        break
      default:
        setEventHeader('Your events')
    }
    handleEventsScreen(eventsScreen)
  }, [eventsScreen])

  return (
    <>
      {userState.loading && <Loader />}
      {eventsScreen === 'list' || eventsScreen === 'detail' ? (
        <>
          {pageEventsData?.length ? (
            <EventList
              eventsScreen={eventsScreen}
              pageEventsData={pageEventsData}
              setSelectedEvent={setSelectedEvent}
              setEventsScreen={setEventsScreen}
              changeHeaderTitle={(headerTitle: any) => setEventHeader(headerTitle)}
            />
          ) : (
            <>
              <div className="CreateEventEmpty">
                <p>
                  {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
                    <>
                      <span> Is there an event coming up?</span> <br />
                      Whether it is a celebration that is solemn or joyful, this is where you invite family, friends and
                      others.
                    </>
                  ) : (
                    <>
                      <span>No events have been added yet, but check back soon.</span>
                    </>
                  )}
                </p>
                {userState.loading === false ? (
                  <>
                    {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
                      <button
                        className="CreateEventCTA"
                        onClick={() => {
                          setEventsScreen('create')
                        }}
                      >
                        <i className="fa fa-eventsicon"></i>Create New Event
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </>
      ) : eventsScreen === 'create' ? (
        <CreateEvents
          setEventsScreen={setEventsScreen}
          changeHeaderTitle={(headerTitle: any) => setEventHeader(headerTitle)}
        />
      ) : eventsScreen === 'edit' ? (
        <EventEdit
          event={selectedEvent}
          setEventsScreen={setEventsScreen}
          changeHeaderTitle={(headerTitle: any) => setEventHeader(headerTitle)}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default EventPage
