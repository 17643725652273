import 'intro.js/introjs.css'

import { Steps } from 'intro.js-react'
import $ from 'jquery'
import { isMobile } from 'react-device-detect'

import { UserGuideAddQuestion } from '../UserGuide/UserGuide'

const QuestionStepper = (props: any) => {
  // edit post
  const steps = [
    {
      element: '.DesktopAddQuestion',
      title: 'Add Your Custom Questions',
      intro: <UserGuideAddQuestion />,
      tooltipClass: 'UserGuideAddQuestion myTooltipClass',
      hidePrev: true,
      nextLabel: '+ Add a Question',
      position: 'top',
    },
  ]

  const stepsMobile = [
    {
      element: '.DesktopAddQuestion',
      title: 'Add Your Custom Questions',
      intro: <UserGuideAddQuestion />,
      tooltipClass: 'UserGuideAddQuestion myTooltipClass',
      hidePrev: true,
      nextLabel: '+ Add a Question',
      position: 'top',
    },
  ]

  const stepsTabSmallSize = [
    {
      element: '.DesktopAddQuestion',
      title: 'Add Your Custom Questions',
      intro: <UserGuideAddQuestion />,
      tooltipClass: 'UserGuideAddQuestion myTooltipClass tabTooltipClass',
      hidePrev: true,
      nextLabel: '+ Add a Question',
      position: 'bottom',
    },
  ]

  /*  useEffect(() => {
    props.setCantScroll(stepsStatus);
  }, [stepsStatus]); */

  const getMainStpes = () => {
    if (window.innerWidth > 767.98 && window.innerWidth <= 1199.98) {
      return stepsTabSmallSize
    } else {
      return isMobile ? stepsMobile : steps
    }
  }

  const onChange = () => {}

  const questionStepsStart = (stepIndex: number) => {
    setTimeout(() => {
      $('.introjs-tooltip .introjs-tooltipbuttons').prepend(
        `<a role="button" tabindex="0" class="introjs-button butterfly-custum-skip-now butterfly-custum-skip-click-question-${
          stepIndex + 1
        }" data-currentstep="${stepIndex + 1}">Skip</a>`
      )
    }, 200)

    $('body').on('click', `.butterfly-custum-skip-click-question-${stepIndex + 1}`, function () {
      props.onSkip()
      // skipMainStep(stepIndex+1)
    })
  }

  const questionStepsExit = (stepIndex: number) => {
    $('body').off('click', `.butterfly-custum-skip-click-question-${stepIndex + 1}`, function () {})
    // $('.butterfly-custum-skip-now').unbind('click');
    // $('.butterfly-custum-skip-now').detach();
  }

  return (
    <>
      <Steps
        enabled={props.enable}
        steps={getMainStpes()}
        initialStep={0}
        onComplete={props.onComplete}
        onExit={questionStepsExit}
        onStart={questionStepsStart}
        options={{
          showButtons: true,
          exitOnEsc: false,
          exitOnOverlayClick: false,
          keyboardNavigation: false,
          showBullets: false,
          hidePrev: true,
          autoPosition: true,
          disableInteraction: true,
          doneLabel: '+ Add a Question',
        }}
        onChange={onChange}
      />
    </>
  )
}
export default QuestionStepper
