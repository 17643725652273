import './SharePage.scss'

import axios from 'axios'
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { isIOS, isMacOs, isMobile } from 'react-device-detect'
import { ReactMultiEmail } from 'react-multi-email'
import { useDispatch } from 'react-redux'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'

import { appendUTMCode, trackEvent } from '../../../Analytics/GA'
import { environment } from '../../../AppConfig/environment'
import { useAppSelector } from '../../../Hooks'
import { invitePageResponse } from '../../../Redux/slices/pageSlice'
import { store } from '../../../Redux/store'
import { sendInvitationViaMail } from '../../../Redux/thunks/pageThunk'
import { getBlockmembersThunk } from '../../../Redux/thunks/settingsThunk'
import PhoneInputWithCode from '../../Shared/PhoneInput'

const CommonShareContent = (props: any) => {
  const dispatch = useDispatch()
  const pageDetailState: any = useAppSelector((state: any) => state.pagedetail)
  const blockMemberState = useAppSelector((state: any) => state?.settings?.Blockmembers)
  const pageState: any = useAppSelector((state: any) => state.page)
  const userState: any = useAppSelector(state => state.user)
  const textEl: any = useRef(null)

  const [emails, setEmails] = useState<any>([])
  const [SuccessMsg, setSuccessMsg] = useState('')
  const [showShareLink, setShowShareLink] = useState(false)
  const [ph, setPh] = useState('')
  const [smsSuccess] = useState('')

  const [shareViaMail, setShareViaMail] = useState(false)
  const [msgBoxshow, setMsgBoxshow] = useState(false)
  const [showBlockedMember, setShowBlockedMember] = useState(false)
  const [inviteDescription, setInviteDescription] = useState<any>(undefined)

  const [activeShareOption, setActiveShareOption] = useState<any>(undefined)
  const [memberList, setMemberList] = useState<any[]>([])
  const [blockedEmails, setBlockedEmails] = useState([])

  const hideText = props?.hideText

  const sendSms = async (smsdata: any) => {
    const data = JSON.stringify({
      to: smsdata.to,
      body: smsdata.body,
    })
    const token = store.getState().user?.value?.token
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${environment.apiEndPoint}page/sms/send`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    }

    axios(config)
      .then(() => {
        setSuccessMsg('SMS sent successfully')
        setShowShareLink(false)
        setPh('')
        trackEvent('Page Interaction', 'SMS Share', `${userState?.value?.data?._id}`)
      })
      .catch(() => {})
  }

  const copyToClipboard = async () => {
    const range = document.createRange()
    range.selectNode(textEl?.current)
    window?.getSelection()?.addRange(range)
    document.execCommand('copy')
    window?.getSelection()?.removeAllRanges()

    setSuccessMsg('Link Copied')
    trackEvent('Page Interaction', 'Share Link Copy', `${userState?.value?.data?._id}`)
  }

  const resetInviteForm = () => {
    setEmails([])
    setInviteDescription(undefined)
    setShareViaMail(false)
    setMsgBoxshow(false)
  }

  const openInviteForm = () => {
    setEmails([])
    setInviteDescription(undefined)
    setShareViaMail(true)
    setMsgBoxshow(false)
  }

  const sendInvitation = () => {
    const payload = {
      type: 'email',
      pageId: pageDetailState?.data?._id,
      email: emails,
      message: inviteDescription,
    }
    dispatch(sendInvitationViaMail(payload))
    trackEvent('Page Interaction', 'Email Share', `${userState?.value?.data?._id}`, { value: emails.length })
  }

  useEffect(() => {
    setTimeout(() => {
      setSuccessMsg('')
    }, 5000)
  }, [SuccessMsg])

  useEffect(() => {
    if (pageState?.invitePageResp?.success === true) {
      dispatch(invitePageResponse({ ...pageState?.invitePageResp, success: null }))
      resetInviteForm()
      setSuccessMsg('Invitation(s) sent')
    }
  }, [pageState?.invitePageResp])

  useEffect(() => {
    if (pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id) {
      dispatch(getBlockmembersThunk(userState?.value?.data?._id))
    }
  }, [])

  useEffect(() => {
    setMemberList(blockMemberState)
    const blockedList = blockMemberState?.map((members: any) => {
      return members.member.email
    })
    setBlockedEmails(blockedList)
  }, [blockMemberState])

  return (
    <>
      {showShareLink ? (
        <div className="ShareLink">
          {/* ---------Share Upload Link Starts------------ */}
          {pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ? (
            <p>
              That’s a wonderful page you created.
              <br />
              Now’s the time to share it with
              <br />
              family and friends.
              <span>
                {appendUTMCode(window.location.origin + '/' + pageDetailState?.data?.unique_url, 'link', 'link')}
              </span>
            </p>
          ) : (
            <p>
              That&apos;s a wonderful page.
              <br />
              Now’s the time to share it with
              <br />
              family and friends.
              <span>
                {appendUTMCode(window.location.origin + '/' + pageDetailState?.data?.unique_url, 'link', 'link')}
              </span>
            </p>
          )}
          <div className="InputHolder">
            <PhoneInputWithCode
              updatePhoneNumber={(e: any) => {
                setPh(e.value)
              }}
              placeHolder="Phone Number"
              required
            />
          </div>
          <button
            type="button"
            disabled={ph === ''}
            className=""
            onClick={() => {
              const pageUrl = window.location.origin + '/' + pageDetailState.data.unique_url
              const urlWithUTM = appendUTMCode(pageUrl, 'sms', 'sms')
              sendSms({
                to: ph,
                body: `Please visit /${urlWithUTM}`,
              })
            }}
          >
            Send
          </button>
          <p>{smsSuccess}</p>
          {/* ---------Share Upload Link Starts------------ */}
        </div>
      ) : (
        <div>
          {hideText ? (
            ''
          ) : pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ? (
            <h4>
              That’s a wonderful page you created
              <br />
              {pageDetailState.data.belongsTo.name}.
              <br />
              Now’s the time to share it with
              <br />
              family and friends.
            </h4>
          ) : (
            <h4>
              That&apos;s a wonderful page.
              <br />
              Now’s the time to share it with
              <br />
              family and friends.
            </h4>
          )}

          <div className="Icons">
            <span className="iconWrapper">
              <i
                className="fa fa-butterflylink"
                onClick={(e: any) => {
                  copyToClipboard()
                  setActiveShareOption('facebook')
                }}
              ></i>
            </span>
            <span className="iconWrapper">
              <a
                onClick={() => {
                  openInviteForm()
                  setActiveShareOption('mail')
                }}
              >
                <i className="fa fa-butterflyemail2"></i>
              </a>
            </span>
            {isMacOs || isMobile ? (
              <span className="iconWrapper">
                <a
                  href={
                    isMacOs
                      ? 'sms:&body=Hi,%0a%0a' +
                        pageDetailState?.data?.belongsTo?.name +
                        ' has created a page for ' +
                        pageDetailState?.data?.first_name +
                        ' ' +
                        pageDetailState?.data?.last_name +
                        ' on Butterfly. Please accept the invite below and help ' +
                        pageDetailState?.data?.belongsTo?.name +
                        ' make this page as beautiful as can be. %0a%0aAccept here: ' +
                        appendUTMCode(`${window.location.origin}/${pageDetailState?.data?.unique_url}`, 'sms', 'sms')
                      : isIOS
                      ? 'sms:&body=Hi,%0a%0a' +
                        pageDetailState?.data?.belongsTo?.name +
                        ' has created a page for ' +
                        pageDetailState?.data?.first_name +
                        ' ' +
                        pageDetailState?.data?.last_name +
                        ' on Butterfly. Please accept the invite below and help ' +
                        pageDetailState?.data?.belongsTo?.name +
                        ' make this page as beautiful as can be. %0a%0aAccept here: ' +
                        appendUTMCode(`${window.location.origin}/${pageDetailState?.data?.unique_url}`, 'sms', 'sms')
                      : 'sms:?body=Hi,%0a%0a' +
                        pageDetailState?.data?.belongsTo?.name +
                        ' has created a page for ' +
                        pageDetailState?.data?.first_name +
                        ' ' +
                        pageDetailState?.data?.last_name +
                        ' on Butterfly. Please accept the invite below and help ' +
                        pageDetailState?.data?.belongsTo?.name +
                        ' make this page as beautiful as can be. %0a%0aAccept here: ' +
                        appendUTMCode(`${window.location.origin}/${pageDetailState?.data?.unique_url}`, 'sms', 'sms')
                  }
                  onClick={() => {
                    setActiveShareOption('sms')
                  }}
                >
                  <i
                    className="fa fa-butterflymessage"
                    onClick={() => {
                      /* setShowShareLink(true) */
                    }}
                  ></i>
                </a>
              </span>
            ) : (
              <span className="iconWrapper">
                <i
                  className="fa fa-butterflymessage"
                  onClick={() => {
                    setShowShareLink(true)
                    setActiveShareOption('sms')
                  }}
                ></i>
              </span>
            )}
            <span className="iconWrapper">
              <FacebookShareButton
                url={appendUTMCode(
                  `${window.location.origin}/${pageDetailState?.data?.unique_url}`,
                  'facebook_post',
                  'social_media'
                )}
                quote={pageDetailState?.data?.first_name + ' ' + pageDetailState?.data?.last_name}
                className="Demo__some-network__share-button"
              >
                <i
                  className="fa fa-butterflyfacebook"
                  onClick={() => {
                    setActiveShareOption('facebook')
                    trackEvent('Page Interaction', 'Facebook Share', `${userState?.value?.data?._id}`)
                  }}
                ></i>
              </FacebookShareButton>
            </span>
            <span className="iconWrapper">
              <TwitterShareButton
                url={appendUTMCode(
                  `${window.location.origin}/${pageDetailState?.data?.unique_url}`,
                  'twitter_post',
                  'social_media'
                )}
                title={pageDetailState?.data?.first_name + ' ' + pageDetailState?.data?.last_name}
                className="Demo__some-network__share-button"
              >
                <i
                  className="fa fa-butterflytwitter"
                  onClick={() => {
                    setActiveShareOption('twitter')
                    trackEvent('Page Interaction', 'Twitter Share', `${userState?.value?.data?._id}`)
                  }}
                ></i>
              </TwitterShareButton>
            </span>
            <span className="iconWrapper">
              <LinkedinShareButton
                url={appendUTMCode(
                  `${window.location.origin}/${pageDetailState?.data?.unique_url}`,
                  'linkedin_post',
                  'social_media'
                )}
                windowWidth={750}
                windowHeight={600}
                className="Demo__some-network__share-button"
              >
                <i
                  className="fa fa-butterflylinkedin"
                  onClick={() => {
                    setActiveShareOption('linkedin')
                    trackEvent('Page Interaction', 'LinkedIn Share', `${userState?.value?.data?._id}`)
                  }}
                ></i>
              </LinkedinShareButton>
            </span>
          </div>

          {SuccessMsg !== '' ? (
            <div className="form-group text-center">
              <span className="text text-success">{SuccessMsg}</span>
            </div>
          ) : (
            ''
          )}
          <span ref={textEl} style={{ fontSize: '0px' }}>
            {appendUTMCode(window.location.origin + '/' + pageDetailState?.data?.unique_url, 'link', 'link')}
          </span>
          {shareViaMail && activeShareOption === 'mail' ? (
            <div className="InviteEmail row">
              <div
                className={cn('', {
                  'invite-label':
                    pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id && memberList.length,
                })}
              >
                <h4>Invite Guests</h4>
                {pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id && memberList.length ? (
                  <h5
                    onClick={() => {
                      setShowBlockedMember(!showBlockedMember)
                    }}
                  >
                    {`${showBlockedMember ? 'Hide' : 'Show'} `} blocked members
                  </h5>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12">
                <ReactMultiEmail
                  className="guest-multi-email"
                  placeholder="Paste or enter guest email"
                  emails={emails}
                  onChange={(_emails: string[]) => {
                    setEmails(_emails)
                  }}
                  getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => {
                            removeEmail(index)
                          }}
                        >
                          ×
                        </span>
                      </div>
                    )
                  }}
                />
              </div>
              {showBlockedMember && (
                <>
                  <h4 className="blocked-members-label">Blocked members</h4>
                  <div className="col-md-12">
                    <ReactMultiEmail
                      className="guest-multi-email blocked-members-list"
                      placeholder="Paste or enter guest email"
                      emails={blockedEmails}
                      getLabel={(email: string, index: number) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                          </div>
                        )
                      }}
                    />
                  </div>
                </>
              )}
              <div className="text-left col-md-12 checkboxField">
                <input
                  type="checkbox"
                  id="1"
                  className="vh"
                  checked={msgBoxshow}
                  onChange={(e: any) => {
                    setMsgBoxshow(e?.target?.checked)
                    setInviteDescription(undefined)
                  }}
                />
                <label htmlFor="1">Add a personalized message</label>
              </div>
              {msgBoxshow ? (
                <div className="col-md-12 ">
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Type your message here"
                    onChange={(e: any) => {
                      setInviteDescription(e?.target?.value)
                    }}
                  >
                    {inviteDescription}
                  </textarea>
                  <span className="text text-danger">{inviteDescription === '' ? 'Please enter description' : ''}</span>
                </div>
              ) : (
                ''
              )}
              <div className="col-md-12 Ctas">
                <button type="button" className="cancel" onClick={resetInviteForm}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="sendInvite"
                  disabled={
                    emails.length === 0 || (msgBoxshow && (inviteDescription === '' || inviteDescription === undefined))
                  }
                  onClick={sendInvitation}
                >
                  Send Invite
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  )
}
export default CommonShareContent
