import AxiosClass from '../../Api/Interceptor'
import SettingsApi from '../../Api/SettingsApi'
import { addPageAction } from '../slices/pageDetailSlice'
import {
  changePasswordResponse,
  changePaymentPlanResponse,
  getBlockmembers,
  getPlanDetailResponse,
  notificationSettingsListResponse,
  updateNotifcationSettingsResponse,
  updateUserDetail,
} from '../slices/settingsSlice'
import { loading } from '../slices/userSlice'

export const updateUserInfo: any = (payload: any, updateState: boolean = true) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(updateState))
    new SettingsApi(AxiosClass)
      .updateUser_info(payload)
      .then((r: any) => {
        if (r) {
          if (updateState) {
            dispatch(updateUserDetail(r))
            dispatch(addPageAction('reloadPage'))
          }
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const changePassword: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new SettingsApi(AxiosClass)
      .changePW(payload)
      .then((r: any) => {
        if (r) {
          dispatch(changePasswordResponse(r))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getBlockmembersThunk: any = (userId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new SettingsApi(AxiosClass)
      .getBlockmembers(userId)
      .then((r: any) => {
        if (r) {
          dispatch(getBlockmembers(r.data))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const unblockMemberThunk: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new SettingsApi(AxiosClass)
      .unblockMember(payload)
      .then((r: any) => {
        if (r) {
          // dispatch(getBlockmembers(r.data));
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getnotificationSettingsThunk: any = (userId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new SettingsApi(AxiosClass)
      .getNotifcationSettings(userId)
      .then((r: any) => {
        if (r) {
          dispatch(notificationSettingsListResponse(r.data))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const updatenotificationSettingsThunk: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new SettingsApi(AxiosClass)
      .updateNotifcationSettings(payload)
      .then((r: any) => {
        if (r) {
          dispatch(updateNotifcationSettingsResponse(r))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const paymentPlanChange: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new SettingsApi(AxiosClass)
      .changePaymentPlan(payload)
      .then((r: any) => {
        if (r) {
          dispatch(changePaymentPlanResponse(r))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getPaymentDetails: any = (userId: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new SettingsApi(AxiosClass)
      .getCurrentPlanDetails(userId)
      .then((r: any) => {
        if (r) {
          dispatch(getPlanDetailResponse(r))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
