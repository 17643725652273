import './PageNotFound.scss'

import Lottie from 'lottie-react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import * as animationData from '../../Assets/Lottie/butterfly-404.json'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { type pageDetail } from '../../Redux/slices/pageDetailSlice'
import { loading } from '../../Redux/slices/userSlice'
import Footer from '../Footer/Footer'
import Loader from '../Loader/Loader'

const PageNotFound = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)
  const userState = useAppSelector(state => state.user)

  const backTohistory = () => {
    navigate(-1)
  }

  useEffect(() => {
    dispatch(loading(false))
    if (!userState?.value?.data?._id && pageDetailState?.data?.hidden_response?.message === "Member doesn't exists") {
      navigate(RouteConstants.REGISTER)
    }
  }, [])

  return !userState?.value?.data?._id &&
    (pageDetailState?.data?.hidden_response?.message === "Member doesn't exists" ||
      pageDetailState?.data?.privacy_status === 'PRIVATE') ? (
    <Loader />
  ) : (
    <div className="lottie-loader-404">
      <div className="Wrapper404">
        <Lottie animationData={animationData} />
        <button
          onClick={() => {
            backTohistory()
          }}
        >
          Go Back
        </button>
      </div>
      <Footer isdetailed={false} />
    </div>
  )
}

export default PageNotFound
