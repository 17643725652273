import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'

import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useDispatch } from 'react-redux'
import * as Realm from 'realm-web'

import { environment } from './AppConfig/environment'
import { socket } from './AppConfig/socket'
import { useAppSelector } from './Hooks'
import { setRealmUser } from './Redux/slices/metaSlice'
import { type pageDetail, updatePageCounts } from './Redux/slices/pageDetailSlice'
import { CustomRoutes } from './Routes'

// Create the Application

const RealApp = new Realm.App({ id: `${environment.RealmAppId}` })
// initialize GA
ReactGA.initialize('G-CBVBQNETCM')

function App() {
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)

  // Set state variables
  const [, setUser] = useState<any>()

  const dispatch = useDispatch()
  useEffect(() => {
    socket.connect()
    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    const streamlogin = async () => {
      // Authenticate anonymously
      const credentials = Realm.Credentials.emailPassword('shivanshu.l@position2.com', 'Admin@123')

      const user = await RealApp.logIn(credentials)
      setUser(user)
      dispatch(setRealmUser(user))

      const mongodb = RealApp.currentUser?.mongoClient('mongodb-atlas')
      if (!mongodb) return
      const collection = mongodb.db(`${environment.mongodbStream}`).collection('countstreams')

      for await (const change of collection.watch({ page: pageDetailState?.data?._id })) {
        console.log('Count stream ', change)
        // @ts-expect-error fullDocument does not exist
        if (change.fullDocument.page.toString() === pageDetailState?.data._id) {
          // @ts-expect-error fullDocument does not exist
          countReceive(change.fullDocument)
        } else {
          console.log('NO PAGE  DTAIL ', pageDetailState?.data.unique_url)
        }
      }
    }
    streamlogin()
  }, [pageDetailState.data])

  const countReceive = (data: any) => {
    if (data) {
      dispatch(updatePageCounts(data))
    }
  }

  return <CustomRoutes />
}
export default App
