import cn from 'classnames'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Realm from 'realm-web'

import { trackEvent } from '../../Analytics/GA'
import { environment } from '../../AppConfig/environment'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { setPopup, setSidePanel } from '../../Redux/slices/metaSlice'
import { getNotificationCountResponse } from '../../Redux/slices/NotificationSlice'
import { setActiveSideSlug } from '../../Redux/slices/pageDetailSlice'
import { logout } from '../../Redux/slices/userSlice'
import { getNotificationCount } from '../../Redux/thunks/NotificationThunk'

const RealApp = new Realm.App({ id: `${environment.RealmAppId}` })

export function NavComponent(props: any) {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const interval: any = null

  const userState = useAppSelector(state => state.user)
  const notificationState = useAppSelector((state: any) => state?.notification)
  const [notificationCount, setNotoficationCount] = useState<any>('')
  const [, setIntervalData] = useState<any>('')
  const [, setUser] = useState<any>()

  useEffect(() => {
    dispatch(setActiveSideSlug(''))
    dispatch(setPopup(null))
    dispatch(
      setSidePanel({
        panelBody: null,
        status: false,
      })
    )
  }, [])

  useEffect(() => {
    const UserStateId: any = localStorage.getItem('user_id')
    if (userState?.value?.data?._id && UserStateId !== undefined) {
      dispatch(getNotificationCount(userState?.value?.data?._id))
    }
  }, [])

  useEffect(() => {
    const streamlogin = async () => {
      // Authenticate anonymously
      const user = await RealApp.logIn(Realm.Credentials.anonymous())
      setUser(user)

      const mongodb = RealApp.currentUser?.mongoClient('mongodb-atlas')
      if (!mongodb) return
      const collection = mongodb.db(`${environment.mongodbStream}`).collection('notificationbadges')

      for await (const change of collection.watch({
        belongsTo: userState?.value?.data?._id,
      })) {
        /*  console.log(
          "BELONGS TO BEFORE",
          change.fullDocument.belongsTo.toString()
        ); */
        if (
          change.operationType === 'update' &&
          change.fullDocument.belongsTo.toString() === userState?.value?.data?._id
        ) {
          console.log('BELONGS TO AFTER', change.fullDocument.belongsTo.toString())

          setNotoficationCount(change.fullDocument.count)
          dispatch(
            getNotificationCountResponse({
              ...notificationState?.getNotificationCountResp,
              success: null,
            })
          )
        }
      }
    }

    streamlogin()
  }, [userState?.value?.data?._id])

  useEffect(() => {
    return () => {
      clearInterval(interval)
      setIntervalData('')
    }
  }, [])

  useEffect(() => {
    if (notificationState?.getNotificationCountResp?.success === true) {
      setNotoficationCount(notificationState?.getNotificationCountResp?.data?.count)
      dispatch(
        getNotificationCountResponse({
          ...notificationState?.getNotificationCountResp,
          success: null,
        })
      )
    }
  }, [notificationState?.getNotificationCountResp])

  if (!userState?.value?.data?._id) {
    return (
      <nav className="signout">
        <ul>
          <li
            onClick={() => {
              navigate(RouteConstants.LOGIN)
            }}
          >
            <i className="fa fa-butterflymyaccount"></i>
            <span>Sign In</span>
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <>
      <nav>
        <ul>
          <li
            onClick={() => {
              navigate('/mypages')
              props?.handleClose()
              trackEvent('Site Interaction', 'My Pages View', `${userState?.value?.data?._id}`)
            }}
            className={cn('', {
              active: location.pathname === '/mypages',
            })}
          >
            <i className="fa fa-mypages"></i>
            <span>My Pages</span>
          </li>
          <li
            onClick={() => {
              navigate('/joinedpages')
              props?.handleClose()
              trackEvent('Site Interaction', 'Joined Pages View', `${userState?.value?.data?._id}`)
            }}
            className={location.pathname === '/joinedpages' ? 'active' : ''}
          >
            <i className="fa fa-subscribedpages"></i>
            <span>Joined Pages</span>
          </li>
          <li
            onClick={() => {
              dispatch(setActiveSideSlug('notification-page'))
              dispatch(
                setSidePanel({
                  panelBody: `Notifications`,
                  status: true,
                })
              )
              props?.handleClose()
              trackEvent('Site Interaction', 'Notifications Open', `${userState?.value?.data?._id}`)
            }}
          >
            <i className="fa fa-butterflynotifications">
              {' '}
              {notificationCount !== '' && notificationCount > 0 ? (
                <span className="desktopCount">{notificationCount}</span>
              ) : (
                ''
              )}
            </i>
            <span>
              Notifications{' '}
              {isMobile && notificationCount !== '' && notificationCount > 0 ? (
                <span className="mobileCount">{notificationCount}</span>
              ) : (
                ''
              )}
            </span>
          </li>
          <li
            onClick={() => {
              dispatch(setActiveSideSlug('search-page'))
              dispatch(
                setSidePanel({
                  panelBody: `Search Pages`,
                  status: true,
                })
              )
              props?.handleClose()
              trackEvent('Site Interaction', 'Search Open', `${userState?.value?.data?._id}`)
            }}
          >
            <i className="fa fa-butterflysearch"></i>
            <span>Search</span>
          </li>
          <li
            onClick={() => {
              dispatch(setActiveSideSlug('settings-page'))
              dispatch(
                setSidePanel({
                  panelBody: `My Account`,
                  status: true,
                })
              )
              props?.handleClose()
              trackEvent('Site Interaction', 'Account Settings Open', `${userState?.value?.data?._id}`)
            }}
          >
            <i className="fa fa-butterflymyaccount"></i>
            <span>My Account</span>
          </li>
        </ul>
      </nav>
      <nav className="signout">
        <ul>
          <li
            onClick={() => {
              dispatch(logout())
              navigate(RouteConstants.LOGIN)
              trackEvent('Site Interaction', 'User Logout', `${userState?.value?.data?._id}`)
            }}
          >
            <i className="fa fa-butterflysignout"></i>
            <span>Sign out</span>
          </li>
          <li
            onClick={() => {
              navigate('/page/create')
            }}
            className="CreatePage"
          >
            <i className="fa fa-createpage"></i>
            <span>Create Page</span>
          </li>
        </ul>
      </nav>
    </>
  )
}
