import cn from 'classnames'
import ct from 'countries-and-timezones'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { environment } from '../../AppConfig/environment'
import markerMapIcon from '../../Assets/Images/pointer-pin.svg'
import { createdByYear, createdDateAndByMonth, dateConvert, tConvert } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import { addCurrentEventsDataAction, addPageAction } from '../../Redux/slices/pageDetailSlice'
import { eventInviteUpdateThunk, getEventThunk } from '../../Redux/thunks/pageDetailThunk'
import EventEdit from './EventEdit'

const EventDetails = (props: any) => {
  const mapElement: any = useRef(null)
  const dispatch = useDispatch()
  const pageDetailState: any = useAppSelector((state: any) => state.pagedetail)
  const userState: any = useAppSelector(state => state.user)
  const currentEventData = pageDetailState?.currentEventsData
  const { setEventHeader } = props
  const [isEditEvent, setIseditEvent] = useState(false)
  const [event, setEvent] = useState<any>(props?.event)
  const [eventTimeZone, setEventTimeZone] = useState('')
  const [currentEvent, setCurrentEvent] = useState<any>()
  const defaultProps = {
    center: {
      lat: parseFloat(event.latitude),
      lng: parseFloat(event.longitude),
    },
    zoom: 15,
  }

  const googleRedirection = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${parseFloat(event.latitude)},${parseFloat(event.longitude)}`,
      '_blank'
    )
  }

  const eventTimeSlot = () => {
    if (dateConvert(event?.startDate) === dateConvert(event?.endDate)) {
      return (
        <>
          <p>
            <span>Date</span>
            {`${createdDateAndByMonth(event?.startDate)}, ${createdByYear(event?.startDate)}`}
          </p>
          <p>
            <span>Time</span>
            {`${event.startTime ? tConvert(event?.startTime) : ''} to ${
              event.endTime ? tConvert(event?.endTime) : ''
            } (${eventTimeZone})`}
          </p>
        </>
      )
    } else {
      return (
        <>
          <p>
            <span>Date & Time</span>
            {`${createdDateAndByMonth(event?.startDate)}, ${tConvert(event?.startTime)} - ${createdDateAndByMonth(
              event?.endDate
            )}, ${tConvert(event?.endTime)} (${createdByYear(event?.startDate)}) (${eventTimeZone})`}
          </p>
        </>
      )
    }
  }

  const closeEditEvent = (result: any) => {
    if (result.type === 'update') {
      setEvent(result)
    }
    setIseditEvent(false)
    mapElement?.current?.refresh()
    setEventHeader('View all events')
  }

  useEffect(() => {
    dispatch(addPageAction('side-panel-top-scroll'))
    if (event._id) {
      dispatch(getEventThunk(event?._id, userState?.value?.data?._id))
    }
    return () => {
      dispatch(addCurrentEventsDataAction([]))
    }
  }, [])

  useEffect(() => {
    setEvent(props.event)
    const country = ct.getCountry(props?.event?.countryShort)
    setEventTimeZone(country?.timezones[0] ?? '')
  }, [props?.event])

  useEffect(() => {
    setCurrentEvent(currentEventData)
  }, [currentEventData])

  const renderMarkers = (map: any, maps: any) => {
    const icon = {
      url: markerMapIcon, // url
      scaledSize: new maps.Size(30, 30), // scaled size
      origin: new maps.Point(0, 0), // origin
      anchor: new maps.Point(0, 0), // anchor
    }

    const marker = new maps.Marker({
      position: {
        lat: parseFloat(event.latitude),
        lng: parseFloat(event.longitude),
      },
      map,
      title: '',
      icon,
    })
    return marker
  }

  const createMapOptions = (maps: any) => {
    return {
      zoomControl: false,
      gestureHandling: 'none',
      disableDefaultUI: true,
    }
  }

  const checkActionStatus = (type: string) => {
    const ActionRecord = currentEvent[type]?.find(
      (actionData: any) => actionData.email === userState?.value?.data?.email
    )
    if (ActionRecord) {
      return true
    } else {
      return false
    }
  }

  const updateEventAction = (type: string) => {
    const payLoad = {
      email: userState?.value?.data?.email,
      inviteStatus: type, // yes - going, maybe - interested , no - notinterest
    }
    dispatch(eventInviteUpdateThunk(event?._id, payLoad, userState?.value?.data?._id, pageDetailState?.data?._id))
  }

  return !isEditEvent ? (
    <div className="EventDetails">
      <div className="EventImg">
        {event.image ? <img src={event?.image} alt="Event image" /> : <div className="empty-event-image"></div>}
      </div>
      <div className="EventName">
        <h4>{event?.name}</h4>
        <p>
          <span>{currentEventData?.members?.length}</span> Invited &nbsp;|&nbsp;{' '}
          <span>{currentEventData?.going?.length}</span> Going &nbsp;|&nbsp;&nbsp;
          <span>{currentEventData?.interested?.length}</span> Interested
        </p>
      </div>
      <div className="EventDescp">
        <p>{event?.description}</p>
      </div>

      <div className="VenueDetails">
        <div className="lhs">
          <>{eventTimeSlot()}</>
          <p>
            <span>Venue</span>
            {event?.venue}
          </p>
        </div>
        <div className="rhs">
          <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            {event.latitude === '' || event.longitude === '' ? (
              <div className="no-address">Address not found</div>
            ) : (
              <>
                <GoogleMapReact
                  ref={mapElement}
                  bootstrapURLKeys={{
                    key: environment.googleMapApiKey,
                  }}
                  defaultCenter={defaultProps.center}
                  onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                  options={createMapOptions}
                  defaultZoom={defaultProps.zoom}
                ></GoogleMapReact>
                <div
                  onClick={() => {
                    googleRedirection()
                  }}
                  className="google-map-wrapper"
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
      {pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ? (
        <button
          onClick={() => {
            setIseditEvent(true)
          }}
          className="mr-4"
        >
          Edit event details
        </button>
      ) : (
        <>
          <button
            onClick={() => {
              if (!checkActionStatus('notInterest')) {
                updateEventAction('notInterest')
              }
            }}
            className={cn('event-action', {
              active: currentEvent?.isRead && checkActionStatus('notInterest'),
            })}
          >
            No
          </button>
          <button
            onClick={() => {
              if (!checkActionStatus('going')) {
                updateEventAction('going')
              }
            }}
            className={cn('event-action', {
              active: currentEvent?.isRead && checkActionStatus('going'),
            })}
          >
            Yes
          </button>
          <button
            onClick={() => {
              if (!checkActionStatus('interested')) {
                updateEventAction('interested')
              }
            }}
            className={cn('event-action', {
              active: currentEvent?.isRead && checkActionStatus('interested'),
            })}
          >
            Interested
          </button>
        </>
      )}
    </div>
  ) : (
    <EventEdit event={event} changeHeaderTitle={setEventHeader} closeEditEvent={closeEditEvent}></EventEdit>
  )
}

export default EventDetails
