import { type AxiosInstance } from 'axios'

import {
  MYSUBSCIBEPAGE,
  PAGECREATEURL,
  PAGEURLCREATE,
  PAGEVALIDATEURL,
  SHAREINVITEMAILURL,
} from '../../Constants/apiConstants'

export default class PageApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor
  }

  public async createPost(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(PAGECREATEURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async urlValidation(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(PAGEVALIDATEURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async createNewUniqueURL(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(PAGEURLCREATE, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async fetchMySubscribePage(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(MYSUBSCIBEPAGE?.replace(':userId', payload.userId))
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async inviteViaMail(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(SHAREINVITEMAILURL?.replaceAll(':pageId', payload?.pageId).replaceAll(':type', payload?.type), {
            email: payload?.email,
            description: payload?.message,
          })
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
