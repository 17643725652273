import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { createdByMonthYear, isset } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import { getBlockmembers } from '../../Redux/slices/settingsSlice'
import { getBlockmembersThunk, unblockMemberThunk } from '../../Redux/thunks/settingsThunk'

const BlockedMembers = () => {
  const dispatch = useDispatch()
  const userState = useAppSelector((state: any) => state?.user)
  const blockMemberState = useAppSelector((state: any) => state?.settings?.Blockmembers)
  const [memberList, setMemberList] = useState<any[]>([])
  const [memberFilterList, setMemberFilterList] = useState<any[]>([])
  const [querySearch, setQuerySearch] = useState('')

  useEffect(() => {
    dispatch(getBlockmembersThunk(userState?.value?.data?._id))
  }, [])

  useEffect(() => {
    setMemberList(blockMemberState)
    setMemberFilterList(blockMemberState)
  }, [blockMemberState])

  const filterBySearch = (event: any) => {
    const query = event.target.value
    setQuerySearch(query)
    if (query === '') {
      setMemberFilterList(memberList)
      return
    }
    filterFunction(query)
  }

  const filterFunction = (query: string) => {
    // Create copy of item list
    let updatedList = memberList
    // Include all elements which includes the search query
    updatedList = updatedList?.filter(
      (membersItem: any, key: number) => membersItem.member.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
    // Trigger render with updated values
    setMemberFilterList(updatedList)
  }

  const unBlockMember = (unBlockedmembersItem: any) => {
    setQuerySearch('')
    let updatedList = memberList
    // Include all elements which includes the search query
    updatedList = updatedList?.filter(
      (membersItem: any, key: number) => membersItem?.member._id !== unBlockedmembersItem?.member._id
    )
    dispatch(getBlockmembers(updatedList))
    const payload = {
      userId: userState?.value?.data?._id,
      memberId: unBlockedmembersItem?.member?._id,
    }
    dispatch(unblockMemberThunk(payload))

    // Trigger render with updated values
  }

  return (
    <div className="Members">
      <div className="MemberHeader">
        <div className="MembersSearch">
          <i className="fa fa-butterflysearch" aria-hidden="true"></i>
          <input onChange={filterBySearch} value={querySearch} placeholder="Search members" />
        </div>
      </div>
      <div className="MemberBody">
        {memberFilterList?.length ? (
          <ul>
            {memberFilterList?.map((membersItem: any, index: number) => {
              return (
                <li key={index} className="EachMember">
                  <div className="MemberDetail">
                    <div className="owner">
                      <div className="ownerPic">
                        {isset(membersItem?.member?.image) ? (
                          <img src={membersItem?.member?.image} alt={membersItem?.member?.name} />
                        ) : (
                          <div className="ownerAlpha">
                            <span>{membersItem?.member?.name?.charAt(0)}</span>
                          </div>
                        )}
                      </div>
                      <div className="ownerName">
                        {membersItem?.member?.name}
                        <span>Member since {createdByMonthYear(membersItem?.member?.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="IconsList"
                    onClick={() => {
                      unBlockMember(membersItem)
                    }}
                  >
                    <i className="fa fa-butterflyblock" aria-hidden="true"></i>
                    <span>Unblock</span>
                  </div>
                </li>
              )
            })}
          </ul>
        ) : (
          <div className="no-record">No blocked member found</div>
        )}
      </div>
    </div>
  )
}
export default BlockedMembers
