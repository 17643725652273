import 'react-multi-email/dist/style.css'
import 'react-datepicker/dist/react-datepicker.css'

import { Autocomplete, useLoadScript } from '@react-google-maps/api'
import { Formik } from 'formik'
import React, { forwardRef, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
import { ReactMultiEmail } from 'react-multi-email'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { trackEvent } from '../../Analytics/GA'
import { environment } from '../../AppConfig/environment'
import { useAppSelector } from '../../Hooks'
import { addPageAction, createEventsResponse } from '../../Redux/slices/pageDetailSlice'
import { loading } from '../../Redux/slices/userSlice'
import { addNewEvent, uploadMedia } from '../../Redux/thunks/pageDetailThunk'
import RoundedCropper from '../Modals/CropperPopup/RoundedCropper'
import MemberList from './MemberList'

const CreateEvents = (props: any) => {
  const placesLibrary: any = ['places']
  const dispatch = useDispatch()
  const userState: any = useAppSelector(state => state.user)
  const pageDetailState = useAppSelector((state: any) => state.pagedetail)

  const [searchResult, setSearchResult] = useState<any>('Result: none')

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: environment.googleMapApiKey,
    libraries: placesLibrary,
  })

  const { setEventsScreen, changeHeaderTitle } = props
  const [emails, setEmails] = useState<any>([])
  const [imgValidation, setImgValidation] = useState('')
  const [addressValue, setAddressValue] = useState('')
  const [showCropper, setShowCropper] = useState(false)
  const [isSelectMember, setIsSelectMember] = useState(false)
  const [img, setImg] = useState<any>({
    url: '',
    file: '',
    selected_img: '',
  })
  const [startDate, setStartDate] = useState(new Date().toString())
  const [endDate, setEndDate] = useState(new Date(Date.now() + 60000 * 60).toString())

  const schema = Yup.object().shape({
    name: Yup.string().required('name is required'),
    description: Yup.string().required('description is required'),
  })

  const eventSubmit = (data: any) => {
    data.image = img.file
    data.startdate = startDate
    data.enddate = endDate
    data.emails = emails

    const memeberArr: any = []
    for (let i = 0; i < emails?.length; i++) {
      memeberArr.push({ email: emails[i] })
    }
    const startdate = new Date(data?.startdate)
    const enddate = new Date(data?.enddate)

    const payload: any = {
      user: userState?.value?.data?._id,
      page: pageDetailState?.data?._id,
      name: data?.name,
      latitude: '',
      longitude: '',
      description: data?.description,
      startDate: data.startdate,
      startTime: ('0' + startdate.getHours()).slice(-2) + ':' + ('0' + startdate.getMinutes()).slice(-2),
      endDate: data.enddate,
      endTime: ('0' + enddate.getHours()).slice(-2) + ':' + ('0' + enddate.getMinutes()).slice(-2),
      venue: addressValue,
      members: memeberArr,
    }
    dispatch(loading(true))
    if (img.url !== '') {
      const formData: any = new FormData()
      formData.append('media', img.file)
      uploadMedia(formData, pageDetailState?.data?._id).then(response => {
        if (response?.data?.data[0]?.url) {
          payload.image = response?.data?.data[0]?.url
          dispatch(addNewEvent(payload))
        }
      })
    } else {
      dispatch(addNewEvent(payload))
    }
    trackEvent('Page Interaction', 'Event Create', `${userState?.value?.data?._id}`)
  }

  const openImgWindow = () => {
    const ImgInput: any = document.getElementById('page-image')
    if (ImgInput) {
      ImgInput?.click()
    }
  }

  const typeValidation = (event: any) => {
    setImg((data: any) => {
      return {
        ...data,
        file: '',
      }
    })
    const selectedFile = event.target.files[0]
    const imageFile = selectedFile
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgValidation('Only jpg | jpeg | png images allowed.')
      return false
    }
    setImgValidation('')
    if (event.target.files?.length) {
      const reader: any = new FileReader()
      const file: any = selectedFile
      reader.readAsDataURL(file)
      setImg((data: any) => {
        return { ...data, file }
      })
      reader.onload = () => {
        setImg((data: any) => {
          return { ...data, selected_img: reader?.result }
        })
        setShowCropper(true)
      }
    }
  }

  const CroppedImage = (e: any) => {
    if (e && e !== 'cancel') {
      setImg((data: any) => {
        return { ...data, url: URL.createObjectURL(e), file: e }
      })
    }
    setShowCropper(false)
  }

  const filterPassedTime = (time: any) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)
    return currentDate.getTime() < selectedDate.getTime()
  }

  const filterEndPassedTime = (time: any) => {
    const currentDate = new Date(startDate)
    const selectedDate = new Date(time)
    return currentDate.getTime() < selectedDate.getTime()
  }

  const filterStartDate = (date: any) => {
    const selectedDate = new Date(date)
    if (endDate === '') {
      return selectedDate > new Date(-1)
    }

    const currentDate = endDate === '' ? new Date() : new Date(endDate)

    return currentDate.getTime() >= selectedDate.getTime() && selectedDate >= new Date()
  }

  const selectedMemberList = (memberList: any) => {
    setEmails((data: any) => {
      const emailIds: any = []
      memberList?.forEach((e: any) => {
        emailIds.push(e?.user?.email)
      })
      setIsSelectMember(false)
      changeHeaderTitle('View all events')
      return emailIds?.length ? [...data, ...emailIds] : data
    })
  }

  const openMemberList = () => {
    setIsSelectMember(true)
    changeHeaderTitle('Select your page members')
  }

  useEffect(() => {
    if (pageDetailState?.createEventsResp?.success) {
      dispatch(loading(false))
      dispatch(
        createEventsResponse({
          ...pageDetailState?.createEventsResp,
          success: null,
        })
      )
      props.setEventsScreen('list')
    }
  }, [pageDetailState?.createEventsResp])

  useEffect(() => {
    changeHeaderTitle('View all events')
    dispatch(addPageAction('side-panel-top-scroll'))
  }, [])

  const onLoad = (autocomplete: any) => {
    setSearchResult(autocomplete)
  }

  const onPlaceChanged = () => {
    if (searchResult !== null) {
      const place = searchResult.getPlace()
      const name = place.name
      const formattedAddress = place.formatted_address

      const result = formattedAddress.includes(name)
      let lastAddress = formattedAddress
      if (!result) {
        lastAddress = name + ', ' + lastAddress
      }
      setAddressValue(lastAddress)
    }
  }

  const addressSelected = (event: any) => {
    // Access input value
    const inputValue = event.target.value.trim()
    setAddressValue(inputValue)
  }

  const DatePickerCustomInput = forwardRef(
    ({ value, onClick }: { value: any; onClick: any }, ref: React.LegacyRef<HTMLInputElement>) => (
      <input
        className="date-picker form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Select date & time"
        readOnly
      />
    )
  )

  DatePickerCustomInput.displayName = 'DatePickerCustomInput'

  return (
    <div className="CreateEvent">
      <div className={isSelectMember ? 'ProfilePic d-none' : 'ProfilePic'}>
        <div className="ImgCroper">
          {img?.url === '' || img?.url === null ? (
            <span onClick={openImgWindow}>
              <i className="fa fa-camera noImg"></i>Add event image
            </span>
          ) : (
            <>
              <div className="ImgWrapper">
                <img src={img?.url} alt="page-profile-image" />
              </div>
              <span className="cropImg" onClick={openImgWindow}>
                <i className="fa fa-camera"></i>
              </span>
            </>
          )}
          <input type="file" name="image" className="d-none" id="page-image" onChange={typeValidation} />
          <p className="text text-danger errorMessage">{imgValidation}</p>
        </div>
      </div>
      <div className={isSelectMember ? 'FormSection d-none' : 'FormSection'}>
        <Formik
          validateOnMount={true}
          validateOnChange={true}
          validationSchema={schema}
          onSubmit={eventSubmit}
          initialValues={{
            name: '',
            description: '',
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* Event Name Input */}
              <Form.Label htmlFor="email">
                Event Name<sup>*</sup>
              </Form.Label>
              <InputGroup className="mb-4" hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Add name to your event"
                  required
                  name="name"
                  maxLength={40}
                  onChange={handleChange}
                  value={values?.name}
                />
              </InputGroup>

              {/* Event Details Input */}
              <Form.Label htmlFor="details">
                Event Details<sup>*</sup>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id="details"
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="Your event description"
                  name="description"
                  onChange={handleChange}
                  maxLength={250}
                  value={values?.description}
                />
                <span className="text-count">{values?.description.replace(/(\r\n|\n|\r)/g, '').length}/250</span>
              </InputGroup>

              {/* Event Date input */}
              <Row className="mb-4 notMobile">
                <Col>
                  <Form.Label htmlFor="details">
                    Start Date & Time<sup>*</sup>
                  </Form.Label>
                  <DatePicker
                    selected={new Date(startDate)}
                    onChange={(date: any) => {
                      setStartDate(date)
                    }}
                    showTimeSelect
                    selectsStart
                    placeholderText="Select date & time"
                    filterDate={filterStartDate}
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    filterTime={filterPassedTime}
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="form-control"
                    customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                  />
                </Col>
                <Col className="enddate">
                  <Form.Label htmlFor="details">
                    End Date & Time<sup>*</sup>
                  </Form.Label>
                  <DatePicker
                    disabled={!startDate}
                    selected={new Date(endDate)}
                    placeholderText="Select date & time"
                    onChange={(date: any) => {
                      setEndDate(date)
                    }}
                    showTimeSelect
                    minDate={new Date(startDate)}
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    filterTime={filterEndPassedTime}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                  />
                </Col>
              </Row>

              <Row className="mb-4 mobile">
                <Row className="mb-4">
                  <Form.Label htmlFor="details">
                    Start Date & Time<sup>*</sup>
                  </Form.Label>
                  <DatePicker
                    selected={new Date(startDate)}
                    onChange={(date: any) => {
                      setStartDate(date)
                    }}
                    showTimeSelect
                    selectsStart
                    placeholderText="Select date & time"
                    filterDate={filterStartDate}
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    filterTime={filterPassedTime}
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="form-control"
                    customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                  />
                </Row>
                <Row>
                  <Form.Label htmlFor="details">
                    End Date & Time<sup>*</sup>
                  </Form.Label>
                  <DatePicker
                    disabled={!startDate}
                    selected={new Date(endDate)}
                    placeholderText="Select date & time"
                    onChange={(date: any) => {
                      setEndDate(date)
                    }}
                    showTimeSelect
                    minDate={new Date(startDate)}
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    filterTime={filterEndPassedTime}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                  />
                </Row>
              </Row>

              {/* Event Venue Input */}
              <Form.Label htmlFor="venue">
                Venue<sup>*</sup>
              </Form.Label>
              <InputGroup className="mb-4" hasValidation>
                {isLoaded ? (
                  <Autocomplete className="address-autocomplete" onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                    <input
                      type="text"
                      placeholder="Add complete address"
                      className="form-control"
                      onChange={addressSelected}
                    />
                  </Autocomplete>
                ) : (
                  <div>Loading...</div>
                )}
              </InputGroup>

              {/* Event Details Input */}
              <Form.Label htmlFor="details">Add Guests</Form.Label>
              <ReactMultiEmail
                className="guest-multi-email"
                placeholder="Paste or enter guest email"
                emails={emails}
                onChange={(_emails: string[]) => {
                  setEmails(_emails)
                }}
                getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span
                        data-tag-handle
                        onClick={() => {
                          removeEmail(index)
                        }}
                      >
                        ×
                      </span>
                    </div>
                  )
                }}
              />
              <span onClick={openMemberList} className="MemberSelect">
                <i className="fa fa-membersicon"></i>
                Select your page members
              </span>
              <div className="d-flex ActionCta">
                <button type="button" onClick={() => setEventsScreen('list')} className="cancelCta">
                  Cancel
                </button>
                <button type="submit" disabled={!isValid || startDate === '' || endDate === '' || addressValue === ''}>
                  <i className="fa fa-eventsicon"></i>
                  Create Event
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {showCropper ? (
        <RoundedCropper
          show={showCropper}
          selectedImg={img.selected_img}
          setCroppedImage={CroppedImage}
          croppershap={'rect'}
        ></RoundedCropper>
      ) : (
        ''
      )}
      {isSelectMember ? (
        <MemberList setEventsScreen={setEventsScreen} selectedMemberList={selectedMemberList} type={`add`} />
      ) : (
        ''
      )}
    </div>
  )
}

export default CreateEvents
