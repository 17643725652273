import { type AxiosInstance } from 'axios'

import {
  GETNOTIFICATIONCOUNTURL,
  NOTIFICATIONGETURL,
  READALLNOTIFICATIONURL,
  TAKEACTIONNOTIFICATIONURL,
} from '../../Constants/apiConstants'

export default class NotificationApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor
  }

  public async fetchNotificationList(userId: any, pageNo: number) {
    return await new Promise((resolve, reject) => {
      try {
        const url = NOTIFICATIONGETURL?.replace(':belongsTo', userId) + '?page=' + pageNo
        this.interceptor
          ?.get(url)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async takeNotificationActionService(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(TAKEACTIONNOTIFICATIONURL, payload)
          .then((r: any) => {
            resolve({ ...r?.data, ...payload })
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getNotification_Count(userId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETNOTIFICATIONCOUNTURL?.replace(':userId', userId))
          .then((r: any) => {
            resolve({ ...r?.data })
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async updateNotificationReadStatus(userId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.patch(READALLNOTIFICATIONURL?.replace(':userId', userId))
          .then((r: any) => {
            resolve({ ...r?.data })
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
