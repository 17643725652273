import './LinkedExpired.scss'

import { useNavigate } from 'react-router-dom'

import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'

const LinkExpired = () => {
  const navigate = useNavigate()
  const userState = useAppSelector((state: any) => state?.user)

  const backRedirecton = () => {
    navigate(userState?.value?.data?._id ? RouteConstants.MYPAGES : RouteConstants.REGISTER)
  }

  return (
    <div className="Wrapper404 link-expired-content">
      <h3>Oops!</h3>
      <h3>The link is not valid any more.</h3>
      <h6>The link you are trying to access has expired.</h6>

      <button type="button" className="back-btn" onClick={backRedirecton}>
        Go Back
      </button>
    </div>
  )
}
export default LinkExpired
