import './Loader.scss'

import { useAppSelector } from '../../Hooks'
import LottieControl from '../lottie'

function Loader(props: any) {
  const userData: any = useAppSelector((state: any) => state?.user)

  return (
    <div className="GlobalLoader">
      <LottieControl />
      {props.isActive && (
        <>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${userData.loadPercentage}%` }}
              aria-valuenow={userData.loadPercentage}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>

          {(props.totalItems > 1 || props.isExternal) && (
            <div className="UploadCount">
              {props.status ? props.status : userData.loadItemsDone + '/' + props.totalItems}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Loader
