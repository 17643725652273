import React, { useState } from 'react'
import ReactImageVideoLightbox from 'react-image-video-lightbox'

import { isset, timeSince } from '../../commonservice'
import PostMediaView from '../Modals/PostMediaView'
import AudioWaves from '../Shared/AudioWaves'

interface PostCreateNotitification {
  element: any
  key: number
  children?: React.ReactNode
  takeNotification: any
  goToPageSettings: any
  keyindex: number
}

export const PostCreateNotification = ({
  element,
  key,
  takeNotification,
  goToPageSettings,
  keyindex,
}: PostCreateNotitification) => {
  const [mediaPopup, setMediaPopup] = useState<any>({
    data: {},
    status: false,
  })

  const [lightboxActiveIndex, setLightboxActiveIndex] = useState<any>(0)
  const [lightboxData, setLightboxData] = useState<any>('')
  const [lightboxFlag, setLightboxFlag] = useState<any>(false)

  const stopAllMedia = (key: number, vindex: any, type: string) => {
    if (type === 'video') {
      // setPlayvideo(key + vindex);
      const activeItemThumb: any = document.getElementById(`notification-video-thumb-${key}-${vindex}`)
      if (activeItemThumb) {
        activeItemThumb.classList.add('d-none')
      }
      const activeItemPlayer: any = document.getElementById(`notification-video-player-${key}-${vindex}`)
      if (activeItemPlayer) {
        activeItemPlayer.play()
      }
    }
  }

  return (
    <>
      <div
        className="EachNotificationWrapper"
        key={'notification-item-' + key}
        id={keyindex === 0 ? 'notification-item-' + keyindex : ''}
      >
        <div className="EachNotification">
          {element?.notification?.split('Thanks for the post').length === 2 ? (
            <div className="ProfileImg">
              {isset(element?.belongsTo?.image) ? (
                <img src={element?.belongsTo?.image} alt="" />
              ) : (
                <span>{element?.belongsTo?.name?.charAt(0)}</span>
              )}
            </div>
          ) : (
            <div className="ProfileImg">
              {isset(element?.createdBy?.image) ? (
                <img src={element?.createdBy?.image} alt="" />
              ) : (
                <span>{element?.createdBy?.name?.charAt(0)}</span>
              )}
            </div>
          )}
          <div className="NotificationText">
            <div
              dangerouslySetInnerHTML={{
                __html: element?.notification,
              }}
            ></div>
          </div>
        </div>
        <>
          {(element?.media_items?.length === 1 &&
            element.post_description !== '' &&
            element?.media_items?.filter((opt: any) => opt.status === 'APPROVED' || opt.status === 'REJECTED')
              .length !== element?.media_items?.length) ||
          (element?.media_items?.length > 1 &&
            element?.media_items?.filter((opt: any) => opt.status === 'APPROVED' || opt.status === 'REJECTED')
              .length !== element?.media_items?.length) ? (
            <div className="row">
              {element.name === 'owner' ? (
                <div className="ActionCTA">
                  <span
                    className="accept"
                    onClick={() =>
                      takeNotification({
                        actionType: 'approve_post_all',
                        notification: {
                          ...element,
                          status: 'APPROVED',
                          type: 'description',
                        },
                      })
                    }
                  >
                    <i className="fa fa-butterflytick"></i>
                    Approve All
                  </span>
                  <span
                    className="reject"
                    onClick={() =>
                      takeNotification({
                        actionType: 'reject_post_all',
                        notification: {
                          ...element,
                          status: 'REJECTED',
                          type: 'description',
                        },
                      })
                    }
                  >
                    <i className="fa fa-butterflynoapproval"></i>
                    Reject All
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </>

        <>
          {element?.post_description !== '' ? (
            <div className="row">
              <div className="description">
                {element.post_description}
                {element.approval_required && element?.media_items?.length === 0 ? (
                  <>
                    {element?.status === 'PENDING' ? (
                      <div className="ActionCTA">
                        <span
                          className="accept"
                          onClick={() =>
                            takeNotification({
                              actionType: 'approve_post',
                              notification: {
                                ...element,
                                status: 'APPROVED',
                                type: 'description',
                              },
                            })
                          }
                        >
                          <i className="fa fa-butterflytick"></i>
                          Approve
                        </span>
                        <br />

                        <span
                          className="reject"
                          onClick={() =>
                            takeNotification({
                              actionType: 'reject_post',
                              notification: {
                                ...element,
                                status: 'REJECTED',
                                type: 'description',
                              },
                            })
                          }
                        >
                          <i className="fa fa-butterflynoapproval"></i>
                          Reject
                        </span>
                      </div>
                    ) : (
                      <div className="ActionCTA">
                        <span className={element?.status === 'APPROVED' ? 'accept disabled' : 'reject disabled'}>
                          <i
                            className={
                              element?.status === 'APPROVED' ? 'fa fa-butterflytick' : 'fa fa-butterflynoapproval'
                            }
                          ></i>
                          {element?.status[0]?.toUpperCase() + element?.status?.slice(1)?.toLowerCase()}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </>

        <>
          {element?.media_items?.filter((opt: any) => opt.media_type === 'audio')?.length ? (
            <>
              {element?.media_items
                ?.filter((opt: any) => opt.media_type === 'audio')
                ?.map((ele: any, index1: number) => {
                  return (
                    <>
                      <div className="row">
                        <div className="dashed-border-top"></div>
                      </div>
                      <AudioWaves audioURL={ele?.url} stopAllMedia={() => {}} mediaPlay={false} />
                      <div className="row">
                        <div className="EachActionItem no-border">
                          <div className="description">
                            {ele?.caption}
                            {ele.approval_required || element.name === 'member' ? (
                              <div className="ActionCTA">
                                {ele?.status === 'PENDING' && element.name === 'owner' ? (
                                  <>
                                    <span
                                      className="accept"
                                      onClick={() => {
                                        const mediaItems: any = []
                                        element?.media_items?.forEach((e: any) => {
                                          if (e?.url === ele?.url) {
                                            mediaItems.push({
                                              ...e,
                                              status: 'APPROVED',
                                            })
                                          } else {
                                            mediaItems.push(e)
                                          }
                                        })
                                        takeNotification({
                                          actionType: 'approve_post',
                                          notification: {
                                            ...element,
                                            media_items: mediaItems,
                                            status: 'APPROVED',
                                            type: 'media',
                                            media_url: ele.url,
                                            modified_media: ele,
                                          },
                                        })
                                      }}
                                    >
                                      <i className="fa fa-butterflytick"></i>
                                      Approve
                                    </span>
                                    <br />

                                    <span
                                      className="reject"
                                      onClick={() => {
                                        const mediaItems: any = []
                                        element?.media_items?.forEach((e: any) => {
                                          if (e?.url === ele?.url) {
                                            mediaItems.push({
                                              ...e,
                                              status: 'REJECTED',
                                            })
                                          } else {
                                            mediaItems.push(e)
                                          }
                                        })
                                        takeNotification({
                                          actionType: 'reject_post',
                                          notification: {
                                            ...element,
                                            media_items: mediaItems,
                                            type: 'media',
                                            media_url: ele.url,
                                            modified_media: ele,
                                          },
                                        })
                                      }}
                                    >
                                      <i className="fa fa-butterflynoapproval"></i>
                                      Reject
                                    </span>
                                  </>
                                ) : ele?.status === 'PENDING' && element.name === 'member' ? (
                                  ''
                                ) : (
                                  <>
                                    <span
                                      className={ele?.status === 'APPROVED' ? 'accept disabled' : 'reject disabled'}
                                    >
                                      <i
                                        className={
                                          ele?.status === 'APPROVED'
                                            ? 'fa fa-butterflytick'
                                            : 'fa fa-butterflynoapproval'
                                        }
                                      ></i>
                                      {ele?.status[0]?.toUpperCase() + ele?.status?.slice(1)?.toLowerCase()}
                                    </span>
                                  </>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
            </>
          ) : (
            ''
          )}
        </>
        {element?.media_items?.filter((opt: any) => opt.media_type !== 'audio').length ? (
          <div className="row">
            {element?.media_items
              ?.filter((opt: any) => opt.media_type !== 'audio')
              ?.map((ele: any, index1: number) => {
                return (
                  <div
                    className={
                      ele.approval_required
                        ? ele?.media_type === 'video'
                          ? 'EachActionItem video-wrapper'
                          : 'EachActionItem'
                        : ele?.media_type === 'video'
                        ? 'EachActionItem noapprove video-wrapper'
                        : 'EachActionItem noapprove'
                    }
                    key={index1}
                  >
                    <div
                      className={
                        element?.media_items?.filter((opt: any) => opt.media_type === 'video').length
                          ? 'media video-player'
                          : 'media'
                      }
                    >
                      {ele?.media_type === 'image' ? (
                        <img
                          src={ele.url}
                          alt=""
                          onClick={() => {
                            const activeItems: any = []
                            let activeIndex: any = 0
                            element?.media_items
                              ?.filter((opt: any) => opt.media_type === 'image')
                              .forEach((fMedia: any, mIndex: any) => {
                                activeItems.push({
                                  title: fMedia.caption,
                                  type: 'photo',
                                  url: fMedia?.url,
                                  altTag: fMedia.caption,
                                })
                                if (fMedia?.url === ele?.url) {
                                  activeIndex = mIndex
                                }
                              })
                            setLightboxActiveIndex(activeIndex)
                            setLightboxData(activeItems)
                            setLightboxFlag(true)
                          }}
                        />
                      ) : ele?.media_type === 'video' ? (
                        <>
                          <video
                            key={index1}
                            src={ele.url}
                            controls={true}
                            // loop
                            // muted
                            // autoPlay={key + vindex === playideo}
                            id={`notification-video-player-${element?._id}-${index1}`}
                            className="video-player"
                          ></video>
                          <div
                            onClick={() => {
                              stopAllMedia(element?._id, index1, 'video')
                            }}
                            className="video-thumb"
                            id={`notification-video-thumb-${element?._id}-${index1}`}
                            style={{
                              backgroundImage: `url(${
                                ele.type === 'video' && ele.thumbnail ? ele.thumbnail : ele?.url
                              })`,
                            }}
                          >
                            <span
                              onClick={() => {
                                stopAllMedia(element?._id, index1, 'video')
                              }}
                            >
                              <i className="fa fa-play"></i>
                            </span>
                          </div>
                        </>
                      ) : ele.media_type === 'audio' ? (
                        <AudioWaves audioURL={ele?.url} stopAllMedia={() => {}} mediaPlay={false} />
                      ) : (
                        ''
                      )}
                    </div>

                    {ele?.caption !== '' ? <div className="content">{ele?.caption}</div> : ''}
                    {ele.approval_required || element.name === 'member' ? (
                      ele?.status === 'PENDING' && element.name === 'owner' ? (
                        <div className="Ctas">
                          <span
                            className="accept"
                            onClick={() => {
                              const mediaItems: any = []
                              element?.media_items?.forEach((e: any) => {
                                if (e?.url === ele?.url) {
                                  mediaItems.push({
                                    ...e,
                                    status: 'APPROVED',
                                  })
                                } else {
                                  mediaItems.push(e)
                                }
                              })
                              takeNotification({
                                actionType: 'approve_post',
                                notification: {
                                  ...element,
                                  media_items: mediaItems,
                                  status: 'APPROVED',
                                  type: 'media',
                                  media_url: ele.url,
                                  modified_media: ele,
                                },
                              })
                            }}
                          >
                            <i className="fa fa-butterflytick"></i>
                            Approve
                          </span>
                          <br />

                          <span
                            className="reject"
                            onClick={() => {
                              const mediaItems: any = []
                              element?.media_items?.forEach((e: any) => {
                                if (e?.url === ele?.url) {
                                  mediaItems.push({
                                    ...e,
                                    status: 'REJECTED',
                                  })
                                } else {
                                  mediaItems.push(e)
                                }
                              })
                              takeNotification({
                                actionType: 'reject_post',
                                notification: {
                                  ...element,
                                  media_items: mediaItems,
                                  type: 'media',
                                  media_url: ele.url,
                                  modified_media: ele,
                                },
                              })
                            }}
                          >
                            <i className="fa fa-butterflynoapproval"></i>
                            Reject
                          </span>
                        </div>
                      ) : ele?.status === 'PENDING' && element.name === 'member' ? (
                        ''
                      ) : (
                        <div className="Ctas">
                          <span className={ele?.status === 'APPROVED' ? 'accept disabled' : 'reject disabled'}>
                            <i
                              className={
                                ele?.status === 'APPROVED' ? 'fa fa-butterflytick' : 'fa fa-butterflynoapproval'
                              }
                            ></i>
                            {ele?.status[0]?.toUpperCase() + ele?.status?.slice(1)?.toLowerCase()}
                          </span>
                        </div>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
          </div>
        ) : (
          ''
        )}
        <div className="row">
          <div className="time">{timeSince(element.createdAt)}</div>
        </div>
      </div>
      {mediaPopup?.status ? (
        <PostMediaView
          handleclose={() => {
            setMediaPopup({ data: {}, subData: {}, status: false, type: '' })
          }}
          MediaData={mediaPopup}
        ></PostMediaView>
      ) : (
        ''
      )}

      {lightboxFlag ? (
        <div className="light-box-video">
          <ReactImageVideoLightbox
            data={lightboxData}
            startIndex={lightboxActiveIndex}
            showResourceCount={false}
            onCloseCallback={() => {
              // On close
              setLightboxFlag(false)
            }}
            onNavigationCallback={(currentIndex: any) => {}}
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}
