import './AddAlbum.scss'

import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../../Hooks'
import { pageReturnAction } from '../../../Redux/slices/pageDetailSlice'
import { createAlbumThunk, renameAlbumThunk } from '../../../Redux/thunks/pageDetailThunk'
import Loader from '../../Loader/Loader'

function RenameAlbum(props: any) {
  const dispatch = useDispatch()
  const { renamstatus, albumdata, albumpopup, handleclose, pageid } = props
  const [albumName, setAlbumname] = useState(albumdata?.albumName)
  const checkDisbled = () => {
    if (albumpopup === 'add') {
      return albumName.trim() === ''
    } else {
      return albumName === albumdata?.albumName
    }
  }
  const userState: any = useAppSelector(state => state.user)
  const pageDetailReturnAction = useAppSelector((state: any) => state?.pagedetail?.returnAction)

  useEffect(() => {
    if (pageDetailReturnAction === 'add-album-action' || pageDetailReturnAction === 'rename-album-action') {
      handleclose()
      dispatch(pageReturnAction(''))
    }
  }, [pageDetailReturnAction])

  const albumnameAdded = (event: any) => {
    const name = event.target.value
    setAlbumname(name)
  }

  const submitButton = () => {
    if (albumpopup === 'add') {
      dispatch(createAlbumThunk(albumName.trim(), pageid, albumdata?.action))
    } else {
      dispatch(renameAlbumThunk(albumName.trim(), pageid, albumdata?._id))
    }
  }

  return (
    <Modal className="AlbumRename" {...props} show={renamstatus} onHide={handleclose} centered>
      {userState.loading && <Loader />}
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {albumpopup === 'rename' ? <i className="fa fa-butterflyrename"></i> : <i className="fa fa-add-album"></i>}
        <input
          type="text"
          onChange={albumnameAdded}
          placeholder={albumpopup === 'add' ? 'Enter your name album name' : albumdata?.albumName}
          value={albumName}
        />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={submitButton} disabled={checkDisbled()}>
          {albumpopup === 'add' ? 'Add' : 'Save'}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default RenameAlbum
