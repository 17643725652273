import './Members.scss'

import cn from 'classnames'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../Analytics/GA'
import { createdByMonthYear, isset } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import { type pageDetailData, type pageMemberData, pageReturnAction } from '../../Redux/slices/pageDetailSlice'
import { loading } from '../../Redux/slices/userSlice'
import { blockUnblockMemberThunk, pageSendEmailGenericThunk } from '../../Redux/thunks/pageDetailThunk'
import Loader from '../Loader/Loader'
import CommonShareContent from '../Modals/SharePage/CommonShareContent'

const Members = (props: any) => {
  const dispatch = useDispatch()
  const ref: any = useRef<HTMLInputElement>(null)
  const [showMessage, setShowMessage] = useState(false)
  const [filterBySearchQuery, setFilterBySearchQuery] = useState('')
  const [filterByBlockedSearchQuery, setFilterByBlockedSearchQuery] = useState('')
  const [sendIndividual, setSendIndividual] = useState(false)
  const [memberForEmail, setMemberForEmail] = useState<any>()
  const [height, setHeight] = useState(0)
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const userState = useAppSelector(state => state.user)
  const [pageMemberData, setPageMemberData] = useState<pageMemberData[]>([])
  const [pageMembeFilterData, setPageMemberFilterData] = useState<pageMemberData[]>([])
  const [checkedMemberCount, setCheckedMemberCount] = useState<any>()
  const [blockedMemberCount, setBlockedMemberCount] = useState<any>()
  const [messageInfo, setMessageInfo] = useState<string>('')
  const [showBlockedMemberStatus, setShowBlockedMemberStatus] = useState<boolean>(false)

  const [pageBlockedMemberData, setBlockedMemberData] = useState<pageMemberData[]>()
  const [pageBlockedFilterMemberData, setBlockedFilterMemberData] = useState<pageMemberData[]>()

  const pageDetailReturnAction = useAppSelector((state: any) => state?.pagedetail?.returnAction)

  useEffect(() => {
    if (pageDetailReturnAction === 'member-email-send') {
      dispatch(pageReturnAction(''))
      setMessageInfo('')
      setShowMessage(!showMessage)
      setSendIndividual(false)
      setMemberForEmail(null)
      dispatch(loading(false))
    }
  }, [pageDetailReturnAction])

  useLayoutEffect(() => {
    if (ref.current !== null) {
      setHeight(ref.current.offsetHeight)
    }
  })

  useEffect(() => {
    const pageMemberDataUpdate = pageDetailState?.members?.map((members: pageMemberData, index: any) => {
      return {
        ...members,
        isChecked: true,
      }
    })
    const ownerData: any = {
      _id: pageDetailState?.belongsTo?._id,
      user: {
        _id: pageDetailState?.belongsTo?._id,
        name:
          pageDetailState?.belongsTo?.name +
          `${pageDetailState?.belongsTo?._id === userState?.value?.data?._id ? ' (you)' : ''}`,
        email: pageDetailState?.belongsTo?.name,
        createdAt: userState?.value?.data?.createdAt,
        image: pageDetailState?.belongsTo?.image,
      },
      isBlocked: false,
      isChecked: false,
      createdAt: '2023-06-19T07:19:37.594Z',
      updatedAt: '2023-06-19T07:19:37.594Z',
      __v: 0,
    }

    const hasOwner = pageMemberDataUpdate?.find(
      (membersItem: pageMemberData, key: number) => membersItem?.user?._id === pageDetailState?.belongsTo?._id
    )

    if (!hasOwner) {
      pageMemberDataUpdate.unshift(ownerData)
    }

    setPageMemberData(pageMemberDataUpdate)
    if (filterBySearchQuery === '') {
      setPageMemberFilterData(pageMemberDataUpdate)
    } else {
      filterBySearchReturn(filterBySearchQuery)
    }
    setCheckedMemberCount(pageMemberDataUpdate?.length - 1)
    const updateBlockedMember = pageMemberDataUpdate?.filter(
      (membersItem: pageMemberData, key: number) => membersItem.isBlocked
    )
    setBlockedMemberData(updateBlockedMember)

    if (filterByBlockedSearchQuery === '') {
      setBlockedFilterMemberData(updateBlockedMember)
    } else {
      filterBlockedBySearchReturn(filterByBlockedSearchQuery)
    }

    setBlockedMemberCount(updateBlockedMember?.length)
  }, [pageDetailState])

  useEffect(() => {
    setShowBlockedMemberStatus(props.showAllMembers)
  }, [props.showAllMembers])

  const checkBoxUpdate = (members: pageMemberData) => {
    const updatepageMemberData = pageMemberData?.map((membersItem: pageMemberData, key: number) => {
      if (membersItem._id === members._id) {
        return {
          ...membersItem,
          isChecked: !members.isChecked,
        }
      } else {
        return membersItem
      }
    })
    const updatepageFilerMemberData = pageMembeFilterData?.map((membersItem: pageMemberData, key: number) => {
      if (membersItem._id === members._id) {
        return {
          ...membersItem,
          isChecked: !members.isChecked,
        }
      } else {
        return membersItem
      }
    })
    setPageMemberData(updatepageMemberData)
    setPageMemberFilterData(updatepageFilerMemberData)
    const updateCount = updatepageMemberData?.filter(
      (membersItem: pageMemberData, key: number) => membersItem.isChecked
    )
    setCheckedMemberCount(updateCount?.length)
  }

  useEffect(() => {
    if (showBlockedMemberStatus) {
      document.body.classList.add(`blocked-member-screen`)
    } else {
      document.body.classList.remove(`blocked-member-screen`)
    }
  }, [showBlockedMemberStatus])

  const messageValue = (event: any) => {
    // Access input value
    const messageComment = event.target.value.trim()
    setMessageInfo(messageComment)
  }

  const filterBySearch = (event: any) => {
    // Access input value
    const query = event.target.value.trim()
    setFilterBySearchQuery(query)
    filterBySearchReturn(query)
  }

  const filterBySearchReturn = (queryString: string) => {
    if (queryString === '') {
      setPageMemberFilterData(pageMemberData)
      return
    }
    // Create copy of item list
    let updatedList = pageMemberData
    // Include all elements which includes the search query
    updatedList = updatedList?.filter(
      (membersItem: pageMemberData, key: number) =>
        membersItem.user?.name.toLowerCase().includes(queryString.toLowerCase())
    )
    // Trigger render with updated values
    setPageMemberFilterData(updatedList)
  }

  const filterBlockedBySearch = (event: any) => {
    // Access input value
    const query = event.target.value.trim()
    setFilterByBlockedSearchQuery(query)
    filterBlockedBySearchReturn(query)
  }

  const filterBlockedBySearchReturn = (queryString: string) => {
    if (queryString === '') {
      setBlockedFilterMemberData(pageBlockedMemberData)
      return
    }
    // Create copy of item list
    let updatedList = pageBlockedMemberData
    // Include all elements which includes the search query
    updatedList = updatedList?.filter((membersItem: pageMemberData, key: number) =>
      membersItem.user.name.toLowerCase().includes(queryString.toLowerCase())
    )
    // Trigger render with updated values
    setBlockedFilterMemberData(updatedList)
  }

  const canSend = () => {
    if (checkedMemberCount) {
      return messageInfo !== ''
    } else {
      return false
    }
  }

  const selectMemberForEmail = (membersItem: pageMemberData) => {
    setShowMessage(true)
    setSendIndividual(true)
    setMemberForEmail(membersItem)
    scrollToBottom()
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }, 150)
  }

  const UpdateMemberBlockStatus = (status: boolean, members: pageMemberData) => {
    const updatepageMemberData = pageMemberData?.map((membersItem: pageMemberData, key: number) => {
      if (membersItem._id === members._id) {
        return {
          ...membersItem,
          isBlocked: !members.isBlocked,
        }
      } else {
        return membersItem
      }
    })

    const updatepageFilerMemberData = pageMembeFilterData?.map((membersItem: pageMemberData, key: number) => {
      if (membersItem._id === members._id) {
        return {
          ...membersItem,
          isBlocked: !members.isBlocked,
        }
      } else {
        return membersItem
      }
    })
    setPageMemberData(updatepageMemberData)
    setPageMemberFilterData(updatepageFilerMemberData)

    const payLoad = { block: status }
    dispatch(blockUnblockMemberThunk(members?.user?._id, pageDetailState?._id, updatepageMemberData, payLoad))
    trackEvent('Page Interaction', 'Member Block/Unblock', `${members?.user?._id}`)
  }

  const memberListUi = (membersItem: pageMemberData, key: number) => {
    return (
      <li key={key} className="EachMember">
        <div className="MemberDetail">
          <div className="owner">
            <div className="ownerPic">
              {isset(membersItem?.user?.image) ? (
                <img src={membersItem?.user?.image} alt={membersItem?.user?.name} />
              ) : (
                <div className="ownerAlpha">
                  <span>{membersItem?.user?.name?.charAt(0)}</span>
                </div>
              )}
            </div>
            <div className="ownerName">
              {membersItem?.user?.name}
              <span>Member since {createdByMonthYear(membersItem?.createdAt)}</span>
            </div>
          </div>
        </div>
        {pageDetailState?.belongsTo?._id === userState?.value?.data?._id &&
        userState?.value?.data?._id !== membersItem?.user?._id ? (
          <div className="IconsList">
            {showMessage && !sendIndividual ? (
              <span className="checkbox">
                <label className="majesticCheckbox">
                  <input
                    checked={membersItem?.isChecked}
                    onChange={() => {
                      checkBoxUpdate(membersItem)
                    }}
                    type="checkbox"
                  />
                  <span>
                    <i></i>
                  </span>
                </label>
              </span>
            ) : showBlockedMemberStatus ? (
              <>
                <span
                  onClick={() => {
                    UpdateMemberBlockStatus(false, membersItem)
                  }}
                  className="unblock-icon"
                >
                  <i className="fa fa-butterflyblock"></i>
                  <span>Unblock</span>
                </span>
              </>
            ) : (
              <>
                {membersItem.isBlocked ? (
                  <i
                    onClick={() => {
                      UpdateMemberBlockStatus(false, membersItem)
                    }}
                    className="fa fa-butterflyblock unblock"
                  ></i>
                ) : (
                  <i
                    onClick={() => {
                      UpdateMemberBlockStatus(true, membersItem)
                    }}
                    className="fa fa-butterflyblock"
                  ></i>
                )}
                <i
                  onClick={() => {
                    selectMemberForEmail(membersItem)
                  }}
                  className={cn('fa fa-butterflyemail', {
                    active: membersItem?.user?._id === memberForEmail?.user?._id,
                  })}
                ></i>
              </>
            )}
          </div>
        ) : (
          <></>
        )}
      </li>
    )
  }

  const sendMailToMember = () => {
    const sendMessage = `${messageInfo}`
    const toEmails = []
    if (sendIndividual) {
      toEmails.push(memberForEmail?.user?.email)
    } else {
      const checkedMembers = pageMemberData?.filter((membersItem: pageMemberData, key: number) => membersItem.isChecked)
      checkedMembers?.forEach((membersItem: pageMemberData, key: number) => {
        toEmails.push(membersItem.user.email)
      })
    }
    const PayLoadData = {
      to: toEmails,
      subject: 'Better Place Memories: Member Message',
      html: sendMessage,
      pageId: pageDetailState?._id,
      type: 'message',
    }
    dispatch(pageSendEmailGenericThunk(PayLoadData, 'member-email-send'))
    trackEvent('Page Interaction', 'Send Message to Members', `${userState?.value?.data?._id}`)
  }

  return (
    <>
      {userState.loading && <Loader />}
      <div className="MemberHeader">
        {pageMemberData?.length ? (
          <>
            {pageMemberData.length - blockedMemberCount === 1 ? (
              <div className="MembersCount">
                {showBlockedMemberStatus ? blockedMemberCount : pageMemberData.length - blockedMemberCount} Member
              </div>
            ) : (
              <div className="MembersCount">
                {showBlockedMemberStatus ? blockedMemberCount : pageMemberData.length - blockedMemberCount} Members
              </div>
            )}

            {pageMemberData.length - blockedMemberCount > 1 ? (
              <div className="MembersSearch">
                <i className="fa fa-butterflysearch"></i>
                {!showBlockedMemberStatus ? (
                  <input onChange={filterBySearch} placeholder="Search members"></input>
                ) : (
                  <input onChange={filterBlockedBySearch} placeholder="Search members"></input>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {showBlockedMemberStatus ? (
        <div
          className="MemberBody"
          style={{
            overflow: `overlay`,
            maxHeight: `calc(100vh - calc(170px + ${height}px))`,
          }}
        >
          {blockedMemberCount && pageBlockedFilterMemberData?.length ? (
            <ul>
              {pageBlockedFilterMemberData?.map((membersItem: pageMemberData, key: number) => {
                return <React.Fragment key={key}>{memberListUi(membersItem, key)}</React.Fragment>
              })}
            </ul>
          ) : (
            <div className="no-record">No blocked member found</div>
          )}
        </div>
      ) : (
        <div
          className="MemberBody"
          style={{
            overflow: `overlay`,
            maxHeight: `calc(100vh - calc(170px + ${height}px))`,
          }}
        >
          {pageMembeFilterData?.length ? (
            <>
              <ul>
                {pageMembeFilterData?.map((membersItem: pageMemberData, key: number) => {
                  if (!membersItem?.isBlocked) {
                    return <React.Fragment key={key}>{memberListUi(membersItem, key)}</React.Fragment>
                  } else {
                    return <></>
                  }
                })}
              </ul>
              {pageMemberData.length - blockedMemberCount === 1 && pageDetailState?.is_published ? (
                <div className="SharePopup">
                  <CommonShareContent />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="SharePopup">
              <CommonShareContent />
            </div>
          )}
        </div>
      )}
      {pageDetailState?.belongsTo?._id === userState?.value?.data?._id &&
      pageMemberData?.length &&
      !showBlockedMemberStatus &&
      pageMemberData.length - blockedMemberCount > 1 ? (
        <div className="MemberFooter">
          {!showMessage ? (
            <div className="CtaSetOne">
              <button
                className="BlockUserCta"
                onClick={() => {
                  setShowBlockedMemberStatus(!showBlockedMemberStatus)
                  props.showBlockedMember(true)
                }}
              >
                <i className="fa fa-butterflyblockedusers"></i>
                Blocked Members
              </button>
              <button
                className="OutlineBtn"
                onClick={() => {
                  setShowMessage(!showMessage)
                }}
              >
                <i className="fa fa-butterflyemail"></i>
                Message all
              </button>
            </div>
          ) : (
            <div className="CtaSetTwo">
              <div className="text-left">
                <h4>
                  Sending message to{' '}
                  {sendIndividual ? (
                    <>
                      <span>{memberForEmail?.user?.name}</span>
                    </>
                  ) : (
                    <>
                      {pageMemberData?.length === checkedMemberCount ? 'all' : ''} {checkedMemberCount} Member
                      {checkedMemberCount > 1 ? 's' : ''}
                    </>
                  )}
                </h4>
                <p>
                  From: <span>{userState?.value?.data?.name}</span> (You)
                </p>
                <textarea onChange={messageValue} placeholder="Type your message here"></textarea>
              </div>
              <button
                className="OutlineBtn"
                onClick={() => {
                  setMessageInfo('')
                  setShowMessage(!showMessage)
                  setSendIndividual(false)
                  setMemberForEmail(null)
                }}
              >
                Cancel
              </button>
              <button
                className="send"
                disabled={!canSend()}
                onClick={() => {
                  sendMailToMember()
                }}
              >
                Send
              </button>
            </div>
          )}
          <div ref={ref}></div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Members
