/**
 * @enum {RouteConstants}
 * This enumeration is used to store all the paths and other route c
 */

export enum RouteConstants {
  ROOT = '/',
  AUTHRESET = '/auth/reset/:token',
  CREATEFIRSTPAGE = '/create',
  CREATEPAGE = '/page/create',
  LOGIN = '/signin',
  REGISTER = '/register',
  VERIFYEMAIL = '/verify-email',
  FORGOTPASSWORD = '/forgot-password',
  INVITEPAGE = '/i/:pageurl',
  PAGEDETAIL = '/:pageslug',
  MYPAGES = '/mypages',
  QRCODE = '/upload/:pageId/:userId/:type',
  NOTFOUND = '/*',
  MYSUBSCRIPAGES = '/joinedpages',
  PRIVACYPOLICY = '/privacy-policy',
  TERMCONDITION = '/terms-condition',
  LINKEXPIRED = '/link-expired',
  SITEMAP = '/site-map',
  PUBLICPAGES = '/find-pages',
}
