import { useCallback, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import { useAppSelector } from '../../Hooks'
import { setAuthAlert } from '../../Redux/slices/userSlice'
import { loginThunk } from '../../Redux/thunks'
import { Regex } from '../../Regex'
import GsignIn from './GsignIn'

function SignIn() {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string | '' | null>('')
  const [password, setPassword] = useState<string | '' | null>('')
  const [recaptchaToken, setRecaptchaToken] = useState<string | '' | null>('')
  const [error, setError] = useState<any>({
    emailError: {
      state: false,
    },
    passwordError: {
      state: false,
    },
  })
  const dispatch = useDispatch()
  const userState = useAppSelector(state => state.user)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('signup')
    if (token && !recaptchaToken) {
      setRecaptchaToken(prevState => {
        return token
      })
    } else {
      setRecaptchaToken(null)
      return false
    }
  }, [executeRecaptcha])
  useEffect(() => {
    dispatch(
      setAuthAlert({
        status: false,
        content: '',
      })
    )
    setError({
      ...error,
      passwordError: {
        state: false,
      },
    })
  }, [])
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])
  useEffect(() => {
    if (userState?.alert?.content && userState.alert.content === 'Incorrect password.') {
      setError({
        ...error,
        passwordError: {
          state: userState.alert.status,
          message: userState.alert.content,
        },
      })
    } else if (userState?.alert?.content && userState.alert.name === 'generic/not-found') {
      setError({
        ...error,
        emailError: {
          state: userState.alert.status,
          message: userState.alert.content,
        },
      })
    } else {
      setError({
        ...error,
        passwordError: {
          state: false,
        },
      })
    }
  }, [userState.alert])
  const handleSignin = async () => {
    const loginPayload = {
      email,
      password,
      recaptcha_token: (recaptchaToken && recaptchaToken) ?? undefined,
      provider: 'manual',
    }
    if (email && password) {
      dispatch(loginThunk(loginPayload, navigate))
      trackEvent('Page Interaction', 'User Sign In', `${email}`)
    }
  }
  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }

  return (
    <>
      <h2>Welcome back!</h2>
      <form
        onSubmit={e => {
          e.preventDefault()
          return false
        }}
      >
        <div className="inputWithIcon">
          <InputGroup hasValidation>
            <Form.Control
              type="email"
              placeholder="Email"
              required
              value={email ?? ''}
              isInvalid={error.emailError.state}
              disabled={userState.loading}
              onChange={e => {
                setEmail(e.currentTarget.value)
                setError({
                  ...error,
                  emailError: {
                    state: false,
                  },
                })
              }}
              onBlur={e => {
                if (!e.currentTarget.value) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      emailError: {
                        message: 'Email is required',
                        state: true,
                      },
                    }
                  })
                } else if (!e.currentTarget.value.match(Regex.email)) {
                  setError((prevState: any) => {
                    return {
                      ...prevState,
                      emailError: {
                        message: 'Email is not valid',
                        state: true,
                      },
                    }
                  })
                } else {
                  setError({
                    ...error,
                    emailError: {
                      state: false,
                    },
                  })
                }
              }}
            />
            <i className="fa fa-butterflyemail"></i>
            {error.emailError.state && (
              <Form.Control.Feedback type="invalid">{error.emailError.message}</Form.Control.Feedback>
            )}
          </InputGroup>
        </div>
        <div className="inputWithIcon">
          <InputGroup hasValidation>
            <Form.Control
              type={passwordShown ? 'text' : 'password'}
              placeholder="Password"
              required
              isInvalid={error.passwordError.state}
              value={password ?? ''}
              disabled={userState.loading}
              onChange={e => {
                setError({
                  ...error,
                  passwordError: {
                    state: false,
                  },
                })
                setPassword(e.currentTarget.value)
              }}
            />

            <i className="fa fa-butterflylock"></i>
            <i
              onClick={togglePasswordVisiblity}
              className={passwordShown ? 'fa fa-private icon' : 'fa fa-eye-btm icon'}
            ></i>
            {error.passwordError.state && (
              <Form.Control.Feedback type="invalid">{error.passwordError.message}</Form.Control.Feedback>
            )}
          </InputGroup>
        </div>
        {/* <MyVerticallyCenteredModal
					show={true}
					onHide={() => {} }
				/> */}
      </form>

      <>
        <span
          className="fp"
          onClick={() => {
            navigate('/forgot-password')
          }}
        >
          Forgot Password?
        </span>
        <button
          disabled={!email || !password ? true : error.emailError.state}
          onClick={() => {
            handleSignin()
          }}
        >
          Sign in
        </button>
        <span className="or">Or</span>
        <GsignIn name={'Sign in'} />
      </>
    </>
  )
}

export default SignIn
