import './Notification.scss'

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import {
  loadNotification,
  takeNotificationAction,
  updateStatusOfNotificationResponse,
} from '../../Redux/slices/NotificationSlice'
import { loading } from '../../Redux/slices/userSlice'
import {
  getNotification,
  getNotificationCount,
  ReadStatusUpdateNotification,
  takeNotificationActionThunk,
} from '../../Redux/thunks/NotificationThunk'
import Loader from '../Loader/Loader'
import { MessageReplyNotification } from './MessageReplyNotification'
import { PostAudioNotification } from './PostAudioNotification'
import { PostCreateNotification } from './PostCreateNotification'
import { QuestionAnswerNotification } from './QuestionAnswerNotification'
import { SubscribedNotification } from './SubscribedNotification'

// Create the Application

const Notification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userState = useAppSelector((state: any) => state?.user)
  const notificationState = useAppSelector((state: any) => state?.notification)
  const [notifications, setNotifications] = useState<any>(null)

  useEffect(() => {
    dispatch(getNotification(userState?.value?.data?._id, 1))
    dispatch(ReadStatusUpdateNotification(userState?.value?.data?._id))
  }, [])

  useEffect(() => {
    if (notificationState?.notificationList?.success === true) {
      dispatch(loading(false))
      dispatch(loadNotification({ ...notificationState?.notificationList, success: null }))
      setNotifications(notificationState?.notificationList?.data)
    }
  }, [notificationState?.notificationList?.data])

  useEffect(() => {
    if (notificationState?.updateStatusOfNotificationResp?.success === true) {
      dispatch(getNotificationCount(userState?.value?.data?._id))
      dispatch(
        updateStatusOfNotificationResponse({ ...notificationState?.updateStatusOfNotificationResp, status: null })
      )
    }
  }, [notificationState?.updateStatusOfNotificationResp?.success])

  const goToPageSettings = (pageUrl: string, tabName: string) => {
    let urlSegment = RouteConstants.PAGEDETAIL.replace(':pageslug', pageUrl)
    urlSegment = urlSegment + '?from=' + tabName
    navigate(urlSegment)
  }

  const _takeNotificationAction = (data: any) => {
    dispatch(takeNotificationActionThunk(data))
  }

  useEffect(() => {
    if (notificationState?.takeNotificationActionResp?.success === true) {
      const data: any = []
      const notificationArr = notifications
      notificationArr?.forEach((element: any) => {
        if (notificationState?.takeNotificationActionResp?.notification?._id === element._id) {
          if (
            notificationState?.takeNotificationActionResp?.actionType === 'approve_subscription' ||
            notificationState?.takeNotificationActionResp?.actionType === 'reject_subscription'
          ) {
            data.push({
              ...element,
              status:
                notificationState?.takeNotificationActionResp?.actionType === 'approve_subscription'
                  ? 'APPROVED'
                  : 'REJECTED',
            })
          }
          if (
            (notificationState?.takeNotificationActionResp?.actionType === 'approve_post' ||
              notificationState?.takeNotificationActionResp?.actionType === 'reject_post') &&
            notificationState?.takeNotificationActionResp?.notification?.type === 'description'
          ) {
            data.push({
              ...element,
              status:
                notificationState?.takeNotificationActionResp?.actionType === 'approve_post' ? 'APPROVED' : 'REJECTED',
            })
          }
          if (
            (notificationState?.takeNotificationActionResp?.actionType === 'approve_post' ||
              notificationState?.takeNotificationActionResp?.actionType === 'reject_post') &&
            notificationState?.takeNotificationActionResp?.notification?.type === 'media'
          ) {
            const dataArr: any = []
            element.media_items?.forEach((ele: any) => {
              if (ele?.url === notificationState?.takeNotificationActionResp?.notification?.media_url) {
                dataArr.push({
                  ...ele,
                  status:
                    notificationState?.takeNotificationActionResp?.actionType === 'approve_post'
                      ? 'APPROVED'
                      : 'REJECTED',
                })
              } else {
                dataArr.push(ele)
              }
            })
            data.push({
              ...element,
              media_items: dataArr,
              status:
                notificationState?.takeNotificationActionResp?.actionType === 'approve_post'
                  ? 'APPROVED'
                  : element?.status,
            })
          }
          if (
            notificationState?.takeNotificationActionResp?.actionType === 'approve_post_all' ||
            notificationState?.takeNotificationActionResp?.actionType === 'reject_post_all'
          ) {
            const dataArr: any = []
            element.media_items?.forEach((ele: any) => {
              if (ele?.status === 'PENDING') {
                dataArr.push({
                  ...ele,
                  status:
                    notificationState?.takeNotificationActionResp?.actionType === 'approve_post_all'
                      ? 'APPROVED'
                      : 'REJECTED',
                })
              } else {
                dataArr.push(ele)
              }
            })
            data.push({
              ...element,
              media_items: dataArr,
              status:
                notificationState?.takeNotificationActionResp?.actionType === 'approve_post_all'
                  ? 'APPROVED'
                  : 'REJECTED',
            })
          }
        } else {
          data.push(element)
        }
      })
      setNotifications(data)
      dispatch(loading(false))
      dispatch(takeNotificationAction({ ...notificationState?.takeNotificationActionResp, success: null }))
    }
  }, [notificationState?.takeNotificationActionResp])

  const hanldleNotificationRender = (notificationType: string, element: any, key: number) => {
    switch (notificationType) {
      case 'subscribed_notification':
        return (
          <SubscribedNotification
            element={element}
            key={key}
            keyindex={key}
            takeNotification={_takeNotificationAction}
            goToPageSettings={goToPageSettings}
          />
        )
      case 'post_create':
        return (
          <PostCreateNotification
            element={element}
            key={key}
            keyindex={key}
            takeNotification={_takeNotificationAction}
            goToPageSettings={goToPageSettings}
          />
        )
      case 'message_reply':
        return <MessageReplyNotification element={element} key={key} keyindex={key} />
      case 'post_audio':
        return <PostAudioNotification element={element} key={key} keyindex={key} notificationType={notificationType} />
      case 'personal_audio':
        return <PostAudioNotification element={element} key={key} keyindex={key} notificationType={notificationType} />
      case 'question_answer':
        return (
          <QuestionAnswerNotification
            element={element}
            key={key}
            keyindex={key}
            takeNotification={_takeNotificationAction}
            goToPageSettings={goToPageSettings}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div className="NotificationWrapper">
      {userState.loading && <Loader />}
      {notifications?.map((element: any, key: number) => {
        return hanldleNotificationRender(element.type, element, key)
      })}
      {notifications !== null && notifications?.length === 0 ? (
        <div className="emptyMessage">
          <p>No notifications yet, but check back soon.</p>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Notification
