import { type AxiosInstance } from 'axios'

import { DELETEAUDIOMSG, GETAUDIOMSG, LOADAUDIOMESSAGES, NEWSTATEUPDATE } from '../../Constants/apiConstants'

export default class AudioMessageApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor as AxiosInstance
  }

  public async loadAudioMessages(pageId: any) {
    // page id need when add with dynamic data.
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(LOADAUDIOMESSAGES)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getAudioList(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETAUDIOMSG?.replaceAll(':pageId', payload.pageId).replaceAll(':userId', payload.userId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async removeAudioMsg(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(DELETEAUDIOMSG?.replaceAll(':pageId', payload.pageId).replaceAll(':audioId', payload.audioId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async AudioNewStatusUpdate(audioId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(NEWSTATEUPDATE?.replaceAll(':audioId', audioId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
