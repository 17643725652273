import './FeaturesNav.scss'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { setPopup, setSidePanel } from '../../Redux/slices/metaSlice'
import { addPageAction, type pageDetailData, setActiveSideSlug } from '../../Redux/slices/pageDetailSlice'

function FeaturesNav() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const activeSlide: any = useAppSelector((state: any) => state?.pagedetail?.activeSideSlug)

  const userState = useAppSelector(state => state.user)
  useEffect(() => {
    dispatch(setActiveSideSlug(''))
    dispatch(setPopup(null))
    dispatch(
      setSidePanel({
        panelBody: null,
        status: false,
      })
    )
  }, [])

  const canAction = () => {
    if (!userState?.value?.data?._id) {
      loginRedirect()
    }

    if (
      pageDetailState?.belongsTo?._id === userState?.value?.data?._id ||
      pageDetailState?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const isBlur = () => {
    return (
      pageDetailState.privacy_status === 'PRIVATE' &&
      pageDetailState?.belongsTo?._id !== userState?.value?.data?._id &&
      pageDetailState?.subscribtion_status !== 'APPROVED'
    )
  }

  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
  }
  return (
    <ul id="FeaturesNav" className={isBlur() ? 'blur' : ''}>
      <li
        className={activeSlide === '' ? 'active d-none' : 'd-none'}
        onClick={() => {
          if (!userState?.value?.data?._id) {
            loginRedirect()
          }
          dispatch(setActiveSideSlug(''))
          dispatch(addPageAction('post-top-scroll'))
        }}
      >
        <i className="fa fa-linkiconfeeds"></i>
        <label>Feeds</label>
      </li>
      <li
        className={activeSlide === 'question-popup' ? 'active DesktopQuestion' : 'DesktopQuestion'}
        onClick={() => {
          if (canAction()) {
            dispatch(setPopup('question-popup'))
          } else {
            dispatch(addPageAction('show-subsciption-step'))
          }
        }}
      >
        <i className="fa fa-questionsicon"></i>
        <label>Questions</label>
        <span>{pageDetailState?.questions?.length}</span>
      </li>
      <li
        className={activeSlide === 'question-page' ? 'active MobileQuestion' : 'MobileQuestion'}
        onClick={() => {
          if (canAction()) {
            dispatch(setActiveSideSlug('question-page'))
            dispatch(
              setSidePanel({
                panelBody: `Your Questions`,
                status: true,
              })
            )
          } else {
            dispatch(addPageAction('show-subsciption-step'))
          }
        }}
      >
        <i className="fa fa-questionsicon"></i>
        <label>Questions</label>
      </li>
      <li
        className={activeSlide === 'event-page' ? 'active EventsModule DesktopEvents' : 'EventsModule DesktopEvents'}
        id="eventListLink"
        onClick={() => {
          if (!userState?.value?.data?._id) {
            loginRedirect()
          }
          if (!isBlur()) {
            if (canAction()) {
              dispatch(setActiveSideSlug('event-page'))
              dispatch(
                setSidePanel({
                  panelBody: `Your events`,
                  status: true,
                })
              )
            } else {
              dispatch(addPageAction('show-subsciption-step'))
            }
          }
        }}
      >
        <i className="fa fa-eventsicon"></i>
        <label>Events</label>
        <span>{pageDetailState?.counts?.events}</span>
      </li>
      <li
        className={activeSlide === 'gallery-add-page' ? 'active DesktopGallery' : 'DesktopGallery'}
        onClick={() => {
          if (!userState?.value?.data?._id) {
            loginRedirect()
          }
          if (!isBlur()) {
            if (canAction()) {
              dispatch(setActiveSideSlug('gallery-add-page'))
              dispatch(
                setSidePanel({
                  panelBody: `${pageDetailState?.first_name}'s Gallery`,
                  status: true,
                })
              )
            } else {
              dispatch(addPageAction('show-subsciption-step'))
            }
          }
        }}
      >
        <i className="fa fa-galleryicon"></i>
        <label>Gallery</label>
        <span>{pageDetailState?.counts?.gallery}</span>
      </li>
      <li
        className={activeSlide === 'members-page' ? 'active' : ''}
        onClick={() => {
          if (!userState?.value?.data?._id) {
            loginRedirect()
          }
          if (!isBlur()) {
            if (canAction()) {
              dispatch(setActiveSideSlug('members-page'))
              dispatch(
                setSidePanel({
                  panelBody: `Members of ${pageDetailState?.first_name}'s page`,
                  status: true,
                })
              )
            } else {
              dispatch(addPageAction('show-subsciption-step'))
            }
          }
        }}
      >
        <i className="fa fa-membersicon"></i>
        <label>Members</label>
        <span>{(pageDetailState?.counts?.members ?? 0) + 1}</span>
      </li>
      <li
        className={activeSlide === 'fundraiser' ? 'active DesktopFundraiser' : 'DesktopFundraiser'}
        onClick={() => {
          if (!isBlur()) {
            if (canAction()) {
              dispatch(setActiveSideSlug('fundraiser'))
              dispatch(
                setSidePanel({
                  panelBody: `Fundraisers`,
                  status: true,
                })
              )
            } else {
              dispatch(addPageAction('show-subsciption-step'))
            }
          }
        }}
      >
        <i className="fa fa-fundraiser"></i>
        <label>Fundraiser</label>
        <span>{pageDetailState?.counts?.fundraiser}</span>
      </li>
    </ul>
  )
}

export default FeaturesNav
