import 'react-phone-number-input/style.css'
import './phoneinput.scss'

import React, { useEffect, useState } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const PhoneInputWithCode = (props: any) => {
  const [value, setValue] = useState<any>()
  useEffect(() => {
    if (value) {
      props.updatePhoneNumber({ value, error: value && isValidPhoneNumber(value) ? 'true' : 'false' })
    }
  }, [value])

  return (
    <PhoneInput
      international
      countryCallingCodeEditable={false}
      defaultCountry="US"
      value={value}
      onChange={setValue}
      placeholder={props?.placeHolder ? props?.placeHolder : 'Enter phone number'}
      error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
    />
  )
}
export default PhoneInputWithCode
