import AxiosClass from '../../Api/Interceptor'
import PageApi from '../../Api/PageApi'
import { invitePageResponse, loadMySubscribePages } from '../slices/pageSlice'
import { loading } from '../slices/userSlice'

export const getMySubscribePages: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageApi(AxiosClass)
      .fetchMySubscribePage(payload)
      .then((r: any) => {
        if (r) {
          dispatch(loadMySubscribePages(r))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const sendInvitationViaMail: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageApi(AxiosClass)
      .inviteViaMail(payload)
      .then((r: any) => {
        if (r) {
          dispatch(invitePageResponse(r))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
