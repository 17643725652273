import { Component, type ErrorInfo, type ReactNode } from 'react'

interface Props {
  children: ReactNode
  type?: string
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.type === 'module') {
        return (
          <div className="container__missing-module-error">
            <p className="missing-module-error-message">This feature is not available, please check again later.</p>
          </div>
        )
      } else {
        return (
          <div className="container__module-not-loaded">
            <p>
              <span className="module-not-loaded body-message">Oops! This page is currently unavailable.</span>
              <span className="module-not-loaded help-text">
                If the problem persists please report to&nbsp;
                <a href="mailto:support@butterfly.com">support@butterfly.com</a>
              </span>
            </p>
          </div>
        )
      }
    }

    return this.props.children
  }
}

export default ErrorBoundary
