declare global {
  interface Window {
    BACKEND_BASE_URL?: string
    CORS_URL?: string
  }
}

const { BACKEND_BASE_URL, CORS_URL } = window

delete window.BACKEND_BASE_URL
delete window.CORS_URL

const config = {
  BASE_URL: `${CORS_URL}/${BACKEND_BASE_URL}`,
  ACTUAL_URL: BACKEND_BASE_URL,
}

export default config
