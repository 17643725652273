import '../FundraisersPopup/FundraisersPopup.scss'

import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../../Analytics/GA'
import { environment } from '../../../AppConfig/environment'
import { addDonationThunk } from '../../../Redux/thunks/pageDetailThunk'

const FundraisersDonate = (props: any) => {
  const { fundraiser } = props
  const [showDonateWidget, setShowDonateWidget] = useState(false)
  const [donatesend, setDonatesend] = useState(false)
  const dispatch = useDispatch()

  const handleDonateCompleted = (data: any) => {
    if (!donatesend) {
      const fundraiserId = localStorage.getItem('fundraiser_id')
      const userId = localStorage.getItem('user_id')

      if (fundraiserId) {
        const payload = {
          fundraiser_id: fundraiserId,
          user_id: userId,
        }
        dispatch(addDonationThunk(payload))
        setDonatesend(true)
        trackEvent('Page Interaction', 'Fundraiser Donation', `${userId}`)
        props.onHide()
        localStorage.removeItem('fundraiser_id')
        localStorage.removeItem('user_id')
      }
    }
  }

  const handleMessage = (e: any) => {
    if (e.origin !== environment.pledgeOrigin) return
    const data = JSON.parse(e.data)
    switch (data.message) {
      case 'DonateCompleted': {
        handleDonateCompleted(data.data)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setShowDonateWidget(true)
    }, 150)
  }, [fundraiser])

  return (
    <Modal {...props} onHide={props.onHide} className="fundraiser-popup" size="lg" centered>
      <Modal.Header closeButton></Modal.Header>
      <div className="stepTwo fStepTwo">
        <div className="header">
          <h4>{fundraiser?.title}</h4>
        </div>
        <div className="innerwrap">
          <div className="inner-width-section">
            {showDonateWidget ? (
              <div
                className="plg-donate text-center"
                data-widget-id={`${environment?.pledgePartnerKey}/${fundraiser?._id}`}
                data-style-transparent="true"
                data-style-brand-color="$success"
              ></div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FundraisersDonate
