import './FundraisersPopup.scss'
import 'react-datepicker/dist/react-datepicker.css'

import { forwardRef, type LegacyRef, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../../Hooks'
import { setActiveSideSlug } from '../../../Redux/slices/pageDetailSlice'
import { loading } from '../../../Redux/slices/userSlice'
import { editFundraiserThunk, uploadMedia } from '../../../Redux/thunks/pageDetailThunk'
import RoundedCropper from '../CropperPopup/RoundedCropper'

const FundraisersPopupEdit = (props: any) => {
  const { fundraiser, fundraiserpage } = props
  const dispatch = useDispatch()

  const pageDetailState = useAppSelector((state: any) => state.pagedetail.data)
  const [endDate, setEndDate] = useState(new Date(Date.now() + 60000 * 60 * 24 * 7).toString())
  const [isEdited, setIsEdited] = useState(false)
  const [startDate, setStartDate] = useState(new Date().toString())
  const [gole, setgole] = useState<number>()
  const [goleError, setGoleError] = useState(false)
  const [title, settitle] = useState()
  const [discription, setdiscription] = useState<string>()
  const [img, setImg] = useState<any>({
    url: '',
    file: '',
    selected_img: '',
  })
  const [imgValidation, setImgValidation] = useState('')
  const [showCropper, setShowCropper] = useState(false)

  useEffect(() => {
    if (fundraiser.start_time) {
      const sDate = new Date(fundraiser?.start_time)
      const eDate = new Date(fundraiser?.end_time)

      setStartDate(sDate.getFullYear() + '/' + (sDate.getMonth() + 1) + '/' + sDate.getDate())
      setEndDate(eDate.getFullYear() + '/' + (eDate.getMonth() + 1) + '/' + eDate.getDate())
    }

    setgole(fundraiser?.goal)
    settitle(fundraiser?.title)
    setdiscription(fundraiser?.description)
    setImg({ ...img, url: fundraiser?.image })
  }, [fundraiser])

  const openImgWindow = () => {
    const ImgInput: any = document.getElementById('page-image')
    if (ImgInput) {
      ImgInput?.click()
    }
  }

  const typeValidation = (event: any) => {
    setImg((data: any) => {
      return {
        ...data,
        file: '',
      }
    })
    const selectedFile = event.target.files[0]
    const imageFile = selectedFile
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgValidation('Only jpg | jpeg | png images allowed.')
      return false
    }
    setImgValidation('')
    if (event.target.files?.length) {
      const reader: any = new FileReader()
      const file: any = selectedFile
      reader.readAsDataURL(file)
      setImg((data: any) => {
        return { ...data, file }
      })
      reader.onload = () => {
        setImg((data: any) => {
          return { ...data, selected_img: reader?.result }
        })
        setShowCropper(true)
      }
    }
  }

  const CroppedImage = (e: any) => {
    if (e && e !== 'cancel') {
      setIsEdited(true)
      setImg((data: any) => {
        return { ...data, url: URL.createObjectURL(e), file: e }
      })
    }
    setShowCropper(false)
  }

  const DatePickerCustomInput = forwardRef(
    ({ value, onClick }: { value: any; onClick: any }, ref: LegacyRef<HTMLInputElement>) => (
      <input
        className="date-picker form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Select date"
        readOnly
      />
    )
  )

  DatePickerCustomInput.displayName = 'DatePickerCustomInput'

  const canCreate = () => {
    return startDate === '' || endDate === '' || !gole || !title || !discription || !isEdited || goleError
  }

  const editFundraisers = () => {
    const startdateInfo = new Date(startDate)
    const enddateInfo = new Date(endDate)

    const payload = {
      title,
      description: discription,
      image: fundraiser?.image,
      goal: gole,
      start_time: `${startdateInfo.getFullYear()}-${
        startdateInfo.getMonth() + 1
      }-${startdateInfo.getDate()} 00:00:00 UTC`,
      end_time: `${enddateInfo.getFullYear()}-${enddateInfo.getMonth() + 1}-${enddateInfo.getDate()} 00:00:00 UTC`,
    }

    if (img.file !== '') {
      const formData: any = new FormData()
      formData.append('media', img.file)
      dispatch(loading(true))
      uploadMedia(formData, pageDetailState?.data?._id).then(response => {
        if (response?.data?.data[0]?.url) {
          payload.image = response?.data?.data[0]?.url
          dispatch(editFundraiserThunk(payload, fundraiser?._id))
        }
      })
    } else {
      dispatch(editFundraiserThunk(payload, fundraiser?._id))
    }
    if (fundraiserpage === 'edit') {
      props.onHide()
    } else {
      dispatch(setActiveSideSlug(''))
    }
  }

  const filterStartDate = (date: any) => {
    const currentDate = endDate === '' ? new Date() : new Date(endDate)
    const selectedDate = new Date(date)
    return currentDate.getTime() >= selectedDate.getTime() && selectedDate >= new Date()
  }

  return (
    <>
      <div className="fundraiser-popup">
        <div className="stepOne fStepOne">
          <div className="createFundraiser">
            <div className="ProfilePic">
              <div className="ImgCroper" onClick={openImgWindow}>
                {img?.url === '' || img?.url === null ? (
                  <>
                    <div className="ImgWrapper">
                      <div className="empty-event-image"></div>
                    </div>
                    <span className="cropImg">
                      <i className="fa fa-camera"></i>
                    </span>
                  </>
                ) : (
                  <>
                    <div className="ImgWrapper">
                      <img src={img?.url} alt="page-profile-image" />
                    </div>
                    <span className="cropImg">
                      <i className="fa fa-camera"></i>
                    </span>
                  </>
                )}
                <input type="file" name="image" className="d-none" id="page-image" onChange={typeValidation} />
                <p className="text text-danger errorMessage">{imgValidation}</p>
              </div>
            </div>
            <div className="FormSection">
              <Form>
                <Form.Group className="each-group">
                  <Form.Label>
                    {' '}
                    Select beneficiary (Non-profit)<sup>*</sup>
                  </Form.Label>
                  <input type="text" className="readOnly" value={fundraiser?.beneficiary_name} readOnly={true} />
                </Form.Group>
                <Form.Group className="each-group">
                  <Form.Label>
                    How much money do you want to raise?<sup>*</sup>
                  </Form.Label>
                  <div className="two-column">
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="number"
                        value={gole}
                        onChange={(event: any) => {
                          if (event.target.value > 24) {
                            setGoleError(false)
                          } else {
                            setGoleError(true)
                          }
                          setIsEdited(true)
                          setgole(event.target.value.trim())
                        }}
                        onBlur={(event: any) => {
                          setGoleError((gole as number) < 25)
                        }}
                        className="form-control  mr-0"
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </div>
                    {goleError && (
                      <span className="error-message">You have not entered any amount, minimum is 25$</span>
                    )}
                  </div>
                  <div className="two-column">
                    <div className="inner-holder">
                      <Form.Label htmlFor="details">
                        Start date<sup>*</sup>
                      </Form.Label>
                      <DatePicker
                        selected={new Date(startDate)}
                        onChange={(date: any) => {
                          setIsEdited(true)
                          setStartDate(date)
                        }}
                        selectsStart
                        filterDate={filterStartDate}
                        showTimeSelect={false}
                        minDate={new Date()}
                        startDate={new Date(startDate)}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                        customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                        endDate={new Date(endDate)}
                      />
                    </div>
                    <div className="inner-holder">
                      <Form.Label htmlFor="details">
                        End date<sup>*</sup>
                      </Form.Label>
                      <DatePicker
                        selected={new Date(endDate)}
                        onChange={(date: any) => {
                          setIsEdited(true)
                          setEndDate(date)
                        }}
                        selectsEnd
                        showTimeSelect={false}
                        minDate={new Date(startDate)}
                        startDate={new Date(startDate)}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                        customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="each-group">
                  <Form.Label>
                    Give a title to your fundraiser<sup>*</sup>
                  </Form.Label>
                  <input
                    type="text"
                    value={title}
                    maxLength={50}
                    placeholder="Give a title to your fundraiser"
                    onChange={(event: any) => {
                      if (event.target.value.length <= 50) {
                        setIsEdited(true)
                        settitle(event.target.value)
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group className="each-group">
                  <Form.Label>
                    Why are you raising the money?<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    id="details"
                    as="textarea"
                    value={discription}
                    maxLength={150}
                    onChange={(event: any) => {
                      if (event.target.value.length <= 150) {
                        setIsEdited(true)
                        setdiscription(event.target.value)
                      }
                    }}
                    aria-label="With textarea"
                    placeholder="Type your answer here"
                    name="description"
                  />
                  <span className="text-count">{discription?.replace(/(\r\n|\n|\r)/g, '').length ?? 0}/150</span>
                </Form.Group>
                <div className="d-flex ActionCta">
                  <button
                    type="button"
                    onClick={() => {
                      if (fundraiserpage === 'edit') {
                        props.onHide()
                      } else {
                        dispatch(setActiveSideSlug(''))
                      }
                    }}
                    className="cancelCta"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={canCreate()}
                    className="create-fund"
                    onClick={() => {
                      editFundraisers()
                    }}
                  >
                    <i className="fa fa-fundraiser" aria-hidden="true"></i>
                    Update fundraiser
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {showCropper ? (
        <RoundedCropper
          show={showCropper}
          selectedImg={img.selected_img}
          setCroppedImage={CroppedImage}
          croppershap={'rect'}
        ></RoundedCropper>
      ) : (
        ''
      )}
    </>
  )
}

export default FundraisersPopupEdit
