import './PageCard.scss'

import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import { createdBytimeSince, InviteparseURLParams, parseURLParams } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { setSidePanel } from '../../Redux/slices/metaSlice'
import {
  addFundraiserAction,
  addPageAction,
  addPersonalMessage,
  loadPageDetail,
  type pageDetail,
  type pageDetailData,
  setActiveSideSlug,
  subScribePageResponse,
} from '../../Redux/slices/pageDetailSlice'
import { loadPostList } from '../../Redux/slices/post'
import { loading } from '../../Redux/slices/userSlice'
import { pageDetailThunk, pagePublishThunk, subScribePage, unsubScribePage } from '../../Redux/thunks/pageDetailThunk'
import Loader from '../Loader/Loader'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'
import LegacyModal from '../Modals/Legacy/Legacy'
import PageMemberOnboardingModal from '../Modals/PageMemberOnboarding/PageMemberOnboarding'
import SharePage from '../Modals/SharePage/SharePage'

export const PageCard = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const topRef = useRef<null | HTMLDivElement>(null)

  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)
  const postData = useAppSelector((state: any) => state?.post)
  const pageAction = pageDetailState?.pageAction
  const userState = useAppSelector(state => state.user)
  const [pageDetail, setPageDetail] = useState<pageDetailData>()
  const [showMoreLess, setShowMoreLess] = useState(false)
  const [pageDescription, setpPageDescription] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<boolean>(false)
  const [stepsLoaded, setStepsLoaded] = useState<boolean>(true)
  const [legacyModalShow, setLegacyModalShow] = useState(false)
  const [PageMemberOnboardingModalShow, setPageMemberOnboardingModalShow] = useState(false)
  const [eventLoaderShow, setEventLoaderShow] = useState(true)

  const setpsArray = [
    '',
    'show-steps',
    'show-pin-steps',
    'show-question-steps',
    'show-event-steps',
    'show-publish-steps',
    '',
  ]

  const setpsLegacyArray = ['', 'show-legacy-steps', 'show-legacy-pin-steps', '']

  useEffect(() => {
    const urlParams: any = parseURLParams(window.location.href)

    if (urlParams?.from?.length && urlParams?.from[0]) {
      if (urlParams?.from[0] === 'eventview' && urlParams?.eventId?.length && urlParams?.eventId[0]) {
        setEventLoaderShow(true)
        if (document.getElementsByClassName('panel-wrap')?.length) {
          ;(document.getElementsByClassName('panel-wrap')[0] as HTMLElement).style.display = 'none'
        }
      } else {
        setEventLoaderShow(false)
      }
    } else {
      setEventLoaderShow(false)
    }

    return () => {
      localStorage.removeItem('legacy_popup')
      localStorage.removeItem('PageMemberOnboarding_popup')
    }
  }, [])

  useEffect(() => {
    dispatch(loadPageDetail([]))
    dispatch(loadPostList([]))
    dispatch(addPersonalMessage([]))
    dispatch(addFundraiserAction([]))
    const href: any = window.location.pathname
    const urlSegments: any = href.match(/([^/]*)\/*$/)
    if (urlSegments.length) {
      const pageUrl = urlSegments[1]
      let count = 0
      const routeList: any = RouteConstants
      for (const key in routeList) {
        if (routeList[key] === '/' + pageUrl) {
          count = 1
        }
      }
      if (count === 0) {
        dispatch(pageDetailThunk(pageUrl, userState?.value?.data?._id))
      }
    }

    if (userState?.value?.data?._id) {
      localStorage.removeItem('actionUrl')
    }
    return () => {
      if (localStorage.getItem('FromEditPage') !== null) {
        localStorage.removeItem('FromEditPage')
      } else {
        dispatch(setActiveSideSlug(''))
      }
      dispatch(addPageAction('hide-steps'))
    }
  }, [location])

  useEffect(() => {
    if (pageAction === 'reloadPage') {
      const href: any = window.location.pathname
      const urlSegments: any = href.match(/([^/]*)\/*$/)
      if (urlSegments.length) {
        const pageUrl = urlSegments[1]
        let count = 0
        const routeList: any = RouteConstants
        for (const key in routeList) {
          if (routeList[key] === '/' + pageUrl) {
            count = 1
          }
        }
        if (count === 0) {
          dispatch(pageDetailThunk(pageUrl, userState?.value?.data?._id))
        }
      }
      dispatch(addPageAction(''))
    } else if (pageAction === 'page-top-scroll') {
      const barHeight = '80px'
      if (topRef?.current) {
        topRef.current.style.scrollMargin = barHeight
        topRef.current.scrollIntoView({ behavior: 'smooth' })
      }
      dispatch(addPageAction(''))
    }
  }, [pageAction])

  useEffect(() => {
    if (pageDetailState?.data?.first_name) {
      if (
        !pageDetailState?.data?.is_published &&
        pageDetailState?.data?.from_legacy &&
        !localStorage.getItem('legacy_popup')
      ) {
        const element = document.querySelector('.LeftSection')
        element.scrollTop = element.scrollHeight
        setLegacyModalShow(true)
        document.body.classList.add(`legacy`)
        localStorage.setItem('legacy_popup', 'open')
        setTimeout(() => {
          const list = document.querySelectorAll(
            'li.DesktopQuestion, li.EventsModule, li.DesktopFundraiser, button.publishButton'
          )
          for (let i = 0; i < list.length; ++i) {
            list[i].classList.add('legacy-highlight')
          }
        }, 250)
      }

      if (
        userState?.value?.data?.showStep &&
        userState?.value?.data?.show_member_intro &&
        !localStorage.getItem('PageMemberOnboarding_popup') &&
        pageDetailState?.data?.subscribtion_status === ''
      ) {
        console.log('show_member_intro: ' + userState?.value?.data?.show_member_intro)
        const element = document.querySelector('.LeftSection')
        element.scrollTop = element.scrollHeight
        setPageMemberOnboardingModalShow(true)
        document.body.classList.add(`PageMemberOnboarding`)
        localStorage.setItem('PageMemberOnboarding_popup', 'open')
        setTimeout(() => {
          const list = document.querySelectorAll(
            'li.DesktopQuestion, li.EventsModule, li.DesktopGallery, button.shareButton, div.CreatePostBtn'
          )
          for (let i = 0; i < list.length; ++i) {
            list[i].classList.add('PageMemberOnboarding-highlight')
          }
        }, 250)
      }

      const _urlParams: any = parseURLParams(window.location.href)
      if (
        !userState?.value?.data?._id &&
        pageDetailState?.data?.privacy_status !== 'PUBLIC' &&
        !_urlParams?.adminId?.length
      ) {
        navigate(RouteConstants.REGISTER)
      }
      dispatch(loading(false))
      if (
        localStorage.getItem('SubscribeCode') !== null &&
        localStorage.getItem('ForInviteUser') !== null &&
        userState?.value?.data?._id &&
        pageDetailState?.data?.subscribtion_status !== 'APPROVED' &&
        pageDetailState?.data?.subscribtion_status !== 'PENDING'
      ) {
        if (pageDetailState?.data?.privacy_status === 'PUBLIC') {
          const params = InviteparseURLParams(localStorage.getItem('SubscribeCode')) as any
          if (
            params.invite &&
            params.pageownerid &&
            params.invite?.length &&
            params.pageownerid?.length &&
            params.invite[0] === userState?.value?.data?.email
          ) {
            dispatch(loading(true))
            dispatch(
              subScribePage({
                pageId: pageDetailState?.data?._id,
                userId: userState?.value?.data?._id,
                token: params.token?.length ? params.token[0] : '',
                source: 'invite',
              })
            )
          }
        }
        if (pageDetailState?.data?.privacy_status === 'PRIVATE') {
          const params = InviteparseURLParams(localStorage.getItem('SubscribeCode')) as any
          if (
            params.invite &&
            params.pageownerid &&
            params.invite?.length &&
            params.pageownerid?.length &&
            params.invite[0] === userState?.value?.data?.email &&
            params.pageownerid[0] === pageDetailState?.data?.belongsTo?._id
          ) {
            dispatch(loading(true))
            dispatch(
              subScribePage({
                pageId: pageDetailState?.data?._id,
                userId: userState?.value?.data?._id,
                token: params.token?.length ? params.token[0] : '',
                source: 'invite',
              })
            )
          }
        }
        localStorage.removeItem('SubscribeCode')
        localStorage.removeItem('ForInviteUser')
      }
      setPageDetail(pageDetailState?.data)
      setpPageDescription(pageDetailState?.data?.reason)
      if (
        userState?.value?.data?.showStep &&
        stepsLoaded &&
        pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id &&
        !userState?.value?.data?.legacyStep
      ) {
        setStepsLoaded(false)
        const step = userState?.value?.data?.step === '' ? 0 : userState?.value?.data?.step
        const spepShow = setpsArray[step]
        dispatch(addPageAction(spepShow))
      }

      const urlParams: any = parseURLParams(window.location.href)
      if (urlParams?.from?.length && urlParams?.from[0]) {
        if (
          (pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id &&
            urlParams?.from[0] === 'privacy_settings') ||
          urlParams?.from[0] === 'approval_settings'
        ) {
          openEditPagePopup()
        }
        if (urlParams?.from[0] === 'eventview' && urlParams?.eventId?.length && urlParams?.eventId[0]) {
          // setDefaultActiveTab("approvalsetting");
          if (!userState?.value?.data?._id) {
            localStorage.setItem(
              'notificationEventIdRedirect',
              `/${pageDetailState?.data?.unique_url}?from=${urlParams?.from[0]}&eventId=${urlParams?.eventId[0]}`
            )
            loginRedirect()
          } else {
            localStorage.removeItem('notificationEventIdRedirect')
            localStorage.setItem('notificationEventId', urlParams?.eventId[0])
            setTimeout(() => {
              dispatch(loading(true))
              document.getElementById('eventListLink')?.click()
            }, 1550)

            setTimeout(() => {
              setEventLoaderShow(false)
            }, 5000)
          }
        }
        if (
          !userState?.value?.data?._id &&
          (urlParams?.from[0] === 'privacy_settings' || urlParams?.from[0] === 'approval_settings')
        ) {
          localStorage.setItem(
            'actionUrl',
            JSON.stringify({
              page: pageDetailState?.data?.unique_url,
              path: urlParams?.from[0],
            })
          )
        }
      }

      // Backtopage
      if (localStorage.getItem('backToPublicPage') !== null && pageDetailState?.data?.privacy_status === 'PUBLIC') {
        dispatch(loading(true))
        dispatch(
          subScribePage({
            pageId: pageDetailState?.data?._id,
            userId: userState?.value?.data?._id,
          })
        )
        localStorage.removeItem('backToPublicPage')
      }
    }
  }, [pageDetailState?.data])

  useEffect(() => {
    if (pageDetailState?.data?.first_name && postData?.postList?.data?.length) {
      if (
        userState?.value?.data?.showStep &&
        stepsLoaded &&
        pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id &&
        userState?.value?.data?.legacyStep &&
        pageDetailState?.data?.from_legacy &&
        pageDetailState?.data?.is_published
      ) {
        setStepsLoaded(false)
        const step = userState?.value?.data?.step === '' ? 0 : userState?.value?.data?.step
        const spepShow = setpsLegacyArray[step]
        dispatch(addPageAction(spepShow))
      }
    }
  }, [pageDetailState?.data, postData?.postList?.data])

  const openEditPagePopup = () => {
    dispatch(setActiveSideSlug('edit-page'))
    dispatch(
      setSidePanel({
        panelBody: 'Edit your page details',
        status: true,
      })
    )
    trackEvent('Page Interaction', 'Edit Page Open', `${userState?.value?.data?._id}`)
  }

  const pagePublish = () => {
    // add google tag
    window.gtag('event', 'page_publish', {
      event_category: 'conversion',
      event_label: 'Page Published',
    })
    const barHeight = '80px'
    if (topRef?.current) {
      topRef.current.style.scrollMargin = barHeight
      topRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    dispatch(pagePublishThunk(pageDetail?._id, true))
    setLegacyModalShow(false)
    setPageMemberOnboardingModalShow(false)
  }

  const subscribePage = () => {
    if (userState?.value?.data?._id) {
      if (pageDetail?.subscribtion_status === '') {
        dispatch(loading(true))
        dispatch(
          subScribePage({
            pageId: pageDetail?._id,
            userId: userState?.value?.data?._id,
          })
        )
        trackEvent('Page Interaction', 'Page Join', `${userState?.value?.data?._id}`)
        // let respose = ReactGA.event({
        //   category: 'Page Interaction',
        //   action: 'Page Join',
        //   label: 'testing'
        // });
      } else if (pageDetail?.subscribtion_status === 'APPROVED') {
        dispatch(loading(true))
        dispatch(
          unsubScribePage({
            pageId: pageDetail?._id,
            userId: userState?.value?.data?._id,
          })
        )
      }
    } else {
      if (pageDetailState?.data?.privacy_status === 'PUBLIC') {
        localStorage.setItem('backToPublicPage', `/${pageDetailState?.data?.unique_url}`)
      }

      loginRedirect()
    }
  }

  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
    trackEvent('Page Interaction', 'User Redirect to Login', 'From Page')
  }

  const [modalShow, setModalShow] = useState(false)
  const handleClose = () => {
    setModalShow(false)
  }

  useEffect(() => {
    if (pageDetailState?.subScribePageResp?.success === true) {
      dispatch(loading(false))
      dispatch(pageDetailThunk(pageDetailState?.data?.unique_url, userState?.value?.data?._id))
    }
  }, [pageDetailState?.subScribePageResp])

  useEffect(() => {
    if (pageDetailState?.subScribePageResp?.success === true) {
      const href: any = window.location.pathname
      const urlSegments: any = href.match(/([^/]*)\/*$/)
      let pageUrl = ''
      if (urlSegments.length) {
        pageUrl = urlSegments[1]
      }
      localStorage.removeItem('SubscribeCode')
      localStorage.removeItem('ForInviteUser')
      dispatch(pageDetailThunk(pageUrl, userState?.value?.data?._id))
      dispatch(
        subScribePageResponse({
          ...pageDetailState?.subScribePageResp,
          success: null,
        })
      )
    }
    if (
      pageDetailState?.subScribePageResp?.success === false &&
      pageDetailState?.subScribePageResp?.message === 'Member already exists'
    ) {
      const href: any = window.location.pathname
      const urlSegments: any = href.match(/([^/]*)\/*$/)
      let pageUrl = ''
      if (urlSegments.length) {
        pageUrl = urlSegments[1]
      }
      localStorage.removeItem('SubscribeCode')
      localStorage.removeItem('ForInviteUser')
      dispatch(
        subScribePageResponse({
          ...pageDetailState?.subScribePageResp,
          success: null,
        })
      )
      dispatch(pageDetailThunk(pageUrl, userState?.value?.data?._id))
    }
  }, [pageDetailState?.subScribePageResp])

  return (
    <>
      {eventLoaderShow && <Loader />}
      <Card className="PageCard">
        <div className="top-ref" ref={topRef}></div>
        <div className="ImgHolder">
          {pageDetail?.image && pageDetail.image !== null ? (
            <div className="ImgWrapper">
              <Card.Img variant="top" src={pageDetail?.image_cropped?.toString()} />
              {pageDetail?.is_published ? <div></div> : <div className="overlay"></div>}
            </div>
          ) : (
            <>
              {pageDetail?.belongsTo?._id === userState?.value?.data?._id && (
                <div className="noImg">
                  {' '}
                  <i onClick={openEditPagePopup} className="fa fa-uploadpic"></i>{' '}
                </div>
              )}
            </>
          )}
          {pageDetail?._id ? <p>{pageDetail?.first_name + ' ' + pageDetail?.last_name}</p> : <p>Loading...</p>}
          {pageDetail?.is_published && (
            <span className="subscribe">
              <i className="fa fa-butterflysubscribed"></i>
            </span>
          )}
        </div>

        <Card.Body>
          <Card.Text>
            {showMoreLess && pageDescription?.length > 100 ? (
              <p>
                {pageDescription}{' '}
                <span
                  className="ml-2 text text-success"
                  onClick={() => {
                    setShowMoreLess(false)
                  }}
                >
                  Read less
                </span>
              </p>
            ) : !showMoreLess && pageDescription?.length > 100 ? (
              <p>
                {pageDescription
                  ?.toString()
                  ?.substr(0, 100)
                  .substr(
                    0,
                    Math.min(
                      pageDescription?.toString()?.substr(0, 100).length,
                      pageDescription?.toString()?.substr(0, 100).lastIndexOf(' ')
                    )
                  )}{' '}
                <span
                  className="ml-2 text text-success"
                  onClick={() => {
                    setShowMoreLess(true)
                  }}
                >
                  more
                </span>
              </p>
            ) : (
              <p>
                {pageDescription?.toString()?.substring(0, 100)}
                &nbsp;
              </p>
            )}
          </Card.Text>

          <div className="owner">
            <div className="ownerPic">
              {pageDetail?.belongsTo?.image && pageDetail?.belongsTo?.image !== null ? (
                <img src={pageDetail?.belongsTo?.image} alt="" />
              ) : (
                <div className="ownerAlpha">
                  <span>{pageDetail?.belongsTo?.name?.charAt(0)}</span>
                </div>
              )}
            </div>
            <div className="ownerName">
              Created by {pageDetail?.belongsTo?.name}
              <span className="created_at">{createdBytimeSince(pageDetail?.createdAt)}</span>
            </div>
          </div>
        </Card.Body>
        <hr />
        <Card.Footer
          className={cn('', {
            memberView: pageDetail?.belongsTo?._id !== userState?.value?.data?._id && pageDetail?.is_published,
          })}
        >
          {pageDetail?.belongsTo?._id !== userState?.value?.data?._id && pageDetail?.is_published && (
            <span className="subscribe-badge" onClick={subscribePage}>
              {pageDetail?.subscribtion_status === 'APPROVED' ? (
                <div className="subscribe-button">
                  <i className="fa fa-subscribed subscribed"></i>
                  <span>Joined</span>
                </div>
              ) : pageDetail?.subscribtion_status === 'PENDING' ? (
                <div>
                  <i className="fa fa-subscribed peding"></i>
                  <span>Request Pending</span>
                </div>
              ) : (
                <div className="subscribe-button">
                  <i className="fa fa-subscribe"></i>
                  <span>Join</span>
                </div>
              )}
            </span>
          )}

          {pageDetail?.belongsTo?._id === userState?.value?.data?._id && (
            <button onClick={openEditPagePopup}>
              <i className="fa fa-edit"></i>
              Edit
            </button>
          )}

          {pageDetail?.is_published ? (
            <>
              {pageDetail?.privacy_status === 'HIDDEN' && pageDetail?.belongsTo?._id === userState?.value?.data?._id ? (
                <button
                  className="shareButton"
                  onClick={() => {
                    if (userState?.value?.data?._id) {
                      setModalShow(true)
                    } else {
                      loginRedirect()
                    }
                  }}
                >
                  <i className="fa fa-share"></i>
                  Share
                </button>
              ) : (
                ''
              )}

              {pageDetail?.privacy_status !== 'HIDDEN' ? (
                <button
                  disabled={
                    pageDetail?.subscribtion_status !== 'APPROVED' &&
                    pageDetail?.belongsTo?._id !== userState?.value?.data?._id
                  }
                  className="shareButton"
                  onClick={() => {
                    if (userState?.value?.data?._id) {
                      setModalShow(true)
                    } else {
                      loginRedirect()
                    }
                  }}
                >
                  <i className="fa fa-share"></i>
                  Share
                </button>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}

          {!pageDetail?.is_published && pageDetail?.belongsTo?._id === userState?.value?.data?._id && (
            <button className="publishButton highlightGreen" onClick={pagePublish}>
              {legacyModalShow || PageMemberOnboardingModalShow ? (
                <>
                  <i className="fa fa-share"></i>
                  Share
                </>
              ) : (
                <>
                  <i className="fa fa-butterflypublish"></i>
                  Publish
                </>
              )}
            </button>
          )}
        </Card.Footer>
      </Card>
      {legacyModalShow ? <LegacyModal open={legacyModalShow} setOpen={setLegacyModalShow} /> : ''}
      {PageMemberOnboardingModalShow ? (
        <PageMemberOnboardingModal open={PageMemberOnboardingModalShow} setOpen={setPageMemberOnboardingModalShow} />
      ) : (
        ''
      )}
      {modalShow ? (
        <SharePage
          show={modalShow}
          handleClose={() => {
            handleClose()
          }}
        />
      ) : (
        ''
      )}

      {successMessage ? (
        <AlertPopup
          buttonText={false}
          show={!!pageDetailState?.subScribePageResp?.success}
          content={pageDetailState?.subScribePageResp.message}
          state={pageDetailState?.subScribePageResp?.success ? 'SUCCESS' : 'ERROR'}
          onHide={() => {
            dispatch(
              subScribePageResponse({
                ...pageDetailState?.subScribePageResp,
                success: null,
              })
            )
            setSuccessMessage(false)
          }}
        />
      ) : (
        ''
      )}
    </>
  )
}
