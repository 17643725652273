import './Subscribed.scss'

import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

import { trackEvent } from '../../Analytics/GA'
import { useAppSelector } from '../../Hooks'
import { loadPageDetail } from '../../Redux/slices/pageDetailSlice'
import { loadPostList } from '../../Redux/slices/post'
import { getMySubscribePages } from '../../Redux/thunks/pageThunk'
import Footer from '../Footer/Footer'
import Loader from '../Loader/Loader'

const MySubScribePages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Options = [
    {
      value: 'name',
      label: 'Name',
    },
    {
      value: 'date_created',
      label: 'Date Created',
    },
    {
      value: 'number_of_members',
      label: 'Number of Members',
    },
  ]
  const userState: any = useAppSelector(state => state.user)
  const myPageList = useAppSelector((state: any) => state?.page)
  const [totalCount, setTotalCount] = useState<any>('')
  const [pageList, setPageList] = useState<any>([])
  const [getScrollCount] = useState<number>(1)

  const sortPageList = (value: any) => {
    switch (value.value) {
      case 'date_created': {
        const sortedmyPageListCreated = [...pageList]?.sort(
          (objA: any, objB: any) => Number(new Date(objB.createdAt)) - Number(new Date(objA.createdAt))
        )
        setPageList(sortedmyPageListCreated)
        break
      }
      case 'name': {
        const field = 'first_name'
        const sortedmyPageListName = [...pageList]?.sort((a, b) =>
          (a[field] || '').toString().localeCompare((b[field] || '').toString())
        )
        setPageList(sortedmyPageListName)
        break
      }
      case 'number_of_members': {
        const sortedmyPageListMembers = [...pageList]?.sort(
          (objA: any, objB: any) => Number(objB.members?.length) - Number(objA.members?.length)
        )
        setPageList(sortedmyPageListMembers)
        break
      }
      default:
    }
  }

  useEffect(() => {
    dispatch(
      getMySubscribePages({
        userId: userState?.value?.data?._id,
      })
    )
  }, [])

  useEffect(() => {
    if (myPageList?.subscribePages?.data) {
      if (getScrollCount === 1) {
        setPageList(myPageList?.subscribePages?.data)
      } else {
        setPageList([...pageList, ...myPageList?.subscribePages?.data])
      }
      setTotalCount(myPageList?.subscribePages?.totalPage)
    }
  }, [myPageList?.subscribePages])

  return (
    <>
      {userState.loading && <Loader />}
      <div className={isMobile ? 'MySubscribedPages my-subscribe-page-list' : 'MySubscribedPages'}>
        <div className="TopBar">
          <h2>
            Joined Pages <span>{totalCount}</span>
          </h2>
          {totalCount > 1 && (
            <div className="TopBar__right">
              <span>
                Sort by :
                <Select
                  className="butterfly-dropdown-select"
                  classNamePrefix="butterfly"
                  placeholder="Select to sort"
                  options={Options}
                  onChange={value => {
                    sortPageList(value)
                  }}
                  isSearchable={false}
                  defaultValue={{
                    value: 'date_created',
                    label: 'Date Created',
                  }}
                />
              </span>
            </div>
          )}
        </div>
        <div className={!isMobile ? 'wrapper my-subscribe-page-list' : 'wrapper'}>
          {pageList?.map((pagelist: any, index: number) => {
            return (
              <div
                className="card"
                key={index}
                onClick={() => {
                  dispatch(loadPageDetail([]))
                  dispatch(loadPostList([]))
                  navigate(`/${pagelist?.unique_url}`)
                  trackEvent('Site Interaction', 'Page View From Joined Pages', `${pagelist?.unique_url}`)
                }}
              >
                <div className="ImgHolder">
                  <div className="ImgWrapper">
                    {pagelist.image_cropped ? <img className="card-img-top" src={pagelist?.image_cropped} /> : <></>}
                    <div className="overlay"></div>

                    <div className="BottomSection">
                      <p> {`${pagelist?.first_name} ${pagelist?.last_name ?? ''}`} </p>
                      <p className="BottomIcons">
                        {pagelist?.privacy_status === 'PUBLIC' ? (
                          <span className="privacy">
                            <i className="fa fa-butterflyeye" aria-hidden="true"></i>
                            Public
                          </span>
                        ) : pagelist?.privacy_status === 'PRIVATE' ? (
                          <span className="privacy">
                            <i className="fa fa-butterflylock2" aria-hidden="true"></i>
                            Private
                          </span>
                        ) : pagelist?.privacy_status === 'HIDDEN' ? (
                          <span className="privacy">
                            <i className="fa fa-butterflyeyehidden" aria-hidden="true"></i>
                            Hidden
                          </span>
                        ) : (
                          <></>
                        )}
                        <span className="members">
                          <i className="fa fa-membersicon"></i> {pagelist?.members?.length + 1} Members
                        </span>
                        {pagelist?.is_published && (
                          <span className="subscribe">
                            <i className="fa fa-butterflysubscribed" aria-hidden="true"></i>
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Footer isdetailed={false} />
      </div>
    </>
  )
}

export default MySubScribePages
