import './Auth.scss'

import * as jwt from 'jose'
import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import AxiosClass from '../../Api/Interceptor'
import UserApi from '../../Api/UserApi'
import { environment } from '../../AppConfig/environment'
import { butterflyBlack, butterflyWhite } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { ErrorStates, setAppError } from '../../Redux/slices/metaSlice'
import { loading } from '../../Redux/slices/userSlice'
import { Regex } from '../../Regex'
import LottieControl from '../lottie'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'

function ResetPassword() {
  const [password, setPassword] = useState('')
  const [cp, setCP] = useState('')
  const [error, setError] = useState<any>({
    passwordError: {
      state: false,
    },
    confirmPasswordError: {
      state: false,
    },
  })
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useAppSelector(state => state.user)
  const appState = useAppSelector(state => state.metadata)
  const { token } = useParams()
  const [, setIsVerified] = useState(false)
  const secret = new TextEncoder().encode(
    // this key needs to be in Env
    environment.TextDecoder
  )

  useEffect(() => {
    async function initiateComponent() {
      if (!token) {
        Navigate('/')
      } else {
        const decodedToken = await jwt.jwtVerify(token, secret)
        if (decodedToken) {
          setIsVerified(true)
        } else {
          setIsVerified(false)
        }
      }
    }
    initiateComponent()
  }, [])
  const handleResetPassword = async () => {
    dispatch(loading(true))
    if (token) {
      // TODO: use public key or pvt key
      const decodedToken = await jwt.jwtVerify(token, secret)
      if (!decodedToken) {
        Navigate('/')
      } else {
        new UserApi(AxiosClass)
          .resetPass({
            email: decodedToken.payload.email,
            password,
          })
          .then((r: any) => {
            dispatch(loading(false))
            if (r.success) {
              dispatch(
                setAppError({
                  name: 'AuthError',
                  status: true,
                  state: ErrorStates.success,
                  content: 'Password reset successfull',
                })
              )
            } else {
              throw r
            }
          })
          .catch(e => {
            dispatch(
              setAppError({
                name: 'AuthError',
                status: true,
                state: ErrorStates.error,
                content: 'Reset password failed',
              })
            )
            dispatch(loading(false))
          })
      }
    }
  }
  const [passwordShown, setPasswordShown] = useState(false)
  const [ConfPasswordShown, setConfPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }
  const toggleConfPasswordVisiblity = () => {
    setConfPasswordShown(!ConfPasswordShown)
  }
  return (
    <>
      {appState.error?.status && (
        <AlertPopup
          buttonText={false}
          show={!!appState.error.status}
          content={appState.error.content}
          state={appState.error.state}
          onHide={() => {
            if (appState?.error?.content === 'Password reset successfull') {
              dispatch(setAppError(null))
              Navigate(RouteConstants.LOGIN)
            } else {
              dispatch(setAppError(null))
            }
          }}
        />
      )}
      <section id="AuthPage">
        <Container>
          <Row>
            <Col md={7} className="d-none d-md-block">
              <div className="imgHolder">
                <div className="overlay"></div>
                <div className="logo">
                  <img src={butterflyWhite} alt="Butterfly logo" />
                </div>
                <div className="title">A Full Life Deserves a Full Tribute</div>
              </div>
            </Col>
            <Col md={5} className="lhs">
              <div className="d-xs-block d-md-none m-logo">
                <img src={butterflyBlack} alt="Butterfly logo" />
                <div className="title">A Full Life Deserves a Full Tribute</div>
              </div>

              <div className="formHolder forgotPassword">
                <div className="tab-content">
                  <h2>Reset Password</h2>
                  <form>
                    <div className="inputWithIcon">
                      <InputGroup hasValidation>
                        <Form.Control
                          value={password}
                          type={passwordShown ? 'text' : 'password'}
                          placeholder="Password"
                          onChange={e => {
                            setPassword(e.currentTarget.value)
                            if (cp !== '' && e.currentTarget.value !== cp) {
                              setError((prevState: any) => {
                                return {
                                  ...prevState,
                                  passwordError: {
                                    state: false,
                                  },
                                  confirmPasswordError: {
                                    message: "Passwords don't match",
                                    state: true,
                                  },
                                }
                              })
                            } else {
                              setError({
                                ...error,
                                passwordError: {
                                  state: false,
                                },
                                confirmPasswordError: { state: false },
                              })
                            }
                          }}
                          onBlur={e => {
                            if (!e.currentTarget.value) {
                              setError({
                                ...error,
                                passwordError: {
                                  state: false,
                                },
                                confirmPasswordError: { state: false },
                              })
                            } else {
                              if (e.currentTarget.value.length < 8) {
                                setError({
                                  ...error,
                                  confirmPasswordError:
                                    cp !== '' && e.currentTarget.value !== cp
                                      ? {
                                          message: "Passwords don't match",
                                          state: true,
                                        }
                                      : { state: false },
                                  passwordError: {
                                    message: 'Password must be at least 8 characters long',
                                    state: true,
                                  },
                                })
                              } else if (!e.currentTarget.value.match(Regex.password)) {
                                setError((prevState: any) => {
                                  return {
                                    ...prevState,
                                    confirmPasswordError:
                                      cp !== '' && e.currentTarget.value !== cp
                                        ? {
                                            message: "Passwords don't match",
                                            state: true,
                                          }
                                        : { state: false },
                                    passwordError: {
                                      message: 'Password is not valid',
                                      state: true,
                                    },
                                  }
                                })
                              } else {
                                setError({
                                  ...error,
                                  confirmPasswordError:
                                    cp !== '' && e.currentTarget.value !== cp
                                      ? {
                                          message: "Passwords don't match",
                                          state: true,
                                        }
                                      : { state: false },
                                  passwordError: { state: false },
                                })
                              }
                            }
                          }}
                          required
                          isInvalid={error.passwordError.state}
                          disabled={userState.loading}
                          isValid={password !== '' && !error.passwordError.state}
                        />
                        <i className="fa fa-butterflylock"></i>
                        <i
                          onClick={togglePasswordVisiblity}
                          className={passwordShown ? 'fa fa-private icon' : 'fa fa-eye-btm icon'}
                        ></i>
                        {error.passwordError.state && (
                          <Form.Control.Feedback type="invalid">{error.passwordError.message}</Form.Control.Feedback>
                        )}
                      </InputGroup>
                    </div>
                    <div className="inputWithIcon">
                      <InputGroup hasValidation>
                        <Form.Control
                          value={cp}
                          type={ConfPasswordShown ? 'text' : 'password'}
                          placeholder="Confirm Password"
                          onChange={e => {
                            setCP(e.currentTarget.value)
                            setError({
                              ...error,
                              confirmPasswordError: {
                                state: false,
                              },
                            })
                          }}
                          onBlur={e => {
                            if (!e.currentTarget.value) {
                              setError({
                                ...error,
                                confirmPasswordError: {
                                  state: false,
                                },
                              })
                            } else {
                              if (password !== '' && e.currentTarget.value !== password) {
                                setError((prevState: any) => {
                                  return {
                                    ...prevState,
                                    confirmPasswordError: {
                                      message: "Passwords don't match",
                                      state: true,
                                    },
                                  }
                                })
                              } else {
                                setError({
                                  ...error,
                                  confirmPasswordError: { state: false },
                                })
                              }
                            }
                          }}
                          required
                          isInvalid={error.confirmPasswordError.state}
                          disabled={userState.loading}
                          isValid={cp !== '' && !error.confirmPasswordError.state}
                        />
                        <i className="fa fa-butterflylock"></i>
                        <i
                          onClick={toggleConfPasswordVisiblity}
                          className={ConfPasswordShown ? 'fa fa-private icon' : 'fa fa-eye-btm icon'}
                        ></i>
                        {error.confirmPasswordError.state && (
                          <Form.Control.Feedback type="invalid">
                            {error.confirmPasswordError.message}
                          </Form.Control.Feedback>
                        )}
                      </InputGroup>
                    </div>

                    <span className={password.length >= 8 && password.match(Regex.password) ? 'note d-none' : 'note'}>
                      Password must be
                      <span
                        className={
                          password !== ''
                            ? password.length < 8
                              ? 'text text-danger'
                              : 'text text-success'
                            : 'text text-default'
                        }
                      >
                        {' '}
                        at least 8 characters long{' '}
                      </span>
                      and contain a
                      <span
                        className={
                          password !== ''
                            ? password.match(Regex.oneLowercaseChar)
                              ? 'text text-success'
                              : 'text text-danger'
                            : ''
                        }
                      >
                        {' '}
                        lowercase letter
                      </span>
                      , an
                      <span
                        className={
                          password !== ''
                            ? password.match(Regex.oneUpperCaseChar)
                              ? 'text text-success'
                              : 'text text-danger'
                            : ''
                        }
                      >
                        {' '}
                        uppercase letter
                      </span>
                      ,
                      <span
                        className={
                          password !== ''
                            ? password.match(Regex.oneNumberChar)
                              ? 'text text-success'
                              : 'text text-danger'
                            : ''
                        }
                      >
                        {' '}
                        a number
                      </span>
                      , and
                      <span
                        className={
                          password !== ''
                            ? password.match(Regex.oneSpecialChar)
                              ? 'text text-success'
                              : 'text text-danger'
                            : ''
                        }
                      >
                        {' '}
                        a special character
                      </span>
                      .
                    </span>

                    {userState.loading ? (
                      <div
                        style={{
                          height: '40px',
                          width: '40px',
                          margin: 'auto',
                        }}
                      >
                        <LottieControl />
                      </div>
                    ) : (
                      <button
                        disabled={
                          !password || !cp
                            ? true
                            : true && (error.passwordError.state || error.confirmPasswordError.state)
                        }
                        className="Bs"
                        onClick={() => {
                          handleResetPassword()
                        }}
                      >
                        Reset my password
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ResetPassword
