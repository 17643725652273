import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  postList: {
    data: [],
    count: 0,
  },
  postCreateResponse: {},
  pinUnPinPostResponse: {},
  commentOnOffPostResponse: {},
  updatePostResponse: {},
  deletePostResponse: {},
  currentPostComment: [],
  postDetailsResp: {},
}

export const postSlice: any = createSlice({
  name: 'post',
  initialState,
  reducers: {
    loadPostList: (state, action: any) => {
      state.postList.data = action.payload
    },
    loadPostCount: (state, action: any) => {
      state.postList.count = action.payload
    },
    createNewPostResponse: (state, action) => {
      state.postCreateResponse = action.payload
    },
    pinUnpinPostResp: (state, action) => {
      state.pinUnPinPostResponse = action.payload
    },
    commentOnOffPostResp: (state, action) => {
      state.commentOnOffPostResponse = action.payload
    },
    updatePostResponse: (state, action) => {
      state.updatePostResponse = action.payload
    },
    deletePostResponse: (state, action) => {
      state.deletePostResponse = action.payload
    },
    appendNewPost: (state, action) => {
      state.postList.data = [action.payload, ...state.postList.data]
    },
    getPostComments: (state, action) => {
      state.currentPostComment = action.payload
    },
    getPostDetailsResp: (state, action) => {
      state.postDetailsResp = action.payload
    },
    allowPostPagination: (state, action) => {
      state.allowpostpagination = action.payload
    },
  },
})

export const {
  loadPostList,
  loadPostCount,
  createNewPostResponse,
  pinUnpinPostResp,
  updatePostResponse,
  deletePostResponse,
  appendNewPost,
  commentOnOffPostResp,
  getPostComments,
  getPostDetailsResp,
  allowPostPagination,
} = postSlice.actions
export default postSlice.reducer
