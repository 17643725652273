import './PinedFundraiser.scss'

import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { trackEvent } from '../../Analytics/GA'
import { createdDateMonthYear, getPercent, isset, nFormatter, numberFormateIntl } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { setSidePanel } from '../../Redux/slices/metaSlice'
import {
  addFundraiserAction,
  addPageAction,
  addPageActionData,
  type pageDetailData,
  setActiveSideSlug,
  updateFundraisersLikeResponse,
  updateFundraisersResponse,
} from '../../Redux/slices/pageDetailSlice'
import { pageGetAllFundraiserThunk } from '../../Redux/thunks/pageDetailThunk'
import { fundraiserAddLikeThunk, removeFundraiserLikeThunk } from '../../Redux/thunks/postThunk'
import FundraisersPreview from '../Modals/FundraisersPopup/FundraiserPreview'
import FundraisersDonate from '../Modals/FundraisersPopup/FundraisersDonate'
import FundraisersPopupEdit from '../Modals/FundraisersPopup/FundraisersPopupEdit'

const PinedFundraiser = () => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(1)
  const [showEdit, setShowEdit] = useState(false)
  const [showDonate, setShowDonate] = useState(false)
  const [preview, setPreview] = useState(false)
  const [fundraisers, setFundraisers] = useState([])
  const [selectedFundraiser, setSelectedFundraiser] = useState({})

  const previewClose = () => {
    setPreview(false)
  }
  const previewShow = () => {
    setPreview(true)
  }

  const handleEditClose = () => {
    setShowEdit(false)
  }

  const handleDonateClose = () => {
    setSelectedFundraiser({})
    setShowDonate(false)
  }
  const handleDonateShow = () => {
    setShowDonate(true)
  }

  const userState: any = useAppSelector(state => state.user)
  const dispatch = useDispatch()
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const pageFundraiserData = useAppSelector((state: any) => state.pagedetail.fundraiserData)

  const updatedFundraisers = useAppSelector((state: any) => state.pagedetail.updateFundraisersResponseData)

  const updateFundraisersLike = useAppSelector((state: any) => state.pagedetail.updateFundraisersLikeResponseData)

  useEffect(() => {
    if (pageDetailState._id && pageDetailState?.counts?.fundraiser) {
      dispatch(pageGetAllFundraiserThunk(pageDetailState?._id))
    }
  }, [pageDetailState?._id])

  useEffect(() => {
    const updateFundraiserData = pageFundraiserData?.map((e: any, key: number) => {
      return {
        ...e,
        isLikeDisable: false,
      }
    })
    setFundraisers(updateFundraiserData)
  }, [pageFundraiserData])

  useEffect(() => {
    if (updateFundraisersLike?.success === true) {
      if (updateFundraisersLike.data) {
        const fundraiserId = updateFundraisersLike?.data?.fundraiser
        const createdBy = updateFundraisersLike?.data?.createdBy

        const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
          if (e._id === fundraiserId) {
            return {
              ...e,
              isLikeDisable: false,
              likes: e.likes?.map((le: any) => {
                if (le.createdBy === createdBy) {
                  return updateFundraisersLike?.data
                } else {
                  return le
                }
              }),
            }
          } else {
            return {
              ...e,
              isLikeDisable: false,
            }
          }
        })
        setFundraisers(updateFundraiserData)
        dispatch(
          updateFundraisersLikeResponse({
            ...updateFundraisersLike,
            success: null,
          })
        )
      } else {
        const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
          return {
            ...e,
            isLikeDisable: false,
          }
        })
        setFundraisers(updateFundraiserData)
        dispatch(
          updateFundraisersLikeResponse({
            ...updateFundraisersLike,
            success: null,
          })
        )
      }
    }
  }, [updateFundraisersLike])

  useEffect(() => {
    if (updatedFundraisers?.success === true) {
      const updatedFundraisersInfo = fundraisers.map((fundraiser: any) =>
        fundraiser._id === updatedFundraisers?.data?._id ? { ...fundraisers, ...updatedFundraisers?.data } : fundraiser
      )

      dispatch(addFundraiserAction(updatedFundraisersInfo))

      dispatch(
        updateFundraisersResponse({
          ...updatedFundraisers,
          success: null,
        })
      )
    }
  }, [updatedFundraisers])

  const afterChange = (index: number) => {
    setActiveIndex(index + 1)
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange,
    arrows: true,
  }

  const checkLikedOrNot = (userId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.filter((e: any) => e?.createdBy === userId)
    return likeUser.length
  }

  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
  }

  const canAction = () => {
    if (
      pageDetailState?.belongsTo?._id === userState?.value?.data?._id ||
      pageDetailState?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const addlikePost = (userId: string, fundraiserId: string, pageId: string) => {
    const payload = {
      fundraiser: fundraiserId,
      createdBy: userId,
    }
    const LikeAdd = {
      fundraiser: fundraiserId,
      createdBy: userId,
      is_deleted: false,
    }

    const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
      if (e._id === fundraiserId) {
        return {
          ...e,
          isLikeDisable: true,
          likes: [...e.likes, LikeAdd],
        }
      } else {
        return e
      }
    })
    setFundraisers(updateFundraiserData)
    dispatch(fundraiserAddLikeThunk(payload, pageId))
    trackEvent('Page Interaction', 'Fundraiser like', `${userState?.value?.data?._id}`)
  }

  const removelikePost = (userId: string, fundraiserId: string, pageId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.find((e: any) => e?.createdBy === userId)
    const likeId = likeUser?._id

    const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
      if (e._id === fundraiserId) {
        const likesObj = [...e?.likes]
        const objWithIdIndex = likesObj.findIndex((obj: any) => obj._id === likeId)
        if (objWithIdIndex > -1) {
          likesObj?.splice(objWithIdIndex, 1)
        }
        return {
          ...e,
          isLikeDisable: true,
          likes: [...likesObj],
        }
      } else {
        return e
      }
    })
    setFundraisers(updateFundraiserData)
    dispatch(removeFundraiserLikeThunk(fundraiserId, likeId, pageId))
  }

  return (
    <>
      {pageDetailState?.counts?.fundraiser ? (
        <>
          <div className="PinedFundraiser">
            <span className="count">
              {activeIndex}/{fundraisers?.length}
            </span>
            <Slider {...settings}>
              {fundraisers?.map((fundraiser: any, idx: number) => {
                return (
                  <div className="eachFundraiser" key={idx}>
                    <div className="card-header">
                      <div className="owner">
                        <div className="ownerPic">
                          {isset(fundraiser?.beneficiary_image) ? (
                            <img src={fundraiser?.beneficiary_image} alt={fundraiser?.beneficiary_name} />
                          ) : (
                            <div className="ownerAlpha">
                              <span>{fundraiser?.beneficiary_name?.charAt(0)}</span>
                            </div>
                          )}
                        </div>
                        <div className="ownerName">
                          {fundraiser?.beneficiary_name}
                          <span className="created_at">Beneficiary (nonprofiit)</span>
                        </div>
                      </div>
                    </div>
                    <div className="ImageWrapper">
                      {fundraiser.image ? (
                        <img src={fundraiser?.image} alt={fundraiser?.title} />
                      ) : (
                        <div className="no-fundraiser-image"></div>
                      )}
                      <div className="caption">{fundraiser?.title}</div>
                    </div>
                    <div className="card-body">
                      <div className="progressBar">
                        <progress max="100" value={getPercent(fundraiser?.total_raised, fundraiser?.goal)}>
                          {`${getPercent(fundraiser?.total_raised, fundraiser?.goal)}%`}
                        </progress>
                      </div>
                      <div className="raised-fund">
                        {numberFormateIntl(fundraiser?.total_raised)} raised of {numberFormateIntl(fundraiser?.goal)}
                      </div>
                      <div className="donate-info">
                        <div className="donater">
                          <div className="img">
                            {fundraiser?.donation_count ? (
                              <>
                                {fundraiser?.donors_sample.map((donner: any, idx: number) => {
                                  return (
                                    <div className="each-img" key={idx}>
                                      {isset(donner?.image) ? (
                                        <img src={donner?.image} alt={donner?.name} />
                                      ) : (
                                        <div className="ownerAlpha">
                                          <span>{donner?.name?.charAt(0)}</span>
                                        </div>
                                      )}
                                    </div>
                                  )
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="text">
                            {fundraiser?.donation_count > 4 ? '+' : ''}
                            {nFormatter(fundraiser?.donation_count, 1)} donated
                          </div>
                        </div>
                        {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
                          <button
                            onClick={() => {
                              dispatch(addPageActionData(fundraiser))
                              dispatch(setActiveSideSlug('fundraiser'))
                              dispatch(
                                setSidePanel({
                                  panelBody: `Edit Fundraisers`,
                                  status: true,
                                })
                              )
                            }}
                            type="button"
                            className="edit"
                          >
                            Edit
                          </button>
                        ) : (
                          <></>
                        )}
                        <button
                          type="button"
                          onClick={() => {
                            if (!userState?.value?.data?._id) {
                              loginRedirect()
                            } else if (!canAction()) {
                              dispatch(addPageAction('show-subsciption-step'))
                            } else {
                              localStorage.setItem('fundraiser_id', fundraiser?._id)
                              localStorage.setItem('user_id', userState?.value?.data?._id)
                              setSelectedFundraiser(fundraiser)
                              handleDonateShow()
                            }
                          }}
                          className="donatenow"
                        >
                          Donate Now
                        </button>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="date-wrap">{createdDateMonthYear(fundraiser?.createdAt)}</div>
                      <div className="ike-comment-warp">
                        <span>
                          {fundraiser?.counts?.comments ? fundraiser?.counts?.comments : ''}{' '}
                          <i
                            className={fundraiser?.counts?.comments > 0 ? 'fa fa-comment' : 'fa fa-nocomment'}
                            onClick={() => {
                              if (!userState?.value?.data?._id) {
                                loginRedirect()
                              } else if (!canAction()) {
                                dispatch(addPageAction('show-subsciption-step'))
                              } else {
                                setSelectedFundraiser(fundraiser)
                                previewShow()
                              }
                            }}
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>
                          {fundraiser?.likes?.length ? fundraiser?.likes?.length : ''}{' '}
                          {checkLikedOrNot(userState?.value?.data?._id, fundraiser?.likes) ? (
                            <i
                              onClick={() => {
                                if (!userState?.value?.data?._id) {
                                  loginRedirect()
                                } else if (!canAction()) {
                                  dispatch(addPageAction('show-subsciption-step'))
                                } else {
                                  removelikePost(
                                    userState?.value?.data?._id,
                                    fundraiser?._id,
                                    fundraiser?.page,
                                    fundraiser?.likes
                                  )
                                }
                              }}
                              className={cn('fa fa-butterflylike', {
                                disabled: fundraiser?.isLikeDisable || !canAction(),
                              })}
                            ></i>
                          ) : (
                            <i
                              onClick={() => {
                                if (!userState?.value?.data?._id) {
                                  loginRedirect()
                                } else if (!canAction()) {
                                  dispatch(addPageAction('show-subsciption-step'))
                                } else {
                                  addlikePost(userState?.value?.data?._id, fundraiser?._id, fundraiser?.page)
                                }
                              }}
                              className={cn('fa fa-heartoutline', {
                                disabled: fundraiser?.isLikeDisable,
                              })}
                              aria-hidden="true"
                            ></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
          {showEdit && (
            <FundraisersPopupEdit show={showEdit} fundraiser={selectedFundraiser} onHide={handleEditClose} />
          )}
          {showDonate && (
            <FundraisersDonate show={showDonate} onHide={handleDonateClose} fundraiser={selectedFundraiser} />
          )}
          {preview && <FundraisersPreview show={preview} fundraiser={selectedFundraiser} onHide={previewClose} />}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default PinedFundraiser
