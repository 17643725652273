import { createSlice } from '@reduxjs/toolkit'

export interface pageUsersData {
  _id: string
  name: string
  image: string
}

export interface pageQuestionsData {
  question: string
  is_deleted: boolean
  _id: string
  createdAt: string
  updatedAt: string
}

export interface pageMemberData {
  user: {
    _id: string
    name: string
    createdAt: string
    email: string
    image?: string
  }
  subscriptionStatus: string
  isBlocked: boolean
  _id: string
  createdAt: string
  updatedAt: string
  isChecked?: boolean
  isDisabled?: boolean
}
export interface pageDetailData {
  _id: string
  counts: {
    events: number
    gallery: number
    members: number
    questions: number
    fundraiser: number
  }
  belongsTo: pageUsersData
  questions: pageQuestionsData[]
  first_name: string
  last_name?: null | string
  reason: string
  unique_url: string
  approval_required: boolean
  from_legacy?: boolean
  is_published: boolean
  image: null | string
  image_cropped: null | string
  privacy_status: string
  is_deleted: boolean
  createdAt: string
  updatedAt: string
  members: pageMemberData[]
  owner_image: null | string
  subscribtion_status: string
  hidden_response?: any
}

export interface galllery {
  _id: string
  page: string
  url: string
  createdAt: string
  updatedAt: string
  isToggled?: boolean
  __v: number
  createdYear?: string
  createdMonthYear?: string
  type: string
  post: any
  likes: any
  counts: any
  thumbnail?: string
}

export interface albumMetadata {
  albumName: string
  thumbnail: string
  itemCount: number
  isToggled?: boolean
  createdAt: string
  _id: string
}

export interface pageGallleryData {
  gallery: galllery[]
  albumMetadata: albumMetadata[]
  currentAlbum: galllery[]
  galleryCount?: number
}
export interface pageDetail {
  data: any | pageDetailData
  activeSideSlug: string
  editPageResponse: any | object
  gallleryData: any | pageGallleryData
  returnAction: string
  pageAction: string
  pageActionData: any
  eventsData: any
  currentEventsData: any
  createEventsResp: any
  updateEventsResp: any
  myPageList: any
  sendAudioMessageResp: any
  subScribePageResp: any
  searchPage: any
  personalMessage: any
  fundraiserData: any
  updateFundraisersResponseData: any
  updateFundraisersLikeResponseData: any
  addFundraisersResponseData: any
  currentFundraisersComment: any
  eventCount: number
}

const initialState: pageDetail = {
  data: [],
  activeSideSlug: '',
  editPageResponse: {},
  gallleryData: { gallery: [], albumMetadata: [], currentAlbum: [] },
  returnAction: '',
  pageAction: '',
  pageActionData: '',
  eventsData: [],
  fundraiserData: [],
  updateFundraisersResponseData: {},
  updateFundraisersLikeResponseData: {},
  addFundraisersResponseData: {},
  currentEventsData: [],
  createEventsResp: {},
  updateEventsResp: {},
  myPageList: [],
  sendAudioMessageResp: {},
  subScribePageResp: {},
  searchPage: [],
  personalMessage: [],
  currentFundraisersComment: [],
  eventCount: 0,
}

export const pageDetailSlice: any = createSlice({
  name: 'pagedetail',
  initialState,
  reducers: {
    setInitialData: (state, action) => {
      state = initialState
    },
    loadPageDetail: (state, action) => {
      state.data = action.payload
    },
    setActiveSideSlug: (state, action) => {
      state.activeSideSlug = action.payload
    },
    editPageResponse: (state, action) => {
      state.editPageResponse = action.payload
    },
    pagePublish: (state, action) => {
      state.data.is_published = action.payload
    },
    pageGetGalllery: (state, action) => {
      state.gallleryData.gallery = action.payload
    },
    pageGetGallleryCount: (state, action) => {
      state.gallleryData.galleryCount = action.payload
    },
    pageGetGallleryAlbum: (state, action) => {
      state.gallleryData.albumMetadata = action.payload
    },
    pageCurrentAlbum: (state, action) => {
      state.gallleryData.currentAlbum = action.payload
    },
    pageReturnAction: (state, action) => {
      state.returnAction = action.payload
    },
    addQuestions: (state, action) => {
      state.data.questions = action.payload
    },
    addPageAction: (state, action) => {
      state.pageAction = action.payload
    },
    addPageActionData: (state, action) => {
      state.pageActionData = action.payload
    },
    addEventsAction: (state, action) => {
      state.eventsData = action.payload
    },
    addCurrentEventsDataAction: (state, action) => {
      state.currentEventsData = action.payload
    },
    createEventsResponse: (state, action) => {
      state.createEventsResp = action.payload
    },
    updateEventsResponse: (state, action) => {
      state.updateEventsResp = action.payload
    },
    upDataMemberStatus: (state, action) => {
      state.data.members = action.payload
    },
    addmyPageList: (state, action) => {
      state.myPageList = action.payload
    },
    sendAudioMsgResponse: (state, action) => {
      state.sendAudioMessageResp = action.payload
    },
    subScribePageResponse: (state, action) => {
      state.subScribePageResp = action.payload
    },
    updatePageCounts: (state, action) => {
      state.data.counts = action.payload
    },
    addSearchPage: (state, action) => {
      state.searchPage = action.payload
    },
    addPersonalMessage: (state, action) => {
      state.personalMessage = action.payload
    },
    addFundraiserAction: (state, action) => {
      state.fundraiserData = action.payload
    },
    updateFundraisersResponse: (state, action) => {
      state.updateFundraisersResponseData = action.payload
    },
    updateFundraisersLikeResponse: (state, action) => {
      state.updateFundraisersLikeResponseData = action.payload
    },
    addFundraisersResponse: (state, action) => {
      state.addFundraisersResponseData = action.payload
    },
    getFundraisersComments: (state, action) => {
      state.currentFundraisersComment = action.payload
    },
    setEventsCount: (state, action) => {
      state.eventCount = action.payload
    },
  },
})

export const {
  loadPageDetail,
  setActiveSideSlug,
  editPageResponse,
  pagePublish,
  pageGetGalllery,
  pageGetGallleryCount,
  pageCurrentAlbum,
  pageReturnAction,
  addQuestions,
  setInitialData,
  addPageAction,
  addPageActionData,
  addEventsAction,
  addCurrentEventsDataAction,
  upDataMemberStatus,
  createEventsResponse,
  updateEventsResponse,
  addmyPageList,
  sendAudioMsgResponse,
  subScribePageResponse,
  updatePageCounts,
  addSearchPage,
  addPersonalMessage,
  pageGetGallleryAlbum,
  addFundraiserAction,
  updateFundraisersResponse,
  addFundraisersResponse,
  updateFundraisersLikeResponse,
  getFundraisersComments,
  setEventsCount,
} = pageDetailSlice.actions
export default pageDetailSlice.reducer
