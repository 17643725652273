import React, { useEffect, useState } from 'react'
import { config } from 'react-spring'

import { useAppSelector } from '../../Hooks'
import { type pageDetailData } from '../../Redux/slices/pageDetailSlice'
import VerticalCarousel from './VerticalCarousel'
import YourQuestions from './YourQuestions'

const QuestionsPopup = () => {
  const [moveNextCall, setMoveNextCall] = useState('')
  const [showArrows, setShowArrows] = useState(true)
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail?.data)
  const TotalQuestions = pageDetailState?.questions?.length

  const [additionalMessage, setAdditionalMessage] = useState<any>(false)
  const [ansPosted, setAnsPosted] = useState<any>(false)

  const moveNext = (activity: string) => {
    setMoveNextCall(activity)
  }

  useEffect(() => {
    const showErrorStatus = TotalQuestions > 1
    setShowArrows(showErrorStatus)
  }, [TotalQuestions])

  const slides = [
    {
      key: 0,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={0}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 1,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={1}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 2,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={2}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 3,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={3}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 4,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={4}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 5,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={5}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 6,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={6}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 7,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={7}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 8,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={8}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
    {
      key: 9,
      content: (
        <div>
          <YourQuestions
            moveNext={moveNext}
            additionalMessage={additionalMessage}
            setAdditionalMessage={setAdditionalMessage}
            setAnsPosted={setAnsPosted}
            ansPosted={ansPosted}
            number={9}
            showArrows={setShowArrows}
          />
        </div>
      ),
    },
  ]

  const sliderConf = {
    goToSlide: 0,
    offsetRadius: 4,
    showNavigation: showArrows,
    config: config.gentle,
  }

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: '999',
        left: '0',
      }}
    >
      <VerticalCarousel
        slides={slides}
        totalquestions={TotalQuestions}
        moveNextCall={moveNextCall}
        offsetRadius={sliderConf.offsetRadius}
        showNavigation={sliderConf.showNavigation}
        animationConfig={sliderConf.config}
        setMoveNextCall={setMoveNextCall}
      />
    </div>
  )
}

export default QuestionsPopup
