import './FundraisersPopup.scss'
import './FundraiserPreview.scss'

import cn from 'classnames'
import Picker, { Emoji } from 'emoji-picker-react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import nl2br from 'react-nl2br'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../../Analytics/GA'
import { isset, subcommenttimeSince } from '../../../commonservice'
import { RouteConstants } from '../../../Constants/routeConstants'
import { useAppSelector } from '../../../Hooks'
import {
  addPageAction,
  getFundraisersComments,
  type pageDetail,
  updateFundraisersLikeResponse,
} from '../../../Redux/slices/pageDetailSlice'
import { uploadMedia } from '../../../Redux/thunks/pageDetailThunk'
import {
  addReactionForCommentReplyThunk,
  addReactionForCommentThunk,
  createPostCommentReplyThunk,
  createPostCommentThunk,
  editReactionForCommentThunk,
  fundraiserAddLikeThunk,
  getfundraiserCommentsThunk,
  removeFundraiserLikeThunk,
  updatePostCommentThunk,
} from '../../../Redux/thunks/postThunk'
import LottieControl from '../../lottie'
import DeletePopup from '../DeletePopup'
import ReactionListPopup from '../ReactionListPopup/ReactionListPopup'

function FundraisersPreview(props: any) {
  const { fundraiser } = props
  const inputReference = useRef<any>(null)
  const inputReferenceEdit = useRef<any>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)

  const updateFundraisersLike = useAppSelector((state: any) => state.pagedetail.updateFundraisersLikeResponseData)

  const pageDetail = pageDetailState?.data
  const commentData = useAppSelector((state: any) => pageDetailState?.currentFundraisersComment)

  const [height, setHeight] = useState(0)
  const [fundraiserInfo, setFundraiserInfo] = useState<any>()
  const userState: any = useAppSelector(state => state.user)
  const [showLoader, setShowLoader] = useState(false)
  const [postCommentData, setPostCommentData] = useState<any[]>([])
  const [disabledScroll, setDisabledScroll] = useState(false)
  const [mainCommentEdit, setMainCommentEdit] = useState('')
  const [replyData, setReplyData] = useState<any>()
  const [activeReactionOpenId, setActiveReactionOpenId] = useState<any>(false)
  const [reactionComment, setReactionComment] = useState<any>()
  const [editmessageInfo, setEditMessageInfo] = useState<string>('')
  const [messageInfo, setMessageInfo] = useState<string>('')
  const [showCommentImage, setShowCommentImage] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [deleteshow, setDeleteshow] = useState(false)
  const [deleteType, setDeleteType] = useState('fundraiser')
  const [currentPostId, setCurrentPostId] = useState<any>()
  const [additionData, setAdditionData] = useState({})
  const [reactionModalShow, setReactionModalShow] = useState(false)
  const [activeReaction, setActiveReaction] = useState<any>([])
  const [activePost, setActivePost] = useState<any>({})

  const handleDeletesPopupClose = () => {
    setDeleteshow(false)
  }

  const ref = useRef<HTMLInputElement>(null)
  const bottomRef = useRef<null | HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (ref.current !== null) {
      setHeight(ref.current.offsetHeight)
    }
  })

  useEffect(() => {
    setFundraiserInfo(fundraiser)
    setActivePost(fundraiser)
    dispatch(getFundraisersComments([]))
    if (fundraiser?.counts?.comments) {
      setShowLoader(true)
      dispatch(getfundraiserCommentsThunk(fundraiser?._id))
    }
  }, [fundraiser])

  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
  }

  const checkLikedOrNot = (userId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.filter((e: any) => e?.createdBy === userId)
    return likeUser?.length
  }

  const canAction = () => {
    if (pageDetail?.belongsTo?._id === userState?.value?.data?._id || pageDetail?.subscribtion_status === 'APPROVED') {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (commentData?.length) {
      setShowLoader(false)
    }
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      return {
        ...comments,
        isAction: false,
        isEdit: false,
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
    // 👇️ scroll to bottom every time messages change
    if (disabledScroll) {
      setDisabledScroll(false)
      setReactionModalShow(false)
    } else {
      setTimeout(
        () =>
          bottomRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          }),
        800
      )
    }
  }, [commentData])

  const mainCommentAction = (selectcomment: any) => {
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      if (comments._id === selectcomment._id) {
        return {
          ...comments,
          isAction: !selectcomment.isAction,
        }
      } else {
        return {
          ...comments,
          isAction: false,
        }
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
  }

  const editComment = (selectcomment: any) => {
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      if (comments._id === selectcomment._id) {
        setMainCommentEdit(selectcomment.isEdit ? '' : comments.comment)
        return {
          ...comments,
          isEdit: !selectcomment.isEdit,
        }
      } else {
        return {
          ...comments,
          isEdit: false,
        }
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
  }

  const showDeletePopup = (comment: any) => {
    setAdditionData({
      commentId: comment?._id,
      pageid: pageDetail?._id,
      type: 'fundraiser',
      mediaId: '',
    })
    setCurrentPostId(fundraiserInfo?._id)
    setDeleteshow(true)
    setDeleteType('fundraiser')
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      return {
        ...comments,
        isAction: false,
        isEdit: false,
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
  }

  const ReadMore = ({ children }: { children: any }) => {
    const text = children
    const [isReadMore, setIsReadMore] = useState(true)
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore)
    }
    return (
      <>
        {text?.length < 100 ? (
          <>{nl2br(text)}</>
        ) : (
          <>
            {isReadMore ? nl2br(text?.slice(0, 100)) : nl2br(text)}
            <span onClick={toggleReadMore} className="read-or-hide">
              {isReadMore ? '... more' : ' less'}
            </span>
          </>
        )}
      </>
    )
  }

  const editCommentMessage = (event: any) => {
    const messageComment = event.target.value
    setMainCommentEdit(messageComment)
  }

  const groupBy = (key: string) => {
    return function group(array: any) {
      return array?.reduce((acc: any, obj: any) => {
        const property = obj[key]
        acc[property] = acc[property] || []
        acc[property].push(obj)
        return acc
      }, {})
    }
  }

  const groupByReactions = groupBy('name')

  const getReactionsArray = (Reactions: any) => {
    return groupByReactions(Reactions)
  }

  const updateComment = (comment: any) => {
    const payload = {
      comment: mainCommentEdit,
    }
    setDisabledScroll(true)
    const postId = fundraiserInfo?._id
    const type = 'fundraiser'
    dispatch(updatePostCommentThunk(payload, postId, comment?._id, type))
    setEditMessageInfo('')
  }

  const messageValue = (event: any) => {
    // Access input value
    const messageComment = event.target.value
    setMessageInfo(messageComment)
  }

  const canSend = () => {
    return messageInfo.trim() !== ''
  }

  const addNewComment = async () => {
    let mediaUrl = ''

    if (selectedFiles.length) {
      const files = new FormData()
      const fileData = selectedFiles?.filter((e: any) => {
        return e?.from && e?.from !== '' ? false : e
      })
      fileData?.forEach((e: any) => {
        files.append('media', e?.data)
      })
      await uploadMedia(files, pageDetail?._id, props?.editPost?._id).then((response: any) => {
        if (response?.data?.data?.length) {
          response?.data?.data?.forEach((data: any, key: number): any => {
            mediaUrl = data?.url
          })
        }
      })
    }
    if (replyData) {
      const payLoad = {
        comment: replyData?._id,
        reply: messageInfo.trim(),
        createdBy: userState?.value?.data?._id,
        image: mediaUrl,
      }
      const postId = fundraiserInfo?._id
      const type = 'fundraiser'
      dispatch(createPostCommentReplyThunk(payLoad, postId, type))

      setMessageInfo('')
      setReplyData('')
      removeSelected()
      trackEvent('Page Interaction', 'Fundraiser Comment Reply', `${userState?.value?.data?._id}`)
    } else {
      let payLoad = {}
      payLoad = {
        fundraiser: activePost?._id,
        comment: messageInfo.trim(),
        createdBy: userState?.value?.data?._id,
        image: mediaUrl,
      }
      dispatch(createPostCommentThunk(payLoad, pageDetail?._id))
      setMessageInfo('')
      removeSelected()
      trackEvent('Page Interaction', 'Fundraiser Comment', `${userState?.value?.data?._id}`)
    }
  }

  const removeSelected = () => {
    setSelectedFiles([])
    setShowCommentImage(false)
  }

  const openFileSelection = () => {
    const sId = document.getElementById('comment_input_image')
    if (sId) {
      sId?.click()
    }
  }

  const systemFileSelection = (e: any) => {
    if (e?.currentTarget?.files?.length) {
      const image = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'tiff', 'tif', 'svg', 'webp']
      const images = []
      for (let i = 0; i < e?.currentTarget?.files?.length; i++) {
        const extension = e?.currentTarget?.files[i].name
          .substring(e?.currentTarget?.files[i].name.lastIndexOf('.') + 1)
          .toLowerCase()
        if (image.includes(extension)) {
          const fileUrl = URL.createObjectURL(e?.currentTarget?.files[i])

          images.push({
            url: fileUrl,
            data: e?.currentTarget?.files[i],
            thumbnail: fileUrl,
          })
        } else {
          return false
        }
      }
      setSelectedFiles([...images])
      setShowCommentImage(true)
    }
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
    if (reactionComment) {
      const canAddNewReaction = reactionComment?.reactions?.find(
        (react: any) => react?.createdBy?._id === userState?.value?.data?._id
      )
      if (canAddNewReaction) {
        // edit reaction
        const reactId = canAddNewReaction?._id
        const name = event.unified
        const postId = fundraiserInfo?._id
        const type = 'fundraiser'
        dispatch(editReactionForCommentThunk(reactId, name, postId, type))
      } else {
        // add new reaction
        if (reactionComment.reply) {
          const payload = {
            reply: reactionComment?._id,
            createdBy: userState?.value?.data?._id,
            name: event.unified,
          }
          const postId = fundraiserInfo?._id
          const type = 'fundraiser'
          dispatch(addReactionForCommentReplyThunk(payload, postId, type))
          trackEvent('Page Interaction', 'Fundraiser Comment Reply Reaction', `${userState?.value?.data?._id}`)
        } else {
          const payload = {
            comment: reactionComment?._id,
            createdBy: userState?.value?.data?._id,
            name: event.unified,
          }
          const postId = fundraiserInfo?._id
          const type = 'fundraiser'
          dispatch(addReactionForCommentThunk(payload, postId, type))
          trackEvent('Page Interaction', 'Fundraiser Comment Reaction', `${userState?.value?.data?._id}`)
        }
      }

      setActiveReactionOpenId(false)
      setReactionComment(null)
      setDisabledScroll(true)
    } else {
      if (editmessageInfo === '' && mainCommentEdit === '') {
        const textAreaElement: any = document.getElementById('message-input-post-comment')
        setMessageInfo(
          messageInfo.substr(0, textAreaElement.selectionStart) +
            ' ' +
            event.emoji +
            ' ' +
            messageInfo.substr(textAreaElement.selectionEnd)
        )
        inputReference?.current?.focus()
      } else {
        const textAreaElement: any = document.getElementById('message-input-edit-post-comment')

        if (textAreaElement.selectionStart) {
          setMainCommentEdit(
            mainCommentEdit.substr(0, textAreaElement.selectionStart) +
              ' ' +
              event.emoji +
              ' ' +
              mainCommentEdit.substr(textAreaElement.selectionEnd)
          )
        } else {
          setMainCommentEdit(mainCommentEdit + ' ' + event.emoji + ' ')
        }

        inputReferenceEdit?.current?.focus()
      }

      setActiveReactionOpenId(false)
      setReactionComment(null)
    }
  }

  const addlikePost = (userId: string, fundraiserId: string, pageId: string) => {
    const payload = {
      fundraiser: fundraiserId,
      createdBy: userId,
    }
    const LikeAdd = {
      fundraiser: fundraiserId,
      createdBy: userId,
      is_deleted: false,
    }

    const updateFundraiserData: any = {
      ...fundraiserInfo,
      isLikeDisable: true,
      likes: [...fundraiserInfo.likes, LikeAdd],
    }
    setFundraiserInfo(updateFundraiserData)
    dispatch(fundraiserAddLikeThunk(payload, pageId))
    trackEvent('Page Interaction', 'Fundraiser Like', `${userState?.value?.data?._id}`)
  }

  const removelikePost = (userId: string, fundraiserId: string, pageId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.find((e: any) => e?.createdBy === userId)
    const likeId = likeUser?._id

    const likesObj = [...fundraiserInfo?.likes]
    const objWithIdIndex = likesObj.findIndex((obj: any) => obj._id === likeId)
    if (objWithIdIndex > -1) {
      likesObj?.splice(objWithIdIndex, 1)
    }

    const updateFundraiserData: any = {
      ...fundraiserInfo,
      isLikeDisable: true,
      likes: [...likesObj],
    }
    setFundraiserInfo(updateFundraiserData)
    dispatch(removeFundraiserLikeThunk(fundraiserId, likeId, pageId))
  }

  useEffect(() => {
    if (updateFundraisersLike?.success === true) {
      if (updateFundraisersLike.data) {
        const createdBy = updateFundraisersLike?.data?.createdBy
        const updateFundraiserData: any = {
          ...fundraiserInfo,
          isLikeDisable: false,
          likes: fundraiserInfo.likes?.map((le: any) => {
            if (le.createdBy === createdBy) {
              return updateFundraisersLike?.data
            } else {
              return le
            }
          }),
        }
        setFundraiserInfo(updateFundraiserData)
        dispatch(
          updateFundraisersLikeResponse({
            ...updateFundraisersLike,
            success: null,
          })
        )
      } else {
        const updateFundraiserData: any = {
          ...fundraiserInfo,
          isLikeDisable: false,
        }
        setFundraiserInfo(updateFundraiserData)
        dispatch(
          updateFundraisersLikeResponse({
            ...updateFundraisersLike,
            success: null,
          })
        )
      }
    }
  }, [updateFundraisersLike])

  return (
    <>
      <Modal {...props} onHide={props.onHide} className="FundriserPreview" size="xl" centered>
        <div className="PreviewWrapper">
          <div className="ImageSection">
            <Modal.Header closeButton></Modal.Header>
            <div className="Img">
              <div className="card-img gallery-commentpopup-image-back">
                <div className="carousel slide carousel-fade">
                  <div className="carousel-inner">
                    <div className="image-carousel active carousel-item">
                      {fundraiserInfo?.image ? (
                        <img src={fundraiserInfo?.image} alt={fundraiserInfo?.title} />
                      ) : (
                        <div className="no-fundraiser-image"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Commentsection">
            <div ref={ref}>
              <Modal.Header closeButton>
                <div className="owner">
                  <div className="ownerPic">
                    {isset(fundraiserInfo?.beneficiary_image) ? (
                      <img src={fundraiserInfo?.beneficiary_image} alt={fundraiserInfo?.beneficiary_name} />
                    ) : (
                      <div className="ownerAlpha">
                        <span>{fundraiserInfo?.beneficiary_name?.charAt(0)}</span>
                      </div>
                    )}
                  </div>
                  <div className="ownerName">
                    {fundraiserInfo?.beneficiary_name}
                    <span className="created_at">Beneficiary (nonprofit)</span>
                  </div>
                </div>
              </Modal.Header>
              <div className="ImgMobile">
                <div className="card-img gallery-commentpopup-image-back">
                  <div className="carousel slide carousel-fade">
                    <div className="carousel-inner">
                      <div className="image-carousel active carousel-item">
                        {fundraiserInfo?.image ? (
                          <img src={fundraiserInfo?.image} alt={fundraiserInfo?.title} />
                        ) : (
                          <div className="no-fundraiser-image"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className="fundraiserPre-contents">
                  <div className="fundraiserPre-heading">
                    <h6>{fundraiserInfo?.title}</h6>
                    <p>{fundraiserInfo?.description}</p>
                  </div>
                </div>
                <div className="fundraiserPre-ownerdetails">
                  <div className="owner">
                    <div className="ownerPic">
                      {pageDetail?.belongsTo?.image && pageDetail?.belongsTo?.image !== null ? (
                        <img src={pageDetail?.belongsTo?.image} alt="" />
                      ) : (
                        <div className="ownerAlpha">
                          <span>{pageDetail?.belongsTo?.name?.charAt(0)}</span>
                        </div>
                      )}
                    </div>
                    <div className="ownerName">
                      <span className="created_at">Created by</span>
                      {pageDetail?.belongsTo?.name}
                    </div>
                  </div>
                </div>
                <div className="fundraiserPre-reactionWrapper">
                  <span className="addreaction d-none" onClick={() => {}}>
                    <i className="fa fa-butterflyaddreaction"></i>
                  </span>
                  <div className="Reactions">
                    <span>
                      <i
                        className={
                          fundraiser?.counts?.comments > 0 || postCommentData?.length > 0
                            ? 'fa fa-comment'
                            : 'fa fa-nocomment'
                        }
                        aria-hidden="true"
                      ></i>
                      {postCommentData?.length
                        ? postCommentData?.length
                        : fundraiserInfo?.counts?.comments
                        ? fundraiserInfo?.counts?.comments
                        : ''}
                    </span>
                    <span>
                      {checkLikedOrNot(userState?.value?.data?._id, fundraiserInfo?.likes) ? (
                        <i
                          onClick={() => {
                            if (!userState?.value?.data?._id) {
                              loginRedirect()
                            } else if (!canAction()) {
                              dispatch(addPageAction('show-subsciption-step'))
                            } else {
                              removelikePost(
                                userState?.value?.data?._id,
                                fundraiserInfo?._id,
                                fundraiserInfo?.page,
                                fundraiserInfo?.likes
                              )
                            }
                          }}
                          className={cn('fa fa-butterflylike', {
                            disabled: fundraiserInfo?.isLikeDisable || !canAction(),
                          })}
                        ></i>
                      ) : (
                        <i
                          onClick={() => {
                            if (!userState?.value?.data?._id) {
                              loginRedirect()
                            } else if (!canAction()) {
                              dispatch(addPageAction('show-subsciption-step'))
                            } else {
                              addlikePost(userState?.value?.data?._id, fundraiserInfo?._id, fundraiserInfo?.page)
                            }
                          }}
                          className={cn('fa fa-heartoutline', {
                            disabled: fundraiserInfo?.isLikeDisable,
                          })}
                          aria-hidden="true"
                        ></i>
                      )}
                      {fundraiserInfo?.likes?.length ? fundraiserInfo?.likes?.length : ''}
                    </span>
                  </div>
                </div>
              </>
            </div>
            <div
              className="MessageBoard"
              style={{
                overflow: `overlay`,
                maxHeight: `calc(100vh - calc(80px + ${height}px))`,
                height: `calc(100vh - calc(80px + ${height}px))`,
              }}
            >
              <div
                className={cn('chat-wrapper', {
                  EmptyComments: postCommentData?.length === 0,
                })}
              >
                {postCommentData?.length ? (
                  <>
                    {postCommentData?.map((comment: any, index: number) => {
                      return (
                        <div key={index} className="message-wrapper">
                          <div className="d-flex w-100">
                            <div className="ownerPic">
                              {comment?.createdBy?.image ? (
                                <img src={comment?.createdBy?.image} className="message-pp" alt="" />
                              ) : (
                                <div className="ownerAlpha">
                                  <span>{comment?.createdBy?.name?.charAt(0)}</span>
                                </div>
                              )}
                            </div>
                            {!comment?.isEdit ? (
                              <div className="message-box-wrapper">
                                <div className="title-box">
                                  <span className="name">{comment?.createdBy?.name}</span>
                                  {comment?.createdBy?._id === userState?.value?.data?._id ? (
                                    <span className="time">
                                      <span className="comment-time">
                                        {subcommenttimeSince(comment?.updatedAt)} ago
                                      </span>
                                      <i
                                        onClick={() => {
                                          mainCommentAction(comment)
                                        }}
                                        className="fa fa-meatballs"
                                        aria-hidden="true"
                                      ></i>
                                      {comment?.isAction && (
                                        <OutsideClickHandler
                                          className="dropdown"
                                          onOutsideClick={() => {
                                            mainCommentAction(comment)
                                          }}
                                        >
                                          <div className="ActionMenu postActiveMenu">
                                            <ul>
                                              <li
                                                onClick={() => {
                                                  editComment(comment)
                                                }}
                                              >
                                                <i className="fa fa-edit"></i> Edit
                                              </li>
                                              <li
                                                onClick={() => {
                                                  showDeletePopup(comment)
                                                }}
                                              >
                                                <i className="fa fa-butterflydelete"></i> Delete
                                              </li>
                                            </ul>
                                          </div>
                                        </OutsideClickHandler>
                                      )}
                                    </span>
                                  ) : (
                                    <span className="time">{subcommenttimeSince(comment?.updatedAt)} ago</span>
                                  )}
                                </div>
                                <div className="message-box">
                                  <ReadMore>{comment?.comment}</ReadMore>
                                  {comment.image && comment.image !== '' ? (
                                    <div className="chatImageWrapper">
                                      <img src={comment?.image} />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="footer">
                                  <div className="d-flex align-items-center">
                                    <span
                                      onClick={() => {
                                        if (canAction()) {
                                          setReplyData(comment)
                                        } else {
                                          dispatch(addPageAction('show-subsciption-step'))
                                        }
                                      }}
                                      className="reply"
                                    >
                                      Reply
                                    </span>
                                    <span
                                      onClick={() => {
                                        if (canAction()) {
                                          setActiveReactionOpenId(true)
                                          setReactionComment(comment)
                                        } else {
                                          dispatch(addPageAction('show-subsciption-step'))
                                        }
                                      }}
                                      className="addreaction"
                                    >
                                      <i className="fa fa-butterflyaddreaction"></i>
                                    </span>
                                  </div>
                                  {comment?.reactions.length ? (
                                    <div
                                      onClick={() => {
                                        setDisabledScroll(true)
                                        setReactionModalShow(true)
                                        setActiveReaction({
                                          allReaction: comment?.reactions,
                                          reactionGroup: getReactionsArray(comment?.reactions),
                                        })
                                      }}
                                      className="ctas"
                                    >
                                      <span>
                                        {Object.keys(getReactionsArray(comment?.reactions)).map(
                                          (reactionKey: any, index: any) => {
                                            if (index < 4) {
                                              return <Emoji key={index} unified={reactionKey} size={18} />
                                            }
                                            return null
                                          }
                                        )}
                                        {comment?.reactions?.length}
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="message-box-wrapper Edit">
                                <div className="title-box-textarea">
                                  <textarea
                                    value={mainCommentEdit}
                                    onChange={editCommentMessage}
                                    id="message-input-edit-post-comment"
                                    placeholder="Type your comment here"
                                    ref={inputReferenceEdit}
                                  ></textarea>
                                </div>
                                <div className="footer">
                                  <span className="addreaction">
                                    <i
                                      onClick={() => {
                                        setEditMessageInfo(mainCommentEdit)
                                        setActiveReactionOpenId(true)
                                        setReactionComment(null)
                                      }}
                                      className="fa fa-butterflyaddreaction"
                                    ></i>
                                  </span>
                                  <span className="ctas">
                                    <button
                                      onClick={() => {
                                        editComment(comment)
                                      }}
                                      className="cancel"
                                    >
                                      <i className="fa fa-butterflynoapproval"></i>
                                      Cancel
                                    </button>
                                    <button
                                      onClick={() => {
                                        updateComment(comment)
                                      }}
                                      disabled={mainCommentEdit.trim() === ''}
                                      className="save"
                                    >
                                      <i className="fa fa-butterflytick"></i>
                                      Save
                                    </button>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          {comment?.replies?.length ? (
                            <>
                              {comment?.replies?.map((rcomment: any, index: number) => {
                                return (
                                  <div key={index} className="message-wrapper-reply">
                                    <div className="ownerPic">
                                      {rcomment?.createdBy?.image ? (
                                        <img src={rcomment?.createdBy?.image} className="message-pp" alt="" />
                                      ) : (
                                        <div className="ownerAlpha">
                                          <span>{rcomment?.createdBy?.name?.charAt(0)}</span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="message-box-wrapper">
                                      <div className="title-box">
                                        <span className="name">{rcomment?.createdBy?.name}</span>
                                        {rcomment?.createdBy?._id === userState?.value?.data?._id && (
                                          <>
                                            <span className="time">
                                              {subcommenttimeSince(rcomment?.updatedAt)} ago{' '}
                                            </span>
                                            <span className="time d-none">
                                              <i className="fa fa-meatballs" aria-hidden="true"></i>
                                              <div className="ActionMenu postActiveMenu d-none">
                                                <ul>
                                                  <li>
                                                    <i className="fa fa-edit"></i> Edit
                                                  </li>
                                                  <li>
                                                    <i className="fa fa-butterflydelete"></i> Delete
                                                  </li>
                                                </ul>
                                              </div>
                                            </span>
                                          </>
                                        )}
                                      </div>
                                      <div className="message-box">
                                        <ReadMore>{rcomment?.reply}</ReadMore>
                                        {rcomment.image && rcomment.image !== '' ? (
                                          <div className="chatImageWrapper">
                                            <img src={rcomment?.image} />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="footer">
                                        <div>
                                          <span
                                            onClick={() => {
                                              setActiveReactionOpenId(true)
                                              setReactionComment(rcomment)
                                            }}
                                            className="addreaction"
                                          >
                                            <i className="fa fa-butterflyaddreaction"></i>
                                          </span>
                                        </div>
                                        {rcomment?.reactions?.length ? (
                                          <div
                                            onClick={() => {
                                              setDisabledScroll(true)
                                              setReactionModalShow(true)
                                              setActiveReaction({
                                                allReaction: rcomment?.reactions,
                                                reactionGroup: getReactionsArray(rcomment?.reactions),
                                              })
                                            }}
                                            className="ctas"
                                          >
                                            <span>
                                              {Object.keys(getReactionsArray(rcomment?.reactions)).map(
                                                (reactionKey: any, index: any) => {
                                                  if (index < 4) {
                                                    return <Emoji key={index} unified={reactionKey} size={18} />
                                                  }
                                                  return null
                                                }
                                              )}
                                              {rcomment?.reactions?.length}
                                            </span>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )
                    })}
                    <div ref={bottomRef} />
                  </>
                ) : (
                  <>
                    {showLoader ? (
                      <div className="comment-loader">
                        <LottieControl />
                      </div>
                    ) : (
                      <div className="no-comments">{'No comments yet, but check back soon'}</div>
                    )}
                  </>
                )}
              </div>
              {canAction() ? (
                <>
                  {replyData && (
                    <div className="reply-box">
                      <div className="reply-wrapper">
                        <div className="header">
                          <div className="title">Replying to {replyData?.createdBy?.name}</div>
                          <span
                            className="close-btn"
                            onClick={() => {
                              setReplyData(null)
                            }}
                          >
                            <i className="fa fa-butterflynoapproval"></i>
                          </span>
                        </div>
                        <div className="message">{replyData?.comment}</div>
                      </div>
                    </div>
                  )}
                  <div className="chat-input-wrapper">
                    <div className="input-wrapper">
                      <textarea
                        rows={3}
                        ref={inputReference}
                        id="message-input-post-comment"
                        className="chat-input"
                        placeholder="Add a comment"
                        value={messageInfo}
                        onChange={messageValue}
                        onKeyDown={event => {
                          const keyCode = event.which || event.keyCode
                          if (keyCode === 13 && !event.shiftKey && (canSend() || showCommentImage)) {
                            addNewComment()
                            event.preventDefault()
                          }
                        }}
                      ></textarea>
                      {!showCommentImage && (
                        <button
                          onClick={() => {
                            openFileSelection()
                          }}
                          className="emoji-btn"
                          type="button"
                        >
                          <i className="fa fa-fa fa-butterflymedia"></i>
                          <input
                            type="file"
                            className="d-none"
                            id="comment_input_image"
                            onChange={systemFileSelection}
                            accept="image/*"
                          ></input>
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setActiveReactionOpenId(true)
                          setReactionComment(null)
                          setEditMessageInfo('')
                        }}
                        className="emoji-btn"
                        type="button"
                      >
                        <i className="fa fa-butterflysmiley"></i>
                      </button>
                      <button
                        disabled={!canSend() && !showCommentImage}
                        onClick={() => {
                          addNewComment()
                        }}
                        className={cn('chat-send-btn', {
                          'can-send': canSend() || showCommentImage,
                        })}
                        type="button"
                      >
                        <i className="fa fa-butterflysend"></i>
                      </button>
                    </div>
                    {showCommentImage && (
                      <>
                        {selectedFiles?.map((sImage: any, index: number) => {
                          return (
                            <div key={index} className="chatImageHolder">
                              <div className="chatImageWrapper">
                                <img src={sImage.thumbnail} />
                                <span
                                  className="closeBtn"
                                  onClick={() => {
                                    removeSelected()
                                  }}
                                >
                                  <i className="fa fa-close"></i>
                                </span>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}
                    {activeReactionOpenId ? (
                      <div className="reaction-emoji-msgbord">
                        <p
                          onClick={() => {
                            setActiveReactionOpenId(false)
                            setReactionComment(null)
                          }}
                        >
                          &#x2715;
                        </p>
                        <Picker autoFocusSearch={false} onEmojiClick={onEmojiClick} />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {deleteshow ? (
        <DeletePopup
          deleteshow={deleteshow}
          pageid={currentPostId}
          handleclose={() => {
            handleDeletesPopupClose()
          }}
          deleteType={deleteType}
          mediadata={additionData}
          albumdata={{}}
        />
      ) : (
        <></>
      )}

      {reactionModalShow && (
        <ReactionListPopup
          activePost={activePost}
          show={reactionModalShow}
          handleClose={() => {
            setReactionModalShow(false)
          }}
          activeReaction={activeReaction}
          type={'fundraiser'}
        />
      )}
    </>
  )
}

export default FundraisersPreview
