import './Gallery.scss'

import cn from 'classnames'
import { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../Analytics/GA'
import { useAppSelector } from '../../Hooks'
import {
  addPageAction,
  type galllery,
  pageCurrentAlbum,
  type pageDetailData,
  type pageGallleryData,
  pageGetGalllery,
  pageGetGallleryAlbum,
  pageReturnAction,
} from '../../Redux/slices/pageDetailSlice'
import {
  pageGetAlbumGallleryThunk,
  pageGetGallleryAlbumThunk,
  pageGetGallleryThunk,
} from '../../Redux/thunks/pageDetailThunk'
import Loader from '../Loader/Loader'
import AddAlbum from '../Modals/AddAlbum/AddAlbum'
import RenameAlbum from '../Modals/AddAlbum/RenameAlbum'
import DeletePopup from '../Modals/DeletePopup'
import Album from './Album'
import Media from './Media'

function Gallery(props: any) {
  const { showAlbumMedia, showAlbums } = props

  const dispatch = useDispatch()
  const [showThumbnail, setShowThumbnail] = useState(true)
  const [show, setShow] = useState(false)
  const [deleteshow, setDeleteshow] = useState(false)
  const handleDeletesPopupClose = () => {
    setDeleteshow(false)
  }
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }
  const [albumPopup, setAlbumPopup] = useState('add')
  const [rename, setRename] = useState(false)
  const handleRenameClose = () => {
    setRename(false)
  }
  const [activetab, setActiveTab] = useState('media')
  const [deleteType, setDeleteType] = useState('')
  const [mediaType, setMediaType] = useState('all')
  const [showAlbumGalllery, setShowAlbumGalllery] = useState(false)

  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const pageDetailReturnAction = useAppSelector((state: any) => state?.pagedetail?.returnAction)

  const pageGallleryData: pageGallleryData = useAppSelector((state: any) => state.pagedetail.gallleryData)

  const userState: any = useAppSelector(state => state.user)
  const [selectedMedia, setSelectedMedia] = useState<galllery>()
  const [selectedAlbum, setSelectedAlbum] = useState<any>()

  const handleTab = (eventKey: any, event: any) => {
    setActiveTab(eventKey)
  }
  const [albumShortType, setAlbumShortType] = useState('new')
  const [timeLineType, setTimeLineType] = useState('month')
  const [albumDataCall, setAlbumDataCall] = useState(true)

  const handleRename = (type: string) => {
    setAlbumPopup(type)
    if (type === 'add') {
      const actionVal = show ? 'addToAlbum' : ''
      setSelectedAlbum({ albumName: '', _id: '', action: actionVal })
      setShow(false)
    }
    setRename(true)
  }

  const handleDelete = (type: string) => {
    if (type !== 'album') {
      setSelectedAlbum({ albumName: '', _id: '' })
    }

    setDeleteType(type)
    setDeleteshow(true)
  }

  const handleAlbumSelect = (albumData: any) => {
    setMediaType('all')
    setSelectedAlbum(albumData)
    dispatch(pageCurrentAlbum([]))
    dispatch(pageGetAlbumGallleryThunk(albumData?._id))
    setShowAlbumGalllery(true)
    showAlbumMedia(true)
    trackEvent('Page Interaction', 'Album View', `${albumData?.name}`)
  }

  useEffect(() => {
    setShowAlbumGalllery(showAlbums)
  }, [showAlbums])

  useEffect(() => {
    if (pageDetailReturnAction === 'add-album-addToAlbum-action') {
      setRename(false)
      setShow(true)
      dispatch(pageReturnAction(''))
    } else if (pageDetailReturnAction === 'close-album-action') {
      setShow(false)
      dispatch(pageReturnAction(''))
    }
  }, [pageDetailReturnAction])

  useEffect(() => {
    // call galleryAPI
    if (pageDetailState._id) {
      dispatch(pageGetGalllery([]))
      dispatch(pageGetGallleryThunk(pageDetailState?._id, 1))
    }
  }, [])

  useEffect(() => {
    if (pageDetailState._id && pageDetailState?.counts?.gallery) {
      dispatch(pageGetGalllery(pageGallleryData?.gallery))
      dispatch(pageGetGallleryThunk(pageDetailState?._id, 1))
    }
  }, [pageDetailState?.counts?.gallery])

  useEffect(() => {
    // call galleryAPI
    if (pageDetailState._id && activetab === 'albums' && albumDataCall) {
      setAlbumDataCall(false)
      dispatch(pageGetGallleryAlbum([]))
      dispatch(pageGetGallleryAlbumThunk(pageDetailState?._id))
    }
  }, [activetab])

  const albumShort = (event: any) => {
    setAlbumShortType(event.target.value)
  }

  const timeLineSelect = (event: any) => {
    setTimeLineType(event.target.value)
  }

  const typeSelect = (event: any) => {
    setMediaType(event.target.value)
  }

  const handleAddMedia = () => {
    // To close Gallery Slide
    dispatch(addPageAction('add-media'))
  }

  return (
    <>
      {userState.loading && <Loader />}
      <div className="action">
        {(activetab === 'media' || showAlbumGalllery) && (
          <>
            <i
              onClick={() => {
                setShowThumbnail(true)
              }}
              className={cn('fa fa-thumbnail', {
                active: showThumbnail,
              })}
            ></i>
            <i
              onClick={() => {
                setShowThumbnail(false)
                setMediaType('all')
              }}
              className={cn('fa fa-calendar', {
                active: !showThumbnail,
              })}
            ></i>
          </>
        )}
        {activetab === 'media' || showAlbumGalllery ? (
          <>
            {showThumbnail ? (
              <select value={mediaType} onChange={typeSelect}>
                <option value={'all'}>All</option>
                <option value={'image'}>Photo</option>
                <option value={'video'}>Video</option>
                <option value={'audio'}>Audio</option>
              </select>
            ) : (
              <select onChange={timeLineSelect}>
                <option value={'month'}>By Month</option>
                <option value={'year'}>By Year</option>
              </select>
            )}
          </>
        ) : (
          <>
            <select onChange={albumShort}>
              <option value={'new'}>Newest First</option>
              <option value={'old'}>Oldest First</option>
            </select>
          </>
        )}
      </div>
      {showAlbumGalllery && <div className="selected-album-name">{selectedAlbum?.albumName}</div>}
      <Tabs
        defaultActiveKey="media"
        id="uncontrolled-tab-example"
        className={cn('GalleryTab', {
          hide: showAlbumGalllery,
        })}
        onSelect={handleTab}
      >
        {/* ---------Media Tab---------- */}
        <Tab eventKey="media" title="Media" className="gallery-pagination">
          <Media
            showThumbnail={showThumbnail}
            setSelectedMedia={setSelectedMedia}
            handleShow={handleShow}
            timeLineType={timeLineType}
            handleDelete={handleDelete}
            pageGallleryData={pageGallleryData}
            showAlbumGalllery={showAlbumGalllery}
            handleAddMedia={handleAddMedia}
            mediaType={mediaType}
          />
        </Tab>

        {/* -----------Albums Tab---------- */}
        <Tab eventKey="albums" title="Albums" className="album">
          {showAlbumGalllery ? (
            <Media
              showThumbnail={showThumbnail}
              setSelectedMedia={setSelectedMedia}
              handleShow={handleShow}
              timeLineType={timeLineType}
              handleDelete={handleDelete}
              pageGallleryData={pageGallleryData}
              showAlbumGalllery={showAlbumGalllery}
              handleAddMedia={handleAddMedia}
              mediaType={mediaType}
            />
          ) : (
            <Album
              handleRename={handleRename}
              handleDelete={handleDelete}
              handleAlbumSelect={handleAlbumSelect}
              setSelectedAlbum={setSelectedAlbum}
              albumShortType={albumShortType}
            />
          )}
        </Tab>
      </Tabs>

      {show ? (
        <AddAlbum
          show={show}
          mediadata={selectedMedia}
          pageid={pageDetailState?._id}
          handleclose={handleClose}
          handleRename={handleRename}
        />
      ) : (
        ''
      )}
      {rename ? (
        <RenameAlbum
          renamstatus={rename.toString()}
          albumdata={selectedAlbum}
          albumpopup={albumPopup}
          pageid={pageDetailState?._id}
          handleclose={() => {
            handleRenameClose()
          }}
        />
      ) : (
        ''
      )}
      {deleteshow ? (
        <DeletePopup
          deleteshow={deleteshow}
          pageid={pageDetailState?._id}
          handleclose={() => {
            handleDeletesPopupClose()
          }}
          deleteType={deleteType}
          mediadata={selectedMedia}
          albumdata={selectedAlbum}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Gallery
