import './ReportPopup.scss'

import cn from 'classnames'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../../Hooks'
import { addReportThunk } from '../../../Redux/thunks/postThunk'
import Loader from '../../Loader/Loader'

function ReportPopup(props: any) {
  const { handleclose, reportshow, reportdata } = props
  const dispatch = useDispatch()

  const [messageInfo, setMessageInfo] = useState<string>('')
  const userState: any = useAppSelector(state => state.user)
  const submitButton = () => {
    const payload = { ...reportdata, report: messageInfo }
    dispatch(addReportThunk(payload))
    handleclose()
  }

  const reportValue = (event: any) => {
    // Access input value
    const messageComment = event.target.value.trim()
    setMessageInfo(messageComment)
  }

  const canSend = () => {
    return messageInfo !== ''
  }

  return (
    <Modal className="ReportPopup" {...props} show={reportshow} onHide={handleclose} centered size="md">
      {userState.loading && <Loader />}
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <i className="fa fa-report media"></i>
        <textarea onChange={reportValue} placeholder="Type your report message here"></textarea>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={!canSend()}
          className={cn('', {
            mediabutton: canSend(),
          })}
          onClick={submitButton}
        >
          Submit Report
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReportPopup
