import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import thunk from 'redux-thunk'

import audioMessageReducer from './slices/audioMessageSlice'
import messageboardReducer from './slices/messageBoardSlice'
import metaReducer from './slices/metaSlice'
import notificationReducer from './slices/NotificationSlice'
import pageDetailReducer from './slices/pageDetailSlice'
import pageReducer from './slices/pageSlice'
import postReducer from './slices/post'
import settingsReducer from './slices/settingsSlice'
import userReducer from './slices/userSlice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

export const rootReducers = combineReducers({
  user: userReducer,
  metadata: metaReducer,
  messageboard: messageboardReducer,
  audiomessages: audioMessageReducer,
  pagedetail: pageDetailReducer,
  post: postReducer,
  page: pageReducer,
  notification: notificationReducer,
  settings: settingsReducer,
})
const persistedReducer = persistReducer(
  {
    ...persistConfig,
    transforms: [
      encryptTransform({
        // TODO: secret key should be generated by backend pvt key
        secretKey: 'my-super-secret-key',
        onError: () => {},
      }),
    ],
  },
  rootReducers
)
export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  devTools: true,
  middleware: [thunk],
})

export const persistor = persistStore(store)

/**
 * This is required so that dispatch can detect the state types and also thunk methods.
 * Otherwise in typescript dispatch will give type not assignable error
 */

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
