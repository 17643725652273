import { type NavigateFunction } from 'react-router-dom'

import AxiosClass from '../../Api/Interceptor'
import UserApi from '../../Api/UserApi'
import { RouteConstants } from '../../Constants/routeConstants'
import { setAppError } from '../slices/metaSlice'
import {
  loading,
  login,
  setAddConactResponse,
  setAddNewVisitorResponse,
  setAuthAlert,
  setEmail,
  verifyEmail,
} from '../slices/userSlice'

export const registerThunk: any = (registerPayload: any, navigate: NavigateFunction) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new UserApi(AxiosClass)
      .registerUser(registerPayload)
      .then((r: any) => {
        // This condition is temprary as we are working on exception handling from backend
        if (r.success) {
          dispatch(loading(false))
          dispatch(verifyEmail(true))
          dispatch(setEmail(registerPayload.email))
        } else {
          throw r
        }
      })
      .catch(e => {
        dispatch(loading(false))
        dispatch(
          setAppError({
            name: 'AuthError',
            status: true,
            state: 'ERROR',
            content: e.message,
          })
        )
      })
  }
}

export const loginThunk: any = (loginPayload: any, navigate: NavigateFunction) => {
  return (dispatch: any, getstate: any) => {
    dispatch(loading(true))
    dispatch(setEmail(loginPayload.email))
    new UserApi(AxiosClass)
      .loginUser(loginPayload)
      .then((r: any) => {
        dispatch(loading(false))
        // This condition is tempørary as we are working on exception handling from backend
        if (r.success) {
          localStorage.setItem('user_id', r?.data?._id)
          dispatch(login(r))
          if (localStorage.getItem('ForInviteUser') !== null) {
            const pageurl: any = localStorage?.getItem('ForInviteUser')
            navigate(RouteConstants.PAGEDETAIL?.replace(':pageslug', pageurl))
          } else if (localStorage.getItem('actionUrl') !== null) {
            let pData: any = localStorage.getItem('actionUrl')
            pData = JSON.parse(pData)
            navigate(RouteConstants.PAGEDETAIL?.replace(':pageslug', pData?.page + '?from=' + pData?.path))
          } else if (localStorage.getItem('backToPublicPage') !== null) {
            const backURL: any = localStorage.getItem('backToPublicPage')
            navigate(backURL)
          } else if (localStorage.getItem('backToLegacyPage') !== null) {
            const backUrl: any = localStorage.getItem('backToLegacyPage')
            navigate(backUrl)
          } else if (localStorage.getItem('notificationEventIdRedirect') !== null) {
            const backURL: any = localStorage.getItem('notificationEventIdRedirect')
            navigate(backURL)
          } else {
            if (r.data.is_initiated) {
              navigate(RouteConstants.MYPAGES)
            } else {
              navigate(RouteConstants.CREATEFIRSTPAGE)
            }
          }
        } else {
          if (r.data && r.data.isEmail_verified === false) {
            dispatch(verifyEmail(true))
          }
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
        // TODO: convert to enums shared between client and server
        if (e.error.name === 'password/mismatch') {
          dispatch(
            setAuthAlert({
              status: true,
              content: e.message,
              name: e.error.name,
            })
          )
        } else if (e.error.name === 'generic/not-found') {
          dispatch(
            setAuthAlert({
              status: true,
              content: e.message,
              name: e.error.name,
            })
          )
        } else {
          dispatch(
            setAppError({
              name: 'AuthError',
              status: true,
              state: 'ERROR',
              content: e.message,
            })
          )
        }
      })
  }
}

// Unused ??
export const validateEmailThunk = (payload: any, navigate: NavigateFunction) => {
  return (dispatch: any, getstate: any) => {
    new UserApi(AxiosClass)
      .validateEmail(payload)
      .then((r: any) => {
        dispatch(loading(false))
        // This condition is temprary as we are working on exception handling from backen
        if (!r.success) {
          throw r
        } else {
          setAuthAlert({
            status: true,
            content: r.message,
          })
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
        dispatch(
          setAuthAlert({
            status: true,
            content: e.message,
          })
        )
      })
  }
}

export const addNewVisitor: any = (payload: any) => {
  return (dispatch: any, getstate: any) => {
    dispatch(loading(true))
    new UserApi(AxiosClass)
      .addVisitor(payload)
      .then((r: any) => {
        dispatch(loading(false))
        dispatch(setAddNewVisitorResponse(r))
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addConact: any = (payload: any) => {
  return (dispatch: any, getstate: any) => {
    dispatch(loading(true))
    new UserApi(AxiosClass)
      .addContact(payload)
      .then((r: any) => {
        dispatch(loading(false))
        dispatch(setAddConactResponse(r))
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
