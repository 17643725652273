import { environment } from '../AppConfig/environment'

export const SIGNINURL = `${environment.apiEndPoint}auth/signin`
export const SIGNUPURL = `${environment.apiEndPoint}auth/signup`
export const OTPVERIFYURL = `${environment.apiEndPoint}auth/otpVerify`
export const RESENDOTPURL = `${environment.apiEndPoint}auth/resendOtp`
export const FORGOTPWURL = `${environment.apiEndPoint}auth/forgotpasswordop`
export const CHANGEPWURL = `${environment.apiEndPoint}auth/changePassword`
export const EMAILVALIDATIONURL = `${environment.apiEndPoint}auth/emailValidation`

export const PAGECREATEURL = `${environment.apiEndPoint}page/create`
export const PAGEDETAIL = `${environment.apiEndPoint}page/detailPage`
export const PAGEDELETE = `${environment.apiEndPoint}page/delete/:pageId/:userId`

export const PAGEVALIDATEURL = `${environment.apiEndPoint}page/validateURL`
export const PAGEEDIT = `${environment.apiEndPoint}page/update`
export const PAGEURLCREATE = `${environment.apiEndPoint}page/createURL`
export const MEDIAUPLOAD = `${environment.apiEndPoint}media/upload/:pageId`
export const MEDIAUPLOADEXTERNAL = `${environment.apiEndPoint}media/upload-external/:pageId`
export const PAGEPUBLISH = `${environment.apiEndPoint}page/publishPage`
export const PAGEPGETGALLLERY = `${environment.apiEndPoint}media/gallery/:pageId?page=:pageno`
export const PAGEPGETGALLLERYALBUM = `${environment.apiEndPoint}media/metadata/:pageId/album`
export const ADDTOALBUM = `${environment.apiEndPoint}media/album/:albumId/:itemId`
export const CREATEALBUM = `${environment.apiEndPoint}media/album/create`
export const RENAMEALBUM = `${environment.apiEndPoint}media/album/:albumId`
export const ALBUMDELETE = `${environment.apiEndPoint}media/album/:albumId`
export const MEDIADELETE = `${environment.apiEndPoint}media/gallery/:itemId`
export const ALBUMMEDIADELETE = `${environment.apiEndPoint}media/album/remove/:itemId/from/:albumId`
export const PAGEPGETALBUMGALLLERY = `${environment.apiEndPoint}media/album/:albumId`

export const POSTCREATEURL = `${environment.apiEndPoint}page/createPost`
export const GETPOSTURL = `${environment.apiEndPoint}page/:pageId/post/list`
export const PINUNPINPOSTURL = `${environment.apiEndPoint}page/post/:postId/pin`
export const POSTUPDATEURL = `${environment.apiEndPoint}page/post/:postId`
export const POSTDELETEURL = `${environment.apiEndPoint}page/post/:postId`
export const POSTCOMMENTONOFFURL = `${environment.apiEndPoint}page/post/:postId/commentstate`

// Events APIs
export const GETALLEVENTS = `${environment.apiEndPoint}page/events/all/:pageId/:userId`
export const ADDEVENTURL = `${environment.apiEndPoint}page/createEvent`
export const UPDATEEVENTURL = `${environment.apiEndPoint}page/updateEvent`
export const GETEVENT = `${environment.apiEndPoint}page/events/:eventId/:userId`
export const INVITEUPDATE = `${environment.apiEndPoint}page/inviteUpdate/:eventId`
export const EVENTDELETE = `${environment.apiEndPoint}page/events/:eventId`

// Static MockData
// export const LOADCHATMESSAGES = `${window.location.origin+"/StaticData/messageboard.json"}`
export const LOADAUDIOMESSAGES = `${window.location.origin + '/StaticData/audioMessage.json'}`

export const LOADCHATMESSAGES = `${environment.apiEndPoint}page/messageboard/message`

// Send email generic
export const SENDEMAILGENERIC = `${environment.apiEndPoint}page/email/send`

export const BLOCKUNBLOCKMEMBER = `${environment.apiEndPoint}page/member/:memberId/:pageId`

// get All Comments

export const GETALLCOMMENTS = `${environment.apiEndPoint}page/post/:postId/comment`

export const GETALLMEDIACOMMENTS = `${environment.apiEndPoint}page/post/media/:mediaId/comment`

export const POSTCOMMENTCREATE = `${environment.apiEndPoint}page/post/comment`
export const EDITPOSTCOMMENT = `${environment.apiEndPoint}page/post/comment/:commentId`
export const DELETEPOSTCOMMENT = `${environment.apiEndPoint}page/post/comment/:commentId`

//  Reaction for comment/Reply

export const ADDREACTIONFORCOMMENT = `${environment.apiEndPoint}page/post/comment/react`
export const ADDREACTIONFORCOMMENTREPLY = `${environment.apiEndPoint}page/post/comment/reply/react`
export const REMOVEREACTIONCOMMENT = `${environment.apiEndPoint}page/post/comment/react/:reactId`
export const REMOVEREACTION = `${environment.apiEndPoint}page/post/comment/react/:reactId`
export const REMOVEREACTIONCOMMENTREPLY = `${environment.apiEndPoint}page/post/comment/reply/react/:reactId`
export const EDITREACTION = `${environment.apiEndPoint}page/post/comment/react/:reactId`

// Reply
export const POSTCOMMENTCREATEREPLAY = `${environment.apiEndPoint}page/post/comment/reply`

// Like
export const ADDLIKE = `${environment.apiEndPoint}page/post/like`
export const REMOVELIKE = `${environment.apiEndPoint}page/post/:postId/like/:likeId`

// Audio message
export const GETAUDIOMSG = `${environment.apiEndPoint}media/audio/:pageId/:userId`
export const DELETEAUDIOMSG = `${environment.apiEndPoint}media/audio/:pageId/:audioId`
export const NEWSTATEUPDATE = `${environment.apiEndPoint}media/audio/:audioId`
export const SENDAUDIOMSG = `${environment.apiEndPoint}media/audio`

// get my subscribe pages.
export const MYSUBSCIBEPAGE = `${environment.apiEndPoint}page/mysubscibe/:userId`
export const SUBSCRIBEPAGE = `${environment.apiEndPoint}page/:pageId/subscribe/:subscriberId/:token`
export const UNSUBSCRIBEPAGE = `${environment.apiEndPoint}page/:pageId/unsubscribe/:subscriberId`

// My Page
export const MYPAGES = `${environment.apiEndPoint}page/list/:ownerId`

// Remove Reaction
export const COMMENTREACTIONREMOVE = `${environment.apiEndPoint}page/post/comment/react/:reactId`
export const COMMENTREPLYREACTIONREMOVE = `${environment.apiEndPoint}page/post/comment/reply/react/:reactId`

// Notification
export const NOTIFICATIONGETURL = `${environment.apiEndPoint}page/notification/get/:belongsTo`
export const TAKEACTIONNOTIFICATIONURL = `${environment.apiEndPoint}page/notification/takeaction`
export const GETNOTIFICATIONCOUNTURL = `${environment.apiEndPoint}page/badges/notification/all/:userId`
export const READALLNOTIFICATIONURL = `${environment.apiEndPoint}page/readall/notification/:userId`

// Questions
export const REORDERQUESTIONS = `${environment.apiEndPoint}page/questions/reorder/:pageId`

// Share via email
export const SHAREINVITEMAILURL = `${environment.apiEndPoint}page/share/:pageId/:type`

// Search page
export const SEARCHPAGE = `${environment.apiEndPoint}page/search/all`

// Settings tab
export const UPDATEUSERDETAILS = `${environment.apiEndPoint}auth/user/edit`
export const CHANGEPW = `${environment.apiEndPoint}auth/settings/changePwd`
export const GETNOTIFICATIONSETTINGS = `${environment.apiEndPoint}auth/settings/:belongsTo`
export const UPDATENOTIFICATIONSETTINGS = `${environment.apiEndPoint}auth/settings/update`
export const CHANGEPAYMENTPLANURL = `${environment.apiEndPoint}auth/settings/payment`
export const GETPAYMENTDETAILS = `${environment.apiEndPoint}auth/getPayment/:userId`

export const BLOCKMEMBERS = `${environment.apiEndPoint}auth/blockmembers/:userId`
export const UNBLOCKMEMBER = `${environment.apiEndPoint}auth/settings/unblock`

export const GETPERSONALMESSAGE = `${environment.apiEndPoint}page/personalmessage/all/:pageId`

// Report API

export const ADDREPORT = `${environment.apiEndPoint}page/report/add`

// Fundraiser API

export const GETALLFUNDRAISER = `${environment.apiEndPoint}page/fundraiser/all/:pageId`
export const ADDDONATION = `${environment.apiEndPoint}page/fundraiser/addDonation`
export const CREATEFUNDRAISER = `${environment.apiEndPoint}page/fundraiser/create`
export const UPDATEFUNDRAISER = `${environment.apiEndPoint}page/fundraiser/:fundraiserId/update`
export const REMOVEFUNDRAISERLIKE = `${environment.apiEndPoint}page/post/like/fundraiser/:likeId/:fundraiserId`
export const GETALLFUNDRAISERCOMMENTS = `${environment.apiEndPoint}page/post/fundraiser/:fundraiserId/comment`

// Add visitor API
export const ADDVISITORURL = `${environment.AdminApiEndPoint}/visitors/add`

// Contact Form
export const ADDCONTACT = `${environment.AdminApiEndPoint}/contact`

// Get post details
export const POSTDETAIL = `${environment.apiEndPoint}page/details/post/:postId`

// Get all public pages
export const GETPUBLICPAGES = `${environment.apiEndPoint}page/publicPages/:searchChar`
