import ReactGA from 'react-ga4'

type UTMSource = 'link' | 'sms' | 'email' | 'twitter_post' | 'facebook_post' | 'linkedin_post'
type UTMMedium = 'link' | 'sms' | 'email' | 'social_media'
type UTMCampaign = 'legacy_launch'
type UTMContent = 'page_share'

export const trackEvent = async (category: string, action: string, label: string, additionalFields?: any) => {
  ReactGA.event({
    category,
    action,
    label,
    ...additionalFields,
  })
}

export const appendUTMCode = (
  url: string,
  source: UTMSource,
  medium: UTMMedium,
  campaign: UTMCampaign = 'legacy_launch',
  content: UTMContent = 'page_share'
) => {
  let newUrl = `${url}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}&utm_content=${content}`
  // encode & symbol if sent over sms
  if (source === 'sms') {
    newUrl = newUrl.replace(/&/g, '%26')
  }
  return newUrl
}
