import AudioMessageApi from '../../Api/AudioMessageAPI'
import AxiosClass from '../../Api/Interceptor'
import {
  audioMessageListResponse,
  loadAudioMessages,
  newStatusUpdateResponse,
  removeAudioMessageResponse,
} from '../slices/audioMessageSlice'
import { loading, setAuthAlert as setAlert } from '../slices/userSlice'

export const loadAudioMsgThunk: any = (pageId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new AudioMessageApi(AxiosClass)
      .loadAudioMessages(pageId)
      .then((r: any) => {
        dispatch(loadAudioMessages(r))
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
        dispatch(
          setAlert({
            status: true,
            content: e.message,
          })
        )
      })
  }
}

export const getAudioMessges: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new AudioMessageApi(AxiosClass)
      .getAudioList(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(audioMessageListResponse(r?.data))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deleteAudioMessges: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new AudioMessageApi(AxiosClass)
      .removeAudioMsg(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(removeAudioMessageResponse(r))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const newStatusUpdate: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new AudioMessageApi(AxiosClass)
      .AudioNewStatusUpdate(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))
            dispatch(newStatusUpdateResponse(r))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
