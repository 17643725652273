import './Layout.scss'

import Header from '../Components/Header/Header'
import { LayoutContainer } from '../Components/LayoutContainer'
import MobileFeatureNav from '../Components/MobileFeatureNav'

function Layout(props: any) {
  return (
    <>
      <Header />
      <LayoutContainer props>{props.children}</LayoutContainer>
      <MobileFeatureNav />
    </>
  )
}
export default Layout
