import 'react-step-progress-bar/styles.css'

import React from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'

interface Props {
  step: number
}

function ProgBar(props: Props) {
  return (
    <ProgressBar percent={((props.step - 1) * 100) / 2}>
      <Step transition="scale">
        {({ accomplished, index }: any) => <div className={`steps ${accomplished ? 'completed' : ''}`}></div>}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }: any) => <div className={`steps ${accomplished ? 'completed' : ''}`}></div>}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }: any) => <div className={`steps ${accomplished ? 'completed' : ''}`}></div>}
      </Step>
    </ProgressBar>
  )
}

export default ProgBar
