import './Legacy.scss'

import { useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import LegacyLogo from '../../../Assets/Images/ButterflyPartneredWLegacyLG.png'
import { useAppSelector } from '../../../Hooks'
import { type pageDetail, type pageDetailData } from '../../../Redux/slices/pageDetailSlice'
import { pagePublishThunk } from '../../../Redux/thunks/pageDetailThunk'
import CommonShareContent from '../SharePage/CommonShareContent'

function LegacyModal({ open, setOpen }: { open: boolean; setOpen: (param: boolean) => unknown }) {
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)

  const dispatch = useDispatch()
  const pageData: pageDetailData = pageDetailState.data

  const textEl: any = useRef(null)

  const handlePublish = async () => {
    await dispatch(pagePublishThunk(pageData?._id, true))
    setOpen(false)
    localStorage.removeItem('legacy_popup')
    const list = document.querySelectorAll(
      'li.DesktopQuestion, li.EventsModule, li.DesktopFundraiser, button.publishButton'
    )
    for (let i = 0; i < list.length; ++i) {
      list[i].classList.remove('legacy-highlight')
    }
  }

  const handleCloseModal = () => {
    localStorage.removeItem('legacy_popup')
    setOpen(false)
    const list = document.querySelectorAll(
      'li.DesktopQuestion, li.EventsModule, li.DesktopFundraiser, button.publishButton'
    )
    for (let i = 0; i < list.length; ++i) {
      list[i].classList.remove('legacy-highlight')
    }
  }

  return (
    <Modal
      show={open}
      className="legacy-modal"
      backdrop="static"
      onEscapeKeyDown={(e: KeyboardEvent) => {
        e.preventDefault()
      }}
      onHide={handleCloseModal}
    >
      <Modal.Body>
        <span ref={textEl} style={{ fontSize: '0px' }}>
          {window.location.origin + '/' + pageDetailState?.data?.unique_url}
        </span>
        <img className="logo" src={isMobile ? LegacyLogo : LegacyLogo} alt="Logo" />
        <div className="header">Legacy invites you to try Butterfly</div>
        <div className="body">
          Butterfly is an opportunity to celebrate a life with the richness it deserves. Start a page with just one
          click, importing all the information you entered in ObitWriter. After that, add as much as detail as you want
          – photos, videos, more text.
          <br />
          Build a page that honors a life.
        </div>
        <div className="body">
          1. Share your page, as a way to communicate with others and give them the ability to add to it.
        </div>
        <CommonShareContent hideText={true} />
        <div className="body">
          2. Celebrate {pageData?.first_name}’s life by asking some questions that will capture what made him so
          special. For example, one question that inspires people is “What are three words or images that come to mind
          when you hear Aaron’s name?”
        </div>
        <div className="body">3. Add an event.</div>
        <div className="body">4. Create a fundraiser.</div>
        <div className="publishContainer">
          {/* TODO: Refactor the logic. It should not use async. */}
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button className="publishButton" variant="primary" onClick={handlePublish}>
            Publish
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LegacyModal
