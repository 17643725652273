export const Regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/,
  specialChar: /[^\w\s]/gi,
  lowerCase: /[a-z]/g,
  upperCase: /[A-Z]/g,
  number: /[0-9]/g,
  oneLowercaseChar: /^(?=.*[a-z])/,
  oneUpperCaseChar: /^(?=.*[A-Z])/,
  oneSpecialChar: /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/,
  oneNumberChar: /^(?=.*[0-9])/,
}
