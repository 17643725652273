import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { createdByYear, createdDateAndByMonth, dateConvert, tConvert } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import EventDetails from './EventDetails'

const EventList = (props: any) => {
  const { setEventsScreen, eventsScreen } = props

  const pageDetailState: any = useAppSelector((state: any) => state.pagedetail)
  const userState: any = useAppSelector(state => state.user)

  const [showDetail, setShowDetail] = useState(false)
  const [activeEvent, setActiveEvent] = useState<any>({})
  const [pageEventsData, setPageEventsData] = useState<any>([])

  const dateTime = (startDate: Date, startTime: string, endDate: Date, endTime: string) => {
    if (dateConvert(startDate) === dateConvert(endDate)) {
      return `${createdDateAndByMonth(startDate)}, ${tConvert(startTime)} - ${tConvert(endTime)}`
    } else {
      return `${createdDateAndByMonth(startDate)}, ${tConvert(startTime)} - ${createdDateAndByMonth(
        endDate
      )}, ${tConvert(endTime)}`
    }
  }

  const viewEvent = (event: any) => {
    props.changeHeaderTitle('View all events')
    setActiveEvent(event)
    setEventsScreen('detail')
    setShowDetail(true)
  }

  const closeView = (action: string) => {
    if (action === 'close') {
      /* let sec: any = document.getElementsByClassName("close-btn");
      if (sec?.length && sec[0].classList?.contains("d-none")) {
        sec[0].classList?.remove("d-none");
      } */
      setShowDetail(false)
      props.changeHeaderTitle('Your events')
    } else {
      props.setShowDetail(false)
    }
  }
  useEffect(() => {
    if (eventsScreen === 'list') {
      setShowDetail(false)
    }
  }, [eventsScreen])

  useEffect(() => {
    setPageEventsData(props.pageEventsData)
  }, [props?.pageEventsData])

  useEffect(() => {
    setPageEventsData(pageDetailState?.eventsData)
  }, [pageDetailState?.eventsData])

  useEffect(() => {
    if (pageEventsData.length && localStorage.getItem('notificationEventId') !== null) {
      setTimeout(() => {
        const eventId: any = localStorage.getItem('notificationEventId')
        const btnId: any = document.getElementById('event-list-view-btn-' + eventId)
        if (btnId) {
          localStorage.removeItem('notificationEventId')
          btnId.click()
          if (document.getElementsByClassName('panel-wrap').length) {
            const panelwrapper: any = document.getElementsByClassName('panel-wrap')
            panelwrapper[0].style.display = 'block'
          }
        }
      }, 500)
    }
  }, [pageEventsData])

  return (
    <>
      {!showDetail ? (
        <div className="EventList">
          <>
            <Row xs={1} md={2} className="g-5">
              {pageEventsData.map((event: any, key: number) => (
                <Col key={key + Date.now()} id={'event-item' + key}>
                  <Card>
                    <div className="ImageWrapper">
                      {event.image ? (
                        <img
                          src={`${event?.image}?r=${Date.now()}_${key}`}
                          alt="Event image"
                          className="event-img-loading"
                          id={`event_img_${Date.now()}_${key}`}
                        />
                      ) : (
                        <div className="empty-event-image"></div>
                      )}
                    </div>

                    <Card.Body>
                      <Card.Title>{event?.name}</Card.Title>
                      <Card.Text>
                        <p>
                          <span>Date & Time : </span>{' '}
                          {`${dateTime(
                            event?.startDate,
                            event?.startTime,
                            event?.endDate,
                            event?.endTime
                          )} (${createdByYear(event?.startDate)})`}
                        </p>
                        <p className="venue">
                          <span>Venue : </span>{' '}
                          {event.city === '' && event.state === ''
                            ? event.country
                            : `${event.city ?? ''}, ${event.state ?? ''}`}
                        </p>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <div className="text-left d-block">
                        <span>
                          <span className="count">{event?.going?.length}</span> Going
                        </span>
                        <br />
                        <span>
                          <span className="count">{event?.interested?.length}</span> Interested
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          viewEvent(event)
                        }}
                        id={'event-list-view-btn-' + event?._id}
                      >
                        View event
                      </button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
            {pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ? (
              <button className="CreateEventCTA" onClick={() => setEventsScreen('create')}>
                <i className="fa fa-eventsicon"></i>Create New Event
              </button>
            ) : (
              <></>
            )}
          </>
        </div>
      ) : (
        <EventDetails event={activeEvent} setEventHeader={props.changeHeaderTitle} closeDetail={closeView} />
      )}
    </>
  )
}

export default EventList
