import './AudioPopup.scss'
import './../SendAudioMessage/SendAudioMessage.scss'

import Lottie from 'lottie-react'
import { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ReactMediaRecorder } from 'react-media-recorder'
import { useDispatch } from 'react-redux'
import WaveSurfer from 'wavesurfer.js'

import { trackEvent } from '../../../Analytics/GA'
import * as animationData from '../../../Assets/Lottie/audio-lottie.json'
import { useAppSelector } from '../../../Hooks'
import { removeAudioMessageResponse } from '../../../Redux/slices/audioMessageSlice'
import { setSidePanel } from '../../../Redux/slices/metaSlice'
import { setActiveSideSlug } from '../../../Redux/slices/pageDetailSlice'
import { loading } from '../../../Redux/slices/userSlice'
import { deleteAudioMessges, getAudioMessges, newStatusUpdate } from '../../../Redux/thunks/AudioMessageThunk'
import { sendAudioMessage, uploadMedia } from '../../../Redux/thunks/pageDetailThunk'
import Loader from '../../Loader/Loader'
import AudioWaves from '../../Shared/AudioWaves'

const AudioListMobile = (props: any) => {
  const audioMessageState: any = useAppSelector(state => state.audiomessages)
  const pageDetailState: any = useAppSelector(state => state.pagedetail)
  const userState: any = useAppSelector(state => state.user)

  const dispatch = useDispatch()
  const waveformRef: any = useRef()

  /* ---------- For Ownerview script code ---------- */
  const [, setRemainingTime] = useState<any>('')
  const [audioLoading, setAudioLoading] = useState<any>(true)
  const [audioList, setAudioList] = useState([])
  const [, setTotalMessageCount] = useState(0)
  const [deleteShow, setDeleteShow] = useState(false)
  const [isFirst, setIsFirst] = useState(true)
  const [activeAudioMsg, setActiveAudioMsg] = useState<any>({
    _id: 0,
    name: '',
    profileImg: '',
    url: '',
    file: null,
  })

  const waveSurferRef: any = useRef({
    isPlaying: () => false,
  })
  const [isPlaying, toggleIsPlaying] = useState(false)

  const handleShow = (data: any) => {
    setIsFirst(false)
    setActiveAudioMsg(data)
  }

  useEffect(() => {
    if (audioLoading === false && !isFirst) {
      waveSurferRef.current.playPause()
      toggleIsPlaying(waveSurferRef.current.isPlaying())
    }
  }, [audioLoading])

  const handleDeleteClose = () => {
    dispatch(loading(true))
    dispatch(
      deleteAudioMessges({
        pageId: pageDetailState?.data?._id,
        audioId: activeAudioMsg._id,
      })
    )
    setDeleteShow(false)
  }

  const waveColors = document.createElement('canvas').getContext('2d')?.createLinearGradient(0, 0, 550, 0)

  if (waveColors) {
    waveColors.addColorStop(0, '#24B183')
    waveColors.addColorStop(1, '#9b7bf6')
  }

  useEffect(() => {
    if (activeAudioMsg?._id) {
      const waveSurfer = WaveSurfer.create({
        container: waveformRef.current,
        responsive: true,
        barWidth: 1.5,
        barHeight: 2,
        cursorWidth: 0,
        barRadius: 1,
        waveColor: waveColors,
        progressColor: 'rgba(255,255,255,.7)',
        hideScrollbar: true,
        fillParent: true,
        cursorColor: 'green',
      })
      let current
      waveSurfer.on('audioprocess', () => {
        const totalTime = waveSurfer.getDuration()
        current = waveSurfer.getCurrentTime()
        setRemainingTime(totalTime - current)
      })
      waveSurfer.load(activeAudioMsg?.url)
      waveSurfer.on('ready', () => {
        waveSurferRef.current = waveSurfer
        const totalTime = waveSurfer.getDuration()
        setRemainingTime(totalTime)
        setAudioLoading(false)
      })

      waveSurfer.on('loading', function (X: any, evt: any) {
        setAudioLoading(true)
      })

      waveSurfer.on('finish', () => {
        toggleIsPlaying(false)
      })

      waveSurfer.on('play', () => {
        if (activeAudioMsg?.isNew === true) {
          dispatch(newStatusUpdate(activeAudioMsg?._id))
        }
      })

      return () => {
        waveSurfer.destroy()
      }
    }
  }, [activeAudioMsg])

  useEffect(() => {
    if (audioMessageState?.data?.length > 0) {
      let flag = 0
      if (audioMessageState?.data?.length < audioList?.length) {
        setIsFirst(true)
        flag = 1
      }
      if (flag === 1) {
        setActiveAudioMsg(audioMessageState?.data[0])
      }
      if (activeAudioMsg?._id === 0 || activeAudioMsg?._id === undefined) {
        setActiveAudioMsg(audioMessageState?.data[0])
      }
      setAudioList(audioMessageState?.data)
      setTotalMessageCount(audioMessageState?.totalCount)
      dispatch(
        setSidePanel({
          panelBody:
            pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id
              ? `Audio Messages <span>(` + audioMessageState?.totalCount + `)</span>`
              : `Audio Message`,
          status: true,
        })
      )
    } else {
      setActiveAudioMsg(
        audioList?.length
          ? audioList[0]
          : {
              name: '',
              profileImg: '',
              url: '',
            }
      )
    }
    dispatch(loading(false))
  }, [audioMessageState?.data])

  useEffect(() => {
    if (audioMessageState?.deleteAudioMsgResp?.success === true) {
      dispatch(
        getAudioMessges({
          pageId: pageDetailState?.data?._id,
          userId: userState?.value?.data?._id,
        })
      )
      dispatch(
        removeAudioMessageResponse({
          ...audioMessageState?.deleteAudioMsgResp,
          success: null,
        })
      )
    }
  }, [audioMessageState?.deleteAudioMsgResp])
  /* ---------- End Ownerview script code ---------- */

  /* ---------- For Memberview script code ---------- */

  const OnStop = (blobUrl: string, blob: any) => {
    setActiveAudioMsg({
      file: blob,
      url: blobUrl,
    })
  }

  const SendAudioMsg = () => {
    dispatch(loading(true))
    const playload = {
      page: pageDetailState?.data?._id,
      userId: userState?.value?.data?._id,
      url: '',
      ownerId: pageDetailState?.data?.belongsTo?._id,
    }

    const formData: any = new FormData()
    formData.append('media', activeAudioMsg.file)
    uploadMedia(formData, pageDetailState?.data?._id).then(response => {
      if (response?.data?.data[0]?.url) {
        playload.url = response?.data?.data[0]?.url
        dispatch(sendAudioMessage(playload))
        trackEvent('Page Interaction', 'Private Audio Message Post', `${userState?.value?.data?._id}`)
      }
    })
  }

  useEffect(() => {
    if (pageDetailState?.sendAudioMessageResp?.success === true) {
      dispatch(loading(false))
      dispatch(setActiveSideSlug(''))
    }
  }, [pageDetailState?.sendAudioMessageResp])

  const showTimer = (status: string) => {
    let timer: any
    const timerSelector: any = document.getElementById('recorder-timing')
    if (timerSelector && status === 'recording') {
      let hou = 0
      let sec = 1
      timer = setInterval(function () {
        timerSelector.innerHTML = ('0' + hou).slice(-2) + ' : ' + ('0' + sec).slice(-2)
        sec++
        if (sec === 60) {
          hou++
          sec = 0
        }
      }, 1000)
    } else {
      clearInterval(timer)
    }
  }

  /* ---------- End Memberview script code ---------- */

  return pageDetailState?.data?.belongsTo ? (
    <div>
      {pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ? (
        <>
          {/* --- Owner Audio list Section--- */}
          {audioList?.length ? (
            <div className="AudioViewMobile">
              <div className="headerMobile">
                <i className="fa fa-butterflyheadphone"></i>
              </div>
              <div className="CurrentUser">
                <div className="row">
                  <div className="Profile">
                    {activeAudioMsg?.createdBy?.profileImg ? (
                      <img src={activeAudioMsg?.createdBy?.profileImg} alt="" />
                    ) : (
                      <span className="Alpha">{activeAudioMsg?.createdBy?.name.charAt(0)}</span>
                    )}
                    <span>{activeAudioMsg?.createdBy?.name}</span>
                  </div>
                  <div className="AudioWaves">
                    {audioLoading === true ? <div className="text-center mt-4 audio-file-loading">Loading...</div> : ''}
                    <div className="audioWrapper">
                      <div className="waves" ref={waveformRef} />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      waveSurferRef.current.playPause()
                      toggleIsPlaying(waveSurferRef.current.isPlaying())
                    }}
                    className="audioControl ControllerIcon"
                  >
                    {isPlaying ? <i className="fa fa-pause"></i> : <i className="fa fa-butterflyaudioplay"></i>}
                  </div>
                </div>
                <div className="row">
                  <div className="delete text-center">
                    <i
                      className={!deleteShow ? 'fa fa-butterflydelete ' : 'fa fa-butterflydelete d-none'}
                      onClick={() => {
                        setDeleteShow(true)
                      }}
                    ></i>
                    <div className={deleteShow ? 'deleteConfirmation' : 'deleteConfirmation  d-none'}>
                      <span>
                        Are you sure you want to delete the audio message from {activeAudioMsg?.createdBy?.name}?
                      </span>
                      <span>
                        <button
                          className="NoDelete"
                          type="button"
                          onClick={() => {
                            setDeleteShow(false)
                          }}
                        >
                          No
                        </button>
                        <button onClick={handleDeleteClose}>Yes, Delete</button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="OtherUsers">
                <div className="row">
                  {audioList
                    ?.filter((data: any) => data?._id !== activeAudioMsg?._id)
                    ?.map((audioItem: any, key: number) => {
                      return (
                        <div
                          className="Profile"
                          key={key}
                          onClick={() => {
                            handleShow(audioItem)
                          }}
                        >
                          <div className="imgWrapper">
                            {audioItem?.createdBy?.image ? (
                              <img src={audioItem?.createdBy?.image} alt={audioItem?.createdBy?.name} />
                            ) : (
                              <span className="Alpha">{audioItem?.createdBy?.name.charAt(0)}</span>
                            )}
                            <i className="fa fa-play-fa"></i>
                            {audioItem?.isNew ? <span className="new">New</span> : ''}
                          </div>
                          <span>{audioItem.name}</span>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          ) : (
            <div className="EmptyText">
              You don’t have any messages right now, but that doesn’t mean people aren’t thinking about you. Check back
              soon.
            </div>
          )}
        </>
      ) : (
        <>
          {/* --- member Audio send Section--- */}
          <div className="AudioRecordingPopup">
            {userState?.loading && <Loader />}
            <div className="page-owner-img">
              {pageDetailState?.data?.belongsTo?.image ? (
                <img src={pageDetailState?.data?.belongsTo?.image} alt="" height={65} width={65} />
              ) : (
                <span className="Alpha">{pageDetailState?.data?.belongsTo?.name.charAt(0)}</span>
              )}
              <p>Send personalized audio message to {pageDetailState?.data?.belongsTo?.name}</p>
            </div>
            <div className="body">
              <form className="Audio">
                <ReactMediaRecorder
                  audio
                  blobPropertyBag={{
                    type: 'audio/wav',
                  }}
                  onStop={OnStop}
                  render={({ previewStream, status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
                    showTimer(status)
                    return (
                      <div className="AudioPost">
                        {status !== 'stopped' ? (
                          <div className="UploadSec">
                            {status === 'idle' ? (
                              <div className="RecSec">
                                <button type="button" onClick={startRecording}>
                                  <i className="fa fa-butterflymic"></i> Start Recording
                                </button>
                              </div>
                            ) : (
                              <div className="">
                                <div className="Ripple">
                                  <Lottie animationData={animationData} />
                                </div>
                                <div className="RecSec">
                                  <span onClick={stopRecording}>
                                    <i className="fa fa-stoprecording" aria-hidden="true"></i>
                                    Stop Recording
                                  </span>
                                  <p id="recorder-timing"></p>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="UploadSec">
                            <AudioWaves audioURL={mediaBlobUrl} controls stopAllMedia={() => {}} mediaPlay={false} />
                            <div className="row">
                              <div className="delete text-center">
                                <i
                                  className="fa fa-butterflydelete"
                                  aria-hidden="true"
                                  onClick={() => {
                                    setActiveAudioMsg({ url: '', file: null })
                                    clearBlobUrl()
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  }}
                />
              </form>
            </div>
            <div className="footer">
              <div className="row">
                <div className="text-center">
                  <Button type="button" disabled={activeAudioMsg?.file === null} onClick={SendAudioMsg}>
                    Post
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <div>Please open any detail page.</div>
  )
}

export default AudioListMobile
