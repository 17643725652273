import './AlertPopup.scss'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

function AlertPopup(props: any) {
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="Information">
      <Modal.Header closeButton onClick={() => props.onHide()}></Modal.Header>
      <Modal.Body>
        <span>
          {props?.state === 'ERROR' ? (
            <i className="fa fa-trash"></i>
          ) : props?.state === 'SUCCESS' ? (
            <i className="fa fa-tick"></i>
          ) : props?.state === 'WARNING' ? (
            <i className="fa fa-error-outline"></i>
          ) : (
            <i className="fa fa-information-outline"></i>
          )}
        </span>
        <p>{props.content}</p>
      </Modal.Body>
      <Modal.Footer>{props.buttonText && <Button className="delete">{props.buttonText}</Button>}</Modal.Footer>
    </Modal>
  )
}

export default AlertPopup
