import * as jwt from 'jose'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import { environment } from '../../AppConfig/environment'
import { parseURLParams } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { loginThunk } from '../../Redux/thunks'

const FromInvitePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const urlParams: any = parseURLParams(window.location.href)

    if (urlParams?.invite?.length && urlParams?.invite[0]) {
      localStorage.setItem('SubscribeCode', window.location.href)
      const pageUrl: any = window.location.pathname.split('/').pop()
      localStorage.setItem('ForInviteUser', pageUrl)
      if (urlParams?.userExists) {
        trackEvent('Conversion Event', 'Page Visit from Email Share', 'Existing User')
        navigate(RouteConstants.LOGIN)
      } else {
        const userEmail = urlParams?.invite[0].toLowerCase()
        trackEvent('Conversion Event', 'Page Visit from Email Share', 'New User')
        if (urlParams?.token.length && urlParams?.token[0]) {
          logNewUserIn(userEmail, urlParams?.token[0], pageUrl)
        } else {
          navigate(RouteConstants.REGISTER)
        }
      }
    }
  }, [])

  // Auto login new users coming from email invite
  const logNewUserIn = async (email: string, token: string, pageUrl: string) => {
    // added for testing emails
    const userEmail = email.replace(' ', '+')
    const secret = new TextEncoder().encode(
      // this key needs to be in Env
      environment.TextDecoder
    )
    const decodedToken = await jwt.jwtVerify(token, secret)
    const payload = decodedToken.payload
    const loginPayload = {
      email: userEmail,
      password: payload?.temp_password,
      recaptcha_token: undefined,
      provider: 'manual',
    }
    dispatch(loginThunk(loginPayload, navigate))
  }
  return <></>
}
export default FromInvitePage
