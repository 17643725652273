import React from 'react'

import { isset, timeSince } from '../../commonservice'

interface MessageReplyNotificationProps {
  element: any
  key: number
  children?: React.ReactNode
  keyindex: number
}

export const MessageReplyNotification = ({ element, key, keyindex }: MessageReplyNotificationProps) => {
  return (
    <>
      <div
        className="EachNotificationWrapper"
        key={'notification-item-' + key}
        id={keyindex === 0 ? 'notification-item-' + keyindex : ''}
      >
        <div className="EachNotification">
          <div className="ProfileImg">
            {isset(element?.createdBy?.image) ? (
              <img src={element?.createdBy?.image} alt="" />
            ) : (
              <span>{element?.createdBy?.name?.charAt(0)}</span>
            )}
          </div>
          <div className="NotificationText">
            <div
              dangerouslySetInnerHTML={{
                __html: element?.notification,
              }}
            ></div>
            <div className="time">{timeSince(element.createdAt)}</div>
          </div>
        </div>
      </div>
    </>
  )
}
