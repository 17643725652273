import React from 'react'

import { isset, timeSince } from '../../commonservice'

interface SubscribedNotificationProps {
  element: any
  key: number
  children?: React.ReactNode
  takeNotification: any
  goToPageSettings: any
  keyindex: number
}

export const SubscribedNotification = ({ element, key, takeNotification, keyindex }: SubscribedNotificationProps) => {
  return (
    <>
      <div
        className="EachNotificationWrapper"
        key={'notification-item-' + key}
        id={keyindex === 0 ? 'notification-item-' + keyindex : ''}
      >
        <div className="EachNotification">
          <div className="ProfileImg">
            {isset(element?.createdBy?.image) ? (
              <img src={element?.createdBy?.image} alt="" />
            ) : (
              <span>{element?.createdBy?.name?.charAt(0)}</span>
            )}
          </div>
          <div className="NotificationText">
            <div
              dangerouslySetInnerHTML={{
                __html: element?.notification,
              }}
            ></div>
            <div className="time">{timeSince(element.createdAt)}</div>
          </div>
        </div>
        {element.approval_required ? (
          <div className="row">
            {element?.status === 'PENDING' ? (
              <div className="ActionCTA">
                <span
                  className="accept"
                  onClick={() => takeNotification({ actionType: 'approve_subscription', notification: element })}
                >
                  <i className="fa fa-butterflytick"></i>
                  Approve
                </span>
                <span
                  className="reject"
                  onClick={() => takeNotification({ actionType: 'reject_subscription', notification: element })}
                >
                  <i className="fa fa-butterflynoapproval"></i>
                  Reject
                </span>
              </div>
            ) : (
              <div className="ActionCTA">
                <span className={element?.status === 'APPROVED' ? 'accept disabled' : 'reject disabled'}>
                  <i
                    className={element?.status === 'APPROVED' ? 'fa fa-butterflytick' : 'fa fa-butterflynoapproval'}
                  ></i>
                  {element?.status[0]?.toUpperCase() + element?.status?.slice(1)?.toLowerCase()}
                </span>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
