import 'react-datepicker/dist/react-datepicker.css'
import '../FundraisersPopup/FundraisersPopup.scss'

import { forwardRef, useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'
import Turnstone from 'turnstone'
import recentSearchesPlugin from 'turnstone-recent-searches'

import { trackEvent } from '../../../Analytics/GA'
import { environment } from '../../../AppConfig/environment'
import { useAppSelector } from '../../../Hooks'
import { loading } from '../../../Redux/slices/userSlice'
import { addFundraiserThunk, uploadMedia } from '../../../Redux/thunks/pageDetailThunk'
import RoundedCropper from '../CropperPopup/RoundedCropper'

function FundraisersPopup(props: any) {
  const dispatch = useDispatch()

  const userState: any = useAppSelector(state => state.user)
  const pageDetailState: any = useAppSelector((state: any) => state.pagedetail.data)
  const turnstoneRef = useRef()
  const [endDate, setEndDate] = useState(new Date(Date.now() + 60000 * 60 * 24 * 7).toString())
  const [startDate, setStartDate] = useState(new Date().toString())
  const [beneficiaryData, setBeneficiaryData] = useState<any>()
  const [gole, setgole] = useState<number>()
  const [goleError, setGoleError] = useState(false)
  const [title, settitle] = useState()
  const [discription, setdiscription] = useState<string>()
  const [img, setImg] = useState<any>({
    url: '',
    file: '',
    selected_img: '',
  })
  const [imgValidation, setImgValidation] = useState('')
  const [showCropper, setShowCropper] = useState(false)

  const openImgWindow = () => {
    const ImgInput: any = document.getElementById('page-image')
    if (ImgInput) {
      ImgInput?.click()
    }
  }

  const typeValidation = (event: any) => {
    setImg((data: any) => {
      return {
        ...data,
        file: '',
      }
    })
    const selectedFile = event.target.files[0]
    const imageFile = selectedFile
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgValidation('Only jpg | jpeg | png images allowed.')
      return false
    }
    setImgValidation('')
    if (event.target.files?.length) {
      // selectedImage(event?.target.files[0]);
      // setDisplaySelectedImage(URL.createObjectURL(event?.target?.files[0]));

      const reader: any = new FileReader()
      const file: any = selectedFile
      reader.readAsDataURL(file)
      setImg((data: any) => {
        return { ...data, file }
      })
      reader.onload = () => {
        setImg((data: any) => {
          return { ...data, selected_img: reader?.result }
        })
        setShowCropper(true)
      }
    }
  }

  const CroppedImage = (e: any) => {
    if (e && e !== 'cancel') {
      setImg((data: any) => {
        return { ...data, url: URL.createObjectURL(e), file: e }
      })
    }
    setShowCropper(false)
  }

  const DatePickerCustomInput = forwardRef(
    ({ value, onClick }: { value: any; onClick: any }, ref: React.LegacyRef<HTMLInputElement>) => (
      <input
        className="date-picker form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Select date"
        readOnly
      />
    )
  )

  DatePickerCustomInput.displayName = 'DatePickerCustomInput'

  const styles = {
    input: 'w-full border py-2 px-4 text-lg outline-none rounded-md',
    listbox: 'bg-neutral-900 w-full text-slate-50 rounded-md',
    highlightedItem: 'bg-neutral-800',
    query: 'text-oldsilver-800 placeholder:text-slate-600',
    typeahead: 'text-slate-500',
    clearButton:
      'absolute inset-y-0 text-lg right-0 w-10 inline-flex items-center justify-center bg-netural-700 hover:text-red-500',
    noItems: 'cursor-default text-center my-20',
    match: 'font-semibold',
    groupHeading: 'px-5 py-3 text-pink-500',
  }

  const listbox = {
    displayField: 'name',
    data: async (query: string) => {
      if (query.length > 2) {
        const myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${userState?.value?.token}`)

        const requestOptions: any = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        const URL = `${environment.apiEndPoint}page/fundraiser/beneficiary/get-beneficiary?q=${query.replaceAll(
          ' ',
          '+'
        )}`
        const res = await fetch(URL, requestOptions)
        const data = await res.json()
        return data.data.results
      }
    },
    searchType: 'startsWith',
  }

  const Item = ({ item }: { item: { logo_url: string; name: string } }) => {
    const avatar = `${item.logo_url}`
    return (
      <div className="flex items-center cursor-pointer px-5 py-4">
        <img width={35} height={35} src={avatar} alt={item.name} className="rounded-full object-cover mr-3" />
        <p>{item.name}</p>
      </div>
    )
  }

  useEffect(() => {}, [beneficiaryData])

  const canCreate = () => {
    return startDate === '' || endDate === '' || !beneficiaryData || !gole || !title || !discription || goleError
  }

  const createFundraisers = () => {
    const startdateInfo = new Date(startDate)
    const enddateInfo = new Date(endDate)

    const payload = {
      page: pageDetailState?._id,
      user: userState?.value?.data?._id,
      title,
      description: discription,
      image: '',
      goal: gole,
      start_time: `${startdateInfo.getFullYear()}-${
        startdateInfo.getMonth() + 1
      }-${startdateInfo.getDate()} 00:00:00 UTC`,
      end_time: `${enddateInfo.getFullYear()}-${enddateInfo.getMonth() + 1}-${enddateInfo.getDate()} 00:00:00 UTC`,
      amount_raised: '0',
      donation_count: '0',
      beneficiary_name: beneficiaryData?.name,
      beneficiary_id: beneficiaryData?.id,
      beneficiary_image: beneficiaryData?.logo_url,
    }

    if (img.url !== '') {
      const formData: any = new FormData()
      formData.append('media', img.file)
      dispatch(loading(true))
      uploadMedia(formData, pageDetailState?.data?._id).then(response => {
        if (response?.data?.data[0]?.url) {
          payload.image = response?.data?.data[0]?.url
          dispatch(addFundraiserThunk(payload))
        }
      })
    } else {
      dispatch(addFundraiserThunk(payload))
    }
    trackEvent('Page Interaction', 'Fundraiser Create', `${userState?.value?.data?._id}`)
    props.onHide()
  }

  const filterStartDate = (date: any) => {
    const selectedDate = new Date(date)
    if (endDate === '') {
      return selectedDate > new Date(-1)
    }

    const currentDate = endDate === '' ? new Date() : new Date(endDate)

    return currentDate.getTime() >= selectedDate.getTime() && selectedDate >= new Date()
  }

  return (
    <>
      <div className="fundraiser-popup">
        <div className="stepOne fStepOne">
          <div className="createFundraiser">
            <div className="ProfilePic">
              <div className="ImgCroper" onClick={openImgWindow}>
                {img?.url === '' || img?.url === null ? (
                  <span>
                    <i className="fa fa-camera noImg" aria-hidden="true"></i>
                  </span>
                ) : (
                  <>
                    <div className="ImgWrapper">
                      <img src={img?.url} alt="page-profile-image" />
                    </div>
                    <span className="cropImg">
                      <i className="fa fa-camera"></i>
                    </span>
                  </>
                )}
                <input type="file" name="image" className="d-none" id="page-image" onChange={typeValidation} />
                <p className="text text-danger errorMessage">{imgValidation}</p>
              </div>
            </div>
            <div className="FormSection">
              <Form>
                <Form.Group className="each-group">
                  <Form.Label>
                    Give a title to your fundraiser<sup>*</sup>
                  </Form.Label>
                  <input
                    type="text"
                    placeholder="Give a title to your fundraiser"
                    maxLength={50}
                    onChange={(event: any) => {
                      if (event.target.value.length <= 50) {
                        settitle(event.target.value.trim())
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group className="each-group">
                  <Form.Label>
                    Why are you raising the money?<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    id="details"
                    as="textarea"
                    aria-label="With textarea"
                    placeholder="Type your answer here"
                    name="description"
                    maxLength={150}
                    onChange={(event: any) => {
                      if (event.target.value.length <= 150) {
                        setdiscription(event.target.value.trim())
                      }
                    }}
                  />
                  <span className="text-count">{discription?.replace(/(\r\n|\n|\r)/g, '').length ?? 0}/150</span>
                </Form.Group>
                <Form.Group className="each-group">
                  <Form.Label>
                    {' '}
                    Select beneficiary (Non-profit)<sup>*</sup>
                  </Form.Label>
                  <div className="select-wrap">
                    <Turnstone
                      id="search"
                      name="search"
                      ref={turnstoneRef}
                      autoFocus={false}
                      typeahead={true}
                      clearButton={true}
                      debounceWait={250}
                      listboxIsImmutable={true}
                      maxItems={10}
                      noItemsMessage="We couldn't find any character that matches your search"
                      placeholder="Search by name, keyword"
                      listbox={listbox}
                      styles={styles}
                      Item={Item}
                      onSelect={(e: any) => {
                        setBeneficiaryData(e)
                      }}
                      plugins={[recentSearchesPlugin]}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="each-group">
                  <Form.Label>
                    How much money do you want to raise?<sup>*</sup>
                  </Form.Label>
                  <div className="two-column">
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="number"
                        min="0"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onChange={(event: any) => {
                          if (event.target.value > 24) {
                            setGoleError(false)
                          } else {
                            setGoleError(true)
                          }
                          setgole(event.target.value.trim())
                        }}
                        onBlur={(event: any) => {
                          setGoleError((gole as number) < 25)
                        }}
                        className="form-control  mr-0"
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </div>
                    {goleError && (
                      <span className="error-message">You have not entered any amount, minimum is 25$</span>
                    )}
                  </div>
                  <div className="two-column">
                    <div className="inner-holder">
                      <Form.Label htmlFor="details">
                        Start date<sup>*</sup>
                      </Form.Label>
                      <DatePicker
                        selected={new Date(startDate)}
                        onChange={(date: any) => {
                          setStartDate(date)
                        }}
                        selectsStart
                        filterDate={filterStartDate}
                        showTimeSelect={false}
                        minDate={new Date()}
                        startDate={new Date(startDate)}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                        customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                        endDate={new Date(endDate)}
                      />
                    </div>
                    <div className="inner-holder">
                      <Form.Label htmlFor="details">
                        End date<sup>*</sup>
                      </Form.Label>
                      <DatePicker
                        selected={new Date(endDate)}
                        onChange={(date: any) => {
                          setEndDate(date)
                        }}
                        selectsEnd
                        showTimeSelect={false}
                        minDate={startDate === '' ? new Date() : new Date(startDate)}
                        startDate={new Date(startDate)}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                        customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                      />
                    </div>
                  </div>
                </Form.Group>
                <div className="d-flex ActionCta">
                  <button type="button" onClick={() => props.onHide()} className="cancelCta">
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="create-fund"
                    disabled={canCreate()}
                    onClick={() => {
                      createFundraisers()
                    }}
                  >
                    <i className="fa fa-fundraiser" aria-hidden="true"></i>
                    Create fundraiser
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {showCropper ? (
        <RoundedCropper
          show={showCropper}
          selectedImg={img.selected_img}
          setCroppedImage={CroppedImage}
          croppershap={'rect'}
        ></RoundedCropper>
      ) : (
        ''
      )}
    </>
  )
}

export default FundraisersPopup
