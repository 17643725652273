import cn from 'classnames'
import { useEffect, useState } from 'react'

import { createdByMonthYear, isset } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import { type pageDetailData, type pageMemberData } from '../../Redux/slices/pageDetailSlice'

const MemberList = (props: any) => {
  const { selectedMemberList, type, invitedMembers } = props
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const [pageMemberData, setPageMemberData] = useState<pageMemberData[]>()
  const [checkedMemberCount, setCheckedMemberCount] = useState<any>()

  useEffect(() => {
    const unBlockedMembers = pageDetailState.members?.filter(
      (membersItem: pageMemberData, key: number) => !membersItem.isBlocked
    )

    const pageMemberDataUpdate = unBlockedMembers.map((members: pageMemberData, index: any) => {
      if (type === 'edit' && invitedMembers?.length && invitedMembers.includes(members?.user?.email)) {
        return {
          ...members,
          isChecked: true,
          isDisabled: true,
        }
      } else {
        return {
          ...members,
          isChecked: false,
          isDisabled: false,
        }
      }
    })
    setPageMemberData(pageMemberDataUpdate)
  }, [pageDetailState])

  const checkBoxUpdate = (members: pageMemberData) => {
    const updatepageMemberData = pageMemberData?.map((membersItem: pageMemberData, key: number) => {
      if (membersItem._id === members._id) {
        return {
          ...membersItem,
          isChecked: !members.isChecked,
        }
      } else {
        return membersItem
      }
    })
    setPageMemberData(updatepageMemberData)

    const updateCount = updatepageMemberData?.filter(
      (membersItem: pageMemberData, key: number) => membersItem.isChecked && !membersItem.isDisabled
    )
    setCheckedMemberCount(updateCount?.length)
  }

  const selectAllcheckBoxUpdate = (status: boolean) => {
    const updatepageMemberData = pageMemberData?.map((membersItem: pageMemberData, key: number) => {
      if (type === 'edit' && invitedMembers?.length && invitedMembers.includes(membersItem?.user?.email)) {
        return { ...membersItem }
      } else {
        return {
          ...membersItem,
          isChecked: status,
        }
      }
    })
    setPageMemberData(updatepageMemberData)
    const updateCount = updatepageMemberData?.filter(
      (membersItem: pageMemberData, key: number) => membersItem.isChecked && !membersItem.isDisabled
    )
    setCheckedMemberCount(updateCount?.length)
  }

  const addMemberToGuest = (action: string) => {
    if (action === 'add') {
      selectedMemberList(
        pageMemberData?.filter(
          (membersItem: pageMemberData, key: number) => membersItem.isChecked && !membersItem.isDisabled
        )
      )
    } else {
      selectedMemberList([])
    }
  }

  return (
    <>
      <div className="MemberHeader">
        <div className="MembersCount">{pageMemberData?.length} Members</div>
        <div
          className="MembersSelectAll"
          onClick={() => {
            selectAllcheckBoxUpdate(
              pageMemberData?.filter((membersItem: pageMemberData, key: number) => membersItem.isChecked).length !==
                pageMemberData?.length
            )
          }}
        >
          <i className="fa fa-membersicon"></i>
          {pageMemberData?.filter((membersItem: pageMemberData, key: number) => membersItem.isChecked).length ===
          pageMemberData?.length
            ? 'Unselect'
            : 'Select'}{' '}
          all Members
        </div>
      </div>
      <div className="MemberBody">
        <ul>
          {pageMemberData?.map((membersItem: pageMemberData, key: number) => {
            return (
              <li key={key} className="EachMember">
                <div className="MemberDetail">
                  <div className="owner">
                    <div className="ownerPic">
                      {isset(membersItem?.user?.image) ? (
                        <img src={membersItem?.user?.image} alt={membersItem?.user?.name} />
                      ) : (
                        <div className="ownerAlpha">
                          <span>{membersItem?.user?.name?.charAt(0)}</span>
                        </div>
                      )}
                    </div>
                    <div className="ownerName">
                      {membersItem?.user?.name}
                      <span>Member since {createdByMonthYear(membersItem?.createdAt)}</span>
                    </div>
                  </div>
                </div>
                <div className="IconsList">
                  <span className="checkbox">
                    <label className="majesticCheckbox">
                      <input
                        checked={membersItem?.isChecked}
                        onChange={() => {
                          checkBoxUpdate(membersItem)
                        }}
                        type="checkbox"
                        disabled={membersItem?.isDisabled}
                      />
                      <span
                        className={cn('', {
                          disabled: membersItem?.isDisabled,
                        })}
                      >
                        <i></i>
                      </span>
                    </label>
                  </span>
                </div>
              </li>
            )
          })}
        </ul>
        <div className="Cta text-center mt-5">
          <button
            onClick={() => {
              addMemberToGuest('add')
            }}
            disabled={!checkedMemberCount}
          >
            Add members to guest list
          </button>
          <button
            onClick={() => {
              addMemberToGuest('cancel')
            }}
            className="cancelCta"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}

export default MemberList
