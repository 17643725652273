import './SharePage.scss'

import React from 'react'
import Modal from 'react-bootstrap/Modal'

import CommonShareContent from './CommonShareContent'

const SharePage = (props: any) => {
  return (
    <Modal
      {...props}
      size="sm"
      onHide={() => props.handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="SharePopup"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <CommonShareContent />
      </Modal.Body>
    </Modal>
  )
}

export default SharePage
