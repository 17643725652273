import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {}
export const pageSlice: any = createSlice({
  name: 'page',
  initialState,
  reducers: {
    loadMySubscribePages: (state, action) => {
      state.subscribePages = action.payload
    },
    invitePageResponse: (state, action) => {
      state.invitePageResp = action.payload
    },
  },
})

export const { loadMySubscribePages, invitePageResponse } = pageSlice.actions
export default pageSlice.reducer
