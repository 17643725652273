// GoogleTag.tsx
import type React from 'react'
import { useEffect } from 'react'

declare global {
  interface Window {
    dataLayer: any[]
    gtag: (event: string, action: string, options: object) => void
  }
}

const GoogleTag: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-6EF8YHLD86'
    script.async = true
    document.head.appendChild(script)

    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag(...args: any[]) {
        window.dataLayer.push(...args)
      }
      gtag('js', new Date())
      gtag('config', 'G-6EF8YHLD86')
    }
  }, [])

  return null
}

export default GoogleTag
