import './PostPreview.scss'

import cn from 'classnames'
import Picker, { Emoji } from 'emoji-picker-react'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import nl2br from 'react-nl2br'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../Analytics/GA'
import { createdMonthDayYear, htmlContentWithLink, subcommenttimeSince } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import { addPageAction } from '../../Redux/slices/pageDetailSlice'
import { getPostComments } from '../../Redux/slices/post'
import { uploadMedia } from '../../Redux/thunks/pageDetailThunk'
import {
  addReactionForCommentReplyThunk,
  addReactionForCommentThunk,
  createPostCommentReplyThunk,
  createPostCommentThunk,
  editReactionForCommentThunk,
  getPostsCommentsThunk,
  getPostsMediaCommentsThunk,
  postAddLikeThunk,
  removePostLikeThunk,
  updatePostCommentThunk,
} from '../../Redux/thunks/postThunk'
import LottieControl from '../lottie'
import AudioWaves from '../Shared/AudioWaves'

const PostPreview = (props: any) => {
  const dispatch = useDispatch()
  const inputReference = useRef<any>(null)
  const inputReferenceEdit = useRef<any>(null)
  const [replyData, setReplyData] = useState<any>()
  const [activeReactionOpenId, setActiveReactionOpenId] = useState<any>(false)
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLInputElement>(null)
  const commentData = useAppSelector((state: any) => state?.post?.currentPostComment)
  const userState = useAppSelector(state => state.user)
  const pageData = useAppSelector((state: any) => state?.pagedetail)
  const [postCommentData, setPostCommentData] = useState<any[]>([])
  const [messageInfo, setMessageInfo] = useState<string>('')
  const [editmessageInfo, setEditMessageInfo] = useState<string>('')
  const bottomRef = useRef<null | HTMLDivElement>(null)
  const [mainCommentEdit, setMainCommentEdit] = useState('')
  const [disabledScroll, setDisabledScroll] = useState(false)
  const [reactionComment, setReactionComment] = useState<any>()
  const [showLoader, setShowLoader] = useState(false)
  const [activePost, setActivePost] = useState(props?.activePost)
  const [mediaActivePost, setMediaActivePost] = useState(props?.activePost)
  const [activeindex, setActiveIndex] = useState(activePost.activeIndex)
  const [mediaObject, setMediaObject] = useState<any>()
  const postData = useAppSelector((state: any) => state?.post)
  const [likeCount, setLikeCount] = useState(activePost?.counts?.likes)
  const [likes, setlikes] = useState(activePost?.likes)
  const [showCommentImage, setShowCommentImage] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [postCommentInit, setPostCommentInit] = useState(false)
  const [isOpenfromGallery, setIsOpenfromGallery] = useState(false)
  const [likeStatus, setLikeStatus] = useState(true)

  useEffect(() => {
    if (activePost?.activeIndex === null) {
      if (activePost) {
        const activePostData = postData?.postList?.data.find((post: any) => post._id === activePost?._id)
        setLikeStatus(true)
        setlikes(activePostData?.likes)
        setLikeCount(activePostData?.counts?.likes)
      }
    } else {
      const activePostData = postData?.postList?.data.find((post: any) => post._id === activePost?._id)
      if (activePostData) {
        setMediaActivePost(activePostData)
        setLikeStatus(true)
        setlikes(activePostData?.medias[activeindex]?.likes)
        setLikeCount(activePostData?.medias[activeindex]?.counts?.likes)
        if (activePost.openfromGallery) {
          setIsOpenfromGallery(true)
        } else {
          setIsOpenfromGallery(false)
        }
      }
    }
  }, [postData?.postList?.data])

  useEffect(() => {
    dispatch(getPostComments([]))
    if (activePost.activeIndex !== null) {
      // call media Comments
      const mediaObj = mediaActivePost.medias[activeindex]
      setLikeStatus(true)
      setMediaObject(mediaObj)
      setlikes(mediaObj?.likes)
      setLikeCount(mediaObj?.counts?.likes)
      if (activePost.openfromGallery) {
        setIsOpenfromGallery(true)
      } else {
        setIsOpenfromGallery(false)
      }

      if (mediaObj?.counts?.comments) {
        setShowLoader(true)
        dispatch(getPostsMediaCommentsThunk(mediaObj?._id))
      }
    } else {
      // Call Post Comments
      if (activePost?.counts?.comments) {
        setShowLoader(true)
        dispatch(getPostsCommentsThunk(activePost?._id))
      }
    }
  }, [activePost, activeindex])

  useEffect(() => {
    if (commentData?.length) {
      setShowLoader(false)
    }
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      return {
        ...comments,
        isAction: false,
        isEdit: false,
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
    // 👇️ scroll to bottom every time messages change
    if (disabledScroll) {
      setDisabledScroll(false)
      props.reactionModalShow(false)
    } else {
      setTimeout(
        () =>
          bottomRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          }),
        800
      )
    }
  }, [commentData])

  useLayoutEffect(() => {
    if (ref.current !== null) {
      setHeight(ref.current.offsetHeight)
    }
  })

  const canSend = () => {
    return messageInfo.trim() !== ''
  }

  const addNewComment = async () => {
    let mediaUrl = ''

    if (selectedFiles.length) {
      const files = new FormData()
      const fileData = selectedFiles?.filter((e: any) => {
        return e?.from && e?.from !== '' ? false : e
      })
      fileData?.forEach((e: any) => {
        files.append('media', e?.data)
      })
      await uploadMedia(files, pageData?.data?._id, props?.editPost?._id).then(response => {
        if (response?.data?.data?.length) {
          response?.data?.data?.forEach((data: any, key: number): any => {
            mediaUrl = data?.url
          })
        }
      })
    }
    if (replyData) {
      const payLoad = {
        comment: replyData?._id,
        reply: messageInfo.trim(),
        createdBy: userState?.value?.data?._id,
        image: mediaUrl,
      }
      const postId = activePost.activeIndex !== null ? mediaObject?._id : activePost?._id
      const type = activePost.activeIndex !== null ? 'media' : 'post'
      dispatch(createPostCommentReplyThunk(payLoad, postId, type))
      setMessageInfo('')
      setPostCommentInit(false)
      setReplyData('')
      removeSelected()
      trackEvent('Page Interaction', 'Post Comment Reply', `${userState?.value?.data?._id}`)
    } else {
      let payLoad = {}
      if (activePost.activeIndex !== null) {
        // Call Add Comment API
        payLoad = {
          media: mediaObject?._id,
          comment: messageInfo.trim(),
          createdBy: userState?.value?.data?._id,
          image: mediaUrl,
        }
      } else {
        payLoad = {
          post: activePost?._id,
          comment: messageInfo.trim(),
          createdBy: userState?.value?.data?._id,
          image: mediaUrl,
        }
      }
      dispatch(createPostCommentThunk(payLoad, activePost?.page))
      setMessageInfo('')
      setPostCommentInit(false)
      removeSelected()
      trackEvent('Page Interaction', 'Post Comment', `${userState?.value?.data?._id}`)
    }
  }

  const messageValue = (event: any) => {
    // Access input value
    const messageComment = event.target.value
    setMessageInfo(messageComment)
  }

  const checkLikedOrNot = (userId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.filter((e: any) => e?.createdBy?._id === userId)
    return likeUser?.length
  }

  const addlikePost = (userId: string, postId: string, pageId: string) => {
    let payload = {}
    if (activePost.activeIndex !== null) {
      payload = {
        media: mediaObject?._id,
        createdBy: userId,
      }
    } else {
      payload = {
        post: postId,
        createdBy: userId,
      }
    }

    const LikeAdd = {
      post: postId,
      createdBy: {
        _id: userId,
      },
      is_deleted: false,
    }

    setlikes([...likes, LikeAdd])

    setLikeStatus(false)
    dispatch(postAddLikeThunk(payload, pageId, postId))
    trackEvent('Page Interaction', 'Post Like', `${userState?.value?.data?._id}`)
  }

  const removelikePost = (userId: string, postId: string, pageId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.find((e: any) => e?.createdBy?._id === userId)
    const likeId = likeUser?._id
    setLikeStatus(false)
    const type = activePost.activeIndex !== null ? 'media' : 'post'
    const postIdInfo = activePost.activeIndex !== null ? mediaObject?._id : postId
    dispatch(removePostLikeThunk(postIdInfo, likeId, pageId, type, postId))
    trackEvent('Page Interaction', 'Remove Post Like', `${userState?.value?.data?._id}`)
  }

  const mainCommentAction = (selectcomment: any) => {
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      if (comments._id === selectcomment._id) {
        return {
          ...comments,
          isAction: !selectcomment.isAction,
        }
      } else {
        return {
          ...comments,
          isAction: false,
        }
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
  }

  const editComment = (selectcomment: any) => {
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      if (comments._id === selectcomment._id) {
        setMainCommentEdit(selectcomment.isEdit ? '' : comments.comment)
        return {
          ...comments,
          isEdit: !selectcomment.isEdit,
        }
      } else {
        return {
          ...comments,
          isEdit: false,
        }
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
  }

  const editCommentMessage = (event: any) => {
    const messageComment = event.target.value
    setMainCommentEdit(messageComment)
  }

  const updateComment = (comment: any) => {
    const payload = {
      comment: mainCommentEdit,
    }
    setDisabledScroll(true)
    const postId = activePost.activeIndex !== null ? mediaObject?._id : activePost?._id
    const type = activePost.activeIndex !== null ? 'media' : 'post'
    dispatch(updatePostCommentThunk(payload, postId, comment?._id, type))
    setEditMessageInfo('')
  }

  const showDeletePopup = (comment: any) => {
    props.additionData({
      commentId: comment?._id,
      pageid: activePost?.page,
      type: mediaObject?._id ? 'media' : 'post',
      mediaId: mediaObject?._id ?? '',
    })
    props.currentPostId(activePost?._id)
    props.deleteshow(true)
    props.deleteType('comment')
    const PostCommentDataaUpdate = commentData?.map((comments: any, index: any) => {
      return {
        ...comments,
        isAction: false,
        isEdit: false,
      }
    })
    setPostCommentData(PostCommentDataaUpdate)
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
    if (reactionComment) {
      const canAddNewReaction = reactionComment?.reactions?.find(
        (react: any) => react?.createdBy?._id === userState?.value?.data?._id
      )
      if (canAddNewReaction) {
        // edit reaction
        const reactId = canAddNewReaction?._id
        const name = event.unified
        const postId = activePost.activeIndex !== null ? mediaObject?._id : activePost?._id
        const type = activePost.activeIndex !== null ? 'media' : 'post'
        dispatch(editReactionForCommentThunk(reactId, name, postId, type))
      } else {
        // add new reaction
        if (reactionComment.reply) {
          const payload = {
            reply: reactionComment?._id,
            createdBy: userState?.value?.data?._id,
            name: event.unified,
          }
          const postId = activePost.activeIndex !== null ? mediaObject?._id : activePost?._id
          const type = activePost.activeIndex !== null ? 'media' : 'post'
          dispatch(addReactionForCommentReplyThunk(payload, postId, type))
          trackEvent('Page Interaction', 'Post Comment Reply Reaction', `${userState?.value?.data?._id}`)
        } else {
          const payload = {
            comment: reactionComment?._id,
            createdBy: userState?.value?.data?._id,
            name: event.unified,
          }
          const postId = activePost.activeIndex !== null ? mediaObject?._id : activePost?._id
          const type = activePost.activeIndex !== null ? 'media' : 'post'
          dispatch(addReactionForCommentThunk(payload, postId, type))
          trackEvent('Page Interaction', 'Post Comment Reaction', `${userState?.value?.data?._id}`)
        }
      }

      setActiveReactionOpenId(false)
      setReactionComment(null)
      setDisabledScroll(true)
    } else {
      if (editmessageInfo === '' && mainCommentEdit === '') {
        const textAreaElement: any = document.getElementById('message-input-post-comment')
        setMessageInfo(
          messageInfo.substr(0, textAreaElement.selectionStart) +
            ' ' +
            event.emoji +
            ' ' +
            messageInfo.substr(textAreaElement.selectionEnd)
        )
        inputReference?.current?.focus()
      } else {
        const textAreaElement: any = document.getElementById('message-input-edit-post-comment')
        setMainCommentEdit(
          mainCommentEdit.substr(0, textAreaElement.selectionStart) +
            ' ' +
            event.emoji +
            ' ' +
            mainCommentEdit.substr(textAreaElement.selectionEnd)
        )
        inputReferenceEdit?.current?.focus()
      }

      setActiveReactionOpenId(false)
      setReactionComment(null)
    }
  }

  /* Keyboard next previous navigation code */
  const goBack = () => {
    setActiveIndex(activeindex - 1 < 0 ? 0 : activeindex - 1)
    if (activeindex - 1 < 0) {
      setActiveIndex(activePost.medias?.length - 1) // Allow carousel to loop
    } else {
      setActiveIndex(activeindex - 1)
    }
  }

  const goNext = () => {
    if (activeindex + 1 < activePost.medias?.length) {
      setActiveIndex(activeindex + 1)
    } else {
      setActiveIndex(0) // Allow carousel to loop
    }
  }

  useKeyPress()
  // https://usehooks.com/useKeyPress/
  function useKeyPress() {
    const upHandler = (event: KeyboardEvent) => {
      const KEY_CODE_LEFT = 37
      const KEY_CODE_RIGHT = 39
      const { keyCode } = event
      if (keyCode === KEY_CODE_LEFT) {
        goBack()
      }
      if (keyCode === KEY_CODE_RIGHT) {
        goNext()
      }
    }

    // Add event listeners
    useEffect(() => {
      window.addEventListener('keyup', upHandler)
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('keyup', upHandler)
      }
    }, [activeindex]) // Ensures that effect is run on componentDidUpdate in addition to mount and unmount
  }
  /* End keyboard next previous navigation code */

  const groupBy = (key: string) => {
    return function group(array: any) {
      return array?.reduce((acc: any, obj: any) => {
        const property = obj[key]
        acc[property] = acc[property] || []
        acc[property].push(obj)
        return acc
      }, {})
    }
  }

  const groupByReactions = groupBy('name')

  const getReactionsArray = (Reactions: any) => {
    return groupByReactions(Reactions)
  }

  const videoSinglePlay = (vindex: number) => {
    const activeItemThumb: any = document.getElementById(`video-thumb-popup-single-${vindex}`)
    if (activeItemThumb) {
      activeItemThumb.classList.add('d-none')
    }
    const activeItemPlayer: any = document.getElementById(`video-player-popup-single-${vindex}`)
    if (activeItemPlayer) {
      activeItemPlayer.play()
    }
  }

  const canAction = () => {
    if (
      pageData?.data?.belongsTo?._id === userState?.value?.data?._id ||
      pageData?.data?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const activeIndexSet = (mindex: number) => {
    setActivePost({ ...activePost, activeIndex: mindex })
    setMediaActivePost({ ...activePost, activeIndex: mindex })
    setActiveIndex(mindex)
  }

  const openFileSelection = () => {
    const sId = document.getElementById('comment_input_image')
    if (sId) {
      sId?.click()
    }
  }

  const ReadMore = ({ children }: { children: any }) => {
    const text = children
    const [isReadMore, setIsReadMore] = useState(true)
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore)
    }
    return (
      <>
        {text?.length < 100 ? (
          <>{nl2br(text)}</>
        ) : (
          <>
            {isReadMore ? nl2br(text?.slice(0, 100)) : nl2br(text)}
            <span onClick={toggleReadMore} className="read-or-hide">
              {isReadMore ? '... more' : ' less'}
            </span>
          </>
        )}
      </>
    )
  }

  const removeSelected = () => {
    setSelectedFiles([])
    setShowCommentImage(false)
  }

  const systemFileSelection = (e: any) => {
    if (e?.currentTarget?.files?.length) {
      const image = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'tiff', 'tif', 'svg', 'webp']
      const images = []
      for (let i = 0; i < e?.currentTarget?.files?.length; i++) {
        const extension = e?.currentTarget?.files[i].name
          .substring(e?.currentTarget?.files[i].name.lastIndexOf('.') + 1)
          .toLowerCase()
        if (image.includes(extension)) {
          const fileUrl = URL.createObjectURL(e?.currentTarget?.files[i])

          images.push({
            url: fileUrl,
            data: e?.currentTarget?.files[i],
            thumbnail: fileUrl,
          })
        } else {
          return false
        }
      }
      setSelectedFiles([...images])
      setShowCommentImage(true)
    }
  }

  const handleSelect = (selectedIndex: any, e: any) => {
    setActiveIndex(selectedIndex)
  }

  const commentIconSet = (commentLen: any, activeCommentLen: any) => {
    let commentcount = 0

    if (commentLen) {
      commentcount = commentLen?.length
    } else {
      commentcount = activeCommentLen
    }

    return <i className={commentcount > 0 ? 'fa fa-comment' : 'fa fa-nocomment'}></i>
  }

  return (
    <div
      className={cn('PreviewWrapper', {
        'without-image-preview': activePost.activeIndex === null && activePost.medias?.length === 0,
      })}
    >
      {activePost.activeIndex !== null ? (
        <div className="ImageSection">
          <Modal.Header closeButton></Modal.Header>
          <div className="Img">
            <div className="card-img gallery-commentpopup-image-back">
              <Carousel
                fade
                activeIndex={activeindex}
                onSelect={handleSelect}
                indicators={false}
                interval={null}
                keyboard={false}
                controls={activePost.medias?.length > 1}
              >
                {activePost.medias?.forEach((media: any, mindex: number) => {
                  if (media.type === 'image') {
                    return (
                      <Carousel.Item className="image-carousel" key={mindex}>
                        <img className="d-block w-100" src={media?.url} alt={media?.caption} />
                      </Carousel.Item>
                    )
                  } else if (media.type === 'audio') {
                    return (
                      <Carousel.Item className="audio-carousel" key={mindex}>
                        <div className="audio-post">
                          {mindex === activeindex ? (
                            <AudioWaves audioURL={media.url} stopAllMedia={() => {}} mediaPlay={false} />
                          ) : (
                            <></>
                          )}
                        </div>
                      </Carousel.Item>
                    )
                  } else if (media.type === 'video') {
                    return (
                      <Carousel.Item className="video-carousel" key={mindex}>
                        <div className="video-post">
                          <>
                            {mindex === activeindex ? (
                              <>
                                <video
                                  src={media.url}
                                  controls={true}
                                  // loop
                                  // muted
                                  // autoPlay={key + vindex === playideo}
                                  id={`video-player-popup-single-${mindex}`}
                                  className="video-player"
                                ></video>
                                <div
                                  onClick={() => {
                                    videoSinglePlay(mindex)
                                  }}
                                  className={cn('video-thumb', {
                                    'no-video-thumb': !media.thumbnail,
                                  })}
                                  id={`video-thumb-popup-single-${mindex}`}
                                  style={{
                                    backgroundImage: `url(${
                                      media.type === 'video' && media.thumbnail ? media.thumbnail : ''
                                    })`,
                                  }}
                                >
                                  <div className="overlay"></div>
                                  <span
                                    onClick={() => {
                                      videoSinglePlay(mindex)
                                    }}
                                  >
                                    <i className="fa fa-play"></i>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        </div>
                      </Carousel.Item>
                    )
                  }
                })}
              </Carousel>
            </div>
          </div>
        </div>
      ) : activePost.medias?.length > 1 ? (
        <div className="ImageSection Grid">
          <Modal.Header closeButton></Modal.Header>
          <div
            id="grid"
            className={cn('grid-container', {
              'two-image-grid-preview': activePost.medias?.length === 2,
              'three-image-grid-preview': activePost.medias?.length === 3,
              'four-image-grid-preview': activePost.medias?.length === 4,
              'five-image-grid-preview': activePost.medias?.length === 5,
              'more-image-grid-preview': activePost.medias?.length > 5,
            })}
          >
            {activePost.medias?.forEach((media: any, mindex: number) => {
              if (mindex < 5) {
                if (media.type === 'image') {
                  return (
                    <div
                      key={mindex}
                      className={`griditem griditem-preview-${mindex + 1}`}
                      onClick={() => {
                        activeIndexSet(mindex)
                      }}
                      style={{
                        backgroundImage: `url(${media?.url})`,
                      }}
                    >
                      {mindex === 4 && activePost.medias?.length > 5 ? (
                        <div className="more-images">
                          <span>+ {activePost.medias?.length - 5}</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )
                } else if (media.type === 'audio') {
                  return (
                    <div
                      key={mindex}
                      onClick={() => {
                        activeIndexSet(mindex)
                      }}
                      className="griditem"
                    >
                      {mindex === 4 && activePost.medias?.length > 5 ? (
                        <div className="more-images">
                          <span>+ {activePost.medias?.length - 5}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="audio-type-post">
                        <i className="fa fa-butterflyheadphone"></i>
                      </div>
                    </div>
                  )
                } else if (media.type === 'video') {
                  return (
                    <div
                      key={mindex}
                      onClick={() => {
                        activeIndexSet(mindex)
                      }}
                      className="griditem"
                    >
                      <div
                        className={cn('video-type-post', {
                          'no-video-thumb': !media.thumbnail,
                        })}
                        style={{
                          backgroundImage: `url(${media.type === 'video' && media.thumbnail ? media.thumbnail : ''})`,
                        }}
                      >
                        {mindex === 4 && activePost.medias?.length > 5 ? (
                          <div className="more-images">
                            <span>+ {activePost.medias?.length - 5}</span>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="overlay"></div>
                        <i className="fa fa-play"></i>
                      </div>
                    </div>
                  )
                }
              }
            })}
          </div>
        </div>
      ) : activePost.medias?.length === 1 ? (
        <>
          <div className="ImageSection">
            <Modal.Header closeButton></Modal.Header>
            {activePost.medias?.forEach((media: any, mindex: number) => {
              if (media.type === 'image') {
                return (
                  <div className="grid-container single-img">
                    <div
                      key={mindex}
                      className="griditem"
                      // style={{
                      // 	backgroundImage: `url(${media?.url})`,
                      // }}
                    >
                      <img src={`${media?.url}`} alt="" className="w-100" />
                    </div>
                  </div>
                )
              } else if (media.type === 'audio') {
                return (
                  <div key={mindex} className="audio-post">
                    <AudioWaves audioURL={media.url} stopAllMedia={() => {}} mediaPlay={false} />
                  </div>
                )
              } else if (media.type === 'video') {
                return (
                  <div key={mindex} className="video-post">
                    <>
                      <video
                        src={media.url}
                        controls={true}
                        // loop
                        // muted
                        // autoPlay={key + vindex === playideo}
                        id={`video-player-popup-single-${mindex}`}
                        className="video-player"
                      ></video>
                      <div
                        className={cn('video-thumb', {
                          'no-video-thumb': !media.thumbnail,
                        })}
                        id={`video-thumb-popup-single-${mindex}`}
                        style={{
                          backgroundImage: `url(${media.type === 'video' && media.thumbnail ? media.thumbnail : ''})`,
                        }}
                      >
                        <div className="overlay"></div>
                        <span
                          onClick={() => {
                            videoSinglePlay(mindex)
                          }}
                        >
                          <i className="fa fa-play"></i>
                        </span>
                      </div>
                    </>
                  </div>
                )
              }
            })}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="Commentsection">
        <div ref={ref}>
          <Modal.Header closeButton>
            <div className="owner">
              {isOpenfromGallery ? (
                <div className="ownerPic">
                  {mediaObject?.post?.createdBy?.image ? (
                    <img src={mediaObject?.post?.createdBy?.image} alt="" />
                  ) : (
                    <div className="ownerAlpha">
                      <span>{mediaObject?.post?.createdBy?.name?.charAt(0)}</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="ownerPic">
                  {activePost?.createdBy?.image ? (
                    <img src={activePost?.createdBy?.image} alt="" />
                  ) : (
                    <div className="ownerAlpha">
                      <span>{activePost?.createdBy?.name?.charAt(0)}</span>
                    </div>
                  )}
                </div>
              )}
              <div className="ownerName">
                {isOpenfromGallery ? mediaObject?.post?.createdBy?.name : activePost?.createdBy?.name}
                <span className="created_at">
                  {createdMonthDayYear(isOpenfromGallery ? mediaObject?.post?.createdAt : activePost?.createdAt)}
                </span>
              </div>
            </div>
          </Modal.Header>

          {/* -------------------------ONLY FOR QUESTION ------------------------- */}
          {activePost?.title !== '' && (
            <div className="contents">
              <div className="QuestionAnswers">
                {activePost.activeIndex !== null ? (
                  <span>{mediaObject?.caption}</span>
                ) : (
                  <>
                    <p className="Question">{activePost?.title}</p>
                    {activePost?.description?.trim() !== '' ? (
                      <p className="Answer">{htmlContentWithLink(activePost?.description)}</p>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {activePost?.title === '' &&
            (activePost.activeIndex !== null ? (
              <div className="contents">
                <div className="description">
                  <span>{mediaObject?.caption}</span>
                </div>
              </div>
            ) : activePost?.description?.trim() ? (
              <div className="contents">
                <div className="description">
                  <span>{htmlContentWithLink(activePost?.description)}</span>
                </div>
              </div>
            ) : (
              ''
            ))}
          <div className="Reactions">
            <span>
              <React.Fragment>
                {commentIconSet(
                  postCommentData,
                  activePost.activeIndex !== null ? mediaObject?.counts?.comments : activePost?.counts?.comments
                )}
              </React.Fragment>
              {postCommentData?.length
                ? postCommentData?.length
                : activePost.activeIndex !== null
                ? mediaObject?.counts?.comments
                  ? mediaObject?.counts?.comments
                  : ''
                : activePost?.counts?.comments
                ? activePost?.counts?.comments
                : ''}
            </span>
            <span>
              {checkLikedOrNot(userState?.value?.data?._id, likes) ? (
                <i
                  onClick={() => {
                    removelikePost(userState?.value?.data?._id, activePost?._id, activePost?.page, likes)
                  }}
                  className={cn('fa fa-butterflylike', {
                    disabled: !canAction() || !likeStatus,
                  })}
                ></i>
              ) : (
                <i
                  onClick={() => {
                    addlikePost(userState?.value?.data?._id, activePost?._id, activePost?.page)
                  }}
                  className={cn('fa fa-heartoutline', {
                    disabled: !canAction() || !likeStatus,
                  })}
                ></i>
              )}
              {likeCount || (likes?.length ? likes?.length : '')}
            </span>
          </div>
        </div>
        <div
          className="MessageBoard"
          style={{
            overflow: `overlay`,
            maxHeight: `calc(100vh - calc(80px + ${height}px))`,
            height: `calc(100vh - calc(80px + ${height}px))`,
          }}
        >
          <div
            className={cn('chat-wrapper', {
              EmptyComments: postCommentData?.length === 0,
            })}
          >
            {postCommentData?.length ? (
              <>
                {postCommentData?.map((comment: any, index: number) => {
                  return (
                    <div key={index} className="message-wrapper">
                      <div className="d-flex w-100">
                        <div className="ownerPic">
                          {comment?.createdBy?.image ? (
                            <img src={comment?.createdBy?.image} className="message-pp" alt="" />
                          ) : (
                            <div className="ownerAlpha">
                              <span>{comment?.createdBy?.name?.charAt(0)}</span>
                            </div>
                          )}
                        </div>
                        {!comment?.isEdit ? (
                          <div className="message-box-wrapper">
                            <div className="title-box">
                              <span className="name">{comment?.createdBy?.name}</span>
                              {comment?.createdBy?._id === userState?.value?.data?._id ? (
                                <span className="time">
                                  <span className="comment-time">{subcommenttimeSince(comment?.updatedAt)} ago</span>
                                  <i
                                    onClick={() => {
                                      mainCommentAction(comment)
                                    }}
                                    className="fa fa-meatballs"
                                    aria-hidden="true"
                                  ></i>
                                  {comment?.isAction && (
                                    <OutsideClickHandler
                                      className="dropdown"
                                      onOutsideClick={() => {
                                        mainCommentAction(comment)
                                      }}
                                    >
                                      <div className="ActionMenu postActiveMenu">
                                        <ul>
                                          <li
                                            onClick={() => {
                                              editComment(comment)
                                            }}
                                          >
                                            <i className="fa fa-edit"></i> Edit
                                          </li>
                                          <li
                                            onClick={() => {
                                              showDeletePopup(comment)
                                            }}
                                          >
                                            <i className="fa fa-butterflydelete"></i> Delete
                                          </li>
                                        </ul>
                                      </div>
                                    </OutsideClickHandler>
                                  )}
                                </span>
                              ) : (
                                <span className="time">{subcommenttimeSince(comment?.updatedAt)} ago</span>
                              )}
                            </div>
                            <div className="message-box">
                              <ReadMore>{comment?.comment}</ReadMore>
                              {comment.image && comment.image !== '' ? (
                                <div className="chatImageWrapper">
                                  <img src={comment?.image} />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="footer">
                              <div className="d-flex align-items-center">
                                <span
                                  onClick={() => {
                                    if (canAction()) {
                                      setReplyData(comment)
                                    } else {
                                      dispatch(addPageAction('show-subsciption-step'))
                                    }
                                  }}
                                  className="reply"
                                >
                                  Reply
                                </span>
                                <span
                                  onClick={() => {
                                    if (canAction()) {
                                      setActiveReactionOpenId(true)
                                      setReactionComment(comment)
                                    } else {
                                      dispatch(addPageAction('show-subsciption-step'))
                                    }
                                  }}
                                  className="addreaction"
                                >
                                  <i className="fa fa-butterflyaddreaction"></i>
                                </span>
                              </div>
                              {comment?.reactions.length ? (
                                <div
                                  onClick={() => {
                                    setDisabledScroll(true)
                                    props.reactionModalShow(true)
                                    props.setActiveReaction({
                                      allReaction: comment?.reactions,
                                      reactionGroup: getReactionsArray(comment?.reactions),
                                    })
                                  }}
                                  className="ctas"
                                >
                                  <span>
                                    {Object.keys(getReactionsArray(comment?.reactions)).map(
                                      (reactionKey: any, index: any) => {
                                        if (index < 4) {
                                          return <Emoji key={index} unified={reactionKey} size={18} />
                                        }
                                        return null
                                      }
                                    )}
                                    {comment?.reactions?.length}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="message-box-wrapper Edit">
                            <div className="title-box-textarea">
                              <textarea
                                value={mainCommentEdit}
                                onChange={editCommentMessage}
                                id="message-input-edit-post-comment"
                                placeholder="Type your comment here"
                                ref={inputReferenceEdit}
                              ></textarea>
                            </div>
                            <div className="footer">
                              <span className="addreaction">
                                <i
                                  onClick={() => {
                                    setEditMessageInfo(mainCommentEdit)
                                    setActiveReactionOpenId(true)
                                    setReactionComment(null)
                                  }}
                                  className="fa fa-butterflyaddreaction"
                                ></i>
                              </span>
                              <span className="ctas">
                                <button
                                  onClick={() => {
                                    editComment(comment)
                                  }}
                                  className="cancel"
                                >
                                  <i className="fa fa-butterflynoapproval"></i>
                                  Cancel
                                </button>
                                <button
                                  onClick={() => {
                                    updateComment(comment)
                                  }}
                                  disabled={mainCommentEdit.trim() === ''}
                                  className="save"
                                >
                                  <i className="fa fa-butterflytick"></i>
                                  Save
                                </button>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {comment?.replies?.length ? (
                        <>
                          {comment?.replies?.map((rcomment: any, index: number) => {
                            return (
                              <div key={index} className="message-wrapper-reply">
                                <div className="ownerPic">
                                  {rcomment?.createdBy?.image ? (
                                    <img src={rcomment?.createdBy?.image} className="message-pp" alt="" />
                                  ) : (
                                    <div className="ownerAlpha">
                                      <span>{rcomment?.createdBy?.name?.charAt(0)}</span>
                                    </div>
                                  )}
                                </div>
                                <div className="message-box-wrapper">
                                  <div className="title-box">
                                    <span className="name">{rcomment?.createdBy?.name}</span>
                                    {rcomment?.createdBy?._id === userState?.value?.data?._id && (
                                      <>
                                        <span className="time">{subcommenttimeSince(rcomment?.updatedAt)} ago </span>
                                        <span className="time d-none">
                                          <i className="fa fa-meatballs" aria-hidden="true"></i>
                                          <div className="ActionMenu postActiveMenu d-none">
                                            <ul>
                                              <li>
                                                <i className="fa fa-edit"></i> Edit
                                              </li>
                                              <li>
                                                <i className="fa fa-butterflydelete"></i> Delete
                                              </li>
                                            </ul>
                                          </div>
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  <div className="message-box">
                                    <ReadMore>{rcomment?.reply}</ReadMore>
                                    {rcomment.image && rcomment.image !== '' ? (
                                      <div className="chatImageWrapper">
                                        <img src={rcomment?.image} />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="footer">
                                    <div>
                                      <span
                                        onClick={() => {
                                          setActiveReactionOpenId(true)
                                          setReactionComment(rcomment)
                                        }}
                                        className="addreaction"
                                      >
                                        <i className="fa fa-butterflyaddreaction"></i>
                                      </span>
                                    </div>
                                    {rcomment?.reactions?.length ? (
                                      <div
                                        onClick={() => {
                                          setDisabledScroll(true)
                                          props.reactionModalShow(true)
                                          props.setActiveReaction({
                                            allReaction: rcomment?.reactions,
                                            reactionGroup: getReactionsArray(rcomment?.reactions),
                                          })
                                        }}
                                        className="ctas"
                                      >
                                        <span>
                                          {Object.keys(getReactionsArray(rcomment?.reactions)).map(
                                            (reactionKey: any, index: any) => {
                                              if (index < 4) {
                                                return <Emoji key={index} unified={reactionKey} size={18} />
                                              }
                                              return null
                                            }
                                          )}
                                          {rcomment?.reactions?.length}
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                })}
                <div ref={bottomRef} />
              </>
            ) : (
              <>
                {showLoader ? (
                  <div className="comment-loader">
                    <LottieControl />
                  </div>
                ) : (
                  <div className="no-comments">
                    {activePost?.commentsDisabled ? 'Comments Disabled' : 'No comments yet, but check back soon'}
                  </div>
                )}
              </>
            )}
          </div>
          {!activePost?.commentsDisabled && canAction() ? (
            <>
              {replyData && (
                <div className="reply-box">
                  <div className="reply-wrapper">
                    <div className="header">
                      <div className="title">Replying to {replyData?.createdBy?.name}</div>
                      <span
                        className="close-btn"
                        onClick={() => {
                          setReplyData(null)
                        }}
                      >
                        <i className="fa fa-butterflynoapproval"></i>
                      </span>
                    </div>
                    <div className="message">{replyData?.comment}</div>
                  </div>
                </div>
              )}
              <div className="chat-input-wrapper">
                <div className="input-wrapper">
                  <textarea
                    rows={3}
                    ref={inputReference}
                    id="message-input-post-comment"
                    className="chat-input"
                    placeholder="Add a comment"
                    value={messageInfo}
                    onChange={messageValue}
                    onKeyDown={event => {
                      const keyCode = event.which || event.keyCode
                      if (keyCode === 13 && !event.shiftKey && (canSend() || showCommentImage)) {
                        if (!postCommentInit) {
                          setPostCommentInit(true)
                          addNewComment()
                        }
                        event.preventDefault()
                      }
                    }}
                  ></textarea>
                  {!showCommentImage && (
                    <button
                      onClick={() => {
                        openFileSelection()
                      }}
                      className="emoji-btn"
                      type="button"
                    >
                      <i className="fa fa-fa fa-butterflymedia"></i>
                      <input
                        type="file"
                        className="d-none"
                        id="comment_input_image"
                        onChange={systemFileSelection}
                        accept="image/*"
                      ></input>
                    </button>
                  )}
                  <button
                    onClick={() => {
                      setActiveReactionOpenId(true)
                      setReactionComment(null)
                      setEditMessageInfo('')
                    }}
                    className="emoji-btn"
                    type="button"
                  >
                    <i className="fa fa-butterflysmiley"></i>
                  </button>
                  <button
                    disabled={!canSend() && !showCommentImage && postCommentInit}
                    onClick={() => {
                      if (!postCommentInit) {
                        setPostCommentInit(true)
                        addNewComment()
                      }
                    }}
                    className={cn('chat-send-btn', {
                      'can-send': canSend() || showCommentImage,
                    })}
                    type="button"
                  >
                    <i className="fa fa-butterflysend"></i>
                  </button>
                </div>
                {showCommentImage && (
                  <>
                    {selectedFiles?.map((sImage: any, index: number) => {
                      return (
                        <div key={index} className="chatImageHolder">
                          <div className="chatImageWrapper">
                            <img src={sImage.thumbnail} />
                            <span
                              className="closeBtn"
                              onClick={() => {
                                removeSelected()
                              }}
                            >
                              <i className="fa fa-close"></i>
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
                {activeReactionOpenId ? (
                  <div className="reaction-emoji-msgbord">
                    <p
                      onClick={() => {
                        setActiveReactionOpenId(false)
                        setReactionComment(null)
                      }}
                    >
                      &#x2715;
                    </p>
                    <Picker autoFocusSearch={false} onEmojiClick={onEmojiClick} />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : activePost?.commentsDisabled ? (
            <div className="commentsDisabled">Commenting on this post has been turned off by the owner</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default PostPreview
