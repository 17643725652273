// withGoogleTag.tsx
import React from 'react'

import GoogleTag from './index'

const withGoogleTag = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithGoogleTag: React.FC<P> = (props: P) => (
    <>
      <GoogleTag />
      <WrappedComponent {...props} />
    </>
  )

  WithGoogleTag.displayName = `WithGoogleTag(${getDisplayName(WrappedComponent)})`

  return WithGoogleTag
}

function getDisplayName(WrappedComponent: React.ComponentType<any>): string {
  return (WrappedComponent.displayName ?? WrappedComponent.name) || 'Component'
}

export default withGoogleTag
