import './DeletePopup.scss'

import cn from 'classnames'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../../Analytics/GA'
import { useAppSelector } from '../../../Hooks'
import { loading } from '../../../Redux/slices/userSlice'
import { deleteAudioMessges } from '../../../Redux/thunks/AudioMessageThunk'
import {
  deleteAlbumMeidaThunk,
  deleteAlbumThunk,
  deleteMeidaThunk,
  eventDeleteThunk,
  pageDeleteThunk,
} from '../../../Redux/thunks/pageDetailThunk'
import { deletePost, deletePostCommentThunk } from '../../../Redux/thunks/postThunk'

function DeletePopup(props: any) {
  const { deleteshow, handleclose, pageid, deleteType, mediadata, albumdata, deleteButtonLabel } = props
  const dispatch = useDispatch()
  const userState: any = useAppSelector(state => state.user)
  const submitButton = () => {
    if (deleteType === 'album') {
      trackEvent('Page Interaction', 'Delete Album', `${userState?.value?.data?._id}`)
      dispatch(deleteAlbumThunk(pageid, albumdata?._id))
      handleclose()
    } else if (deleteType === 'media') {
      trackEvent('Page Interaction', 'Delete Media', `${userState?.value?.data?._id}`)
      dispatch(deleteMeidaThunk(pageid, mediadata?._id))
      handleclose()
    } else if (deleteType === 'album-media') {
      trackEvent('Page Interaction', 'Delete Media in Album', `${userState?.value?.data?._id}`)
      dispatch(deleteAlbumMeidaThunk(pageid, mediadata?._id, mediadata?.album))
      handleclose()
    } else if (deleteType === 'post') {
      trackEvent('Page Interaction', 'Delete Post', `${userState?.value?.data?._id}`)
      dispatch(deletePost(pageid))
      handleclose()
    } else if (deleteType === 'comment') {
      trackEvent('Page Interaction', 'Delete Comment', `${userState?.value?.data?._id}`)
      dispatch(
        deletePostCommentThunk(pageid, mediadata?.commentId, mediadata?.pageid, mediadata?.type, mediadata?.mediaId)
      )
      handleclose()
    } else if (deleteType === 'fundraiser') {
      trackEvent('Page Interaction', 'Delete Fundraiser', `${userState?.value?.data?._id}`)
      dispatch(
        deletePostCommentThunk(pageid, mediadata?.commentId, mediadata?.pageid, mediadata?.type, mediadata?.mediaId)
      )
      handleclose()
    } else if (deleteType === 'audio_message') {
      trackEvent('Page Interaction', 'Delete Audio Message', `${userState?.value?.data?._id}`)
      dispatch(loading(true))
      dispatch(deleteAudioMessges({ pageId: pageid, audioId: mediadata.audioId }))
      handleclose()
    } else if (deleteType === 'page') {
      dispatch(loading(true))
      dispatch(pageDeleteThunk(pageid, userState?.value?.data?._id))
      handleclose()
    } else if (deleteType === 'event') {
      trackEvent('Page Interaction', 'Delete Event', `${userState?.value?.data?._id}`)
      dispatch(loading(true))
      dispatch(eventDeleteThunk(pageid, mediadata?._id, userState?.value?.data?._id))
      handleclose()
    }
  }
  return (
    <Modal className="DeletePopup" {...props} show={deleteshow} onHide={handleclose} centered size="md">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <i
          className={cn('fa fa-butterflydelete', {
            media: deleteType !== 'album',
          })}
        ></i>
        {deleteType === 'album' ? (
          <p>
            Deleting an album will delete only the album, and the media will still be available under the media section.
          </p>
        ) : deleteType === 'media' ? (
          <p>
            Deleting this will also delete all posts, likes, and comments associated with this media. Are you sure you
            wish to delete?
          </p>
        ) : deleteType === 'album-media' ? (
          <p>
            Deleting this will delete only from the album, and the media will still be available under the media
            section.
          </p>
        ) : deleteType === 'post' ? (
          <p>
            Deleting this will also delete all medias, likes, and comments associated with this post. Are you sure you
            wish to delete?
          </p>
        ) : deleteType === 'comment' ? (
          <p>Are you sure you want to delete this comment? Deleting this comment will be permanantly lost.</p>
        ) : deleteType === 'fundraiser' ? (
          <p>Are you sure you want to delete this comment? Deleting this comment will be permanantly lost.</p>
        ) : deleteType === 'audio_message' ? (
          <p>Are you sure you want to delete this audio message ?</p>
        ) : deleteType === 'page' ? (
          <p>
            Making sure that you really want to delete this page? If so, we will also let all subscribers to the page
            know that.
          </p>
        ) : deleteType === 'event' ? (
          <p>The event &quot;{mediadata?.name}&quot; will be removed. Please inform it&apos;s invited guests.</p>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={submitButton}
          className={cn('', {
            mediabutton: deleteType !== 'album',
          })}
        >
          {!deleteButtonLabel ? 'Delete' : deleteButtonLabel}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeletePopup
