import './PostPopup.scss'

import cn from 'classnames'
import Modal from 'react-bootstrap/Modal'

import PostPreview from '../../PostPreview/PostPreview'

function PostPopup(props: any) {
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={cn('PostPopup', {
        withoutImage: props?.activePost.activeIndex === null,
      })}
    >
      <Modal.Body>
        <PostPreview
          activePost={props.activePost}
          deleteshow={props.deleteshow}
          deleteType={props.deleteType}
          currentPostId={props.currentPostId}
          additionData={props.additionData}
          reactionModalShow={props.reactionModalShow}
          setActiveReaction={props.setActiveReaction}
        />
      </Modal.Body>
    </Modal>
  )
}

export default PostPopup
