import './styles.scss'

import Picker from 'emoji-picker-react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../Analytics/GA'
import { useAppSelector } from '../../Hooks'
import { setPopup } from '../../Redux/slices/metaSlice'
import { addPageAction, type pageDetailData } from '../../Redux/slices/pageDetailSlice'
import { createPost } from '../../Redux/thunks/postThunk'
import Loader from '../Loader/Loader'
import CreatePost from '../Modals/CreatePost/CreatePost'

const YourQuestions = (props: any) => {
  const dispatch = useDispatch()
  const [questionNo, setQuestionNo] = useState(0)
  const [show, setShow] = useState(false)
  const [from, setFrom] = useState('')
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const userData: any = useAppSelector((state: any) => state?.user)
  const postData: any = useAppSelector((state: any) => state?.post)
  const [answer, setAnswer] = useState('')
  const [totalQuestion, setTotalQuestion] = useState<any>()
  const [activeReactionOpen, setActiveReactionOpen] = useState<any>(false)

  const { setAdditionalMessage, additionalMessage, setAnsPosted, ansPosted } = props

  const patternItem = (x: number, lastNum: number) => {
    return x % lastNum
  }

  useEffect(() => {
    const TotalQuestions = pageDetailState.questions?.length
    setTotalQuestion(TotalQuestions)
    setQuestionNo(patternItem(props.number, TotalQuestions))

    /* dispatch(
      setShowStepStatus({
        ...userData?.value,
        data: { ...userData?.value?.data, step: "1", showStep:true,legacyStep:true },
      }),
    ); */
  }, [pageDetailState])

  const handleShow = (param: string) => {
    setFrom(param)
    setShow(true)
    props.showArrows(false)
    if (questionNo + 1 === totalQuestion) {
      setAdditionalMessage(true)
    }
  }

  const answerText = (event: any) => {
    const answerDesc = event.target.value
    setAnswer(answerDesc)
  }

  const canSend = () => {
    return answer.trim() !== ''
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
    //

    const textAreaElement: any = document.getElementById(`question-ans-${props.number}`)
    setAnswer(
      answer.substr(0, textAreaElement.selectionStart) +
        ' ' +
        event.emoji +
        ' ' +
        answer.substr(textAreaElement.selectionEnd)
    )
    setActiveReactionOpen(false)
  }

  useEffect(() => {
    if (postData?.postCreateResponse?.success === true) {
      setAnswer('')
      if (userData?.value?.data?.showStep) {
        setTimeout(() => {
          if (
            userData?.questionAddDeletePopupFlag === null &&
            userData?.value?.data?.step === 4 &&
            userData?.value?.data?.showStep === true
          ) {
            document.getElementById('add-edit-question-link')?.click()
          } else {
            dispatch(setPopup(null))
            dispatch(addPageAction('show-event-steps'))
          }
        }, 850)
      } else {
        if (totalQuestion > 1 && !additionalMessage) {
          props.moveNext('next')
        } else if (additionalMessage) {
          setAnsPosted(true)
        }
      }
    }
  }, [postData?.postCreateResponse])

  const createQuestion = () => {
    const payload = {
      title: pageDetailState.questions?.length > questionNo && pageDetailState.questions[questionNo].question,
      description: answer.trim(),
      page: pageDetailState?._id,
      medias: [],
      createdBy: userData?.value?.data?._id,
      reactions: [],
      questionId: pageDetailState.questions[questionNo]._id,
    }
    if (questionNo + 1 === totalQuestion) {
      setAdditionalMessage(true)
    }
    dispatch(createPost(payload))
    trackEvent('Page Interaction', 'Prompted Post', `${userData?.value?.data?._id}`)
  }

  return (
    <>
      {userData.loading && <Loader />}
      {ansPosted && additionalMessage ? (
        <div className="question_hint">
          <i
            onClick={() => {
              if (
                userData?.questionAddDeletePopupFlag === null &&
                userData?.value?.data?.step === 4 &&
                userData?.value?.data?.showStep === true
              ) {
                document.getElementById('add-edit-question-link')?.click()
              } else {
                dispatch(setPopup(null))
              }
            }}
            className="fa fa-butterflynoapproval"
          ></i>
          <div>
            <h4>We&apos;ve come full circle!</h4>
            <p>Keep sharing..your stories keep the celebration going!</p>
          </div>
          <button className="Cancel" onClick={() => dispatch(setPopup(null))}>
            Cancel
          </button>
          <button
            className="Yes"
            onClick={() => {
              setAdditionalMessage(false)
              setAnsPosted(false)
              props.moveNext('next')
            }}
          >
            Yes
          </button>
        </div>
      ) : (
        <div className="your_question_post">
          <div className="your_question_title">
            <h4>Your questions</h4>
            <i
              onClick={() => {
                if (
                  userData?.questionAddDeletePopupFlag === null &&
                  userData?.value?.data?.step === 4 &&
                  userData?.value?.data?.showStep === true
                ) {
                  document.getElementById('add-edit-question-link')?.click()
                } else {
                  dispatch(setPopup(null))
                  dispatch(addPageAction('show-event-steps'))
                }
              }}
              className="fa fa-butterflynoapproval"
            ></i>
          </div>
          <div className="your_question_memory">
            <h5>{pageDetailState.questions?.length > questionNo && pageDetailState.questions[questionNo].question}</h5>
          </div>
          <div className="your_question_addinput">
            <textarea
              placeholder="Add your answer"
              value={answer}
              onChange={answerText}
              id={`question-ans-${props.number}`}
            ></textarea>
            <i
              className="fa fa-butterflysmiley"
              onClick={() => {
                setActiveReactionOpen(true)
              }}
              aria-hidden="true"
            ></i>
            {activeReactionOpen ? (
              <div className="reaction-emoji-msgbord">
                <p
                  onClick={() => {
                    setActiveReactionOpen(false)
                  }}
                >
                  &#x2715;
                </p>
                <Picker autoFocusSearch={false} onEmojiClick={onEmojiClick} />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="your_question_icons">
            <div className="IconsList">
              <i
                className="fa fa-butterflymedia"
                onClick={() => {
                  handleShow('media')
                }}
              ></i>
              <i
                className="fa fa-videopost"
                onClick={() => {
                  handleShow('video')
                }}
              ></i>
              <i
                className="fa fa-butterflymic  mr-0"
                onClick={() => {
                  handleShow('micaudio')
                }}
              ></i>
            </div>
          </div>
          <div className="your_question_add_post">
            {pageDetailState.belongsTo?._id === userData?.value?.data?._id ? (
              <div className="your_question_Add_Delete">
                <span
                  id="add-edit-question-link"
                  onClick={() => {
                    dispatch(setPopup('question-add-popup'))
                  }}
                >
                  <i className="fa fa-edit"></i>
                  Edit questions
                </span>
              </div>
            ) : (
              <div className="your_question_Add_Delete">{''}</div>
            )}
            <div className="your_question_post_button">
              <button
                onClick={() => {
                  createQuestion()
                }}
                disabled={!canSend()}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      )}

      {show ? (
        <CreatePost
          title={pageDetailState.questions?.length > questionNo && pageDetailState.questions[questionNo].question}
          description={answer}
          questionId={pageDetailState?.questions[questionNo]?._id}
          show={show}
          handleClose={() => {
            if (totalQuestion > 1) {
              props.showArrows(true)
            }
            setShow(false)
            setAdditionalMessage(false)
          }}
          from={from}
          type={'question'}
        />
      ) : (
        ''
      )}
    </>
  )
}
export default YourQuestions
