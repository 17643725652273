import './AudioPopup.scss'

import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import WaveSurfer from 'wavesurfer.js'

import { useAppSelector } from '../../../Hooks'
import { removeAudioMessageResponse } from '../../../Redux/slices/audioMessageSlice'
import { loading } from '../../../Redux/slices/userSlice'
import { deleteAudioMessges, getAudioMessges, newStatusUpdate } from '../../../Redux/thunks/AudioMessageThunk'

function AudioPopup(props: any) {
  const audioMessageState: any = useAppSelector(state => state.audiomessages)
  const pageDetailState: any = useAppSelector(state => state.pagedetail)
  const userState: any = useAppSelector(state => state.user)

  const dispatch = useDispatch()
  const waveformRef: any = useRef()
  const [, setRemainingTime] = useState<any>('')
  const [audioLoading, setAudioLoading] = useState<any>(true)
  const [audioList, setAudioList] = useState([])
  const [, setTotalMessageCount] = useState(0)
  const [deleteShow, setDeleteShow] = useState(false)
  const [isFirst, setIsFirst] = useState(true)
  const [activeAudioMsg, setActiveAudioMsg] = useState<any>({
    name: '',
    profileImg: '',
    url: 'https://butterflyv2-staging-assets.nyc3.cdn.digitaloceanspaces.com/audio/1f1b94c0-ec94-43d9-9b53-09549c1be76d-blob',
  })

  const waveSurferRef: any = useRef({
    isPlaying: () => false,
  })
  const [isPlaying, toggleIsPlaying] = useState(false)

  const handleShow = (data: any) => {
    setIsFirst(false)
    setActiveAudioMsg(data)
  }

  useEffect(() => {
    if (audioLoading === false && !isFirst) {
      waveSurferRef.current.playPause()
      toggleIsPlaying(waveSurferRef.current.isPlaying())
    }
  }, [audioLoading])

  const handleDeleteClose = () => {
    dispatch(loading(true))
    dispatch(
      deleteAudioMessges({
        pageId: pageDetailState?.data?._id,
        audioId: activeAudioMsg._id,
      })
    )
    setDeleteShow(false)
  }

  const waveColors = document.createElement('canvas').getContext('2d')?.createLinearGradient(0, 0, 550, 0)

  if (waveColors) {
    waveColors.addColorStop(0, '#24B183')
    waveColors.addColorStop(1, '#9b7bf6')
  }

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: waveformRef.current,
      responsive: true,
      barWidth: 1.5,
      barHeight: 2,
      cursorWidth: 0,
      barRadius: 1,
      waveColor: waveColors,
      progressColor: 'rgba(255,255,255,.7)',
      hideScrollbar: true,
      fillParent: true,
      cursorColor: 'green',
    })
    let current
    waveSurfer.on('audioprocess', () => {
      const totalTime = waveSurfer.getDuration()
      current = waveSurfer.getCurrentTime()
      setRemainingTime(totalTime - current)
    })
    waveSurfer.load(activeAudioMsg.url)
    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer
      const totalTime = waveSurfer.getDuration()
      setRemainingTime(totalTime)
      setAudioLoading(false)
    })

    waveSurfer.on('loading', function (X: any, evt: any) {
      setAudioLoading(true)
    })

    waveSurfer.on('finish', () => {
      toggleIsPlaying(false)
    })

    waveSurfer.on('play', () => {
      if (activeAudioMsg?.isNew === true) {
        // setActiveAudioMsg({...activeAudioMsg, isNew:false})
        dispatch(newStatusUpdate(activeAudioMsg?._id))
      }
    })

    return () => {
      waveSurfer.destroy()
    }
  }, [activeAudioMsg])

  useEffect(() => {
    if (audioMessageState?.data?.length > 0) {
      let flag = 0
      if (audioMessageState?.data?.length < audioList?.length) {
        setIsFirst(true)
        flag = 1
      }
      if (flag === 1) {
        setActiveAudioMsg(audioMessageState?.data[0])
      }
      setAudioList(audioMessageState?.data)
      setTotalMessageCount(audioMessageState?.totalCount)
    } else {
      setActiveAudioMsg(
        audioList?.length
          ? audioList[0]
          : {
              name: '',
              profileImg: '',
              url: '',
            }
      )
      props.handleClose()
    }
    dispatch(loading(false))
  }, [audioMessageState?.data])

  useEffect(() => {
    setActiveAudioMsg(props?.activeAudioMsg)
  }, [props?.activeAudioMsg])

  useEffect(() => {
    if (audioMessageState?.deleteAudioMsgResp?.success === true) {
      dispatch(
        getAudioMessges({
          pageId: pageDetailState?.data?._id,
          userId: userState?.value?.data?._id,
        })
      )
      dispatch(
        removeAudioMessageResponse({
          ...audioMessageState?.deleteAudioMsgResp,
          success: null,
        })
      )
    }
  }, [audioMessageState?.deleteAudioMsgResp])

  return (
    <Modal show={props.show} onHide={() => props.handleClose()} centered className="AudioViewModal">
      <Modal.Header closeButton>
        <i className="fa fa-butterflyheadphone"></i>
      </Modal.Header>
      <Modal.Body>
        <div className="CurrentUser">
          <div className="row">
            <div className="Profile">
              {activeAudioMsg?.createdBy?.image ? (
                <img src={activeAudioMsg?.createdBy?.image} alt={activeAudioMsg?.createdBy?.name} />
              ) : (
                <span className="Alpha">{activeAudioMsg?.createdBy?.name.charAt(0)}</span>
              )}
              <span>{activeAudioMsg?.createdBy?.name}</span>
            </div>
            <div className="AudioWaves">
              {audioLoading === true ? <div className="text-center mt-4 audio-file-loading">Loading...</div> : ''}
              <div className="audioWrapper">
                <div className="waves" ref={waveformRef} />
              </div>
            </div>
            <div
              onClick={() => {
                waveSurferRef.current.playPause()
                toggleIsPlaying(waveSurferRef.current.isPlaying())
              }}
              className="audioControl ControllerIcon"
            >
              {isPlaying ? <i className="fa fa-pause"></i> : <i className="fa fa-butterflyaudioplay"></i>}
            </div>
          </div>

          <div className="deleteConfirmation d-none">
            <span>Are you sure you want to delete the audio message from Derek?</span>
            <span>
              <button className="NoDelete">No</button>
              <button>Yes, Deletes</button>
            </span>
          </div>

          <div className="row">
            <div className="delete text-center">
              <i
                className={!deleteShow ? 'fa fa-butterflydelete ' : 'fa fa-butterflydelete d-none'}
                onClick={() => {
                  setDeleteShow(true)
                }}
              ></i>
              <div className={deleteShow ? 'deleteConfirmation' : 'deleteConfirmation  d-none'}>
                <span>Are you sure you want to delete the audio message from {activeAudioMsg?.createdBy?.name}?</span>
                <span>
                  <button
                    className="NoDelete"
                    type="button"
                    onClick={() => {
                      setDeleteShow(false)
                    }}
                  >
                    No
                  </button>
                  <button onClick={handleDeleteClose}>Yes, Delete</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {audioList?.length > 1 ? (
        <Modal.Footer>
          <div className="OtherUsers">
            <div className="row">
              {audioList
                ?.filter((data: any) => data?._id !== activeAudioMsg?._id)
                ?.map((audioItem: any, key: number) => {
                  return (
                    <div
                      className="Profile"
                      key={key}
                      onClick={() => {
                        handleShow(audioItem)
                      }}
                    >
                      <div className="imgWrapper">
                        {audioItem?.createdBy?.image ? (
                          <img src={audioItem?.createdBy?.image} alt={audioItem?.createdBy?.name} />
                        ) : (
                          <span className="Alpha">{audioItem?.createdBy?.name.charAt(0)}</span>
                        )}
                        <i className="fa fa-play-fa"></i>
                        {audioItem?.isNew ? <span className="new">New</span> : ''}
                      </div>
                      <span>{audioItem.name}</span>
                    </div>
                  )
                })}
            </div>
          </div>
        </Modal.Footer>
      ) : (
        ''
      )}
    </Modal>
  )
}

export default AudioPopup
