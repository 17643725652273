import { useEffect, useState } from 'react'

export const useAuth = () => {
  const UserState: any = localStorage.getItem('user_id')
  const [state, setState] = useState<any>(UserState !== null)
  useEffect(() => {
    if (UserState === null) {
      setState(false)
    } else {
      setState(true)
    }
  }, [UserState])
  return state
}
