import 'bootstrap/dist/css/bootstrap.min.css'
import './Fundraiser.scss'

import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import { createdDateMonthYear, getPercent, isset, nFormatter, numberFormateIntl } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import {
  addFundraiserAction,
  addFundraisersResponse,
  addPageAction,
  addPageActionData,
  type pageDetailData,
  updateFundraisersLikeResponse,
  updateFundraisersResponse,
} from '../../Redux/slices/pageDetailSlice'
import { pageGetAllFundraiserThunk } from '../../Redux/thunks/pageDetailThunk'
import { fundraiserAddLikeThunk, removeFundraiserLikeThunk } from '../../Redux/thunks/postThunk'
import Loader from '../Loader/Loader'
import FundraisersPreview from '../Modals/FundraisersPopup/FundraiserPreview'
import FundraisersDonate from '../Modals/FundraisersPopup/FundraisersDonate'
import FundraisersPopup from '../Modals/FundraisersPopup/FundraisersPopup'
import FundraisersPopupEdit from '../Modals/FundraisersPopup/FundraisersPopupEdit'

const Fundraiser = (props: any) => {
  const { setfundraisercurrentpage, updatefundraisercurrentpage } = props

  const userState: any = useAppSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)

  const pageActionData = useAppSelector((state: any) => state.pagedetail.pageActionData)

  const pageFundraiserData = useAppSelector((state: any) => state.pagedetail.fundraiserData)

  const updatedFundraisers = useAppSelector((state: any) => state.pagedetail.updateFundraisersResponseData)

  const updateFundraisersLike = useAppSelector((state: any) => state.pagedetail.updateFundraisersLikeResponseData)

  const addFundraiser = useAppSelector((state: any) => state.pagedetail.addFundraisersResponseData)

  const [show, setShow] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showDonate, setShowDonate] = useState(false)
  const [preview, setPreview] = useState(false)
  const [fundraisers, setFundraisers] = useState([])
  const [selectedFundraiser, setSelectedFundraiser] = useState({})
  const [fundraiserpage, setFundraiserpage] = useState('list')

  const previewClose = () => {
    setPreview(false)
  }
  const previewShow = () => {
    setPreview(true)
  }

  const handleClose = () => {
    setShow(false)
    setFundraiserpage('list')
    setfundraisercurrentpage('list')
  }
  const handleShow = () => {
    setShow(true)
    setFundraiserpage('create')
    setfundraisercurrentpage('create')
  }

  const handleEditClose = () => {
    setShowEdit(false)
    setFundraiserpage('list')
    setfundraisercurrentpage('list')
  }
  const handleEditShow = () => {
    setShowEdit(true)
    setFundraiserpage('edit')
    setfundraisercurrentpage('edit')
  }

  const handleDonateClose = () => {
    setSelectedFundraiser({})
    setShowDonate(false)
  }
  const handleDonateShow = () => {
    setShowDonate(true)
  }

  useEffect(() => {
    return () => {
      // dispatch(addFundraiserAction([]));
    }
  }, [])

  useEffect(() => {
    setFundraiserpage(updatefundraisercurrentpage)
    if (updatefundraisercurrentpage === 'edit-direct') {
      setShowEdit(true)
    }
  }, [updatefundraisercurrentpage])

  useEffect(() => {
    if (pageActionData !== '') {
      setSelectedFundraiser(pageActionData)
      dispatch(addPageActionData(''))
    }
  }, [pageActionData])

  useEffect(() => {
    if (pageDetailState._id && pageDetailState?.counts?.fundraiser) {
      dispatch(pageGetAllFundraiserThunk(pageDetailState?._id))
    }
  }, [pageDetailState?._id])

  useEffect(() => {
    setFundraisers(pageFundraiserData)
  }, [pageFundraiserData])

  useEffect(() => {
    if (updatedFundraisers?.success === true) {
      const updatedFundraisersInfo = fundraisers.map((fundraiser: any) =>
        fundraiser._id === updatedFundraisers?.data?._id ? { ...fundraisers, ...updatedFundraisers?.data } : fundraiser
      )

      dispatch(addFundraiserAction(updatedFundraisersInfo))

      dispatch(
        updateFundraisersResponse({
          ...updatedFundraisers,
          success: null,
        })
      )
    }
  }, [updatedFundraisers])

  useEffect(() => {
    if (updateFundraisersLike?.success === true) {
      if (updateFundraisersLike.data) {
        const fundraiserId = updateFundraisersLike?.data?.fundraiser
        const createdBy = updateFundraisersLike?.data?.createdBy

        const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
          if (e._id === fundraiserId) {
            return {
              ...e,
              isLikeDisable: false,
              likes: e.likes?.map((le: any) => {
                if (le.createdBy === createdBy) {
                  return updateFundraisersLike?.data
                } else {
                  return le
                }
              }),
            }
          } else {
            return {
              ...e,
              isLikeDisable: false,
            }
          }
        })
        setFundraisers(updateFundraiserData)
        dispatch(
          updateFundraisersLikeResponse({
            ...updateFundraisersLike,
            success: null,
          })
        )
      } else {
        const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
          return {
            ...e,
            isLikeDisable: false,
          }
        })
        setFundraisers(updateFundraiserData)
        dispatch(
          updateFundraisersLikeResponse({
            ...updateFundraisersLike,
            success: null,
          })
        )
      }
    }
  }, [updateFundraisersLike])

  useEffect(() => {
    if (addFundraiser?.success === true) {
      const addfundraisersInfo: any[] = [...fundraisers]

      addfundraisersInfo.unshift(addFundraiser?.data)

      dispatch(addFundraiserAction(addfundraisersInfo))

      dispatch(
        addFundraisersResponse({
          ...addFundraiser,
          success: null,
        })
      )
    }
  }, [addFundraiser])

  const checkLikedOrNot = (userId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.filter((e: any) => e?.createdBy === userId)
    return likeUser.length
  }
  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
  }

  const canAction = () => {
    if (
      pageDetailState?.belongsTo?._id === userState?.value?.data?._id ||
      pageDetailState?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const addlikePost = (userId: string, fundraiserId: string, pageId: string) => {
    const payload = {
      fundraiser: fundraiserId,
      createdBy: userId,
    }
    const LikeAdd = {
      fundraiser: fundraiserId,
      createdBy: userId,
      is_deleted: false,
    }

    const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
      if (e._id === fundraiserId) {
        return {
          ...e,
          isLikeDisable: true,
          likes: [...e.likes, LikeAdd],
        }
      } else {
        return e
      }
    })
    setFundraisers(updateFundraiserData)
    dispatch(fundraiserAddLikeThunk(payload, pageId))
    trackEvent('Page Interaction', 'Fundraiser Like', `${userState?.value?.data?._id}`)
  }

  const removelikePost = (userId: string, fundraiserId: string, pageId: string, postLikeObject: any) => {
    const likeUser = postLikeObject?.find((e: any) => e?.createdBy === userId)
    const likeId = likeUser?._id

    const updateFundraiserData: any = fundraisers?.map((e: any, key: number) => {
      if (e._id === fundraiserId) {
        const likesObj = [...e?.likes]
        const objWithIdIndex = likesObj.findIndex((obj: any) => obj._id === likeId)
        if (objWithIdIndex > -1) {
          likesObj?.splice(objWithIdIndex, 1)
        }
        return {
          ...e,
          isLikeDisable: true,
          likes: [...likesObj],
        }
      } else {
        return e
      }
    })
    setFundraisers(updateFundraiserData)
    dispatch(removeFundraiserLikeThunk(fundraiserId, likeId, pageId))
  }

  return (
    <>
      {userState.loading && <Loader />}
      {fundraiserpage === 'list' ? (
        <>
          <div className="fundraiserSliderWrapper">
            {fundraisers?.length ? (
              <>
                {fundraisers?.map((fundraiser: any, key: number) => {
                  return (
                    <div className="eachFundraiser" key={Date.now() + 'fundraiser' + key} id={'fundraiser-item' + key}>
                      <div className={`ImageWrapper ${fundraiser.image ? '' : 'noImgWrapper'}`}>
                        {fundraiser.image ? (
                          <img
                            src={`${fundraiser?.image}?r=${Date.now()}_${key}`}
                            alt={fundraiser?.title}
                            id={'fundraiseImg' + Date.now() + key}
                          />
                        ) : (
                          <div className="no-fundraiser-image"></div>
                        )}
                        <div className="caption">{fundraiser?.title}</div>
                      </div>
                      <div className="card-body">
                        <div className="title">{fundraiser?.beneficiary_name}</div>
                        <p>{fundraiser?.description}</p>
                        <div className="progressBar">
                          <progress max="100" value={getPercent(fundraiser?.total_raised, fundraiser?.goal)}>
                            {`${getPercent(fundraiser?.total_raised, fundraiser?.goal)}%`}
                          </progress>
                        </div>
                        <div className="raised-fund">
                          {numberFormateIntl(fundraiser?.total_raised)} raised of {numberFormateIntl(fundraiser?.goal)}
                        </div>
                        <div className="donate-info">
                          <div className="donater">
                            <div className="img">
                              {fundraiser?.donation_count ? (
                                <>
                                  {fundraiser?.donors_sample.map((donner: any, idx: number) => {
                                    return (
                                      <div className="each-img" key={idx}>
                                        {isset(donner?.image) ? (
                                          <img src={donner?.image} alt={donner?.name} />
                                        ) : (
                                          <div className="ownerAlpha">
                                            <span>{donner?.name?.charAt(0)}</span>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="text">
                              {fundraiser?.donation_count > 4 ? '+' : ''}
                              {nFormatter(fundraiser?.donation_count, 1)} donated
                            </div>
                          </div>
                          {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
                            <button
                              onClick={() => {
                                setSelectedFundraiser(fundraiser)
                                handleEditShow()
                              }}
                              type="button"
                              className="edit"
                            >
                              Edit
                            </button>
                          ) : (
                            <></>
                          )}
                          <button
                            type="button"
                            onClick={() => {
                              if (!userState?.value?.data?._id) {
                                loginRedirect()
                              } else if (!canAction()) {
                                dispatch(addPageAction('show-subsciption-step'))
                              } else {
                                localStorage.setItem('fundraiser_id', fundraiser?._id)
                                localStorage.setItem('user_id', userState?.value?.data?._id)
                                setSelectedFundraiser(fundraiser)
                                handleDonateShow()
                              }
                            }}
                            className="donatenow"
                          >
                            Donate Now
                          </button>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="date-wrap">{createdDateMonthYear(fundraiser?.createdAt)}</div>
                        <div className="like-comment-warp">
                          <span>
                            {fundraiser?.counts?.comments ? fundraiser?.counts?.comments : ''}{' '}
                            <i
                              className={fundraiser?.counts?.comments > 0 ? 'fa fa-comment' : 'fa fa-nocomment'}
                              aria-hidden="true"
                              onClick={() => {
                                if (!userState?.value?.data?._id) {
                                  loginRedirect()
                                } else if (!canAction()) {
                                  dispatch(addPageAction('show-subsciption-step'))
                                } else {
                                  setSelectedFundraiser(fundraiser)
                                  previewShow()
                                }
                              }}
                            ></i>
                          </span>
                          <span>
                            {fundraiser?.likes?.length ? fundraiser?.likes?.length : ''}{' '}
                            {checkLikedOrNot(userState?.value?.data?._id, fundraiser?.likes) ? (
                              <i
                                onClick={() => {
                                  if (!userState?.value?.data?._id) {
                                    loginRedirect()
                                  } else if (!canAction()) {
                                    dispatch(addPageAction('show-subsciption-step'))
                                  } else {
                                    removelikePost(
                                      userState?.value?.data?._id,
                                      fundraiser?._id,
                                      fundraiser?.page,
                                      fundraiser?.likes
                                    )
                                  }
                                }}
                                className={cn('fa fa-butterflylike', {
                                  disabled: fundraiser?.isLikeDisable || !canAction(),
                                })}
                              ></i>
                            ) : (
                              <i
                                onClick={() => {
                                  if (!userState?.value?.data?._id) {
                                    loginRedirect()
                                  } else if (!canAction()) {
                                    dispatch(addPageAction('show-subsciption-step'))
                                  } else {
                                    addlikePost(userState?.value?.data?._id, fundraiser?._id, fundraiser?.page)
                                  }
                                }}
                                className={cn('fa fa-heartoutline', {
                                  disabled: fundraiser?.isLikeDisable,
                                })}
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : !pageDetailState?.counts?.fundraiser ? (
              <>
                <div className="CreateFundraiserEmpty">
                  <p>
                    {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
                      <>
                        <span> Ready to make an impact? Start a fundraiser today!</span>
                      </>
                    ) : (
                      <>
                        <span>Check back soon, fundraisers coming your way!</span>
                      </>
                    )}
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
            <div className="btn-wrap">
              <button type="button" onClick={handleShow} className="create-fund">
                <i className="fa fa-fundraiser" aria-hidden="true"></i>Create fundraiser
              </button>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : fundraiserpage === 'create' ? (
        <>
          <div>{show && <FundraisersPopup show={show} onHide={handleClose} />}</div>
        </>
      ) : fundraiserpage === 'edit' || fundraiserpage === 'edit-direct' ? (
        <>
          <div>
            {showEdit && (
              <FundraisersPopupEdit
                show={showEdit}
                fundraiserpage={fundraiserpage}
                fundraiser={selectedFundraiser}
                onHide={handleEditClose}
              />
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      {showDonate && <FundraisersDonate show={showDonate} onHide={handleDonateClose} fundraiser={selectedFundraiser} />}
      {preview && <FundraisersPreview show={preview} fundraiser={selectedFundraiser} onHide={previewClose} />}
    </>
  )
}

export default Fundraiser
