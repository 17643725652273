import { type AxiosInstance } from 'axios'

import { LOADCHATMESSAGES } from '../../Constants/apiConstants'

export default class MessageBoardApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor
  }

  public async loadMessages(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(`${LOADCHATMESSAGES}/${payload.pageId}`)
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
