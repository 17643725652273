import AxiosClass from '../../Api/Interceptor'
import PostApi from '../../Api/PostAPI'
import { getFundraisersComments, updateFundraisersLikeResponse } from '../slices/pageDetailSlice'
import {
  allowPostPagination,
  commentOnOffPostResp,
  createNewPostResponse,
  deletePostResponse,
  getPostComments,
  getPostDetailsResp,
  loadPostCount,
  loadPostList,
  pinUnpinPostResp,
  updatePostResponse,
} from '../slices/post'
import { loading } from '../slices/userSlice'
import { pageGetAllFundraiserThunk } from './pageDetailThunk'

export const createPost: any = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .createPost(data)
      .then((r: any) => {
        if (r) {
          // Create new Post
          const { post, pagedetail, user } = getState()

          const pageData = pagedetail?.data
          const userData = user?.value?.data
          if (pageData?.belongsTo?._id === userData?._id || !pageData?.approval_required) {
            const getAllPost = [...post?.postList?.data]
            getAllPost.unshift(r.data)
            dispatch(loadPostList(getAllPost))
          }

          dispatch(createNewPostResponse(r))
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getPosts: any = (pageId: any, pagecount: number = 1) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .getPostList(pageId, pagecount)
      .then((r: any) => {
        if (r) {
          const { post } = getState()
          if (pagecount === 1) {
            dispatch(loadPostCount(r?.totalRecords))
            // dispatch(loadPostList(r?.data));
            dispatch(loadPostList([...post.postList?.data, ...r.data]))
          } else {
            dispatch(loadPostList([...post.postList?.data, ...r.data]))
          }
          setTimeout(function () {
            dispatch(allowPostPagination(true))
            // localStorage.removeItem("mobilepostpagination");
            localStorage.setItem('allowPostPagination', '1')
          }, 0)
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getPinnedPosts: any = (pageId: any, pagecount: number = 1) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .getPinPostList(pageId)
      .then((r: any) => {
        if (r) {
          const { post } = getState()
          const getAllPost = [...r.data, ...post?.postList?.data]
          dispatch(loadPostList(getAllPost))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pinUnpinPost: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .pinUnpinPagePost(payload)
      .then((r: any) => {
        if (r) {
          r.data.postId = payload.postId
          r.data.success = true
          dispatch(pinUnpinPostResp(r?.data))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const commentOnOffPost: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .commentOnOffPagePost(payload)
      .then((r: any) => {
        if (r) {
          r.data.postId = payload.postId
          r.data.success = true
          dispatch(commentOnOffPostResp(r?.data))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const updatePost: any = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .updatePagePost(data)
      .then((r: any) => {
        if (r) {
          r.data.medias = data.medias
          dispatch(updatePostResponse(r))
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deletePost: any = (postId: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .deletePagePost(postId)
      .then((r: any) => {
        if (r) {
          r.postId = postId
          dispatch(deletePostResponse(r))
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getPostsCommentsThunk: any = (postId: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .getPostAllComments(postId)
      .then((r: any) => {
        if (r) {
          dispatch(getPostComments(r?.data))
          const { post } = getState()
          const updatePostList = post.postList?.data?.map((postInfo: any) => {
            if (postInfo?._id === postId) {
              return {
                ...postInfo,
                counts: {
                  ...postInfo.counts,
                  comments: r?.data?.length,
                },
              }
            } else {
              return postInfo
            }
          })
          dispatch(loadPostList(updatePostList))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getfundraiserCommentsThunk: any = (fundraiserId: any) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .getfundraiserAllComments(fundraiserId)
      .then((r: any) => {
        if (r) {
          dispatch(getFundraisersComments(r?.data))
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getPostsMediaCommentsThunk: any = (mediaId: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .getPostMediaComments(mediaId)
      .then((r: any) => {
        if (r) {
          dispatch(getPostComments(r?.data))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const createPostCommentThunk: any = (payload: any, pageId: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .createPostComment(payload)
      .then((r: any) => {
        if (r) {
          if (payload.post) {
            dispatch(getPostsCommentsThunk(payload?.post))
            // Add main post comment
            // dispatch(getPosts(pageId));
          } else if (payload.media) {
            dispatch(getPostsMediaCommentsThunk(payload?.media))
            // dispatch(getPosts(pageId));
          } else if (payload.fundraiser) {
            dispatch(getfundraiserCommentsThunk(payload?.fundraiser))
            dispatch(pageGetAllFundraiserThunk(pageId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const createPostCommentReplyThunk: any = (payload: any, postId: string, type: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .createPostCommentReply(payload)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(postId))
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const updatePostCommentThunk: any = (payload: any, postId: string, commentId: string, type: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .updatePostComment(payload, commentId)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(postId))
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deletePostCommentThunk: any = (
  postId: string,
  commentId: string,
  pageId: string,
  type: string,
  mediaId: string
) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .deletePostComment(commentId)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
            // Delete Post Comment
            // dispatch(getPosts(pageId));
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(mediaId))
            // dispatch(getPosts(pageId));
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
            dispatch(pageGetAllFundraiserThunk(pageId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const postAddLikeThunk: any = (payload: any, pageId: string, postId: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .PostAddLike(payload)
      .then((r: any) => {
        if (r) {
          const { post } = getState()
          if (payload?.post) {
            const LikeAdd = {
              post: r?.data?.post,
              createdBy: {
                _id: r?.data?.createdBy,
              },
              is_deleted: false,
              _id: r?.data?._id,
            }
            // Post add Like
            const updatePostList = post.postList?.data?.map((postInfo: any) => {
              if (postInfo?._id === r?.data?.post) {
                return {
                  ...postInfo,
                  counts: {
                    ...postInfo.counts,
                    likes: postInfo?.counts?.likes + 1,
                  },
                  likes: [...postInfo?.likes, LikeAdd],
                }
              } else {
                return postInfo
              }
            })
            dispatch(loadPostList(updatePostList))
          } else {
            const LikeAdd = {
              media: r?.data?.media,
              createdBy: {
                _id: r?.data?.createdBy,
              },
              is_deleted: false,
              _id: r?.data?._id,
            }
            const updatePostList = post.postList?.data?.map((postInfo: any) => {
              if (postInfo?._id === postId) {
                return {
                  ...postInfo,
                  medias: postInfo?.medias.map((mediasInfo: any) => {
                    if (mediasInfo?._id === r?.data?.media) {
                      return {
                        ...mediasInfo,
                        counts: {
                          ...mediasInfo.counts,
                          likes: mediasInfo?.counts?.likes + 1,
                        },
                        likes: [...mediasInfo?.likes, LikeAdd],
                      }
                    } else {
                      return mediasInfo
                    }
                  }),
                }
              } else {
                return postInfo
              }
            })
            dispatch(loadPostList(updatePostList))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const fundraiserAddLikeThunk: any = (payload: any, pageId: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .PostAddLike(payload)
      .then((r: any) => {
        if (r) {
          /* empty */
        }
        if (r.success) {
          dispatch(updateFundraisersLikeResponse(r))
          dispatch(pageGetAllFundraiserThunk(pageId))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addReportThunk: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .addReport(payload)
      .then((r: any) => {
        if (r) {
          dispatch(loading(false))
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const removePostLikeThunk: any = (
  postId: string,
  likeId: string,
  pageId: string,
  type: string,
  postinfo: string
) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .removePostLike(postId, likeId)
      .then((r: any) => {
        if (r) {
          const { post } = getState()
          if (type === 'post') {
            const updatePostList = post.postList?.data?.map((postInfo: any) => {
              if (postInfo?._id === postId) {
                return {
                  ...postInfo,
                  counts: {
                    ...postInfo.counts,
                    likes: postInfo?.counts?.likes - 1,
                  },
                  likes: postInfo?.likes?.filter((postLikes: any) => postLikes?._id !== likeId),
                }
              } else {
                return postInfo
              }
            })
            dispatch(loadPostList(updatePostList))
          } else {
            const updatePostList = post.postList?.data?.map((postInfo: any) => {
              if (postInfo?._id === postinfo) {
                return {
                  ...postInfo,
                  medias: postInfo?.medias.map((mediasInfo: any) => {
                    if (mediasInfo?._id === postId) {
                      return {
                        ...mediasInfo,
                        counts: {
                          ...mediasInfo.counts,
                          likes: mediasInfo?.counts?.likes - 1,
                        },
                        likes: mediasInfo?.likes?.filter((mediasLikes: any) => mediasLikes?._id !== likeId),
                      }
                    } else {
                      return mediasInfo
                    }
                  }),
                }
              } else {
                return postInfo
              }
            })
            dispatch(loadPostList(updatePostList))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const removeFundraiserLikeThunk: any = (fundraiserId: string, likeId: string, pageId: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .removeFundraisertLike(fundraiserId, likeId)
      .then((r: any) => {
        if (r) {
          /* empty */
        }
        if (r.success) {
          dispatch(updateFundraisersLikeResponse(r))
          dispatch(pageGetAllFundraiserThunk(pageId))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addReactionForCommentThunk: any = (payload: any, postId: string, type: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .addReactionForComment(payload)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(postId))
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addReactionForCommentReplyThunk: any = (payload: any, postId: string, type: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .addReactionForCommentReply(payload)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(postId))
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deleteCommentReactionThunk: any = (reactId: string, postId: string, type: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .deleteCommentReaction(reactId)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(postId))
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
          }
        }
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deleteCommentReplyReactionThunk: any = (reactId: string, postId: string, type: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PostApi(AxiosClass)
      .deleteCommentReplyReaction(reactId)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(postId))
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const editReactionForCommentThunk: any = (reactId: string, name: string, postId: string, type: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .editCommentReaction(reactId, name)
      .then((r: any) => {
        if (r) {
          if (type === 'post') {
            dispatch(getPostsCommentsThunk(postId))
          } else if (type === 'media') {
            dispatch(getPostsMediaCommentsThunk(postId))
          } else if (type === 'fundraiser') {
            dispatch(getfundraiserCommentsThunk(postId))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const postDetails: any = (postId: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .getPostDetails(postId)
      .then((r: any) => {
        if (r.success) {
          dispatch(getPostDetailsResp(r))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const postDetailsLoad: any = (postId: string) => {
  return (dispatch: any, getState: any) => {
    new PostApi(AxiosClass)
      .getPostDetails(postId)
      .then((r: any) => {
        if (r.success) {
          const { post } = getState()
          const getAllPost = [...post?.postList?.data]

          const updatePostList = getAllPost?.map((postInfo: any) => {
            if (postInfo?._id === postId) {
              return {
                ...postInfo,
                medias: r?.data[0]?.medias,
              }
            } else {
              return postInfo
            }
          })
          dispatch(loadPostList(updatePostList))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
