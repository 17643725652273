import './Settings.scss'

import { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import BlockedMembers from './BlockMembers'
import NotificationSettings from './NotificationSettings'
import OwnerDetail from './userDetails'

function Settings() {
  const [activetab, setActiveTab] = useState('ownerdetails')
  const handleTab = (eventKey: any, event: any) => {
    setActiveTab(eventKey)
  }
  return (
    <>
      <Tabs
        defaultActiveKey="ownerdetails"
        id="uncontrolled-tab-example"
        onSelect={handleTab}
        className="mb-3 SettingTab"
      >
        <Tab eventKey="ownerdetails" title="Details">
          {activetab === 'ownerdetails' ? <OwnerDetail /> : <></>}
        </Tab>
        <Tab eventKey="notifications" title="Notifications" className="NotificationSettings">
          {activetab === 'notifications' ? <NotificationSettings /> : <></>}
        </Tab>
        <Tab eventKey="blockedmembers" title="Blocked members" className="BlockedMembers">
          {activetab === 'blockedmembers' ? <BlockedMembers /> : <></>}
        </Tab>
      </Tabs>
    </>
  )
}

export default Settings
