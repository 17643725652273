import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { environment } from '../../AppConfig/environment'

const PublicPageList = () => {
  const navigate = useNavigate()
  const [pages, setPages] = useState([])
  const [letters, setLetters] = useState('abcdefghijklmnopqrstuvwxyz'.split(''))

  const handleLetterClick = async (letter: string) => {
    setLetters('abcdefghijklmnopqrstuvwxyz'.replace(letter, '').split(''))
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${environment.apiEndPoint}page/publicPages/${letter}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    axios(config)
      .then((response: any) => {
        setPages(response.data.data)
      })
      .catch(() => {})
    navigate('/find-pages/' + letter)
  }

  const handlePageClick = (page: any) => {
    navigate(`/${page.unique_url}`)
  }

  const handleBackClick = () => {
    setPages([])
    setLetters('abcdefghijklmnopqrstuvwxyz'.split(''))
    navigate('/find-pages')
  }

  return (
    <div>
      {letters?.length < 26 ? (
        <button onClick={handleBackClick}>Back</button>
      ) : (
        <button
          onClick={() => {
            navigate('/')
          }}
        >
          Home
        </button>
      )}

      {pages.length > 0
        ? pages.map((page: any, i: number) => {
            return (
              <button
                key={i}
                onClick={() => {
                  handlePageClick(page)
                }}
              >
                {page.first_name + ' ' + page.last_name}
              </button>
            )
          })
        : letters.map((letter: any) => (
            <button
              key={letter}
              onClick={() => {
                handleLetterClick(letter)
              }}
            >
              {letter}
            </button>
          ))}
    </div>
  )
}

export default PublicPageList
