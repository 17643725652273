import './SendAudioMessage.scss'

import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ReactMediaRecorder } from 'react-media-recorder'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../../Analytics/GA'
import * as animationData from '../../../Assets/Lottie/audio-lottie.json'
import { useAppSelector } from '../../../Hooks'
import { loading } from '../../../Redux/slices/userSlice'
import { sendAudioMessage, uploadMedia } from '../../../Redux/thunks/pageDetailThunk'
import Loader from '../../Loader/Loader'
import AudioWaves from '../../Shared/AudioWaves'

const SendAudioMessage = (props: any) => {
  const dispatch = useDispatch()
  const pageDetailState: any = useAppSelector(state => state.pagedetail)
  const userState: any = useAppSelector(state => state.user)
  const [activeAudioMsg, setActiveAudioMsg] = useState<any>({
    url: '',
    file: null,
  })

  const OnStop = (blobUrl: string, blob: any) => {
    setActiveAudioMsg({
      file: blob,
      url: blobUrl,
    })
  }

  const SendAudioMsg = () => {
    dispatch(loading(true))
    // uploadMedia({}).
    const playload = {
      page: pageDetailState?.data?._id,
      userId: userState?.value?.data?._id,
      url: '',
      ownerId: pageDetailState?.data?.belongsTo?._id,
    }

    const formData: any = new FormData()
    formData.append('media', activeAudioMsg.file)
    uploadMedia(formData, pageDetailState?.data?._id).then(response => {
      if (response?.data?.data[0]?.url) {
        playload.url = response?.data?.data[0]?.url
        dispatch(sendAudioMessage(playload))
      }
    })
    trackEvent('Page Interaction', 'Private Audio Message Post', `${userState?.value?.data?._id}`)
  }

  useEffect(() => {
    if (pageDetailState?.sendAudioMessageResp?.success === true) {
      dispatch(loading(false))
      props.handleClose('success')
    }
  }, [pageDetailState?.sendAudioMessageResp])

  const showTimer = (status: string) => {
    let timer: any
    const timerSelector: any = document.getElementById('recorder-timing')
    if (timerSelector && status === 'recording') {
      let hou = 0
      let sec = 1
      timer = setInterval(function () {
        timerSelector.innerHTML = ('0' + hou).slice(-2) + ' : ' + ('0' + sec).slice(-2)
        sec++
        if (sec === 60) {
          hou++
          sec = 0
        }
      }, 1000)
    } else {
      clearInterval(timer)
    }
  }

  return (
    <Modal show={props.show} onHide={() => props.handleClose('close')} centered className="AudioRecordingPopup">
      {userState?.loading && <Loader />}
      <Modal.Header closeButton>
        <div className="page-owner-img">
          {pageDetailState?.data?.belongsTo?.image ? (
            <img src={pageDetailState?.data?.belongsTo?.image} alt="" height={65} width={65} />
          ) : (
            <span className="Alpha">{pageDetailState?.data?.belongsTo?.name.charAt(0)}</span>
          )}
          <p>Send personalized audio message to {pageDetailState?.data?.belongsTo?.name}</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className="Audio">
          <ReactMediaRecorder
            audio
            blobPropertyBag={{
              type: 'audio/wav',
            }}
            onStop={OnStop}
            render={({ previewStream, status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
              showTimer(status)
              return (
                <div className="AudioPost">
                  {status !== 'stopped' ? (
                    <div className="AudioPost">
                      {status === 'idle' ? (
                        <div className="UploadSec">
                          <div className="RecSec">
                            <button type="button" onClick={startRecording}>
                              <i className="fa fa-butterflymic"></i> Start Recording
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="UploadSec">
                          <div className="Ripple">
                            <Lottie animationData={animationData} />
                          </div>
                          <div className="RecSec">
                            <span onClick={stopRecording}>
                              <i className="fa fa-stoprecording" aria-hidden="true"></i>
                              Stop Recording
                            </span>
                            <p id="recorder-timing"></p>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="UploadSec">
                      <AudioWaves audioURL={mediaBlobUrl} controls stopAllMedia={() => {}} mediaPlay={false} />
                      <div className="row">
                        <div className="delete text-center">
                          <i
                            className="fa fa-butterflydelete"
                            aria-hidden="true"
                            onClick={() => {
                              setActiveAudioMsg({ url: '', file: null })
                              clearBlobUrl()
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            }}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="delete text-center">
            <Button type="button" disabled={activeAudioMsg?.file === null} onClick={SendAudioMsg}>
              Post
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default SendAudioMessage
