import './LeftSection.scss'

import React from 'react'

export const LeftSection = (props: any) => {
  return (
    <>
      <div className="LeftSection">
        {props.component?.leftView?.map((component: any, index: any) => {
          return <React.Fragment key={index}>{component}</React.Fragment>
        })}
      </div>
    </>
  )
}
