import './../../Modals/AudioPopup/AudioPopup.scss'

import { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'

const AudioWaves = (props: any) => {
  const waveformRef: any = useRef()
  const [, setRemainingTime] = useState<any>('')
  const [audioLoading, setAudioLoading] = useState<any>(true)

  const waveSurferRef: any = useRef({
    isPlaying: () => false,
  })
  const [isPlaying, toggleIsPlaying] = useState(false)

  const waveColors = document.createElement('canvas').getContext('2d')?.createLinearGradient(0, 0, 550, 0)

  waveColors?.addColorStop(0, '#24B183')
  waveColors?.addColorStop(1, '#9b7bf6')

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: waveformRef.current,
      responsive: true,
      barWidth: 1.5,
      barHeight: 2,
      cursorWidth: 0,
      barRadius: 1,
      waveColor: waveColors,
      progressColor: 'rgba(255,255,255,.7)',
      hideScrollbar: true,
      fillParent: true,
      cursorColor: 'green',
    })
    let current
    waveSurfer.on('audioprocess', () => {
      const totalTime = waveSurfer.getDuration()
      current = waveSurfer.getCurrentTime()
      setRemainingTime(totalTime - current)
    })
    waveSurfer.load(props.audioURL)
    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer
      const totalTime = waveSurfer.getDuration()
      setRemainingTime(totalTime)
      setAudioLoading(false)
    })

    waveSurfer.on('loading', function (X: any, evt: any) {
      setAudioLoading(true)
    })

    waveSurfer.on('finish', () => {
      toggleIsPlaying(false)
    })

    return () => {
      waveSurfer.destroy()
    }
  }, [props.audioURL])

  useEffect(() => {
    if (props?.mediaPlay === false && audioLoading === false) {
      waveSurferRef?.current?.pause()
      toggleIsPlaying(false)
    }
  }, [props?.mediaPlay, audioLoading])

  return (
    <div className="row">
      <div className="AudioWaves">
        {audioLoading === true ? <div className="text-center mt-4 audio-file-loading">Loading...</div> : ''}
        <div className="audioWrapper">
          <div className="waves" ref={waveformRef} />

          {/* <div className="remaining-time-sec">
                        <p>{remainingTime ? secondsToTime(remainingTime) : ''}</p>
                    </div> */}
        </div>
      </div>
      <div
        onClick={() => {
          waveSurferRef.current.playPause()
          toggleIsPlaying(waveSurferRef.current.isPlaying())
          props.stopAllMedia()
        }}
        className="audioControl ControllerIcon"
      >
        {isPlaying ? <i className="fa fa-pause"></i> : <i className="fa fa-butterflyaudioplay"></i>}
      </div>
    </div>
  )
}
export default AudioWaves
