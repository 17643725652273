import 'react-datepicker/dist/react-datepicker.css'

import { Autocomplete, useLoadScript } from '@react-google-maps/api'
import { Formik } from 'formik'
import { forwardRef, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
import { ReactMultiEmail } from 'react-multi-email'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { environment } from '../../AppConfig/environment'
import { useAppSelector } from '../../Hooks'
import { addPageAction, updateEventsResponse } from '../../Redux/slices/pageDetailSlice'
import { loading } from '../../Redux/slices/userSlice'
import { updateEvent, uploadMedia } from '../../Redux/thunks/pageDetailThunk'
import RoundedCropper from '../Modals/CropperPopup/RoundedCropper'
import MemberList from './MemberList'

const EventEdit = (props: any) => {
  const { setEventsScreen, event } = props
  const placesLibrary: any = ['places']
  const dispatch = useDispatch()
  const userState: any = useAppSelector(state => state.user)
  const pageDetailState: any = useAppSelector((state: any) => state.pagedetail)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: environment.googleMapApiKey,
    libraries: placesLibrary,
  })
  const [searchResult, setSearchResult] = useState<any>('Result: none')
  const [startDate, setStartDate] = useState(new Date().toString())
  const [addressValue, setAddressValue] = useState(event?.venue)
  const [emails, setEmails] = useState<any>([])
  const [isSelectMember, setIsSelectMember] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [showInvites, setShowInvites] = useState(false)
  const [invitesEmails, setInvitesEmails] = useState([])
  const [endDate, setEndDate] = useState(new Date(Date.now() + 60000 * 60).toString())
  const [img, setImg] = useState<any>({
    url: '',
    file: '',
    selected_img: '',
  })
  const [imgValidation, setImgValidation] = useState('')
  const [showCropper, setShowCropper] = useState(false)

  const filterPassedTime = (time: any) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)
    return currentDate.getTime() < selectedDate.getTime()
  }

  const filterEndPassedTime = (time: any) => {
    const currentDate = new Date(startDate)
    const selectedDate = new Date(time)
    return currentDate.getTime() < selectedDate.getTime()
  }

  const filterStartDate = (time: any) => {
    const currentDate = endDate === '' ? new Date() : new Date(endDate)
    const selectedDate = new Date(time)
    return currentDate.getTime() > selectedDate.getTime() && selectedDate > new Date()
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('name is required'),
    description: Yup.string().required('description is required'),
  })

  const eventSubmit = (data: any) => {
    data.startdate = startDate
    data.enddate = endDate

    const startdate = new Date(data?.startdate)
    const enddate = new Date(data?.enddate)

    const memeberArr: any[] = [...event?.members]

    for (let i = 0; i < emails?.length; i++) {
      memeberArr.push({ email: emails[i] })
    }

    const payload: any = {
      eventId: event?._id,
      user: userState?.value?.data?._id,
      page: pageDetailState?.data?._id,
      name: data?.name,
      latitude: event?.latitude,
      longitude: event?.longitude,
      description: data?.description,
      startDate: data.startdate,
      startTime: ('0' + startdate.getHours()).slice(-2) + ':' + ('0' + startdate.getMinutes()).slice(-2),
      endDate: data.enddate,
      endTime: ('0' + enddate.getHours()).slice(-2) + ':' + ('0' + enddate.getMinutes()).slice(-2),
      venue: addressValue.trim(),
      members: memeberArr,
      image: event?.image,
    }
    dispatch(loading(true))
    if (img?.file && img?.file !== null && img?.file !== '') {
      const formData: any = new FormData()
      formData.append('media', img.file)
      uploadMedia(formData, pageDetailState?.data?._id).then(response => {
        if (response?.data?.data[0]?.url) {
          payload.image = response?.data?.data[0]?.url
          dispatch(updateEvent(payload))
        }
      })
    } else {
      dispatch(updateEvent(payload))
    }
  }

  const openImgWindow = () => {
    const ImgInput: any = document.getElementById('page-image')
    if (ImgInput) {
      ImgInput?.click()
    }
  }

  const typeValidation = (event: any) => {
    setImg((data: any) => {
      return {
        ...data,
        file: '',
      }
    })
    const selectedFile = event.target.files[0]
    const imageFile = selectedFile
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgValidation('Only jpg | jpeg | png images allowed.')
      return false
    }
    setImgValidation('')
    if (event.target.files?.length) {
      const reader: any = new FileReader()
      const file: any = selectedFile
      reader.readAsDataURL(file)
      setImg((data: any) => {
        return { ...data, file }
      })
      reader.onload = () => {
        setImg((data: any) => {
          return { ...data, selected_img: reader?.result }
        })
        setShowCropper(true)
      }
    }
  }

  const CroppedImage = (e: any) => {
    if (e && e !== 'cancel') {
      setIsEdited(true)
      setImg((data: any) => {
        return { ...data, url: URL.createObjectURL(e), file: e }
      })
    }
    setShowCropper(false)
  }

  useEffect(() => {
    const sDate = new Date(event?.startDate)
    const eDate = new Date(event?.endDate)
    const _startDate =
      sDate.getFullYear() + '/' + (sDate.getMonth() + 1) + '/' + sDate.getDate() + ' ' + event?.startTime

    const _endDate = eDate.getFullYear() + '/' + (eDate.getMonth() + 1) + '/' + eDate.getDate() + ' ' + event?.endTime

    setStartDate(_startDate)
    setEndDate(_endDate)

    setImg({ ...img, url: event?.image })

    const invitedList = event?.members?.map((members: any, index: number) => {
      return members.email
    })
    setInvitesEmails(invitedList)
  }, [event])

  useEffect(() => {
    props.changeHeaderTitle('Edit event')
    dispatch(addPageAction('side-panel-top-scroll'))
  }, [])

  useEffect(() => {
    if (pageDetailState?.updateEventsResp?.success === true) {
      dispatch(
        updateEventsResponse({
          ...pageDetailState?.updateEventsResp,
          success: null,
        })
      )
      dispatch(loading(false))
      props?.closeEditEvent({
        ...pageDetailState?.updateEventsResp?.data,
        type: 'update',
      })
    }
  }, [pageDetailState?.updateEventsResp])

  const onLoad = (autocomplete: any) => {
    setSearchResult(autocomplete)
  }

  const onPlaceChanged = () => {
    if (searchResult !== null) {
      setIsEdited(true)
      const place = searchResult.getPlace()
      const name = place.name
      const formattedAddress = place.formatted_address

      const result = formattedAddress.includes(name)
      let lastAddress = formattedAddress
      if (!result) {
        lastAddress = name + ', ' + lastAddress
      }
      setAddressValue(lastAddress)
    }
  }

  const addressSelected = (event: any) => {
    // Access input value
    const inputValue = event.target.value
    setAddressValue(inputValue)
  }

  const selectedMemberList = (memberList: any) => {
    setIsEdited(true)
    setEmails((data: any) => {
      const emailIds: any = []
      memberList?.forEach((e: any) => {
        emailIds.push(e?.user?.email)
      })
      setIsSelectMember(false)
      props.changeHeaderTitle('Edit event')
      return emailIds?.length ? [...data, ...emailIds] : data
    })
  }

  const openMemberList = () => {
    setIsSelectMember(true)
    props.changeHeaderTitle('Select your page members')
  }

  const DatePickerCustomInput = forwardRef(
    ({ value, onClick }: { value: any; onClick: any }, ref: React.LegacyRef<HTMLInputElement>) => (
      <input
        className="date-picker form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder="Select date & time"
        readOnly
      />
    )
  )

  DatePickerCustomInput.displayName = 'DatePickerCustomInput'

  const handleOnChange = () => {
    setIsEdited(true)
  }

  return (
    <>
      <div className={isSelectMember ? 'EventEdit d-none' : 'EventEdit'}>
        <div className={'EventImg'}>
          {img?.url === '' || img?.url === null ? (
            <>
              <div className="ImgWrapper">
                <div className="empty-event-image"></div>
              </div>
              <span className="cropImg" onClick={openImgWindow}>
                <i className="fa fa-camera"></i>
              </span>
            </>
          ) : (
            <>
              <div className="ImgWrapper">
                <img src={img?.url} alt="page-profile-image" />
              </div>
              <span className="cropImg" onClick={openImgWindow}>
                <i className="fa fa-camera"></i>
              </span>
            </>
          )}
          <input type="file" name="image" className="d-none" id="page-image" onChange={typeValidation} />
          <p className="text text-danger errorMessage">{imgValidation}</p>
        </div>
        <div className={'FormSection'}>
          <Formik
            validateOnMount={true}
            validateOnChange={true}
            validationSchema={schema}
            onSubmit={eventSubmit}
            initialValues={{
              name: event?.name,
              description: event?.description,
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
              <Form noValidate onSubmit={handleSubmit} onChange={handleOnChange}>
                {/* Event Name Input */}
                <Form.Label htmlFor="email">
                  Event Name<sup>*</sup>
                </Form.Label>
                <InputGroup className="mb-3" hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Add name to your event"
                    required
                    name="name"
                    maxLength={40}
                    onChange={handleChange}
                    value={values?.name}
                  />
                </InputGroup>

                {/* Event Details Input */}
                <Form.Label htmlFor="details">
                  Event Details<sup>*</sup>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    className="event-textarea"
                    id="details"
                    as="textarea"
                    aria-label="With textarea"
                    placeholder="Your event description"
                    name="description"
                    maxLength={250}
                    onChange={handleChange}
                    value={values?.description}
                  />
                  <span className="text-count">{values?.description.replace(/(\r\n|\n|\r)/g, '').length}/250</span>
                </InputGroup>

                {/* Event Date input */}
                <Row className="mb-4 notMobile">
                  <Col>
                    <Form.Label htmlFor="details">
                      Start Date & Time<sup>*</sup>
                    </Form.Label>
                    <DatePicker
                      selected={new Date(startDate)}
                      onChange={(date: any) => {
                        setStartDate(date)
                        setIsEdited(true)
                      }}
                      showTimeSelect
                      selectsStart
                      filterDate={filterStartDate}
                      startDate={new Date(startDate)}
                      placeholderText="Select date & time"
                      filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                    />
                  </Col>
                  <Col>
                    <Form.Label htmlFor="details">
                      End Date & Time<sup>*</sup>
                    </Form.Label>
                    <DatePicker
                      selected={new Date(endDate)}
                      onChange={(date: any) => {
                        setIsEdited(true)
                        setEndDate(date)
                      }}
                      showTimeSelect
                      minDate={new Date(startDate)}
                      startDate={new Date(startDate)}
                      placeholderText="Select date & time"
                      filterTime={filterEndPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                    />
                  </Col>
                </Row>

                <Row className="mb-4 mobile">
                  <Row className="mb-4">
                    <Form.Label htmlFor="details">
                      Start Date & Time<sup>*</sup>
                    </Form.Label>
                    <DatePicker
                      selected={new Date(startDate)}
                      onChange={(date: any) => {
                        setStartDate(date)
                        setIsEdited(true)
                      }}
                      showTimeSelect
                      selectsStart
                      filterDate={filterStartDate}
                      startDate={new Date(startDate)}
                      placeholderText="Select date & time"
                      filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                    />
                  </Row>
                  <Row>
                    <Form.Label htmlFor="details">
                      End Date & Time<sup>*</sup>
                    </Form.Label>
                    <DatePicker
                      selected={new Date(endDate)}
                      onChange={(date: any) => {
                        setIsEdited(true)
                        setEndDate(date)
                      }}
                      showTimeSelect
                      minDate={new Date(startDate)}
                      startDate={new Date(startDate)}
                      placeholderText="Select date & time"
                      filterTime={filterEndPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      customInput={<DatePickerCustomInput value={undefined} onClick={undefined} />}
                    />
                  </Row>
                </Row>

                {/* Event Venue Input */}
                <Form.Label htmlFor="venue">
                  Venue<sup>*</sup>
                </Form.Label>
                <InputGroup className="mb-3" hasValidation>
                  {isLoaded ? (
                    <Autocomplete className="address-autocomplete" onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                      <input
                        type="text"
                        value={addressValue}
                        placeholder="Add complete address"
                        className="form-control"
                        onChange={addressSelected}
                      />
                    </Autocomplete>
                  ) : (
                    <div>Loading...</div>
                  )}
                </InputGroup>
                {/* Event Details Input */}
                <Form.Label htmlFor="guestMultiEmail" className="guest-label">
                  <div className="add-guest-section">
                    <span>Add Guests</span>
                    {event?.members?.length ? (
                      <span
                        className="show-all-invites"
                        onClick={() => {
                          setShowInvites(!showInvites)
                        }}
                      >{`${showInvites ? `Hide` : `Show`} invites (${event?.members?.length})`}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </Form.Label>
                <ReactMultiEmail
                  className="guest-multi-email"
                  placeholder="Paste or enter guest email"
                  emails={emails}
                  onChange={(_emails: string[]) => {
                    setIsEdited(true)
                    setEmails(_emails)
                  }}
                  getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => {
                            removeEmail(index)
                          }}
                        >
                          ×
                        </span>
                      </div>
                    )
                  }}
                />
                {showInvites && (
                  <>
                    <Form.Label htmlFor="invitedguestMultiEmail">
                      <div className="add-guest-section">
                        <span>Invited Guests</span>
                      </div>
                    </Form.Label>
                    <ReactMultiEmail
                      className="guest-multi-email invited-guests-list"
                      placeholder="Paste or enter guest email"
                      emails={invitesEmails}
                      autoFocus={false}
                      getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                          </div>
                        )
                      }}
                    />
                  </>
                )}
                <span onClick={openMemberList} className="MemberSelect">
                  <i className="fa fa-membersicon"></i>
                  Select your page members
                </span>
                <div className="d-flex">
                  <button
                    type="button"
                    onClick={() => props?.closeEditEvent({ type: 'cancel' })}
                    id="edit-event-cancel"
                    className="cancelCta"
                  >
                    Cancel
                  </button>

                  <button
                    disabled={
                      !isValid ||
                      !isEdited ||
                      startDate === '' ||
                      startDate === null ||
                      endDate === '' ||
                      endDate === null ||
                      addressValue.trim() === ''
                    }
                    type="submit"
                  >
                    Save event
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {showCropper ? (
        <RoundedCropper
          show={showCropper}
          selectedImg={img.selected_img}
          setCroppedImage={CroppedImage}
          croppershap={'rect'}
        ></RoundedCropper>
      ) : (
        ''
      )}
      {isSelectMember ? (
        <MemberList
          setEventsScreen={setEventsScreen}
          selectedMemberList={selectedMemberList}
          type={`edit`}
          invitedMembers={invitesEmails}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default EventEdit
