import './EditPage.scss'

import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { butterflyBlack, parseURLParams } from '../../commonservice'
import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { editPageResponse, loadPageDetail } from '../../Redux/slices/pageDetailSlice'
import { loading } from '../../Redux/slices/userSlice'
import {
  editPageDetailThunk,
  pageDetailThunk,
  pageURLCheckValdation,
  uploadMedia,
} from '../../Redux/thunks/pageDetailThunk'
import Loader from '../Loader/Loader'
import RoundedCropper from '../Modals/CropperPopup/RoundedCropper'

const EditPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [editpageData, setEditPageData] = useState<any>({})
  const [image, selectedImage] = useState<any>('')
  const [urlError, setUrlError] = useState<string>('')
  const [, setDisplaySelectedImage] = useState<any>('')
  const [showCropper, setShowCropper] = useState(false)
  const [imgValidation, setImgValidation] = useState('')
  const [defaultActiveTab, setDefaultActiveTab] = useState('pageinfo')
  const [isUrlUpdate, setIsUrlUpdate] = useState(false)
  const [approvalRequired, setApprovalRequired] = useState<any>()

  const [img, setImg] = useState<any>({
    url: '',
    file: '',
    selected_img: '',
  })
  const [saveText, setSaveText] = useState('Save')

  const pageDetailState: any = useAppSelector((state: any) => state.pagedetail)
  const userState: any = useAppSelector(state => state.user)

  useEffect(() => {
    if (pageDetailState?.data?.first_name) {
      setApprovalRequired(pageDetailState?.data?.approval_required)
      setEditPageData(pageDetailState?.data)
      setDisplaySelectedImage(pageDetailState?.data?.image)
      setImg((data: any) => {
        return {
          ...data,
          url: pageDetailState?.data?.image_cropped,
        }
      })

      const href: any = window.location.pathname
      const urlSegments: any = href.match(/([^/]*)\/*$/)
      const urlParams: any = parseURLParams(window.location.href)

      if (
        urlSegments.length === 2 &&
        pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id &&
        urlParams?.from?.length &&
        urlParams?.from[0]
      ) {
        if (urlParams?.from[0] === 'privacy_settings') {
          setDefaultActiveTab('privacysetting')
        }
        if (urlParams?.from[0] === 'approval_settings') {
          setDefaultActiveTab('approvalsetting')
        }
      }
    }
  }, [pageDetailState?.data])

  useEffect(() => {
    if (pageDetailState?.editPageResponse?.success === true) {
      setSaveText('Saved')
      dispatch(pageDetailThunk(pageDetailState?.data?.unique_url, userState?.value?.data?._id))
      localStorage.setItem('FromEditPage', 'true')
      if (isUrlUpdate) {
        navigate(RouteConstants.PAGEDETAIL?.replace(':pageslug', pageDetailState?.data?.unique_url))
        setIsUrlUpdate(false)
      }
      dispatch(
        editPageResponse({
          message: pageDetailState?.editPageResponse?.message,
          success: null,
        })
      )
    }
  }, [pageDetailState?.editPageResponse])

  const pageSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('first name is required')
      .min(3, 'First name should be atleast 3 characters')
      .matches(/^[a-zA-Z'"]/, 'First letter must be alphabet'),
    last_name: Yup.string().matches(/^[a-zA-Z'"]/, 'First letter must be alphabet'),
    reason: Yup.string().required().max(250),
  })

  const pageUrlSchema = Yup.object().shape({
    checkURL: Yup.boolean(),
    unique_url: Yup.string()
      .required('unique url is required')
      .min(1, 'Unique url must be at least 1 character')
      .matches(/^[a-z0-9]+$/i, 'Only letters and numbers are allowed'),
  })

  const privacyStatusSchema = Yup.object().shape({
    privacy_status: Yup.string(),
  })

  const editPageTabSubmit: any = (data: any) => {
    dispatch(loading(true))

    data.first_name = data?.first_name !== '' ? capitalize(data?.first_name) : ''
    data.last_name = data?.last_name !== '' ? capitalize(data?.last_name) : ''

    let data1: any = data
    data.page_id = editpageData._id
    data.belongsTo = userState?.value?.data?._id

    if (image) {
      setSaveText('Saving')
      const formData: any = new FormData()
      formData.append('media', image)
      if (img?.file) {
        formData.append('media', img?.file)
      }
      uploadMedia(formData, editpageData?._id).then(response => {
        if (response?.data?.data[0]?.url) {
          data.image = response?.data?.data[0]?.url
          data.image_cropped = response?.data?.data[1]?.url
          data1 = data
          data.type = 'page_update'
          setImg({ ...img, file: '' })
          dispatch(editPageDetailThunk(data))
          delete data1.belongsTo
          dispatch(loadPageDetail({ ...editpageData, ...data1 }))
        }
      })
    } else {
      data.image = image || editpageData?.image
      data.type = 'page_update'
      setSaveText('Saving')
      dispatch(editPageDetailThunk(data))
      delete data1.belongsTo
      dispatch(loadPageDetail({ ...editpageData, ...data1 }))
    }
  }

  const editPageURLSubmit: any = (data: any) => {
    const data1: any = data
    data.page_id = editpageData._id
    data.belongsTo = userState?.value?.data?._id
    data.type = 'url_update'
    setSaveText('Saving')
    dispatch(editPageDetailThunk(data))
    delete data1.belongsTo
    setIsUrlUpdate(true)
    dispatch(loadPageDetail({ ...editpageData, ...data1 }))
  }

  const editPagePrivacyStatusSubmit: any = (data: any) => {
    const data1: any = data
    data.page_id = editpageData._id
    data.belongsTo = userState?.value?.data?._id
    data.type = 'privacy_update'
    setSaveText('Saving')
    dispatch(editPageDetailThunk(data))
    delete data1.belongsTo
    dispatch(loadPageDetail({ ...editpageData, ...data1 }))
  }

  const editpageApprovalReqSubmit: any = (data: any) => {
    data.approval_required = !(data.approvalRequired === 0 || data.approvalRequired === false)
    const data1: any = data
    data.page_id = editpageData?._id
    data.belongsTo = userState?.value?.data?._id
    data.type = 'setting_update'
    setSaveText('Saving')
    dispatch(editPageDetailThunk(data))
    delete data1.belongsTo
    dispatch(loadPageDetail({ ...editpageData, ...data1 }))
  }

  const openImgWindow = () => {
    const ImgInput: any = document.getElementById('page-image')
    if (ImgInput) {
      ImgInput?.click()
    }
  }

  const urlValidation = (e: any) => {
    setUrlError(' ')
    pageURLCheckValdation(e?.target?.value).then((r: any) => {
      //  dispatch(loading(false));
      if (r.message === 'Already Exist') {
        setUrlError('Url already exist')
      } else {
        setUrlError('')
      }
    })
  }

  const typeValidation = (event: any) => {
    setImg((data: any) => {
      return {
        ...data,
        file: '',
      }
    })
    const selectedFile = event.target.files[0]
    const imageFile = selectedFile
    if (!imageFile.name.match(/\.(jpg|jpeg|png|webp)$/)) {
      setImgValidation('Only jpg | jpeg | png | webp images allowed.')
      return false
    }
    setImgValidation('')
    if (event.target.files?.length) {
      selectedImage(event?.target.files[0])
      setDisplaySelectedImage(URL.createObjectURL(event?.target?.files[0]))

      const reader: any = new FileReader()
      const file: any = selectedFile
      reader.readAsDataURL(file)
      setImg((data: any) => {
        return { ...data, file }
      })
      reader.onload = () => {
        setImg((data: any) => {
          return { ...data, selected_img: reader?.result }
        })
        setShowCropper(true)
      }
    }
  }

  const CroppedImage = (e: any) => {
    if (e && e !== 'cancel') {
      setImg((data: any) => {
        return { ...data, url: URL.createObjectURL(e), file: e }
      })
    }
    setShowCropper(false)
  }

  const capitalize = (s: string) => {
    return s[0]?.toUpperCase() + s?.slice(1)
  }

  return editpageData?.first_name ? (
    <>
      {userState.loading && <Loader />}
      <Tabs defaultActiveKey={defaultActiveTab} id="uncontrolled-tab-example" className="mb-3 EditTab">
        <Tab eventKey="pageinfo" title="Page Info" className="pageinfo">
          <Formik
            validateOnMount={true}
            validateOnChange={true}
            validationSchema={pageSchema}
            onSubmit={editPageTabSubmit}
            initialValues={{
              first_name: editpageData.first_name,
              last_name: editpageData.last_name,
              reason: editpageData.reason,
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                onChange={() => {
                  setSaveText('Save')
                }}
              >
                <div className="Wrapper">
                  <div className="formHolder">
                    <div className="d-xs-block d-md-none m-logo">
                      <img src={butterflyBlack} alt="Butterfly logo" />
                      <div className="title">A Full Life Deserves a Full Tribute</div>
                    </div>
                    <div className="stepWrapper">
                      <div className="steps stepOne">
                        <div className="ImgCroper">
                          {img?.url === '' || img?.url === null ? (
                            <span onClick={openImgWindow} className="NoImgWrap">
                              <i className="fa fa-camera noImg"></i>Add a page image
                            </span>
                          ) : (
                            <>
                              <div className="ImgWrapper">
                                <img src={img?.url} alt="page-profile-image" />
                              </div>
                              <span className="cropImg" onClick={openImgWindow}>
                                <i className="fa fa-camera"></i>
                              </span>
                            </>
                          )}

                          <input
                            type="file"
                            name="image"
                            className="d-none"
                            id="page-image"
                            onChange={typeValidation}
                          />
                          <p className="text text-danger errorMessage">{imgValidation}</p>
                        </div>
                        <Row className="mt-4">
                          <Form.Group as={Col} lg={6} className="mb-4 mb-lg-0" controlId="formGridFname">
                            <Form.Label>
                              First Name<sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              name="first_name"
                              type="text"
                              required
                              value={values.first_name}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group as={Col} lg={6} controlId="formGrid">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              name="last_name"
                              type="text"
                              required
                              value={values.last_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Row>
                        <Row className=" mt-4 mb-4">
                          <Form.Group>
                            <Form.Label>
                              Your page description<sup>*</sup>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              id="page_reason"
                              placeholder="Tell audiance why you are creating this page and this will be your welcome message to all"
                              name="reason"
                              required
                              maxLength={250}
                              value={values.reason}
                              onChange={handleChange}
                              onInput={(e: any) => {
                                if (e?.target?.value?.length > 250) {
                                  const pageReason: any = document.getElementById('page_reason')
                                  pageReason.value = e?.target?.value.slice(0, 250)
                                }
                              }}
                            />
                            <span className="textcount">{values?.reason?.replace(/(\r\n|\n|\r)/g, '').length}/250</span>
                          </Form.Group>
                        </Row>
                        <div className="mobile-alignment">
                          <Button
                            type="submit"
                            disabled={
                              !isValid ||
                              (values.first_name === pageDetailState?.data?.first_name &&
                                values.last_name === pageDetailState?.data?.last_name &&
                                values.reason === pageDetailState?.data?.reason &&
                                img?.file === '')
                            }
                          >
                            {saveText}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Tab>

        <Tab eventKey="pageurl" title="Page Url" className="pageurl">
          <Formik
            validateOnMount={true}
            validateOnChange={true}
            validationSchema={pageUrlSchema}
            onSubmit={editPageURLSubmit}
            initialValues={{
              unique_url: editpageData.unique_url,
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setValues }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="Wrapper">
                  <div className="formHolder">
                    <div className="d-xs-block d-md-none m-logo">
                      <img src={butterflyBlack} alt="Butterfly logo" />
                      <div className="title">A Full Life Deserves a Full Tribute</div>
                    </div>
                    <div className="stepWrapper">
                      <div className="steps stepUrl">
                        <h3>This is the creative part. Name your page!</h3>
                        <p>
                          It can be short and sweet. It can be longer and intriguing. This is unique to your page only.
                        </p>
                        <span className="url">butterfly.co/{pageDetailState?.data?.unique_url}</span>
                        <input
                          type="text"
                          placeholder="Enter new url"
                          name="unique_url"
                          value={values.unique_url}
                          onChange={(e: any) => {
                            handleChange(e)
                            setUrlError('')
                            if (e.target.value !== pageDetailState?.data?.unique_url) {
                              urlValidation(e)
                            }
                          }}
                          onBlur={e => {}}
                        />
                        <p className="text text-danger">
                          {errors.unique_url === 'Only letters and numbers are allowed' ? errors.unique_url : ''}
                        </p>
                        <p className="text text-danger">{urlError}</p>
                        <div className="mobile-alignment">
                          <Button
                            type="submit"
                            disabled={
                              !isValid || urlError !== '' || values.unique_url === pageDetailState?.data?.unique_url
                            }
                          >
                            {saveText}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Tab>

        <Tab eventKey="privacysetting" title="Privacy Settings" className="privacysetting">
          <Formik
            validateOnMount={true}
            validateOnChange={true}
            validationSchema={privacyStatusSchema}
            onSubmit={editPagePrivacyStatusSubmit}
            initialValues={{
              privacy_status: editpageData.privacy_status,
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="Wrapper">
                  <div className="formHolder">
                    <div className="stepWrapper">
                      <div className="steps stepTwo">
                        <h2>Privacy settings for your page</h2>
                        <p>These settings can be updated at any time</p>
                        <div className="privacy">
                          <Card>
                            <Card.Body className="d-flex p-0">
                              <div className="lhs">
                                <div className="title">
                                  <i className="fa fa-butterflyeye" aria-hidden="true"></i>
                                  <span>Public</span>
                                </div>
                                <p className="subTitle">This page can be viewed and shared by anyone.</p>
                              </div>
                              <div className="rhs">
                                <label className="switch switch200">
                                  <input
                                    type="radio"
                                    id="public"
                                    name="privacy_status"
                                    value="PUBLIC"
                                    onChange={handleChange}
                                    checked={values.privacy_status === 'PUBLIC'}
                                  />
                                  <span className="slider slider200"></span>
                                </label>
                              </div>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body className="d-flex p-0">
                              <div className="lhs">
                                <div className="title">
                                  <i className="fa fa-butterflylock2" aria-hidden="true"></i>
                                  <span>Private</span>
                                </div>
                                <p className="subTitle">
                                  All viewers must be approved by you to see the contents of this page.
                                </p>
                              </div>
                              <div className="rhs">
                                <label className="switch switch200">
                                  <input
                                    type="radio"
                                    id="private"
                                    name="privacy_status"
                                    value="PRIVATE"
                                    onChange={handleChange}
                                    checked={values.privacy_status === 'PRIVATE'}
                                  />
                                  <span className="slider slider200"></span>
                                </label>
                              </div>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Body className="d-flex p-0">
                              <div className="lhs">
                                <div className="title">
                                  <i className="fa fa-butterflyeyehidden" aria-hidden="true"></i>
                                  <span>Hidden</span>
                                </div>
                                <p className="subTitle">This page is visible by invite only.</p>
                              </div>
                              <div className="rhs">
                                <label className="switch switch200">
                                  <input
                                    type="radio"
                                    id="hidden"
                                    name="privacy_status"
                                    value="HIDDEN"
                                    onChange={handleChange}
                                    checked={values.privacy_status === 'HIDDEN'}
                                  />
                                  <span className="slider slider200"></span>
                                </label>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="justify-content-between d-flex">
                          <Button
                            type="submit"
                            disabled={values.privacy_status === pageDetailState?.data?.privacy_status}
                          >
                            {saveText}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Tab>

        <Tab eventKey="approvalsetting" title="Approval Settings" className="approvalsetting">
          <form className="">
            <div className="Wrapper">
              <div className="formHolder">
                <div className="stepWrapper">
                  <div className="steps stepTwo stepThree">
                    <h2>Confirm these approval settings or your page.</h2>
                    <p>If they’re OK, don’t do anything. They can be updated at any time.</p>
                    <div className="privacy">
                      <Card>
                        <Card.Body className="d-flex p-0">
                          <div className="lhs">
                            <div className="title">
                              <i className="fa fa-butterflynoapproval" aria-hidden="true"></i>
                              <span>Approvals required</span>
                            </div>
                            <p className="subTitle">
                              When turned on, member posts must be approved by you before they appear on this Page.
                            </p>
                          </div>
                          <div className="rhs">
                            <label className="switch switch200" htmlFor="approvalRequired_true">
                              <input
                                id="approvalRequired_true"
                                type="radio"
                                checked={approvalRequired}
                                onChange={(e: any) => {
                                  setApprovalRequired(!approvalRequired)
                                }}
                                onClick={(e: any) => {
                                  setApprovalRequired(!approvalRequired)
                                }}
                              />
                              <span className="slider slider200"></span>
                            </label>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="justify-content-between d-flex">
                      <Button
                        type="button"
                        disabled={approvalRequired === pageDetailState?.data?.approvalRequired}
                        onClick={() => {
                          editpageApprovalReqSubmit({ approvalRequired })
                        }}
                      >
                        {saveText}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Tab>
      </Tabs>
      {showCropper ? (
        <RoundedCropper
          show={showCropper}
          selectedImg={img.selected_img}
          setCroppedImage={CroppedImage}
          croppershap={'rect'}
        ></RoundedCropper>
      ) : (
        ''
      )}
    </>
  ) : (
    <></>
  )
}

export default EditPage
