import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '../../Hooks'
import { updateNotifcationSettingsResponse } from '../../Redux/slices/settingsSlice'
import { loading } from '../../Redux/slices/userSlice'
import { getnotificationSettingsThunk, updatenotificationSettingsThunk } from '../../Redux/thunks/settingsThunk'
import Loader from '../Loader/Loader'

const NotificationSettings = () => {
  const dispatch = useDispatch()
  const userState = useAppSelector((state: any) => state?.user)
  const settingsState = useAppSelector((state: any) => state?.settings)
  const [notificationList, setNotificationList] = useState<any>(undefined)
  const [recentlyUpdated, setRecentlyUpdated] = useState<any>({})

  useEffect(() => {
    dispatch(getnotificationSettingsThunk(userState?.value?.data?._id))
  }, [])

  useEffect(() => {
    if (settingsState.notificationSettingsListResp) {
      dispatch(loading(false))
      setNotificationList(settingsState.notificationSettingsListResp)
    }
  }, [settingsState.notificationSettingsListResp])

  const changeNotificationSettings = (type: string, value: boolean) => {
    const payload: any = {
      notificationId: notificationList?._id,
      belongsTo: userState?.value?.data?._id,
    }
    payload[type] = !value
    const status: any = {}
    status[type] = !value
    setRecentlyUpdated(status)
    dispatch(loading(true))
    dispatch(updatenotificationSettingsThunk(payload))
  }

  useEffect(() => {
    if (settingsState?.updateNotifcationSettingsResp?.success === true) {
      setNotificationList({ ...notificationList, ...recentlyUpdated })
      setRecentlyUpdated({})
      dispatch(updateNotifcationSettingsResponse({ ...settingsState?.updateNotifcationSettingsResp, success: null }))
      dispatch(loading(false))
    }
    dispatch(loading(false))
  }, [settingsState?.updateNotifcationSettingsResp?.success])

  return (
    <Form>
      {(userState.loading || settingsState.notificationSettingsListResp === undefined) && <Loader />}
      {notificationList !== undefined ? (
        <div className="privacy">
          <h3>My Pages</h3>
          <Card>
            <Card.Body className="d-flex p-0">
              <div className="lhs">
                <p className="subTitle">Someone requested access to your Private Page.</p>
              </div>
              <div className="rhs">
                <label className="switch switch200 default-set">
                  <input
                    type="checkbox"
                    onClick={() => {
                      changeNotificationSettings('myPrivatePage', notificationList?.myPrivatePage)
                    }}
                    checked={true}
                    disabled={true}
                  />
                  <span className="slider slider200"></span>
                </label>
              </div>
            </Card.Body>
            <Card.Body className="d-flex p-0">
              <div className="lhs">
                <p className="subTitle">Approval for Feed, Gallery, or Questions</p>
              </div>
              <div className="rhs">
                <label className="switch switch200 default-set">
                  <input
                    type="checkbox"
                    onClick={() => {
                      changeNotificationSettings('myFeedApproval', notificationList?.myFeedApproval)
                    }}
                    checked={true}
                    disabled={true}
                  />
                  <span className="slider slider200"></span>
                </label>
              </div>
            </Card.Body>
            <Card.Body className="d-flex p-0">
              <div className="lhs">
                <p className="subTitle">Someone joined your page.</p>
              </div>
              <div className="rhs">
                <label className="switch switch200">
                  <input
                    type="checkbox"
                    onClick={() => {
                      changeNotificationSettings('mySubscriptionPage', notificationList?.mySubscriptionPage)
                    }}
                    checked={notificationList?.mySubscriptionPage}
                  />
                  <span className="slider slider200"></span>
                </label>
              </div>
            </Card.Body>
          </Card>
          <h3>Joined Pages</h3>
          <Card>
            <Card.Body className="d-flex p-0">
              <div className="lhs">
                <p className="subTitle">Your page access request has been approved or declined.</p>
              </div>
              <div className="rhs">
                <label className="switch switch200">
                  <input
                    type="checkbox"
                    onClick={() => {
                      changeNotificationSettings('subscribeRequestAccess', notificationList?.subscribeRequestAccess)
                    }}
                    checked={notificationList?.subscribeRequestAccess}
                  />
                  <span className="slider slider200"></span>
                </label>
              </div>
            </Card.Body>
            <Card.Body className="d-flex p-0">
              <div className="lhs">
                <p className="subTitle">Approval for Feed, Gallery, or Questions</p>
              </div>
              <div className="rhs">
                <label className="switch switch200">
                  <input
                    type="checkbox"
                    onClick={() => {
                      changeNotificationSettings('subscribeFeedApproval', notificationList?.subscribeFeedApproval)
                    }}
                    checked={notificationList?.subscribeFeedApproval}
                  />
                  <span className="slider slider200"></span>
                </label>
              </div>
            </Card.Body>
          </Card>
        </div>
      ) : (
        ''
      )}
    </Form>
  )
}
export default NotificationSettings
