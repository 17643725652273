import './Search.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { trackEvent } from '../../Analytics/GA'
import { createdBytimeSince } from '../../commonservice'
import { useAppSelector } from '../../Hooks'
import {
  addPageAction,
  addSearchPage,
  loadPageDetail,
  type pageDetail,
  setActiveSideSlug,
} from '../../Redux/slices/pageDetailSlice'
import { allowPostPagination, loadPostList } from '../../Redux/slices/post'
import { loading } from '../../Redux/slices/userSlice'
import { searchPageThunk, subScribePage, unsubScribePage } from '../../Redux/thunks/pageDetailThunk'
import Loader from '../Loader/Loader'

const Search = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const userData = useAppSelector((state: any) => state?.user)
  const pageData = useAppSelector((state: any) => state?.pagedetail)
  const [searchData, setSearchData] = useState<any>([])
  const [showMoreLess] = useState(false)
  const pageDetailState: pageDetail = useAppSelector((state: any) => state.pagedetail)
  const pageAction = pageDetailState?.pageAction

  useEffect(() => {
    setSearchData(pageData?.searchPage)
  }, [pageData?.searchPage])

  useEffect(() => {
    return () => {
      setSearchData([])
      dispatch(addSearchPage([]))
    }
  }, [])

  const searchtext = (event: any) => {
    const searchQuery = event.target.value
    setSearch(searchQuery)
    if (searchQuery.trim().length > 2) {
      const payLoad = {
        userId: userData?.value?.data?._id,
        searchKey: searchQuery.trim(),
      }
      dispatch(addPageAction('search-started'))
      dispatch(searchPageThunk(payLoad))
    } else if (searchQuery.trim() === '') {
      setSearchData([])
      dispatch(addSearchPage([]))
    }
  }

  const subscribePage = (pagelist: any, e: any) => {
    e.stopPropagation()
    const searchpayLoad = {
      userId: userData?.value?.data?._id,
      searchKey: search,
    }
    if (pagelist?.subscriptionStatus === '') {
      dispatch(loading(true))
      dispatch(
        subScribePage(
          {
            pageId: pagelist?._id,
            userId: userData?.value?.data?._id,
          },
          true,
          searchpayLoad
        )
      )
    } else if (pagelist?.subscriptionStatus === 'APPROVED') {
      dispatch(loading(true))
      dispatch(
        unsubScribePage(
          {
            pageId: pagelist?._id,
            userId: userData?.value?.data?._id,
          },
          true,
          searchpayLoad
        )
      )
    }
  }

  return (
    <>
      {userData.loading && <Loader />}
      <div className="search-text">
        Lots of people are creating Butterfly pages for the people they care about and the moments that matter.
      </div>
      <div className="searchInput">
        <i className="fa fa-butterflysearch"></i>
        <label htmlFor="">Search here to find the page you are looking for</label>
        <input value={search} onChange={searchtext} onBlur={searchtext} placeholder="" />
      </div>
      <div className="wrapper">
        {searchData?.length && search.trim() ? (
          <>
            {searchData?.map((pagelist: any, index: number) => {
              return (
                <div
                  key={index}
                  className="card"
                  onClick={() => {
                    dispatch(setActiveSideSlug(''))
                    dispatch(loadPageDetail([]))
                    dispatch(loadPostList([]))
                    dispatch(allowPostPagination(false))
                    localStorage.setItem('fromSearch', '1')
                    navigate(`/${pagelist?.unique_url}`)
                  }}
                >
                  <div className="ImgHolder">
                    <div className="ImgWrapper">
                      {pagelist.image_cropped ? <img className="card-img-top" src={pagelist?.image_cropped} /> : <></>}
                      <div className="overlay"></div>

                      <div className="BottomSection">
                        <p>{`${pagelist?.first_name} ${pagelist?.last_name ?? ''}`}</p>
                        <div className="BottomIcons">
                          {pagelist?.privacy_status === 'PUBLIC' ? (
                            <span className="privacy">
                              <i className="fa fa-butterflyeye" aria-hidden="true"></i>
                              Public
                            </span>
                          ) : pagelist?.privacy_status === 'PRIVATE' ? (
                            <span className="privacy">
                              <i className="fa fa-butterflylock2" aria-hidden="true"></i>
                              Private
                            </span>
                          ) : pagelist?.privacy_status === 'HIDDEN' ? (
                            <span className="privacy">
                              <i className="fa fa-butterflyeyehidden" aria-hidden="true"></i>
                              Hidden
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="members">
                            <i className="fa fa-membersicon"></i> {pagelist?.members?.length + 1} Members
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card__body">
                    {showMoreLess && pagelist?.reason?.length > 90 ? (
                      <p>{pagelist?.reason}</p>
                    ) : !showMoreLess && pagelist?.reason?.length > 90 ? (
                      <p>
                        {pagelist?.reason
                          ?.toString()
                          ?.substr(0, 90)
                          .substr(
                            0,
                            Math.min(
                              pagelist?.reason?.toString()?.substr(0, 90).length,
                              pagelist?.reason?.toString()?.substr(0, 90).lastIndexOf(' ')
                            )
                          )}{' '}
                        {'...'}
                      </p>
                    ) : (
                      <p>
                        {pagelist?.reason?.toString()?.substring(0, 90)}
                        &nbsp;
                      </p>
                    )}

                    <div className="owner">
                      <div className="ownerPic">
                        {pagelist?.belongsTo?.image && pagelist?.belongsTo?.image !== null ? (
                          <img src={pagelist?.belongsTo?.image} alt={pagelist?.belongsTo?.name} />
                        ) : (
                          <div className="ownerAlpha">
                            <span>{pagelist?.belongsTo?.name?.charAt(0)}</span>
                          </div>
                        )}
                      </div>
                      <div className="ownerName">
                        Created by {pagelist?.belongsTo?.name}
                        <span className="created_at">{createdBytimeSince(pagelist?.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card__footer">
                    <div>
                      {pagelist?.belongsTo?._id === userData?.value?.data?._id ? (
                        <div className="subscribe-button active">
                          <i className="fa fa-butterflysubscribed"></i>
                          <span>Owner</span>
                        </div>
                      ) : (
                        <>
                          {pagelist?.subscriptionStatus === 'APPROVED' ? (
                            <div
                              className="subscribe-button"
                              onClick={(e: any) => {
                                subscribePage(pagelist, e)
                              }}
                            >
                              <i className="fa fa-subscribed subscribed"></i>
                              <span>Joined</span>
                            </div>
                          ) : pagelist?.subscriptionStatus === 'PENDING' ? (
                            <div className="subscribe-button">
                              <i className="fa fa-subscribed peding"></i>
                              <span>Request Pending</span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                    <button
                      className="card__btn"
                      onClick={() => {
                        dispatch(setActiveSideSlug(''))
                        dispatch(loadPageDetail([]))
                        dispatch(loadPostList([]))
                        dispatch(allowPostPagination(false))
                        localStorage.setItem('fromSearch', '1')
                        navigate(`/${pagelist?.unique_url}`)
                        trackEvent('Site Interaction', 'Page View From Search', `${pagelist?.unique_url}`)
                      }}
                    >
                      View page
                    </button>
                  </div>
                </div>
              )
            })}
          </>
        ) : search.trim() && pageAction === '' ? (
          <div className="no-result">0 search results</div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default Search
