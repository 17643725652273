import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  updateUserDetailsResp: {},
  Blockmembers: [],
}
export const settingsSlice: any = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateUserDetail: (state, action) => {
      state.updateUserDetailsResp = action.payload
    },
    changePasswordResponse: (state, action) => {
      state.changePWResp = action.payload
    },
    getBlockmembers: (state, action) => {
      state.Blockmembers = action.payload
    },
    notificationSettingsListResponse: (state, action) => {
      state.notificationSettingsListResp = action.payload
    },
    updateNotifcationSettingsResponse: (state, action) => {
      state.updateNotifcationSettingsResp = action.payload
    },
    changePaymentPlanResponse: (state, action) => {
      state.changePaymentPlanResp = action.payload
    },
    getPlanDetailResponse: (state, action) => {
      state.getPlanDetailResp = action.payload
    },
  },
})

export const {
  updateUserDetail,
  changePasswordResponse,
  getBlockmembers,
  notificationSettingsListResponse,
  updateNotifcationSettingsResponse,
  changePaymentPlanResponse,
  getPlanDetailResponse,
} = settingsSlice.actions
export default settingsSlice.reducer
