import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import AudioMessage from '../Components/AudioMessage/AudioMessage'
import FeaturesNav from '../Components/FeaturesNav/FeaturesNav'
import Footer from '../Components/Footer/Footer'
import PinedFundraiser from '../Components/Fundraiser/PinedFundraiser'
import MessageBoard from '../Components/MessageBoard/MessageBoard'
import { PageCard } from '../Components/PageCard'
import CreatePostSec from '../Components/PageDetails/createPost/index'
import { RouteConstants } from '../Constants/routeConstants'
import ErrorBoundary from '../ErrorBoundary'
import { useAppSelector } from '../Hooks'
import { useAuth } from '../Hooks/useAuth'
import withLayout from '../Layouts/withLayout'
import { setAddNewVisitorResponse } from '../Redux/slices/userSlice'
import { addNewVisitor } from '../Redux/thunks'
import PrivateRoute from './PrivateRoute'
import { RoutesConfig } from './RoutesConfig'

// TODO - Need to automatically map the routes from config
export const CustomRoutes = () => {
  const dispatch = useDispatch()
  const authState = useAuth()
  const appState = useAppSelector(state => state.metadata)
  const [errorState, setErrorState] = useState(null)
  const userState = useAppSelector(state => state.user)

  useEffect(() => {
    setErrorState(appState.error)
  }, [appState.error])

  useEffect(() => {
    // Visitor API call
    let sessionId = sessionStorage.getItem('session_id')
    if (!sessionId) {
      sessionId = uuidv4()
      sessionStorage.setItem('session_id', `${sessionId}`)
      const payload = { visitorId: sessionId }
      dispatch(addNewVisitor(payload))
    }
  }, [])

  useEffect(() => {
    if (userState?.addNewVisitorResponse?.status === true) {
      dispatch(setAddNewVisitorResponse({ status: null, message: '' }))
    }
  }, [userState?.addNewVisitorResponse])

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          {/* ------ Public Route ----- */}
          {RoutesConfig.filter((f: any) => f.isPrivate === false).map((r, key) => {
            return (
              <Route
                element={
                  r.path === RouteConstants.PAGEDETAIL ? (
                    withLayout(
                      {
                        leftView: [<PageCard key={'lv1-' + key} />, <FeaturesNav key={'lv2-' + key} />],
                        midView: [<CreatePostSec key={'mv1-' + key} />],
                        rightView: [
                          <div key={'rv1-' + key} className="SectionWrapper">
                            <PinedFundraiser key={'1c82-' + key} />
                            <MessageBoard key={'1c56' + key} type={'message'} />
                            <AudioMessage key={'1c83' + key} />
                          </div>,
                          <Footer isdetailed={true} key="footer" />,
                        ],
                      },
                      {
                        auth: authState,
                        appState: errorState,
                        data: userState,
                      }
                    )
                  ) : r.path === RouteConstants.LINKEXPIRED ? (
                    withLayout(
                      {
                        allView: r.component,
                      },
                      {
                        auth: authState,
                        appState: errorState,
                        data: userState,
                      }
                    )
                  ) : (
                    <r.component key={'def-' + key} />
                  )
                }
                path={r.path}
                key={'main-' + key}
              ></Route>
            )
          })}
          {/* ------ Public Route ----- */}
          {/* ------ Private Route ----- */}
          <Route path="/" element={<PrivateRoute />}>
            {RoutesConfig.filter((f: any) => f.isPrivate === true).map((r, key) => {
              return (
                <Route
                  element={
                    r.path === RouteConstants.CREATEPAGE || r.path === RouteConstants.CREATEFIRSTPAGE ? (
                      <r.component />
                    ) : (
                      withLayout(
                        {
                          allView: r.component,
                        },
                        {
                          auth: authState,
                          appState: errorState,
                          data: userState,
                        }
                      )
                    )
                  }
                  path={r.path}
                  key={key}
                ></Route>
              )
            })}
          </Route>
          {/* ------ Private Route ----- */}
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  )
}
