import { trackEvent } from '../../Analytics/GA'
import AxiosClass from '../../Api/Interceptor'
import PageApi from '../../Api/PageApi'
import PageDetailApi from '../../Api/PageDetailAPI'
import { InviteparseURLParams } from '../../commonservice'
import {
  addCurrentEventsDataAction,
  addEventsAction,
  addFundraiserAction,
  addFundraisersResponse,
  addmyPageList,
  addPageAction,
  addPersonalMessage,
  addSearchPage,
  createEventsResponse,
  editPageResponse,
  loadPageDetail,
  pageCurrentAlbum,
  pageGetGalllery,
  pageGetGallleryAlbum,
  pageGetGallleryCount,
  pagePublish,
  pageReturnAction,
  sendAudioMsgResponse,
  setEventsCount,
  subScribePageResponse,
  upDataMemberStatus,
  updateEventsResponse,
  updateFundraisersResponse,
} from '../slices/pageDetailSlice'
import { loading } from '../slices/userSlice'
import { getPosts } from './postThunk'

export const pageDetailThunk: any = (uniqueUrl: string, belongsTo: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .getPageDetail(uniqueUrl, belongsTo)
      .then((r: any) => {
        if (r) {
          if (r?.hidden_response?.success === false && r?.hidden_response?.message === "Member doesn't exists") {
            const { user } = getState()
            dispatch(loading(true))
            if (
              localStorage.getItem('SubscribeCode') !== null &&
              localStorage.getItem('ForInviteUser') !== null &&
              user?.value?.data?._id
            ) {
              const params: any = InviteparseURLParams(localStorage.getItem('SubscribeCode'))
              if (
                params.invite &&
                params.pageownerid &&
                params.pageid &&
                params.invite?.length &&
                params.pageownerid?.length &&
                params.pageid?.length &&
                params.invite[0] === user?.value?.data?.email
              ) {
                dispatch(
                  subScribePage({
                    pageId: params.pageid?.length ? params.pageid[0] : '',
                    userId: user?.value?.data?._id,
                    token: params.token?.length ? params.token[0] : '',
                    source: 'invite',
                  })
                )
              }
              if (
                params.invite &&
                params.pageownerid &&
                params.pageid &&
                params.invite?.length &&
                params.pageownerid?.length &&
                params.pageid?.length &&
                params.invite[0] !== user?.value?.data?.email
              ) {
                const href: any = window.location.pathname
                const urlSegments: any = href.match(/([^/]*)\/*$/)
                let pageUrl: any = ''
                if (urlSegments.length) {
                  pageUrl = urlSegments[1]
                }
                localStorage.removeItem('SubscribeCode')
                localStorage.removeItem('ForInviteUser')
                dispatch(pageDetailThunk(pageUrl, user?.value?.data?._id))
              }
            } else {
              dispatch(loadPageDetail(r))
            }
          } else {
            dispatch(loadPageDetail(r))
          }
        }
        if (r.success) {
          /* empty */
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const editPageDetailThunk: any = (data: string) => {
  return (dispatch: any, getState: any) => {
    new PageDetailApi(AxiosClass)
      .updatePageDetail(data)
      .then((r: any) => {
        dispatch(editPageResponse(r))
        if (r.success) {
          dispatch(loading(false))
        } else {
          throw r
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const uploadMedia = async (payload: any, pageId: any, postId: any = null) => {
  return await new PageDetailApi(AxiosClass)
    .uploadMedia(payload, pageId, postId)
    .then((r: any) => {
      return r
    })
    .catch((e: any) => {
      return e
    })
}

export const uploadMediaExternal = async (payload: any, pageId: any, postId: any = null) => {
  return await new PageDetailApi(AxiosClass)
    .uploadMediaExternal(payload, pageId, postId)
    .then((r: any) => {
      return r
    })
    .catch((e: any) => {
      return e
    })
}

export const pageURLCheckValdation = async (uniqueUrl: string) => {
  return await new PageApi(AxiosClass)
    .urlValidation({ unique_url: uniqueUrl })
    .then((r: any) => {
      return r
    })
    .catch((e: any) => {
      return e
    })
}

export const pagePublishThunk: any = (pageId: string, status: boolean) => {
  if (status) {
    trackEvent('Page Interaction', 'Publish Page', `${pageId}`)
  } else {
    trackEvent('Page Interaction', 'Unpublish Page', `${pageId}`)
  }
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pagePublish(pageId, status)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(pagePublish(status))
            dispatch(loading(false))
            dispatch(addPageAction('show-share-steps'))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
export const pageGetGallleryThunk: any = (pageId: string, pageno: number, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageGetGalllery(pageId, pageno)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            if (r.page !== 1) {
              const { pagedetail } = getState()
              dispatch(pageGetGalllery([...pagedetail.gallleryData?.gallery, ...r.data]))
            } else {
              dispatch(pageGetGalllery(r.data))
            }
            dispatch(pageGetGallleryCount(r?.totalRecords))
            dispatch(loading(false))
            dispatch(pageReturnAction(returlCallBack))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pageGetGallleryAlbumThunk: any = (pageId: string, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageGetGallleryAlbum(pageId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(pageGetGallleryAlbum(r.data))
            dispatch(loading(false))
            dispatch(pageReturnAction(returlCallBack))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pageGetEventsThunk: any = (
  pageId: string,
  userId: string,
  pageno: number,
  returlCallBack: string = ''
) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageGetEvents(pageId, userId, pageno)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            if (pageno !== 1) {
              const { pagedetail } = getState()
              dispatch(addEventsAction([...pagedetail.eventsData, ...r.data]))
            } else {
              dispatch(setEventsCount(r.totalRecords))
              dispatch(addEventsAction(r.data))
            }
            if (localStorage.getItem('notificationEventId') === null) {
              dispatch(loading(false))
            }
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pageGetAllFundraiserThunk: any = (pageId: string, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    new PageDetailApi(AxiosClass)
      .pageGetAllFundraiser(pageId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(addFundraiserAction(r.data))
            dispatch(loading(false))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addDonationThunk: any = (payLoad: any, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .addDonation(payLoad)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(updateFundraisersResponse(r))
            dispatch(loading(false))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addFundraiserThunk: any = (payLoad: any, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .addFundraiser(payLoad)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(addFundraisersResponse(r))
            dispatch(loading(false))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const editFundraiserThunk: any = (payLoad: any, fundraiserId: string, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .editFundraiser(payLoad, fundraiserId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(updateFundraisersResponse(r))
            dispatch(loading(false))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getEventThunk: any = (eventId: string, userId: string, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .getEvent(eventId, userId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(addCurrentEventsDataAction(r.data))
            dispatch(loading(false))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const eventInviteUpdateThunk: any = (
  eventId: string,
  payload: string,
  userId: string,
  pageId: string,
  returlCallBack: string = ''
) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .eventInviteUpdate(eventId, payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(getEventThunk(eventId, userId))
            dispatch(pageGetEventsThunk(pageId, userId, 1))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pageGetAlbumGallleryThunk: any = (albumId: string, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageGetAlbumGalllery(albumId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(pageCurrentAlbum(r.data?.items))
            dispatch(loading(false))
            dispatch(pageReturnAction(returlCallBack))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addToAlbumThunk: any = (mediaId: string, albumId: string, pageid: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .addToAlbum(mediaId, albumId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(pageGetGallleryAlbumThunk(pageid, 'close-album-action'))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const createAlbumThunk: any = (name: string, pageid: string, action: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageCreateAlbum(pageid, name)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            const actionOnAdd = action === 'addToAlbum' ? 'add-album-addToAlbum-action' : 'add-album-action'
            dispatch(pageGetGallleryAlbumThunk(pageid, actionOnAdd))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const renameAlbumThunk: any = (name: string, pageid: string, albumid: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageRenameAlbum(albumid, name)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(pageGetGallleryAlbumThunk(pageid, 'rename-album-action'))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deleteAlbumThunk: any = (pageid: string, albumid: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageAlbumDelete(albumid)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(pageGetGallleryAlbumThunk(pageid))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deleteMeidaThunk: any = (pageid: string, mediaid: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageMediaDelete(mediaid)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            const { pagedetail } = getState()
            dispatch(pageGetGalllery(pagedetail.gallleryData?.gallery.filter((e: any) => e._id !== mediaid)))
            // Delete Media from Post
            dispatch(getPosts(pageid))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const deleteAlbumMeidaThunk: any = (pageid: string, mediaid: string, albumId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageMediaAlbumDelete(mediaid, albumId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))

            dispatch(pageGetAlbumGallleryThunk(albumId))
            dispatch(pageGetGallleryAlbumThunk(pageid))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pageSendEmailGenericThunk: any = (PayLoadData: any, returlCallBack: string = '') => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .sendEmailGeneric(PayLoadData)
      .then((r: any) => {
        if (r) {
          if (r.status) {
            dispatch(pageReturnAction(returlCallBack))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}
export const blockUnblockMemberThunk: any = (
  memberId: string,
  pageId: string,
  updatepageMemberData: any,
  payLoad: any,
  returlCallBack: string = ''
) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .blockUnblockMember(memberId, pageId, payLoad)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))
            dispatch(upDataMemberStatus(updatepageMemberData))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const addNewEvent: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .addEvent(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))
            dispatch(createEventsResponse(r))
            dispatch(pageGetEventsThunk(payload?.page, payload?.user, 1))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const updateEvent: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .updatePageEvent(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))
            dispatch(updateEventsResponse({ ...r, payload }))
            dispatch(pageGetEventsThunk(payload?.page, payload?.user, 1))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const getMyPageListThunk: any = (ownerId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .getMyPageList(ownerId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))
            dispatch(addmyPageList(r.data))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pageDeleteThunk: any = (pageId: string, userId: string) => {
  trackEvent('Page Interaction', 'Delete Page', `${pageId}`)
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .pageDelete(pageId, userId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))
            dispatch(pageReturnAction('back-to-mypage'))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const eventDeleteThunk: any = (pageId: string, eventId: string, userId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .eventDelete(eventId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(addCurrentEventsDataAction([]))
            dispatch(pageGetEventsThunk(pageId, userId, 1))
            dispatch(pageReturnAction('back-to-event-list'))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const sendAudioMessage: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .sendAudioMsg(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(sendAudioMsgResponse(r))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const subScribePage: any = (payload: any, isSearch: boolean = false, searchpayLoad: any = {}) => {
  return (dispatch: any, getState: any) => {
    new PageDetailApi(AxiosClass)
      .subScribe_Page(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            const { user } = getState()
            if (localStorage.getItem('SubscribeCode') !== null && localStorage.getItem('ForInviteUser') !== null) {
              const href: any = window.location.pathname
              const urlSegments: any = href.match(/([^/]*)\/*$/)
              let pageUrl: any = ''
              if (urlSegments.length) {
                pageUrl = urlSegments[1]
              }
              localStorage.removeItem('SubscribeCode')
              localStorage.removeItem('ForInviteUser')
              dispatch(pageDetailThunk(pageUrl, user?.value?.data?._id))
            }

            if (!isSearch) {
              dispatch(subScribePageResponse(r))
            } else {
              // searchpayLoad
              dispatch(searchPageThunk(searchpayLoad))
            }
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const unsubScribePage: any = (payload: any, isSearch: boolean = false, searchpayLoad: any = {}) => {
  return (dispatch: any, getState: any) => {
    new PageDetailApi(AxiosClass)
      .unsubScribe_Page(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            if (!isSearch) {
              dispatch(subScribePageResponse(r))
            } else {
              // searchpayLoad
              dispatch(searchPageThunk(searchpayLoad))
            }
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const reorderQuestionsThunk: any = (payload: any, pageId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loading(true))
    new PageDetailApi(AxiosClass)
      .reorderQuestions(payload, pageId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(loading(false))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const searchPageThunk: any = (payload: any) => {
  return (dispatch: any, getState: any) => {
    new PageDetailApi(AxiosClass)
      .searchPage(payload)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(addSearchPage(r.data))
            dispatch(loading(false))
            dispatch(addPageAction(''))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const pageGetPersonalMessageThunk: any = (pageId: string) => {
  return (dispatch: any, getState: any) => {
    new PageDetailApi(AxiosClass)
      .pageGetPersonalMessage(pageId)
      .then((r: any) => {
        if (r) {
          if (r.success) {
            dispatch(addPersonalMessage(r.data))
            dispatch(loading(false))
          } else {
            throw r
          }
        }
      })
      .catch((e: any) => {
        dispatch(loading(false))
      })
  }
}

export const GdriveMediaUpload: any = async (data: any) => {
  return await new PageDetailApi(AxiosClass)
    .GdriveMediaUpload(data)
    .then((r: any) => {
      return r
    })
    .catch((e: any) => {
      return e
    })
}
