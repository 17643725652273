import './RightSection.scss'

import React from 'react'

import { useAppSelector } from '../../Hooks'
import { type pageDetailData } from '../../Redux/slices/pageDetailSlice'

export const RightSection = (props: any) => {
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const userState = useAppSelector(state => state.user)
  const isBlur = () => {
    return (
      pageDetailState.privacy_status === 'PRIVATE' &&
      pageDetailState?.belongsTo?._id !== userState?.value?.data?._id &&
      pageDetailState?.subscribtion_status !== 'APPROVED'
    )
  }
  return (
    <>
      <div className={isBlur() ? ' isblur RightSection' : 'RightSection'}>
        {props.component?.rightView?.map((component: any, index: any) => {
          return <React.Fragment key={index}>{component}</React.Fragment>
        })}
      </div>
    </>
  )
}
