import '../../../Layouts/LayoutSections/MidSection.scss'

import { useEffect, useState } from 'react'
import { deviceType, isMobile, isMobileOnly } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { isset } from '../../../commonservice'
import { RouteConstants } from '../../../Constants/routeConstants'
import { useAppSelector } from '../../../Hooks'
import { addPageAction, type pageDetailData } from '../../../Redux/slices/pageDetailSlice'
import { createNewPostResponse } from '../../../Redux/slices/post'
import { loading } from '../../../Redux/slices/userSlice'
import AlertPopup from '../../Modals/AlertPopup/AlertPopup'
import CreatePost from '../../Modals/CreatePost/CreatePost'
import { PageCard } from '../../PageCard'
import Post from '../../Post/Post'

const CreatePostSec = (props: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useAppSelector((state: any) => state?.user)
  const postData: any = useAppSelector((state: any) => state?.post)
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)
  const pageAction = useAppSelector((state: any) => state?.pagedetail?.pageAction)

  const [isMeidaPost, setIsMeidaPost] = useState(false)
  const [show, setShow] = useState(false)
  const [from, setFrom] = useState('')
  const [createSuccess, setCreateSuccess] = useState(false)
  const handleClose = () => {
    setShow(false)
    // dispatch(addPageAction(""));
    setIsMeidaPost(false)
  }
  const handleShow = (param: string) => {
    setFrom(param)
    setShow(true)
  }

  useEffect(() => {
    if (postData?.postCreateResponse?.success === true) {
      const medias: any = []
      postData?.postCreateResponse?.data?.medias?.forEach((e: any) => {
        medias.push({ ...e, type: e?.type })
      })
      handleClose()
      if (pageDetailState?.belongsTo?._id !== userData?.value?.data?._id && pageDetailState?.approval_required) {
        dispatch(loading(false))
      }
      dispatch(
        createNewPostResponse({
          ...postData?.postCreateResponse,
          success: null,
        })
      )
    }
  }, [postData?.postCreateResponse])

  useEffect(() => {
    if (pageAction === 'add-media') {
      setIsMeidaPost(true)
      setFrom('media')
      setShow(true)
      dispatch(addPageAction(''))
    } else if (pageAction === 'show-description-post') {
      setFrom('description')
      setShow(true)
      dispatch(addPageAction(''))
    }
  }, [pageAction])

  const canAction = () => {
    if (!userData?.value?.data?._id) {
      loginRedirect()
    }
    if (
      pageDetailState?.belongsTo?._id === userData?.value?.data?._id ||
      pageDetailState?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
  }

  return (
    <>
      <PageCard />
      {isMobileOnly || deviceType === 'mobile' ? (
        ''
      ) : userData?.value?.data?._id ? (
        <div className="CreatePostBtn">
          <div className="form-group">
            <div className="ProfilePic">
              {isset(userData?.value?.data?.image) ? (
                <img src={userData?.value?.data?.image} alt="" />
              ) : (
                <div className="ownerAlpha">
                  <span>{userData?.value?.data?.name?.charAt(0)}</span>
                </div>
              )}
            </div>
            <textarea
              rows={1}
              id="input"
              readOnly={true}
              placeholder="Create a post"
              onClick={() => {
                if (canAction()) {
                  handleShow('description')
                } else {
                  dispatch(addPageAction('show-subsciption-step'))
                }
              }}
            />
            <div className="IconsList">
              <i
                className="fa fa-butterflymedia"
                onClick={() => {
                  if (canAction()) {
                    handleShow('media')
                  } else {
                    dispatch(addPageAction('show-subsciption-step'))
                  }
                }}
              ></i>
              <i
                className="fa fa-videopost"
                onClick={() => {
                  if (canAction()) {
                    handleShow('video')
                  } else {
                    dispatch(addPageAction('show-subsciption-step'))
                  }
                }}
              ></i>
              <i
                className="fa fa-butterflymic"
                onClick={() => {
                  if (canAction()) {
                    handleShow('micaudio')
                  } else {
                    dispatch(addPageAction('show-subsciption-step'))
                  }
                }}
              ></i>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <Post />
      {(isMobile || deviceType === 'mobile') && userData?.value?.data?._id ? (
        <button
          type="button"
          className="CreatePostFixed"
          onClick={() => {
            if (canAction()) {
              handleShow('description')
            } else {
              dispatch(addPageAction('show-subsciption-step'))
            }
          }}
        >
          <i className="fa fa-plus"></i> Create Post
        </button>
      ) : (
        ''
      )}
      {show ? (
        <CreatePost
          title="Create Post"
          isMeidaPost={isMeidaPost}
          show={show}
          handleClose={() => {
            handleClose()
          }}
          from={from}
        />
      ) : (
        ''
      )}
      {createSuccess ? (
        <AlertPopup
          buttonText={false}
          show={createSuccess}
          content={'Awaiting approval from page owner.'}
          state={'SUCCESS'}
          onHide={() => {
            setCreateSuccess(false)
          }}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default CreatePostSec
