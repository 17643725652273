import { type AxiosInstance } from 'axios'

import {
  BLOCKMEMBERS,
  CHANGEPAYMENTPLANURL,
  CHANGEPW,
  GETNOTIFICATIONSETTINGS,
  GETPAYMENTDETAILS,
  UNBLOCKMEMBER,
  UPDATENOTIFICATIONSETTINGS,
  UPDATEUSERDETAILS,
} from '../../Constants/apiConstants'

export default class SettingsApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor
  }

  public async updateUser_info(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(UPDATEUSERDETAILS, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async unblockMember(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(UNBLOCKMEMBER, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getBlockmembers(userId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(BLOCKMEMBERS?.replace(':userId', userId))
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getNotifcationSettings(userId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETNOTIFICATIONSETTINGS?.replace(':belongsTo', userId))
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async changePW(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(CHANGEPW, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async updateNotifcationSettings(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(UPDATENOTIFICATIONSETTINGS, payload)
          .then(r => {
            resolve({ ...r.data, ...payload })
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async changePaymentPlan(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(CHANGEPAYMENTPLANURL, payload)
          .then(r => {
            resolve({ ...r.data, ...payload })
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getCurrentPlanDetails(userId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETPAYMENTDETAILS?.replace(':userId', userId))
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
