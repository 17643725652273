import { type AxiosInstance } from 'axios'

import {
  ADDLIKE,
  ADDREACTIONFORCOMMENT,
  ADDREACTIONFORCOMMENTREPLY,
  ADDREPORT,
  COMMENTREACTIONREMOVE,
  COMMENTREPLYREACTIONREMOVE,
  DELETEPOSTCOMMENT,
  EDITPOSTCOMMENT,
  EDITREACTION,
  GETALLCOMMENTS,
  GETALLFUNDRAISERCOMMENTS,
  GETALLMEDIACOMMENTS,
  GETPOSTURL,
  PINUNPINPOSTURL,
  POSTCOMMENTCREATE,
  POSTCOMMENTCREATEREPLAY,
  POSTCOMMENTONOFFURL,
  POSTCREATEURL,
  POSTDELETEURL,
  POSTDETAIL,
  POSTUPDATEURL,
  REMOVEFUNDRAISERLIKE,
  REMOVELIKE,
  REMOVEREACTION,
} from '../../Constants/apiConstants'

export default class PostApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor
  }

  public async createPost(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(POSTCREATEURL, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getPostList(pageId: any, pagecount: number) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETPOSTURL?.replace(':pageId', pageId) + `?page=${pagecount}`)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getPinPostList(pageId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(PINUNPINPOSTURL?.replace(':postId', pageId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getfundraiserAllComments(fundraiserId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETALLFUNDRAISERCOMMENTS?.replace(':fundraiserId', fundraiserId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getPostAllComments(postId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETALLCOMMENTS?.replace(':postId', postId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getPostMediaComments(mediaId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETALLMEDIACOMMENTS?.replace(':mediaId', mediaId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async createPostComment(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(POSTCOMMENTCREATE, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async createPostCommentReply(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(POSTCOMMENTCREATEREPLAY, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async PostAddLike(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDLIKE, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pinUnpinPagePost(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(PINUNPINPOSTURL?.replace(':postId', payload?.postId), {
            pin: payload?.status,
          })
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async commentOnOffPagePost(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(POSTCOMMENTONOFFURL?.replace(':postId', payload?.postId), {
            commentState: payload?.status,
          })
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async updatePagePost(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(POSTUPDATEURL?.replace(':postId', payload._id), payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async updatePostComment(payload: any, commentId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(EDITPOSTCOMMENT?.replace(':commentId', commentId), payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async deletePagePost(postId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(POSTDELETEURL?.replace(':postId', postId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async removePostLike(postId: string, likeId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(REMOVELIKE?.replace(':postId', postId).replace(':likeId', likeId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async removeFundraisertLike(fundraiserId: string, likeId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(REMOVEFUNDRAISERLIKE?.replace(':fundraiserId', fundraiserId).replace(':likeId', likeId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async deletePostComment(commentId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(DELETEPOSTCOMMENT?.replace(':commentId', commentId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async addReactionForComment(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDREACTIONFORCOMMENT, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async addReactionForCommentReply(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDREACTIONFORCOMMENTREPLY, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async addReport(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDREPORT, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async deleteCommentReaction(reactId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(COMMENTREACTIONREMOVE?.replace(':reactId', reactId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async removeReaction(reactId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(REMOVEREACTION?.replace(':reactId', reactId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async deleteCommentReplyReaction(reactId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(COMMENTREPLYREACTIONREMOVE?.replace(':reactId', reactId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async editCommentReaction(reactId: string, name: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(EDITREACTION?.replace(':reactId', reactId), {
            name,
          })
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getPostDetails(postId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(POSTDETAIL?.replace(':postId', postId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
