import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  data: [],
  totalCount: 0,
  deleteAudioMsgResp: {},
  newStatusUpdateResp: {},
}

export const audioMessageSlice: any = createSlice({
  name: 'audioMessages',
  initialState,
  reducers: {
    loadAudioMessages: (state, action) => {
      state.data = action.payload.data
      state.totalCount = action.payload.totalCount
    },
    audioMessageListResponse: (state, action) => {
      state.data = action.payload?.allAudios
      state.totalCount = action.payload?.count
    },
    removeAudioMessageResponse: (state, action) => {
      state.deleteAudioMsgResp = action.payload
    },
    newStatusUpdateResponse: (state, action) => {
      state.newStatusUpdateResp = action.payload
    },
  },
})

export const { loadAudioMessages, audioMessageListResponse, removeAudioMessageResponse, newStatusUpdateResponse } =
  audioMessageSlice.actions
export default audioMessageSlice.reducer
