import './AudioMessage.scss'

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { RouteConstants } from '../../Constants/routeConstants'
import { useAppSelector } from '../../Hooks'
import { newStatusUpdateResponse } from '../../Redux/slices/audioMessageSlice'
import { addPageAction, sendAudioMsgResponse } from '../../Redux/slices/pageDetailSlice'
import { loading } from '../../Redux/slices/userSlice'
import { getAudioMessges } from '../../Redux/thunks/AudioMessageThunk'
import AlertPopup from '../Modals/AlertPopup/AlertPopup'
import AudioPopup from '../Modals/AudioPopup/AudioPopup'
import SendAudioMessage from '../Modals/SendAudioMessage'

function AudioMessage(): JSX.Element {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const audioMessageState: any = useAppSelector(state => state.audiomessages)
  const pageDetailState: any = useAppSelector(state => state.pagedetail)
  const userState: any = useAppSelector(state => state.user)

  const [audioList, setAudioList] = useState([])
  const [totalMessageCount, setTotalMessageCount] = useState(0)
  const [show, setShow] = useState(false)
  const [openSendAudioMsgPopup, setOpenSendAudioMsgPopup] = useState(false)
  const [sendSuccessMessage, showSendSuccessMessage] = useState(false)
  const [activeAudioMsg, setActiveAudioMsg] = useState<any>({})
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = (data: any) => {
    setShow(true)
    setActiveAudioMsg(data)
  }

  useEffect(() => {
    if (pageDetailState?.data?._id) {
      dispatch(loading(true))
      dispatch(
        getAudioMessges({
          pageId: pageDetailState?.data?._id,
          userId: userState?.value?.data?._id,
        })
      )
    }
  }, [pageDetailState?.data?._id])

  useEffect(() => {
    if (audioMessageState?.data?.length >= 0) {
      setAudioList(audioMessageState?.data)
      setTotalMessageCount(audioMessageState?.totalCount)
      dispatch(loading(false))
    }
  }, [audioMessageState?.data])

  useEffect(() => {
    if (audioMessageState?.newStatusUpdateResp?.success === true) {
      dispatch(
        getAudioMessges({
          pageId: pageDetailState?.data?._id,
          userId: userState?.value?.data?._id,
        })
      )
      dispatch(
        newStatusUpdateResponse({
          ...audioMessageState?.newStatusUpdateResp,
          success: null,
        })
      )
    }
  }, [audioMessageState?.newStatusUpdateResp])

  useEffect(() => {
    if (audioMessageState?.data?.length >= 0) {
      setAudioList(audioMessageState?.data)
      setTotalMessageCount(audioMessageState?.totalCount)
      dispatch(loading(false))
    }
  }, [audioMessageState?.data])

  useEffect(() => {
    if (audioMessageState?.newStatusUpdateResp?.success === true) {
      dispatch(
        getAudioMessges({
          pageId: pageDetailState?.data?._id,
          userId: userState?.value?.data?._id,
        })
      )
      dispatch(
        newStatusUpdateResponse({
          ...audioMessageState?.newStatusUpdateResp,
          success: null,
        })
      )
    }
  }, [audioMessageState?.newStatusUpdateResp])

  const canAction = () => {
    if (!userState?.value?.data?._id) {
      loginRedirect()
    }
    if (
      pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ||
      pageDetailState?.data?.subscribtion_status === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  const loginRedirect = () => {
    navigate(RouteConstants.LOGIN)
  }

  return pageDetailState?.data?.belongsTo ? (
    pageDetailState?.data?.belongsTo?._id === userState?.value?.data?._id ? (
      <section id="AudioMessage">
        {/* For OwnerView */}
        <div className="header">
          Audio Messages <span>({totalMessageCount})</span>
        </div>
        <div className="thumbnailWrapper">
          {totalMessageCount ? (
            <>
              {audioList?.map((audioItem: any, key: number) => {
                return (
                  <div
                    className="eachThumbnail"
                    key={key}
                    onClick={() => {
                      handleShow(audioItem)
                    }}
                  >
                    {audioItem?.createdBy?.image ? (
                      <img src={audioItem?.createdBy?.image} alt={audioItem?.createdBy?.name} />
                    ) : (
                      audioItem?.createdBy?.name.charAt(0)
                    )}
                    <i className="fa fa-play-fa"></i>
                    {audioItem?.isNew ? <span className="new">New</span> : ''}
                  </div>
                )
              })}
            </>
          ) : totalMessageCount === 0 ? (
            <div className="NoAudio">
              You don’t have any messages right now,
              <br />
              but that doesn’t mean people aren’t thinking about you.
              <br />
              Check back soon.
            </div>
          ) : (
            <div />
          )}
        </div>
        {show ? (
          <AudioPopup
            show={show}
            handleClose={() => {
              handleClose()
            }}
            activeAudioMsg={activeAudioMsg}
          />
        ) : (
          <div />
        )}
      </section>
    ) : (
      <>
        <section
          id="AudioMessage"
          className=" MemberView"
          onClick={() => {
            if (canAction()) {
              setOpenSendAudioMsgPopup(true)
            } else {
              dispatch(addPageAction('show-subsciption-step'))
            }
          }}
        >
          {/* For MemberView */}
          <div className="AudioIcon">
            <i className="fa fa-audio-record"></i>
          </div>
          <div className="AudioText">
            <h6>
              A picture is worth a thousand words, and a voice message is worth thousands more.{' '}
              <p>Leave a personal voice message that only {pageDetailState?.data?.belongsTo?.name} can hear.</p>
            </h6>
          </div>
        </section>
        {openSendAudioMsgPopup ? (
          <SendAudioMessage
            show={openSendAudioMsgPopup}
            handleClose={() => {
              setOpenSendAudioMsgPopup(false)
              showSendSuccessMessage(true)
            }}
          />
        ) : (
          <div />
        )}
        {sendSuccessMessage ? (
          <AlertPopup
            buttonText={false}
            show={!!pageDetailState?.sendAudioMessageResp.success}
            content={pageDetailState?.sendAudioMessageResp.message}
            state={!pageDetailState?.sendAudioMessageResp.success ? 'ERROR' : 'SUCCESS'}
            onHide={() => {
              dispatch(
                sendAudioMsgResponse({
                  ...pageDetailState?.sendAudioMessageResp,
                  success: null,
                })
              )
              showSendSuccessMessage(false)
            }}
          />
        ) : (
          <div />
        )}
      </>
    )
  ) : (
    <div />
  )
}
export default AudioMessage
