import './AddDeleteQuestion.scss'

import axios from 'axios'
import { Reorder } from 'framer-motion'
import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../../Analytics/GA'
import { environment } from '../../../AppConfig/environment'
import { useAppSelector } from '../../../Hooks'
import { addQuestions } from '../../../Redux/slices/pageDetailSlice'
import { loadPostList } from '../../../Redux/slices/post'
import { loading, setAddDeleteQuestionPopupFlag } from '../../../Redux/slices/userSlice'
import { store } from '../../../Redux/store'
import { reorderQuestionsThunk } from '../../../Redux/thunks/pageDetailThunk'
import Loader from '../../Loader/Loader'
import QuestionStepper from '../../MobileQuestion/questionSteper'

function AddDeleteQuestion(props: any) {
  const [, setAddButtonActive] = useState<boolean>()
  const dispatch = useDispatch()
  const pageDetailState = useAppSelector((state: any) => state.pagedetail.data)
  const userState: any = useAppSelector(state => state.user)
  const [questionInput, setQuestionInput] = useState('')
  const [questionItem, setQuestionItem] = useState<any>()
  const [canSave, setCanSave] = useState(false)
  const [questionOrderIndex, setQuestionOrderIndex] = useState<number[]>([])
  const [showQuestionStapper, setShowQuestionStapper] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  const postData = useAppSelector((state: any) => state?.post)

  useEffect(() => {
    setQuestionOrderIndex(Array.from(Array(pageDetailState.questions.length).keys()))
    setQuestionItem(pageDetailState.questions)
  }, [pageDetailState])

  useEffect(() => {
    setTimeout(() => {
      if (
        userState?.questionAddDeletePopupFlag === null &&
        userState?.value?.data?.step === 4 &&
        userState?.value?.data?.showStep === true
      ) {
        dispatch(setAddDeleteQuestionPopupFlag(true))
        setShowQuestionStapper(true)
      }
    }, 200)
  }, [])

  function handleAddQuestion() {
    if (questionInput) {
      const data = JSON.stringify({
        questions: [
          {
            question: questionInput,
          },
        ],
      })
      dispatch(loading(true))
      const token = store.getState().user?.value?.token
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${environment.apiEndPoint}page/questions/${pageDetailState._id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      }

      axios(config)
        .then(response => {
          dispatch(addQuestions(response.data.data))
          document.getElementById('add-question-sec')?.classList?.add('d-none')
          setAddButtonActive(false)
          document.getElementById('addQBtn')?.classList.remove('d-none')
          dispatch(loading(false))
          trackEvent('Page Interaction', 'Question Prompt Add', `${userState?.value?.data?._id}`)
        })
        .catch(() => {
          dispatch(loading(false))
        })
    }
  }

  function handleDeleteQuestion(questionId: any) {
    const node = document.getElementById(questionId)
    node?.classList.add('d-none')
    dispatch(loading(true))
    const token = store.getState().user?.value?.token
    const config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${environment.apiEndPoint}page/questions/${pageDetailState._id}/${questionId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    axios(config)
      .then(response => {
        dispatch(addQuestions(response.data.data))
        const updatePostList = postData?.postList?.data?.filter((postInfo: any) => postInfo?.questionId !== questionId)
        dispatch(loadPostList(updatePostList))

        dispatch(loading(false))
        const qList: any = document.getElementsByClassName('questionWrap')
        if (qList?.length) {
          for (let i = 0; i < qList.length; i++) {
            qList[i]?.classList?.remove('d-none')
          }
        }
        trackEvent('Page Interaction', 'Question Prompt Delete', `${userState?.value?.data?._id}`)
      })
      .catch(() => {
        const qList: any = document.getElementsByClassName('questionWrap')
        if (qList?.length) {
          for (let i = 0; i < qList.length; i++) {
            qList[i]?.classList?.remove('d-none')
          }
        }
        dispatch(loading(false))
      })
  }

  // const handle drag sorting

  const saveReOrder = () => {
    /* ----- On leaving component if order is changed then update the status ----- */
    const data = []
    for (let i = 0; i < questionOrderIndex.length; i++) {
      data.push(questionItem[questionOrderIndex[i]])
    }
    if (data.length) {
      dispatch(addQuestions(data))
      setCanSave(false)
      const payLoad = {
        questions: data,
      }
      setShowLoader(true)
      dispatch(reorderQuestionsThunk(payLoad, pageDetailState._id))
      trackEvent('Page Interaction', 'Question Prompts Reorder', `${userState?.value?.data?._id}`)
      setTimeout(() => {
        setShowLoader(false)
      }, 1500)
    }
    /* --------------------------------------------------------------------------- */
  }

  const closeQuestionStapper = () => {
    setShowQuestionStapper(false)
    const addBtn: any = document.getElementById('addQBtn')
    if (addBtn) {
      addBtn?.click()
    }
  }

  const closeSkipQuestionStapper = () => {
    setShowQuestionStapper(false)
    props.handleClose()
  }

  return (
    <Modal
      {...props}
      onHide={() => props.handleClose()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="AddDeleteQuestion"
    >
      {showLoader && <Loader />}
      <Modal.Header closeButton>
        {' '}
        <Modal.Title>Edit Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="questionHolder">
          <Reorder.Group
            axis="y"
            values={questionOrderIndex}
            onReorder={e => {
              setQuestionOrderIndex(e)
              setCanSave(true)
            }}
          >
            {questionOrderIndex?.length > 0 &&
              questionOrderIndex.map(q => {
                return (
                  <Reorder.Item value={q} key={q}>
                    <div className="questionContainer">
                      <div className="questionWrap" id={'question-list-popup1_' + questionItem[q]._id}>
                        <div className="circular-shape">
                          <i className="fa fa-drag"></i>
                        </div>

                        <div className="question-title">{questionItem[q].question}</div>
                        {pageDetailState?.questions?.length !== 1 ? (
                          <button
                            type="button"
                            className="delete btn btn-primary deleteBtn"
                            onClick={() => {
                              let node = document.getElementById(questionItem[q]._id)
                              node?.classList.remove('d-none')
                              node = document.getElementById('question-list-popup1_' + questionItem[q]._id)
                              node?.classList.add('d-none')
                            }}
                          >
                            <i className="delete-question fa fa-trash" aria-hidden="true"></i>
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="question-expand d-none" id={questionItem[q]._id}>
                        <div className="title">{questionItem[q].question}</div>
                        <p>
                          Sometimes the questions we ask are no longer relevant. Or you want to replace them with others
                          that you&apos;ve created, That&apos;s why we make it easy for you to delete the standard
                          questions. Just remember that when you delete, you are simultaneously eliminating any and all
                          responses in the feed.
                        </p>
                        <div className="inner-holder">
                          <button
                            type="button"
                            className="no"
                            onClick={() => {
                              let node = document.getElementById(questionItem[q]._id)
                              node?.classList.add('d-none')

                              node = document.getElementById('question-list-popup1_' + questionItem[q]._id)
                              node?.classList.remove('d-none')
                            }}
                          >
                            <span>No, Keep Question</span>
                          </button>
                          <button
                            type="button"
                            className="yes"
                            onClick={() => {
                              handleDeleteQuestion(questionItem[q]._id)
                            }}
                          >
                            Yes, Delete Question and Answers
                          </button>
                        </div>
                      </div>
                    </div>
                  </Reorder.Item>
                )
              })}
          </Reorder.Group>
        </div>
        <div className="addQuestions">
          <div className="answers-wrap d-none" id="add-question-sec">
            <textarea
              placeholder="Add your question here"
              value={questionInput}
              onChange={e => {
                setQuestionInput(e.currentTarget.value)
              }}
            />
            <div className="ans-holder">
              <button
                type="button"
                className="save"
                onClick={() => {
                  handleAddQuestion()
                }}
              >
                <i className="fa fa-plus"></i>
                Add
              </button>
              <button
                type="button"
                className="cancel"
                onClick={() => {
                  document.getElementById('addQBtn')?.classList.remove('d-none')

                  setAddButtonActive(false)
                }}
              >
                <i className="fa fa-butterflynoapproval"></i>
                Cancel
              </button>
            </div>
          </div>
          <div className="ctaWrapper">
            <div className="lhs">
              {questionOrderIndex?.length < 10 ? (
                <>
                  <Button
                    className="question-add-icon DesktopAddQuestion"
                    type="button"
                    id="addQBtn"
                    onClick={() => {
                      setQuestionInput('')
                      document.getElementById('addQBtn')?.classList.add('d-none')
                      setAddButtonActive(false)
                    }}
                  >
                    <i className="fa fa-plus"></i>
                    Add more question
                  </Button>
                  <QuestionStepper
                    enable={showQuestionStapper}
                    onComplete={closeQuestionStapper}
                    onSkip={closeSkipQuestionStapper}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="rhs">
              <button className="cancelCta" onClick={props.handleClose}>
                Cancel
              </button>
              <button
                className="saveCta"
                onClick={() => {
                  saveReOrder()
                }}
                disabled={!canSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddDeleteQuestion
