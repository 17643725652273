import { Auth } from '../Components/Auth/Auth'
import AuthWithGoogleTag from '../Components/Auth/AuthWithGTag'
import ForgotPassword from '../Components/Auth/ForgotPassword'
import ResetPassword from '../Components/Auth/ResetPassword'
import CreatePageWithGoogleTag from '../Components/CreatePage/CreatePageWithGTag'
import FromInvitePage from '../Components/FromInvitePage'
import PrivacyPolicy from '../Components/Generic/PrivacyPolicy'
import SiteMap from '../Components/Generic/SiteMap'
import TermsandCondition from '../Components/Generic/Terms'
import Home from '../Components/Home'
import LinkExpired from '../Components/LinkExpired'
import MyPages from '../Components/MyPages'
import PageCardWithGoogleTag from '../Components/PageCard/PageCardWithGTag'
import PageNotFound from '../Components/PageNotFound'
import PublicPageList from '../Components/PublicPagesList'
import QrCode from '../Components/QrCode/QrCode'
import MySubScribePages from '../Components/Subscribed'
import { RouteConstants } from '../Constants/routeConstants'

export const RoutesConfig = [
  {
    path: RouteConstants.ROOT,
    component: Home,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.LOGIN,
    component: Auth,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.CREATEPAGE,
    component: CreatePageWithGoogleTag,
    isPrivate: true,
    withLayout: false,
  },
  {
    path: RouteConstants.CREATEFIRSTPAGE,
    component: CreatePageWithGoogleTag,
    isPrivate: true,
    withLayout: false,
  },
  {
    path: RouteConstants.AUTHRESET,
    component: ResetPassword,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.INVITEPAGE,
    component: FromInvitePage,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.REGISTER,
    component: AuthWithGoogleTag,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.VERIFYEMAIL,
    component: Auth,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.FORGOTPASSWORD,
    component: ForgotPassword,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.CREATEPAGE,
    component: CreatePageWithGoogleTag,
    isPrivate: true,
    withLayout: false,
  },
  {
    path: RouteConstants.PAGEDETAIL,
    component: PageCardWithGoogleTag,
    isPrivate: false,
  },
  {
    path: RouteConstants.MYPAGES,
    component: MyPages,
    isPrivate: true,
  },
  {
    path: RouteConstants.MYSUBSCRIPAGES,
    component: MySubScribePages,
    isPrivate: true,
  },
  {
    path: RouteConstants.QRCODE,
    component: QrCode,
    isPrivate: false,
  },
  {
    path: RouteConstants.NOTFOUND,
    component: PageNotFound,
    isPrivate: false,
    withLayout: false,
  },
  {
    path: RouteConstants.TERMCONDITION,
    component: TermsandCondition,
    isPrivate: false,
  },
  {
    path: RouteConstants.PRIVACYPOLICY,
    component: PrivacyPolicy,
    isPrivate: false,
  },
  {
    path: RouteConstants.LINKEXPIRED,
    component: LinkExpired,
    isPrivate: false,
  },
  {
    path: RouteConstants.SITEMAP,
    component: SiteMap,
    isPrivate: false,
  },
  {
    path: RouteConstants.PUBLICPAGES + '/*',
    component: PublicPageList,
    isPrivate: false,
    withLayout: false,
  },
]
