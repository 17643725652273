import { type AxiosInstance } from 'axios'

import {
  ADDCONTACT,
  ADDVISITORURL,
  CHANGEPWURL,
  EMAILVALIDATIONURL,
  FORGOTPWURL,
  OTPVERIFYURL,
  RESENDOTPURL,
  SIGNINURL,
  SIGNUPURL,
} from '../../Constants/apiConstants'

export default class UserApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor
  }

  public async loginUser(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(SIGNINURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async registerUser(payload?: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(SIGNUPURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async verifyOTP(payload?: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(OTPVERIFYURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async resendOTP(payload?: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(RESENDOTPURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async forgotpass(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(FORGOTPWURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async resetPass(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(CHANGEPWURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async validateEmail(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(EMAILVALIDATIONURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getUserInfoFromToken(accessToken: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
            },
          })
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public addVisitor = async (payload: any) => {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDVISITORURL, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public addContact = async (payload: any) => {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDCONTACT, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
