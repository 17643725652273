import axios, { type AxiosInstance } from 'axios'

import {
  ADDDONATION,
  ADDEVENTURL,
  ADDTOALBUM,
  ALBUMDELETE,
  ALBUMMEDIADELETE,
  BLOCKUNBLOCKMEMBER,
  CREATEALBUM,
  CREATEFUNDRAISER,
  EVENTDELETE,
  GETALLEVENTS,
  GETALLFUNDRAISER,
  GETEVENT,
  GETPERSONALMESSAGE,
  INVITEUPDATE,
  MEDIADELETE,
  MEDIAUPLOAD,
  MEDIAUPLOADEXTERNAL,
  MYPAGES,
  PAGEDELETE,
  PAGEDETAIL,
  PAGEEDIT,
  PAGEPGETALBUMGALLLERY,
  PAGEPGETGALLLERY,
  PAGEPGETGALLLERYALBUM,
  PAGEPUBLISH,
  RENAMEALBUM,
  REORDERQUESTIONS,
  SEARCHPAGE,
  SENDAUDIOMSG,
  SENDEMAILGENERIC,
  SUBSCRIBEPAGE,
  UNSUBSCRIBEPAGE,
  UPDATEEVENTURL,
  UPDATEFUNDRAISER,
} from '../../Constants/apiConstants'
import { store } from '../../Redux/store'

export default class PageApi {
  private readonly interceptor: AxiosInstance | null
  constructor(interceptor: AxiosInstance | null) {
    this.interceptor = interceptor
  }

  public async getPageDetail(uniqueUrl: string, belongsTo: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(PAGEDETAIL, { unique_url: uniqueUrl, userId: belongsTo })
          .then((r: any) => {
            if (r.data.success) {
              const data = {
                ...r.data.data,
                owner_name: r?.data?.owner_name,
                owner_image: r?.data?.owner_image,
                status: r.data.status,
              }
              resolve(data)
            } else {
              const data = {
                ...r.data,
                status: r.data.status,
                hidden_response: r?.data?.message === "Member doesn't exists" ? r?.data : null,
              }
              resolve(data)
            }
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async updatePageDetail(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(PAGEEDIT, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async uploadMedia(payload: any, pageId: any, postId: any = null) {
    return await new Promise((resolve, reject) => {
      try {
        const headers = { 'Content-Type': 'multipart/form-data' }
        let url = ''
        if (pageId !== null && pageId) {
          url =
            postId !== null && postId
              ? MEDIAUPLOAD?.replace(':pageId', pageId) + '/' + postId
              : MEDIAUPLOAD?.replace(':pageId', pageId)
        } else {
          url = MEDIAUPLOAD?.replace(':pageId', '')
        }

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url,
          headers,
          data: payload,
        }

        axios({
          ...config,
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent
            const percent = Math.floor((loaded * 100) / (total as number))
            if (percent < 100) {
              console.log(`${loaded} bytes of ${total} bytes. ${percent}%`)
            }
          },
        })
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async uploadMediaExternal(payload: any, pageId: any, postId: any = null) {
    return await new Promise((resolve, reject) => {
      try {
        const headers = { 'Content-Type': 'multipart/form-data' }
        let url = ''
        if (pageId !== null && pageId) {
          url =
            postId !== null && postId
              ? MEDIAUPLOADEXTERNAL?.replace(':pageId', pageId) + '/' + postId
              : MEDIAUPLOADEXTERNAL?.replace(':pageId', pageId)
        } else {
          url = MEDIAUPLOADEXTERNAL?.replace(':pageId', '')
        }
        this.interceptor
          ?.put(url, payload, {
            headers,
          })
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pagePublish(pageId: any, status: boolean) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(PAGEPUBLISH, { page_id: pageId, publish: status })
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageGetGalllery(pageId: any, pageNo: number) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(PAGEPGETGALLLERY?.replaceAll(':pageId', pageId)?.replaceAll(':pageno', pageNo?.toString()))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageGetGallleryAlbum(pageId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(PAGEPGETGALLLERYALBUM?.replace(':pageId', pageId))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageGetAlbumGalllery(albumId: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(PAGEPGETALBUMGALLLERY?.replace(':albumId', albumId))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async addToAlbum(mediaId: string, albumId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(ADDTOALBUM?.replaceAll(':albumId', albumId).replaceAll(':itemId', mediaId), {})
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageCreateAlbum(pageId: any, name: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.patch(CREATEALBUM, { page: pageId, name })
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageRenameAlbum(albumid: any, name: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(RENAMEALBUM.replace(':albumId', albumid), { name })
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageAlbumDelete(albumid: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(ALBUMDELETE.replace(':albumId', albumid))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageDelete(pageId: string, userId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(PAGEDELETE.replace(':pageId', pageId).replace(':userId', userId))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async eventDelete(eventId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(EVENTDELETE.replace(':eventId', eventId))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageMediaDelete(mediaid: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(MEDIADELETE.replace(':itemId', mediaid))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageMediaAlbumDelete(mediaid: string, albumId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.delete(ALBUMMEDIADELETE.replace(':itemId', mediaid).replace(':albumId', albumId))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async sendEmailGeneric(payloadData: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(SENDEMAILGENERIC, payloadData)
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageGetEvents(pageId: string, userId: string, pageno: number) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETALLEVENTS?.replace(':pageId', pageId).replace(':userId', userId) + '?page=' + pageno)
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageGetAllFundraiser(pageId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETALLFUNDRAISER?.replace(':pageId', pageId))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async pageGetPersonalMessage(pageId: string) {
    const token = store.getState().user?.value?.token
    return await new Promise((resolve, reject) => {
      try {
        if (token)
          this.interceptor
            ?.get(GETPERSONALMESSAGE?.replace(':pageId', pageId))
            .then((r: any) => {
              resolve(r.data)
            })
            .catch(e => {
              reject(e)
            })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getEvent(eventId: string, userId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(GETEVENT?.replace(':eventId', eventId).replace(':userId', userId))
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async eventInviteUpdate(eventId: string, payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(INVITEUPDATE?.replace(':eventId', eventId), payload)
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async blockUnblockMember(memberId: string, pageId: string, payLoad: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(BLOCKUNBLOCKMEMBER?.replaceAll(':memberId', memberId).replaceAll(':pageId', pageId), payLoad)
          .then((r: any) => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async addEvent(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDEVENTURL, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async updatePageEvent(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(UPDATEEVENTURL, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async getMyPageList(ownerId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.get(MYPAGES?.replace(':ownerId', ownerId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async sendAudioMsg(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(SENDAUDIOMSG, payload)
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async subScribe_Page(payload: any) {
    const token = store.getState().user?.value?.token
    return await new Promise((resolve, reject) => {
      try {
        let URL = ''
        if (payload.token) {
          URL = SUBSCRIBEPAGE?.replaceAll(':pageId', payload.pageId)
            .replaceAll(':subscriberId', payload.userId)
            .replaceAll(':token', payload.token)
        } else {
          URL = SUBSCRIBEPAGE?.replaceAll(':pageId', payload.pageId)
            .replaceAll(':subscriberId', payload.userId)
            .replaceAll(':token', '')
        }
        if (token)
          this.interceptor
            ?.put(URL, { source: payload?.token ? 'invite' : undefined })
            .then((r: any) => {
              resolve(r?.data)
            })
            .catch(e => {
              reject(e)
            })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async unsubScribe_Page(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(UNSUBSCRIBEPAGE?.replaceAll(':pageId', payload.pageId).replaceAll(':subscriberId', payload.userId))
          .then((r: any) => {
            resolve(r?.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async reorderQuestions(payload: any, pageId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.patch(REORDERQUESTIONS?.replace(':pageId', pageId), payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async searchPage(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(SEARCHPAGE, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async addDonation(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(ADDDONATION, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async addFundraiser(payload: any) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.post(CREATEFUNDRAISER, payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async editFundraiser(payload: any, fundraiserId: string) {
    return await new Promise((resolve, reject) => {
      try {
        this.interceptor
          ?.put(UPDATEFUNDRAISER?.replace(':fundraiserId', fundraiserId), payload)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  public async GdriveMediaUpload(data: any) {
    return await new Promise((resolve, reject) => {
      try {
        const id = data.id
        const token = data.token
        const config: any = {
          maxBodyLength: Infinity,
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
        this.interceptor
          ?.get('https://www.googleapis.com/drive/v3/files/' + id + '?alt=media', config)
          .then(r => {
            resolve(r.data)
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }
}
