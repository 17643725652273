import cn from 'classnames'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { useAppSelector } from '../../../Hooks'
import { type albumMetadata, type pageDetailData, type pageGallleryData } from '../../../Redux/slices/pageDetailSlice'

const Album = (props: any) => {
  const { handleRename, albumShortType, setSelectedAlbum, handleDelete, handleAlbumSelect } = props
  const pageGallleryData: pageGallleryData = useAppSelector((state: any) => state.pagedetail.gallleryData)
  const [albumData, setAlbumData] = useState<any>()
  const ref = useRef<HTMLInputElement>(null)
  const [height, setHeight] = useState(0)
  const userState: any = useAppSelector(state => state.user)
  const pageDetailState: pageDetailData = useAppSelector((state: any) => state.pagedetail.data)

  useLayoutEffect(() => {
    if (ref.current !== null) {
      setHeight(ref.current.offsetWidth)
    }
  })
  useEffect(() => {
    const pageAlbumDataUpdate = pageGallleryData?.albumMetadata?.map((album: albumMetadata, index: number) => {
      return {
        ...album,
        isToggled: false,
      }
    })
    setAlbumData(pageAlbumDataUpdate)
  }, [pageGallleryData?.albumMetadata, albumShortType])

  useEffect(() => {
    if (albumShortType !== 'new') {
      const sortedAlbumDataDesc = [...albumData]?.sort(
        (objA: albumMetadata, objB: albumMetadata) =>
          Number(new Date(objB.createdAt)) - Number(new Date(objA.createdAt))
      )
      setAlbumData(sortedAlbumDataDesc)
    }
  }, [albumShortType])

  const toggleUpdate = (album: albumMetadata) => {
    const updatepageAlbumData = albumData?.map((albumItem: albumMetadata, index: number) => {
      if (album._id === albumItem._id) {
        return {
          ...albumItem,
          isToggled: !albumItem.isToggled,
        }
      } else {
        return {
          ...albumItem,
          isToggled: false,
        }
      }
    })
    setAlbumData(updatepageAlbumData)
  }

  return (
    <>
      <div className="album">
        {pageDetailState.belongsTo?._id === userState?.value?.data?._id && !isMobile ? (
          <div className="eachTilesWrapper">
            <div className="eachTiles firstTiles">
              <button onClick={() => handleRename('add')}>
                <i className="fa fa-additem"></i>
                <span>Create Album</span>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        {pageGallleryData?.albumMetadata?.length ? (
          <>
            {albumData?.map((album: albumMetadata, index: number) => {
              return (
                <div key={index} className="eachTilesWrapper">
                  <div
                    className={cn('eachTiles', {
                      NoImg: album.thumbnail === '',
                    })}
                    ref={ref}
                    style={{
                      maxHeight: `${height}px`,
                      minHeight: `${height}px`,
                    }}
                  >
                    {album?.itemCount && album.thumbnail ? (
                      <img
                        onClick={() => {
                          handleAlbumSelect(album)
                        }}
                        src={album.thumbnail}
                        alt={album?.albumName}
                      />
                    ) : (
                      <div
                        className="no-image-component"
                        onClick={() => {
                          handleAlbumSelect(album)
                        }}
                      ></div>
                    )}
                    {pageDetailState.belongsTo?._id === userState?.value?.data?._id && (
                      <span
                        className="metalballs"
                        onClick={() => {
                          toggleUpdate(album)
                        }}
                      >
                        <i className=" fa fa-meatballs"></i>
                      </span>
                    )}
                    {album?.isToggled && (
                      <div className="overlay">
                        <span
                          className="close"
                          onClick={() => {
                            toggleUpdate(album)
                          }}
                        >
                          <i className=" fa fa-close"></i>
                        </span>
                        <div>
                          <span
                            onClick={() => {
                              handleDelete('album')
                              setSelectedAlbum(album)
                            }}
                          >
                            <i className="fa fa-butterflydelete"></i>
                            Delete
                          </span>
                          <span
                            onClick={() => {
                              handleRename('rename')
                              setSelectedAlbum(album)
                            }}
                          >
                            <i className="fa fa-rename"></i>Rename
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="albumDetails">
                    <div className="albumName">{album?.albumName}</div>
                    <div className="count">
                      {album?.itemCount ? album?.itemCount : 'Empty'} item
                      {album?.itemCount > 1 ? 's' : ''}
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <></>
        )}
      </div>
      {pageDetailState.belongsTo?._id === userState?.value?.data?._id ? (
        <button className="CreateMedia createAlbum" onClick={() => handleRename('add')}>
          <i className="fa fa-plus"></i> Create Album
        </button>
      ) : (
        <></>
      )}
    </>
  )
}

export default Album
