import './AddAlbum.scss'

import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'

import { trackEvent } from '../../../Analytics/GA'
import { useAppSelector } from '../../../Hooks'
import { type albumMetadata, type pageGallleryData } from '../../../Redux/slices/pageDetailSlice'
import { addToAlbumThunk } from '../../../Redux/thunks/pageDetailThunk'
import Loader from '../../Loader/Loader'

function AddAlbum(props: any) {
  const pageGallleryData: pageGallleryData = useAppSelector((state: any) => state.pagedetail.gallleryData)
  const userState: any = useAppSelector(state => state.user)
  const [albumData, setAlbumData] = useState<albumMetadata[]>()
  const dispatch = useDispatch()
  useEffect(() => {
    const pageAlbumDataUpdate = pageGallleryData.albumMetadata?.map((album: albumMetadata, index: number) => {
      return {
        ...album,
        isToggled: false,
      }
    })
    setAlbumData(pageAlbumDataUpdate)
  }, [pageGallleryData])

  const addMediaInAlbum = (albumId: string) => {
    // Call Add to Album API
    dispatch(addToAlbumThunk(props.mediadata?._id, albumId, props.pageid))
    trackEvent('Page Interaction', 'Media Added to Album', `${userState?.value?.data?._id}`)
  }

  return (
    <Modal className="GalleryModal" {...props} show={props.show} onHide={props.handleclose} centered>
      {userState.loading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title>Create a new album or add to an old album</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="album">
          <div className="eachTilesWrapper">
            <div className="eachTiles firstTiles">
              <button onClick={() => props.handleRename('add')}>
                <i className="fa fa-additem"></i>
                <span>Create Album</span>
              </button>
            </div>
          </div>
          {pageGallleryData?.gallery?.length && (
            <>
              {albumData?.map((album: albumMetadata, index: number) => {
                return (
                  <div key={index} className="eachTilesWrapper">
                    <div className="eachTiles ">
                      {album?.itemCount && album?.thumbnail ? (
                        <img src={album?.thumbnail} alt={album?.albumName} />
                      ) : (
                        <div className="no-album-image"></div>
                      )}
                      <div className="overlay">
                        <div
                          onClick={() => {
                            addMediaInAlbum(album?._id)
                          }}
                        >
                          <span>
                            <i className="fa fa-additem"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="albumDetails">
                      <div className="albumName">{album?.albumName}</div>
                      <div className="count">
                        {album.itemCount ? album.itemCount : 'Empty'} item{album?.itemCount > 1 ? 's' : ''}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddAlbum
