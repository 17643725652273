import { createSlice } from '@reduxjs/toolkit'

interface UserState {
  value: unknown | null
  loading: boolean
  verification: boolean
  alert: {
    status: boolean
    content: string
    name: string
  }
  email?: string
  initiated: boolean
  addNewVisitorResponse: any
  addConactResponse: any
  loadPercentage: any
  loadTotalItems: any
  loadItemsDone: any
  questionAddDeletePopupFlag: any
  prevPageId: any
  pagePublishFromEdit: any
}
// Define the initial state using that type
const initialState: UserState = {
  value: {},
  loading: false,
  verification: false,
  alert: {
    status: false,
    content: '',
    name: '',
  },
  email: undefined,
  initiated: false,
  addNewVisitorResponse: {},
  addConactResponse: {},
  loadPercentage: 0,
  loadTotalItems: 0,
  loadItemsDone: 0,
  questionAddDeletePopupFlag: null,
  prevPageId: null,
  pagePublishFromEdit: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload
    },
    logout: state => {
      localStorage.removeItem('user_id')
      localStorage.clear()
      // Resetting the root persist

      state.value = {}
      state.loading = false
      state.verification = false
      state.alert = {
        status: false,
        content: '',
        name: '',
      }
      state.email = undefined
      state.initiated = false
      state.addNewVisitorResponse = {}
      state.addConactResponse = {}
      state.loadPercentage = 0
      state.loadTotalItems = 0
      state.loadItemsDone = 0
      state.questionAddDeletePopupFlag = null
      state.prevPageId = null
      state.pagePublishFromEdit = false
      // state.alert = {
      //   status: false,
      //   content: "",
      //   name: ""
      // }
      // state.email = "";
      // state.loading = false;
      // state.verification = false;
      // state.initiated = false
    },
    register: (state, action) => {
      state.value = action.payload
    },
    loading: (state, action) => {
      state.loading = action.payload
    },
    verifyEmail: (state, action) => {
      state.verification = action.payload
    },
    setAlert: (state, action) => {
      state.alert = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setInitiated: (state, action) => {
      state.initiated = action.payload
    },
    setShowStepStatus: (state, action) => {
      state.value = action.payload
    },
    setAddNewVisitorResponse: (state, action) => {
      state.addNewVisitorResponse = action.payload
    },
    setAddConactResponse: (state, action) => {
      state.addConactResponse = action.payload
    },
    setLoadPercentage: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.loadPercentage = action.payload
    },
    setLoadItemsDone: (state, action) => {
      state.loadItemsDone = action.payload
    },
    setAddDeleteQuestionPopupFlag: (state, action) => {
      state.questionAddDeletePopupFlag = action.payload
    },
    setPrevPageId: (state, action) => {
      state.prevPageId = action.payload
    },
    pagePublishFrom: (state, action) => {
      state.pagePublishFromEdit = action.payload
    },
  },
})

export const {
  login,
  logout,
  register,
  loading,
  verifyEmail,
  setAlert: setAuthAlert,
  setEmail,
  setInitiated,
  setShowStepStatus,
  setAddNewVisitorResponse,
  setAddConactResponse,
  setLoadPercentage,
  setLoadItemsDone,
  setAddDeleteQuestionPopupFlag,
  setPrevPageId,
  pagePublishFrom,
} = userSlice.actions
export const selectUser = (state: any) => state.user
export default userSlice.reducer
