import { useState } from 'react'
import { Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom'

import { butterflyBlack, logoNoText } from '../../commonservice'
import Loader from '../Loader/Loader'
import ProgBar from './ProgressBar'

function StepThree(props: any) {
  const navigate = useNavigate()
  const [approval, setApproval] = useState(1)
  const d = new Date()
  const year = d.getFullYear()

  return (
    <>
      <div className="imgHolder d-none d-md-block">
        <div className="contentWrapper stepThreeBg">
          <div className="sideBar">
            <div
              className="logo"
              onClick={() => {
                navigate('/mypages')
              }}
            >
              <img src={logoNoText} alt="Butterfly Logo" />
            </div>
            {window.location.pathname !== '/create' ? (
              <ul className="Back">
                <li
                  onClick={() => {
                    window.history.back()
                  }}
                >
                  <i className="fa fa-arrow-left"></i>
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
          <div className="overlay"></div>
          <div className="title">
            Your Page. <br />
            Your Choice.
          </div>
        </div>
      </div>
      <div className="formHolder">
        {props.propsData[4] && <Loader />}
        <div className="d-xs-block d-md-none m-logo">
          <img src={butterflyBlack} alt="Butterfly logo" />
          <div className="title">A Full Life Deserves a Full Tribute</div>
        </div>
        <ProgBar step={props.propsData[2]} />
        <div className="stepWrapper">
          <div className="steps stepTwo stepThree">
            <h2>Confirm these approval settings or your page.</h2>
            <p>If they’re OK, don’t do anything. They can be updated at any time.</p>
            <Form>
              <div className="privacy">
                <Card>
                  <Card.Body className="d-flex p-0">
                    <div className="lhs">
                      <div className="title">
                        <i className="fa fa-butterflyapproval" aria-hidden="true"></i>
                        <span>Approvals required</span>
                      </div>
                      <p className="subTitle">
                        When turned on, member posts must be approved by you before they appear on this Page.
                      </p>
                    </div>
                    <div className="rhs">
                      <label className="switch switch200">
                        <input
                          onClick={(e: any) => {
                            setApproval(e.target.value === 0 ? 1 : 0)

                            props.handleForm({
                              approval_required: !!e.target.value,
                            })
                          }}
                          type="radio"
                          value={approval}
                          checked={approval === 0}
                        />
                        <span className="slider slider200"></span>
                      </label>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="justify-content-between d-flex">
                <Button className="prev" onClick={props.propsData[1]} disabled={props.propsData[2] === 1}>
                  Previous
                </Button>
                <Button
                  onClick={() => {
                    props.propsData[0]()
                    props.submitForm()
                  }}
                >
                  Add content to your page
                </Button>
              </div>
            </Form>
          </div>
        </div>
        {/* <div className="text-center d-block">
					<p className="CopyRight w-100 text-center mt-5">© 2023 Butterfly.co</p>
				</div> */}
        <div className="position-absolute">
          <p className="CopyRight text-center position-fixed">© {year} Better Place Memories</p>
        </div>
      </div>
    </>
  )
}

export default StepThree
