import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { RouteConstants } from '../Constants/routeConstants'
import { useAppSelector } from '../Hooks'

const PrivateRoute = () => {
  const authData: any = useAppSelector((state: any) => state?.user)
  const { value }: any = authData
  const isLogin = getUserState(value?.data?._id)
  const location = useLocation()
  const redirectToUrl: any = localStorage?.getItem('redirectToUrl')
  const publicPageWithoutLogin: any = localStorage?.getItem('publicPageWithoutLogin')
  function getUserState(authInput: any): any {
    if (authInput) {
      return authInput
    } else {
      if (!localStorage.getItem('accessToken')) {
        const pageSlug: any =
          localStorage.getItem('redirectToUrl') !== null ? localStorage.getItem('redirectToUrl') : ''
        const subscriberEmail: any =
          localStorage.getItem('subscriber_email') !== null ? localStorage.getItem('subscriber_email') : ''
        localStorage.clear()
        if (pageSlug && pageSlug !== '' && subscriberEmail && subscriberEmail !== '') {
          localStorage.setItem('redirectToUrl', pageSlug)
          localStorage.setItem('subscriber_email', subscriberEmail)
        }
        return false
      } else {
        return true
      }
    }
  }

  return isLogin ? (
    <>
      {redirectToUrl && redirectToUrl !== null && redirectToUrl !== '' ? (
        <Navigate to={RouteConstants?.PAGEDETAIL?.replace(':pageslug', redirectToUrl)}></Navigate>
      ) : (
        ''
      )}

      {publicPageWithoutLogin && publicPageWithoutLogin !== null && publicPageWithoutLogin !== '' ? (
        <Navigate to={RouteConstants?.PAGEDETAIL?.replace(':pageslug', publicPageWithoutLogin)}></Navigate>
      ) : (
        ''
      )}

      <Outlet />
    </>
  ) : (
    <Navigate to={RouteConstants.LOGIN} replace state={{ from: location }} />
  )
}

export default PrivateRoute
